import Vue from 'vue';
import Router from 'vue-router';
import Login from '../components/Login'
import Register from '../components/Register'
import RecoverPassword from '../components/RecoverPassword'
import Settings from '../components/Settings'
import Product from '../components/Product'
import BrowseFarmProducts from '../components/BrowseFarmProducts'
import BrowseFarms from '../components/BrowseFarms'
import Inbox from '../components/Inbox'
import Chat from '../components/Chat'
import Orders from '../components/Orders'
import MiniWebsite from '../components/MiniWebsite'
import PageNotFound from '../components/PageNotFound'
import RedundantNavigation from '../components/RedundantNavigation'
import PaymentMethods from '../components/PaymentMethods'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        //---------- START all ------------//
        { 
            path: "*", 
            name: 'PageNotFound',
            component: PageNotFound 
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/recover',
            name: 'RecoverPassword',
            component: RecoverPassword
        },
        {
            path: '',
            component: BrowseFarms,
        },
        {
            path: '/',
            component: BrowseFarms,
        },
        {
            path: '/browsefarms',
            component: BrowseFarms,
        },
        {
            path: '/user/:userID/products',
            component: BrowseFarmProducts,
            //name: 'MiniWebsite',
        },
        {
            path: '/user/:userID/items/:itemId',
            component: Product,
            name: 'Product',
        },
        {
            path: '/user/:userID/miniWebsite',
            component: MiniWebsite,
            name: 'MiniWebsite',
        },
        {
            path: '/loading',
            component: RedundantNavigation,
            name: 'RedundantNavigation',
        },
        {
            path: '/paymentMethods',
            component: PaymentMethods,
            name: 'PaymentMethods',
        },
        //---------- END all ------------//
        //---------- START all authenticated ------------//
        {
            path: '/settings',
            name: 'Settings',
            component: Settings
        },
        {
            path: '/inbox',
            component: Inbox,
            name: 'Inbox',
        },
        {
            path: '/inbox/:userID',
            component: Chat,
            name: 'Chat',
        },
        {
            path: '/orders',
            component: Orders,
            name: 'Orders',
        },
        //---------- END all authenticated ------------//
        //---------- END authenticated sellers)  

    ]
});

export default router