<template>
  <div class="searchSection">
    <meta name="viewport" 
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
    <!--
     <SearchBar v-model="searchInputValue" @selectSuggestion="changeSIV" @submit="handleFormSubmit"
     :autocompletedResults="autocompletedResults"
     :autocompletedSuggestions="autocompletedSuggestions"/>
     !-->
    <div v-if="searchState.wasSearched" class="sui-layout-body">
      <div class="sui-layout-body__inner">
        <!-- 
        <SearchSort v-model="sortBy" />
        <SearchPagingInfo :search-state="searchState" />
        !-->
      </div>
      <div class="sui-layout-main-body">
        
        <div class="searchmap-section">
          <SearchMap
            :results="searchState.results"
            :linkFilter="linkFilter"
            @locationFilter="setLocationFilter"
          />
        </div>
        <div class="searchresults-section">
          <div style="height: 10px"></div>
          <!--
          <div class="tabs">
            <input type="radio" id="about" value="about" v-model="picked" />
            <label class="tab" for="products">Farms</label>

            <input type="radio" id="about" value="about" v-model="picked" />
            <label class="tab" for="about">Listings</label>
          </div>
          !-->
          <div class="searchfilters-section">
            <SearchFilters
              @productTypeFilter="setProductTypeFilter"
              :available_item_types="available_item_types"
              :available_services="available_services"
              :available_event_types="available_event_types"
            />
          </div>
          <div>
            <SearchResults
              :results="searchState.results"
              :searchInputValue="sivToSearchResultProducts"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchDriver } from "@elastic/search-ui";
import SearchResults from "./SearchResults";
import SearchMap from "./SearchMap";
import SearchFilters from "./SearchFilters";
//import SearchBar from "./SearchBar"

import { mapGetters } from "vuex";

import "regenerator-runtime/runtime";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

export default {
  components: {
    SearchResults,
    SearchMap,
    SearchFilters,
    //SearchBar,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    thereAreResults() {
      return this.searchState.totalResults && this.searchState.totalResults > 0;
    },
  },
  data() {
    return {
      searchInputValue: "",
      sivToSearchResultProducts: "",
      searchState: {},
      resultsPerPage: 1000,
      sortBy: "relevance",
      driver: null,
      driverAvailable: false,
      lastEventWasRouterGo: false,
      arrMoves: [],
      linkFilter: null,
      lat_lon_str: null,
      autocompletedSuggestions: null,
      autocompletedResults: null,
      available_item_types: [],
      available_services: [],
      available_event_types: [],
    };
  },

  watch: {
    async driverAvailable() {
      var vm = this;

      this.driver.subscribeToStateChanges((state) => {
        this.searchState = state;
        ////console.log(state.filters);
        if (state.filters.length > 0) {
          this.$emit("lastFilteredUrl", window.location.href);
        }
        ////console.log(state.filters);
        ////console.log(state.autocompletedSuggestions);
        ////console.log(state.autocompletedResults);
        if (state.autocompletedResults.length > 3) {
          if (vm.searchInputValue !== "") {
            this.autocompletedResults = state.autocompletedResults.slice(0, 3);
          } else {
            this.autocompletedResults = null;
          }
        } else {
          this.autocompletedResults = state.autocompletedResults;
        }

        var suggestions = [];
        if (state.autocompletedSuggestions.documents) {
          for (var document of state.autocompletedSuggestions.documents) {
            ////console.log(document);
            if (document.suggestion.includes("http")) {
              //do nothing
              ////console.log("contains http");
            } else {
              suggestions.push(document);
              if (suggestions.length === 5) {
                break;
              }
            }
          }

          this.autocompletedSuggestions = suggestions;
        }

        ////console.log(this.searchState);
      });
      await this.restoreUIFromURL();
    },
    lat_lon_str() {
      ////console.log(this.lat_lon_str);
      if (
        this.lat_lon_str !== null &&
        typeof this.lat_lon_str !== "undefined"
      ) {
        ////console.log(this.linkFilter);
        this.linkFilter = this.lat_lon_str;
      }
    },
    sortBy(newSortBy) {
      this.driver.setSort(newSortBy, "asc");
    },
    async searchInputValue() {
      ////console.log(this.searchInputValue);
      /*
      if (this.searchInputValue === "" || this.searchInputValue === null) {
        this.autocompletedSuggestions = null;
        this.autocompletedResults = null;
      }
      */
      await this.driver.setSearchTerm(this.searchInputValue, {
        shouldClearFilters: false,
        autocompleteSuggestions: true,
        autocompleteResults: true,

        refresh: false,
        //debounce: 500,
      });
      ////console.log("SIV watcher")
    },
  },
  methods: {
    changeSIV(payload) {
      this.searchInputValue = payload;
      //console.log("changeSIV");
      this.handleFormSubmit();
    },
    setProductTypeFilter(payload) {
      if (payload.operation === "remove") {
        this.driver.removeFilter(
          payload.filterField,
          payload.productType,
          payload.filterType
        );
      } else {
        this.driver.addFilter(
          payload.filterField,
          payload.productType,
          payload.filterType
        );
      }
    },
    setLocationFilter(payload) {
      this.driver.setFilter("lat_lon_str", payload);
    },
    async handleFormSubmit() {
      this.$router.replace("/browsefarms/");

      await this.driver.setSearchTerm(this.searchInputValue, {
        shouldClearFilters: false,
        urlPushDebounceLength: 1000000000,
        //autocompleteSuggestions: true,
        //autocompleteResults: true,
        //refresh: false,
      });
      this.sivToSearchResultProducts = this.searchInputValue;
    },
    async initDriver() {
      const connector = await new AppSearchAPIConnector({
        endpointBase:
          "https://9418f3a3b99042b3991649e2c5fec3df.ent-search.us-central1.gcp.cloud.es.io",
        searchKey: "search-zyftvpix7rftzf7ocirgacdk",
        engineName: "profiles",
        hostIdentifier: "host-fuckyou",
      });

      const config = {
        debug: false,
        apiConnector: connector,
        urlPushDebounceLength: 1000000000, //This prevents searchable urls as techincally the query needs 1666  hours ((1b ms)) to be changed
        //trackUrlState: false,

        initialState: {
          resultsPerPage: this.resultsPerPage,
          filters: [
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //{ field: "user_id", values: [this.userID], type: "all" },
            //{ field: "category", values: ["event"], type: "none" },
            //{ field: "availability", values: ["deleted"], type: "none" },
          ],
        },
      };
      this.driver = await new SearchDriver(config);
      this.driverAvailable = true;
    },
    async restoreUIFromURL() {
      const {
        searchTerm,
        sortField,
        resultsPerPage,
        filters,
      } = this.driver.getState();

      //console.log(filters);

      if (this.user.searchInputValue !== "") {
        this.sivToSearchResultProducts = this.user.searchInputValue;
        this.searchInputValue = this.user.searchInputValue;
        this.user.searchInputValue = "";
      } else {
        //My addition
        this.sivToSearchResultProducts = searchTerm;
        this.searchInputValue = searchTerm;
      }

      // restoring UI from url query
      this.sortBy = sortField;
      this.resultsPerPage = resultsPerPage;
      await filters.forEach((filter) => {
        this[filter.field] = filter.values;
        ////console.log(this[filter.field]);
        ////console.log(filter.field);
      });
      //console.log(this['available_item_types']);
    },
  },

  beforeMount() {
    this.initDriver();

    //this.addEventListener();
  },
};
</script> 

<style scoped>

.searchSection {
  z-index: 9999;
  /* height: 100%; */
  margin-top: 0px;
  margin-bottom: -5px;
  padding: 0px;
  padding-top: 40px;
  /* overflow-y: hidden; */
}
.sui-layout-body {
  height: 100%;
}
.sui-layout-body__inner {
  height: 100%;
}

.searchresults-section {
  background-color: rgb(252, 252, 252);
  overflow-x: hidden;
  /* padding-top: 20px; */
}

.searchfilters-section {
  padding: 0px;
  padding-top: 5px;
  background-color: rgb(252, 252, 252);
  box-shadow: 0 3px 3px rgba(75, 75, 75, 0.116);
}

@media (min-width: 850px) {
  .searchmap-section {
    float: right;
    width: 40%;
    height: 95.4vh;
  }
  .searchresults-section {
    float: left;
    width: 60%;
    overflow-y: auto;
    height: 95.9vh;
    margin-top: -5px;
  }
  .searchfilters-section{
    box-shadow: 0 3px 3px rgba(75, 75, 75, 0.116);
    position: sticky;
    top: 10px;
    z-index: 10;
    background-color: white;
  }
}

@media (max-width: 850px) {
  .searchmap-section {
    height: 185px;
  }
}

.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  /* box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15); */
  /* padding: 0.4em; */
  border-radius: 99px;
  /* margin-right: 2%;
  margin-left: 2%; */
  width: 100%;
  margin-bottom: 10px;
}

.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8em;
  min-height: fit-content;
  width: 100%;
  font-size: 1em;
  font-weight: normal;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  text-align: center;
  margin-top: 0.5em;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid #dedede;
  font-family: Google Sans;
  color: rgba(0, 0, 0, 0.664);
  padding: 4px;
}
.tab-no-left-margin {
  margin-left: 0px;
}
.tab-no-right-margin {
  margin-right: 0px;
}

input[type="radio"]:checked + label {
  box-shadow: inset 0 3px#42b983e5;
}
</style>