<template>
  <div class="overall-container">
    <div style="height: 5px"></div>
    <div class="tabs">
      <input type="radio" id="products" value="products" v-model="picked" />
      <label class="tab" for="products">Products</label>

      <input type="radio" id="contact" value="contact" v-model="picked" />
      <label class="tab" for="contact">Info</label>

      <div v-if="user.data" class="tabs">
        <button
          @click="router('/inbox', false)"
          v-if="userID === user.data.uid"
          class="tab"
          for="chat"
        >
          Messages
        </button>

        <button
          @click="goToChat()"
          v-if="userID != user.data.uid && loadedOtherProfile"
          class="tab"
          for="chat"
        >
          Message
        </button>
      </div>

      <div v-else class="tabs">
        <button
          @click="goToChat()"
          v-if="loadedOtherProfile"
          class="tab"
          for="chat"
        >
          Message
        </button>
      </div>
    </div>

    <div class="sellerCreateNewItemComponent" v-if="user.data">
      <SellerCreateNewItemComponent v-if="userID === user.data.uid" />
    </div>
    <div class="searchBar">
      <SearchBar v-model="searchInputValue" @submit="handleFormSubmit" />
    </div>
    <div v-if="searchState.wasSearched">
      <div v-if="user.data">
        <SearchFilters
          v-if="user.data.uid === userID"
          @setAvailabilityFilter="setAvailabilityFilter"
        />
      </div>
      <SearchResults :results="searchState.results" />
    </div>
  </div>
</template>


<script>
import { SearchDriver } from "@elastic/search-ui";
//import config from "./searchConfig";
import SearchResults from "./SearchResults";
//import SearchHeader from "./SearchHeader";
import SearchBar from "./SearchBar";
import SearchPagingInfo from "./SearchPagingInfo";
import SearchSort from "./SearchSort";
import SearchFilters from "./SearchFilters";
import SellerCreateNewItemComponent from "./SellerCreateNewItemComponent";

import { mapGetters } from "vuex";

import "regenerator-runtime/runtime";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import { db } from "../../../main.js";

//const driver = new SearchDriver(config);

export default {
  components: {
    SearchResults,
    //SearchHeader,
    SearchPagingInfo,
    SearchSort,
    SearchFilters,
    SearchBar,
    SellerCreateNewItemComponent,
  },
  data() {
    return {
      searchInputValue: "",
      searchState: {},
      resultsPerPage: 1000,
      sortBy: "relevance",
      driver: null,
      driverAvailable: false,
      userID: this.$route.params.userID,
      picked: "products",
      loadedOtherProfile: false,

      addedEventListener: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    thereAreResults() {
      return this.searchState.totalResults && this.searchState.totalResults > 0;
    },
    maxDate() {
      var currentDate = new Date();
      var maxYear = currentDate.getFullYear() + 1;
      currentDate.setFullYear(maxYear);
      return currentDate;
    },
  },
  watch: {
    sortBy(newSortBy) {
      var ascOrDec;
      if (newSortBy !== "") {
        if (newSortBy.substring(0, 3) === "asc") {
          ascOrDec = "asc";
        } else {
          ascOrDec = "desc";
        }
        newSortBy = newSortBy.substring(4);
      }

      this.driver.setSort(newSortBy, ascOrDec);
    },
    driverAvailable() {
      this.restoreUIFromURL();
      this.setInitFilters();
    },
    picked() {
      //console.log(this.$router.currentRoute.path);
      if (this.picked !== "products") {
        var path = "/user/" + this.userID + "/miniWebsite#" + this.picked;
        this.$router.replace({ path: path });
      }
    },
  },
  methods: {
    router(path, replace) {
      this.$router.push({ path: path });
    },
    goToChat() {
      this.$router.push({ path: `/inbox/${this.userID}` });
    },
    goToCreate() {
      this.$router.push({ path: "/create" });
    },
    getProfile(userID) {
      var docRef = db.collection("profile").doc(userID);
      var vm = this;
      docRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            vm.otherProfile = await doc.data();
            vm.loadedOtherProfile = true;
          } else {
            //console.log("No such document!");
          }
        })
        .catch(function (error) {
          //console.log("Error getting document:", error);
        });
    },
    setAvailabilityFilter(payload) {
      //console.log("setavailability triggered");
      //console.log(payload.operaton);
      if (payload.operation === "add") {
        //console.log('if (payload.operaton === "add")')
        this.driver.setFilter(
          payload.filterField,
          payload.filterVal,
          payload.filterType
        );
      } else {
        this.driver.removeFilter(
          payload.filterField,
          payload.filterVal,
          payload.filterType
        );
      }
    },
    async handleFormSubmit() {
      ////console.log("handleformsubmit");
      await this.driver.setSearchTerm(this.searchInputValue, {
        shouldClearFilters: false,
        debounce: 10,
      });
    },
    async initDriver() {
      const connector = await new AppSearchAPIConnector({
        endpointBase:
          "https://9418f3a3b99042b3991649e2c5fec3df.ent-search.us-central1.gcp.cloud.es.io",
        searchKey: "search-zyftvpix7rftzf7ocirgacdk",
        engineName: "products",
        hostIdentifier: "host-loveyou",
      });

      const config = {
        debug: false,
        apiConnector: connector,
        urlPushDebounceLength: 100000000,

        initialState: {
          resultsPerPage: this.resultsPerPage,
          filters: [
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //Adding filters here could make searchable urls easier to deal with because having the filters here makes it so the starting url includes all of the default filters.
            //{ field: "user_id", values: [this.userID], type: "all" },
            //{ field: "category", values: ["event"], type: "none" },
            //{ field: "availability", values: ["deleted"], type: "none" },
          ],
        },
      };

      this.driver = await new SearchDriver(config);
      this.driverAvailable = true;
      //this.setInitFilters();
    },

    setInitFilters() {
      this.driver.addFilter("user_id", this.userID, "all");
      this.driver.addFilter("category", "event", "none");
      this.driver.addFilter("availability", "deleted", "none");
    },
    restoreUIFromURL() {
      const {
        searchTerm,
        sortField,
        resultsPerPage,
        filters,
      } = this.driver.getState();

      // restoring UI from url query
      this.searchInputValue = searchTerm;
      this.sortBy = sortField;
      this.resultsPerPage = resultsPerPage;
      filters.forEach((filter) => {
        this[filter.field] = filter.values;
      });

      //this.driver.reset();

      this.driver.subscribeToStateChanges(async (state) => {
        this.searchState = state;
        //console.log(state.filters);
      });
    },
  },

  beforeMount() {
    this.initDriver();
    this.getProfile(this.userID);
    //var vm = this;
  },
};
</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.overall-container {
  max-height: 100vh;
  margin-top: 10px;
}
.tabs {
  display: flex;
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  /* box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15); */
  /* padding: 0.4em; */
  border-radius: 99px;
  /* margin-right: 2%;
  margin-left: 2%; */
  width: 100%;
  margin-bottom: 10px;
}

.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8em;
  width: 100%;
  font-size: 1em;
  font-weight: normal;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  text-align: center;
  min-width: fit-content;
  margin-top: 0.5em;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid #dedede;
  font-family: Google Sans;
  color: rgba(0, 0, 0, 0.664);
  padding: 4px;
}

input[type="radio"]:checked + label {
  /* color: #185ee0; */
  box-shadow: inset 0 3px#42b983e5;
}

/* Set the size of the div element that contains the map */
#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
.buy-button {
  float: right;
  width: 100px;
  background-color: #42b983;
  font-family: Quantico;
  padding: 0px;
  border: none;

  margin: 3px 3px;

  font-size: 10px;
  height: 20px;
  line-height: 10px;

  text-align: center;
  border-radius: 5px;

  color: #ffffff;
  border-width: 2px;
}

.sellerCreateNewItemComponent {
  margin-bottom: 1px;
}
</style>