<template>
  <div v-if="availability !== 'deleted'">
    <h1 @click="disableAlert" class="alert" v-bind:class="{'alert-success': alert.type == 'success', 'alert-error': alert.type == 'error'}"> {{alert.msg}}" </h1>
    <div class="col-8 container card" v-if="item && loaded">
      <div class="spacer"></div>
      <div class="row overall-row">
        <div class="col non-images-col">
          <div class="row title-col">
            <div class="title">
              {{ item.name }}
            </div>
            <div class="spacer"></div>
            <div class="row">
              <div class="col title-col">
                <div class="sub-title-1" v-if="item.description">
                  {{ item.description }}
                </div>
              </div>
              <div class="badge-col">
                <div v-if="item.tags">
                  <div v-if="item.tags.length > 0">
                    <button
                      v-for="(tag, index) in item.tags"
                      :key="index"
                      disabled
                      style="border-color: #42b983"
                      class="btn"
                    >
                      {{ tag }}
                    </button>
                  </div>
                </div>

                <div v-else>
                  <button
                    disabled
                    style="border-color: #42b983; visibility: hidden"
                    class="btn"
                  >
                    Placeholder 1
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="row">
            <div class="row">
              <div class="title-col">
                <div class="price">${{ item.price }}/unit</div>
              </div>
            </div>
            <div class="spacer"></div>

            <!-- new quantity selector start -->
            <div class="quantity-container">
              <div class="item-quantity">
                <div class="number-input">
                  <button
                    @click="changeNewQuantity('dec', $event)"
                    class="plus-minus"
                  >
                    <i class="fas fa-minus"></i>
                  </button>

                  <div v-if="user.cart[item.userId]" class="">
                    <div class="">
                      <input
                        v-if="user.cart[item.userId][itemId]"
                        type="number"
                        min="0"
                        oninput="validity.valid||(value='')"
                        v-model="newQuantity"
                        class="quantity edit-quantity"
                        placeholder="Select"
                      />
                      <input
                        v-else
                        type="number"
                        min="0"
                        oninput="validity.valid||(value='')"
                        v-model="newQuantity"
                        class=""
                        placeholder="Select"
                      />
                    </div>
                  </div>

                  <input
                    v-else
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='')"
                    v-model="newQuantity"
                    class=""
                  />
                  <button
                    @click="changeNewQuantity('inc', $event)"
                    class="plus-minus"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="button-col">
              <button v-if="!newQuantity" class="add-to-cart">
                Add to cart
              </button>
              <button v-else class="add-to-cart" @click="addToCart(item)">
                Add {{ newQuantity }} to cart ${{ newQuantity * item.price }}
              </button>
            </div>
          </div>
        </div>
        <div class="col images-col">
            
            <div
              v-if="selectedImg"
              class="image-row"
              :style="{
                'background-image': 'url(' + selectedImg + ')',
              }"
            ></div>

            <div class="lil-image-row">
              <div v-if="item.images.length > 0">
                <img
                  @click="changeSelectedImg(0)"
                  class="lil-image"
                  :src="item.images[0]"
                />
              </div>

              <div v-if="item.images.length > 1">
                <img
                  v-for="(image, index) in item.images.slice(
                    1,
                    item.images.length - 1
                  )"
                  :key="index"
                  @click="changeSelectedImg(index + 1)"
                  class="lil-image lil-image-with-margin"
                  :src="item.images[index + 1]"
                />
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../main.js";
import store from "../store";

export default {
  components: {},
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    newQuantity() {
      if (this.newQuantity === 0) {
        this.newQuantity = null;
      }
    },
  },
  data() {
    return {
      itemId: this.$route.params.itemId,
      userID: this.$route.params.userID,
      item: {},
      imagePreviewIndex: 0,
      loaded: false,
      newQuantity: null,
      selectedImg: null,
      availability: null,
      alert: {
        type: null,
        msg: null
      },
    };
  },

  methods: {
    disableAlert() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    },
    changeSelectedImg(index) {
      this.selectedImg = this.item.images[index];
    },
    changeNewQuantity(operation, event) {
      console.log(event.target);
      if (operation === "inc") {
        if (this.newQuantity === null) {
          this.newQuantity = 1;
        } else {
          this.newQuantity += 1;
        }
      } else {
        if (this.newQuantity <= 0) {
          this.newQuantity = 0;
        } else {
          this.newQuantity -= 1;
        }
      }
    },
    removeItem(item) {
      store.dispatch("removeItem", item);
    },
    addToCart(item) {
      this.$data.newQuantity = parseInt(this.$data.newQuantity, 10);
      if (this.$data.newQuantity === 0) {
        this.removeItem(item);
        this.$data.newQuantity = null;
        return -1;
      }

      if (
        this.$data.newQuantity > 0 &&
        Number.isInteger(this.$data.newQuantity)
      ) {
        item.quantity = this.$data.newQuantity;
        this.$data.newQuantity = null;
        store.dispatch("addItem", item);
        this.$set(this.alert, 'type', null);
        this.$set(this.alert, 'msg', null);
      } else {
        this.$set(this.alert, 'type', "error");
        this.$set(this.alert, 'msg', "Please select a valid quantity.");
      }
    },

    getItem() {
      var vm = this;
      var docRef = db
        .collection("profile")
        .doc(this.userID)
        .collection("items")
        .doc(this.itemId);

      docRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            vm.item = doc.data();

            vm.availability = vm.item.availability;
            if (vm.item.images) {
              if (vm.item.images.length > 0) {
                vm.selectedImg = await vm.item.images[0];
                console.log(vm.selectedImg);
                console.log(vm.item);
              }
            }
            vm.loaded = true;
          } else {
            vm.$set(vm.alert, 'type', "error");
            vm.$set(vm.alert, 'msg', "This item does not exist");
          }
        })
        .catch( (error) => {
            vm.$set(vm.alert, 'type', "error");
            vm.$set(vm.alert, 'msg', "This item does not exist. Error: " + error);
        });
    },
  },
  mounted() {
    this.getItem();
  },
};
</script>


<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/5.4.0/css/font-awesome.min.css"); 
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

 .alert {
	margin: 5px 0px;
	padding: 8px;
	border-radius: 5px;

	font-family: 'Open Sans', sans-serif;
	font-size: 1.1rem;
	font-weight: 300;
	letter-spacing: 1px;

  top: 20px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
	padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: 'FontAwesome';
  float: left;
  padding: 10px;
    &:hover {
      cursor: pointer;
    }
}

.alert-error {
  color: #D8000C;
  background-color: #ffe6e6;
  border: 1px solid darken(#FFBABA, 15%);
  display: block;
}
.alert-error:before {
  content: '\f071';
  font-family: 'FontAwesome';
  padding-right: 3px;
}

.alert-success {
  color: #4F8A10;
  background-color: #DFF2BF;
  border: 1px solid darken(#DFF2BF, 15%);
  display: block;
}
.alert-success:before {
  content: '\f058';
	font-family: 'FontAwesome';
}

.row {
  margin-left: 0px;
}
.search-result {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  align-items: center;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-result__heading {
  text-align: center;
  margin: 0;
}

.search-result__body {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

.search-result__data {
  padding-top: 50px;
}

.search-result__flavor {
  margin: 0;
}

.search-result__image {
  flex: none;
  width: 256px;
  height: 387px;
}

/* browseFilter1Component.vue */
.btn {
  /* box-sizing: border-box; */
  /* display: inline; */
  /* position: relative; */
  color: rgb(52, 52, 52);
  font-family: Quantico;
  padding: 0px 1px 0px 1px;
  border-radius: 3px;
  border-color: #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  min-width: 90px;
  margin: 3px 3px;
  /* Hover */
  /* Pressed */
  font-size: 10px;
  font-weight: normal;
  line-height: 18px;
  opacity: 1;
}

/* end */


.container {
  margin: auto;
  padding: 5px;
  max-width: 450px;
  min-width: 450px;
  height: fit-content;
}
.overall-row {
}
.non-images-col {
  /* margin-right: -15px; */
  width: calc(275px);
}
.title {
  font-size: 1.5em;
  color: #132536;
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
}
.title-col {
  width: 100%;
  padding: 0px;
}
.spacer {
  height: 8px;
}
.images-col {
  /*height: 100%; */
  /*
  border-style: solid;
  border-width: 2px;
  */
  /* margin-left: -15px; */
}
.image-row {
  width: 175px;
  height: 70%;

  float: left;
  border-style: solid;
  border-width: 0.1em;
  border-color: #b5b5b5;
  border-radius: 2px;
  background-size: cover;
}
.lil-image-row {
  width: 175px;
  height: 30%;
  padding-top: 3%;
  float: left;
  background-size: cover;
  margin-right: 0%;
}
.lil-image {
  width: 31.33%;
  min-height: 70px;
  max-height: 70px;
  background-size: cover;
  border-style: solid;
  border-width: 0.1em;
  border-color: #b5b5b5;
  border-radius: 2px;
  float: left;
}

.lil-image-with-margin {
  margin-left: 3%;
  float: left;
}

.sub-title-col {
  width: 55%;
  float: left;
}
.sub-title-1 {
  font-size: 0.9em;
  color: #3e4348;
  float: left;
}
.badge-col {
  width: 45%;
  float: right;
  min-height: 80px;
  max-height: 80px;
}
.badge-1 {
  font-size: 10px;
  font-weight: 300;
  margin-bottom: 0.5em;
  padding-top: 0.2em;
  padding-right: 0.2em;
  padding-left: 0.2em;
  display: block;
  color: gray;
  background-color: white;
  border-style: solid;
  border-color: yellow;
  border-width: 2px;
  text-align: center;
}
.price {
  font-size: 16px;
  color: #000000;
  float: left;
}
.button-col {
  width: 100%;
  float: left;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-left: 0.1em;
}
.add-to-cart {
  float: left;
  width: 100%;
  font-size: 1em;
  background-color: #42b983;
  border-radius: 0.4em;
  border-width: 0.1em;
  border-color: #42b983;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  padding: 0em;
  height: 25px;
}
.see-more {
  float: left;
  width: 100%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 255);
  border-radius: 0.4em;
  border-width: 0.1em;
  border-color: #42b983;
  color: #42b983;
  font-weight: 700;
  padding: 0.1em;
  margin-top: 0.4em;
  height: 25px;
}

/*  quantity selector start */
.quantity-container {
  font-size: 14px;
  height: 50px;
  width: 100%;
  padding: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.item-quantity {
  width: 90%;
  float: left;
  margin: 5%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  /* border: 1px solid #42b983; */
  border: none;
  display: inline-flex;
  border-radius: 5px;
  height: 25px;
  background-color: white;
}
.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 25px;
  cursor: pointer;
  position: relative;
}
.plus-minus {
  color: #42b983;
  font-size: 15px;
}
.number-input input[type="number"] {
  width: 140px;
  padding: 10px;
  border: solid #ddd;
  border-width: 0 2px;
  font-size: 15px;
  height: 25px;
  font-weight: 400;
  text-align: center;
}
/*  quantity selector end */
</style>
