<template>
  <div class="form-container">
    <div class="form-card">
      <div class="form-header">
                  <div @click="disableAlert">{{alert.msg}}</div>
        <div class="title">Reset Password</div>
        <div class="tagline">Enter your UrbanGrow account email</div>
      </div>


      <form action="#" @submit.prevent="submit">
        <div class="form-group row">
          <input class="form-input" 
              placeholder="Email"
              id="email" 
              type="email"
              name="email"
              value
              required
              autofocus
              v-model="form.email">
        </div>


      <div class="guest">Follow instructions via the link sent to your email</div>
      <div class="login-bar">
        <button type="submit" class="next">Submit</button>
      </div>
      </form>
    </div>
  </div>

</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      alert: {
        type: null,
        msg: null,
      },
    };
  },
  components: {
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    },
    submit() {
      var vm = this;
      var actionCodeSettings = {
        url: "http://urbangrow.io/login",
      };

      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email, actionCodeSettings)
        .then(() => {
          vm.$set(vm.alert, "type", "success");
          vm.$set(
            vm.alert,
            "msg",
              "Check your registered email to reset the password!"
          );
          this.form = {
            email: "",
          };
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
              error
          );
        });
    },
  },
  mounted() {
      let jquery = document.createElement('script')
      jquery.setAttribute('src', 'https://code.jquery.com/jquery-2.1.1.min.js')
      document.head.appendChild(jquery)

      let materialize = document.createElement('script')
      materialize.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.5/js/materialize.min.js')
      document.head.appendChild(materialize)

    },
};
</script>

<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/5.4.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap');


/* start of form style */
.form-container {
  display: flex;
  height: 100vh;
  font-family: Google Sans;
  user-select: none;
}
.form-card {
  background: #fff;
  width: 412px;
  height: auto;
  padding: 48px 36px;
  border-radius: 8px;
  margin: auto;
  margin-top: 60px;
}
.form-header {
  text-align: center;
  font-weight: 400;
}
.title {
  padding: 15px 0;
  font-size: 24px;
}
.tagline {
  font-size: 16px;
  padding-bottom: 18px;
}
.form-label {
  display: block;
  position: absolute;
  padding: 0 5px;
  width: auto;
  color: #5f6368;
  background: #fff;
  transition: all 150ms ease;
  transform: translate(12px, -37px);
  cursor: text;
}
.form-input {
  padding: 16px;
  margin: auto;
  margin-top: 20px;
  font-size: 17px;
  background: #fff;
  width: calc(100% - 30px);
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.form-input:focus {
  outline: none;
  /* Compensate increased border with padding */
  padding: 15px;
  border: 2px solid #42b983e5;
}
.form-input:not(:placeholder-shown) + .form-label, .form-input:focus + .form-label {
  transform: translate(8px, -62px);
  font-size: 13px;
}
.form-input:focus + .form-label {
  color: #42b983e5;
}
.links {
  color: #42b983e5;
  font-size: 14px;
  padding-top: 10px;
  cursor: pointer;
  font-weight: 500;
}
.guest {
  margin-top: 32px;
  font-size: 14px;
  color: #5f6368;
}
.login-bar {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}
.next {
  width: 100%;
  margin-left: auto;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  background: #42b983e5;
  font-weight: 500;
  font-size: 16px;
}
.next:hover {
  background: #42b983e5;
}
@media only screen and (max-width: 600px) {
  .card {
    border: none;
  }
}


/*  end of form style */

</style>