<template>
  <!-- https://codepen.io/OGOGOG/pen/YzGdreM -->
  <div class="card">
    <div class="accordion">
      <div
@click="disableAlert"
            v-if="alert.msg"
            class="alert"
            v-bind:class="{
              'alert-success': alert.type == 'success',
              'alert-error': alert.type == 'error',
            }"

          >
            {{ alert.msg }}"
          </div>
      <input  type="checkbox" name="collapse" id="handle1" />
      <h2 class="handle" >
        <label @click='showForm = !showForm' class="title" for="handle1" > Create New Item Listing</label>
        <!-- <label @click='showForm = !showForm' class="title" for="handle1" v-if="user.seller_connected_account_id"> Create New Item Listing</label>
        <label class="title" for="handle1" v-else> Connect Stripe To Add Products</label> -->
      </h2>
      <div class="content" v-if="showForm === true">
        <div style="height: 100%" class="card-body">

          


          <div class="form-group">
            <br />
            <div class="upload-wrapper img-wrap">
              <img
                v-if="item.images[0]"
                :src="item.images[item.images.length - 1]"
                class="upload-image"
                alt=""
              />
              <img  v-if="item.images.length < 4" :src="imageIcon" class="else-upload-image" alt=""/>
              <input
                type="file"
                v-if="item.images.length < 4"
                @change="uploadImage"
                id="file-input"
                class="file-input__input"
                name="file-input"
                ref="imageToUpload"
                accept="image/*"
              />

              <!-- <label class="file-input__label" for="file-input">
                  <span class="label-plus">+</span>
                </label> -->
            </div>
          </div>

          <div class="form-group preview-images-form-group">
            <div class="row preview-images-row">
              <div
                v-for="(image, index) in item.images"
                :key="image"
                class="preview-images-margins"
              >
                <div class="img-wrap preview-images-wrapper">
                  <img :src="image" class="preview-images" />
                  <span
                    class="delete-img"
                    style="z-index: 2"
                    @click="deleteImage(image, index)"
                  >
                    <svg
                      class="x-icon"
                      viewBox="0 0 512 512"
                      width="13pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
                        fill="#f44336"
                      />
                      <path
                        d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0"
                        fill="#fafafa"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <p class="card-subtitle">Item Name</p>
            <input
              type="text"
              v-model="item.name"
              placeholder="Item Name"
              class="form-control form-control-short"
            />
          </div>
          <div class="form-group">
            <p class="card-subtitle">Price per unit</p>
            <!-- fix the data -->
            <input
              type="number"
              v-model="item.price"
              placeholder="Price per unit"
              class="form-control form-control-short"
            />
          </div>

          <div class="expansion-spacer"></div>

          <div class="form-group form-group-bottom form-group-unit">
            <p class="card-subtitle">Unit</p>
            <!-- fix the data -->
            <select
              class="form-control form-control-lg"
              v-model="item.unit"
            >
              <option disabled value="">Unit</option>
              <option v-for="unit in units" :key="unit">
                {{ unit }}
              </option>
            </select>
          </div>
          
         
          <div class="form-group form-group-bottom form-group-min-order">
            <p class="card-subtitle">Minimum Order (units)</p>
            <!-- fix the data -->
            <input
              type="number"
              v-model="item.minQuantity"
              placeholder="Min Quantity Order"
              class="form-control form-control-bottom-right"
            />
          </div>
          <div class="form-group form-group-bottom">
            <p class="card-subtitle">Item Category</p>
            <select
              class="form-control form-control-bottom-right form-control-lg"
              v-model="item.category"
            >
              <option disabled value="">Category</option>
              <option
                v-for="category in user.itemFilters.categories"
                :key="category"
              >
                {{ category }}
              </option>
            </select>
          </div>
          
          <div class="form-group form-group-bottom" v-if="item.type !== 'N/A'">
            <p class="card-subtitle">Item Type</p>
            <select class="form-control form-control-lg" 
            
            v-model="item.type">
              <option disabled value="">Type</option>
              <option v-for="type in activatedCategory" :key="type">
                {{ type }}
              </option>
              
            </select>
          </div>
          <div class="form-group form-group-bottom-last">
            <p class="card-subtitle">Labels</p>
            <div
              v-for="tag in user.itemFilters.tags"
              :key="tag"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="tag"
                v-model="item.tags"
              />
              <label class="form-check-label" for="defaultCheck1">{{
                tag
              }}</label>
            </div>
          </div>
          <div class="form-group form-group-bottom">
            <p class="card-subtitle">Description</p>
            <textarea
              placeholder="Optional"
              class="form-control description"
              v-model="item.description"
              maxlength="150"
            ></textarea>
          </div>
          <div class="form-group form-group-bottom">
            <p class="card-subtitle">Method of Exchange</p>
            <select
              class="form-control form-control-lg"
              v-model="item.pickupOrDelivery"
              disabled
            >
              <option  value="delivery">Delivery</option>
            </select>
          </div>

          <form
            class="shadow-md rounded px-4 mt-2 pb-1 form-group form-group-bottom"
            @submit.prevent
          >
            <div class="mb-4">
              <span class="block text-center mb-3 card-subtitle"
                >Item Availability</span
              >
              <v-date-picker
                v-model="item.range"
                mode="date"
                :masks="masks"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div
                    class="flex flex-col flex-row justify-start items-center"
                  >
                    <div class="relative flex-grow">
                      <svg
                        class="text-gray-600 w-6 h-full mx-2 absolute pointer-events-none"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                      <input
                        class="flex-grow pl-12 pr-2 py-1 bg-gray-100 border rounded w-full date-range-input"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                    </div>

                    <span class="flex-shrink-0 m-2">
                      <svg
                        class="w-4 h-4 stroke-current text-gray-600"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        />
                      </svg>
                    </span>

                    <div class="relative flex-grow">
                      <svg
                        class="text-gray-600 w-6 h-full mx-2 absolute pointer-events-none"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                      <input
                        class="flex-grow pl-12 pr-2 py-1 bg-gray-100 border rounded w-full date-range-input"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </form>

        </div>
        <div class="">
          <input
            type="submit"
            @click="listItem"
            value="Add Item"
            class="btn btn-primary w-100"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import { db } from "../../../main.js";
import store from "../../../store";

export default {
  computed: {
    ...mapGetters({
      user: "user",
      routeHistory: "routeHistory",
    }),
    activatedCategory() {
      if (this.item.category) {
        var categoryLowerCase = this.item.category.toLowerCase();
        var typesOfCategory = categoryLowerCase + "Types";
        return this.user.itemFilters.types[typesOfCategory];
      } else {
        return [];
      }
    },
  },
  watch: {
    showForm() {
      console.log("showform");
      this.resetItem();
    },
    "item.category"(newVal, oldVal) {
      var vm = this;
      console.log(newVal);
      if (newVal === "N/A") {
        vm.$set(vm.item, "type", "N/A");
      } else {
        vm.$set(vm.item, "type", null);
      }
      console.log(vm.item.type);
    },
  },
  data() {
    return {
      showForm: false,
      masks: {
        input: "YYYY-MM-DD",
      },
      alert: {
        type: null, //error or success
        msg: null,
      },
      imageIcon: "https://i.ibb.co/26Zdwvd/image.png",
      imagePreviewIndex: 0,
      placeholder:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcROzjZULCGkCo2TTbq1S1IgNoiNSzJvHgW_Rw&usqp=CAU",
      units: ["lb", "oz", "pc", "gal", "liter"],
      item: {
        name: null,
        images: [],
        price: null,
        category: null,
        type: null,
        description: "",
        pickupOrDelivery: "delivery",
        availability: "available",
        tags: [],
        availableQuantity: 10000,
        minQuantity: 1,
        range: {
          start: new Date(),
          end: new Date("January 1, 2025 00:00:00"),
          unit: null,
        },
      },
    };
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
    },
    resetItem() {
      var vm = this;
      var resetRange = {
        start: new Date(),
        end: new Date("January 1, 2025 00:00:00"),
      };
      vm.$set(vm.item, "unit", null);
      vm.$set(vm.item, "name", null);
      vm.$set(vm.item, "images", []);
      vm.$set(vm.item, "price", null);
      vm.$set(vm.item, "category", null);
      vm.$set(vm.item, "type", null);
      vm.$set(vm.item, "description", "");
      vm.$set(vm.item, "pickupOrDelivery", "delivery");
      vm.$set(vm.item, "tags", []);
      vm.$set(vm.item, "availableQuantity", 10000);
      vm.$set(vm.item, "minQuantity", 1);
      vm.$set(vm.item, "range", resetRange);
    },
    async listItem() {
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);

      var invalidFields = await this.validateFields();
      if ((await invalidFields.length) > 0) {
        this.$set(this.alert, "type", "error");
        this.$set(
          this.alert,
          "msg",
          "You have these invalid fields: " + invalidFields
        );
        return -1;
      }
      var inDb = await this.checkItemNameInDb(this.item.name);
      if (inDb) {
        this.$set(this.alert, "type", "error");
        this.$set(
          this.alert,
          "msg",
          'Item name: "' +
            this.item.name +
            '" is already in your products. Please use another name or delete the item with this name'
        );
        return -1;
      }

      //Check if all fields are valid

      const timestamp = firebase.firestore.Timestamp.now();
      var batch = db.batch();
      var vm = this;

      this.item.createdAt = timestamp;
      this.item.lastUpdatedAt = timestamp;
      this.item.userID = this.user.data.uid;
      this.item.seller_lat_lon = this.user.profile.lat_lon;
      this.item.seller_name = this.user.profile.name;
      this.item.seller_address = this.user.profile.address;
      // this.item.seller_pickup_instructions = this.user.profile.pickupInstructions;
      if (this.user.profile.stripeAccountId) {
        this.item.seller_connected_account_id = this.user.profile.stripeAccountId;
      }

      var itemRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("items")
        .doc();

      var profileRef = db.collection("profile").doc(this.user.data.uid);

      this.item.docId = itemRef.id;

      var item = this.item;

      batch.set(itemRef, item, { merge: true });
      batch.update(profileRef, {
        availableItemNames: firebase.firestore.FieldValue.arrayUnion(item.name),

        allItemCategories: firebase.firestore.FieldValue.arrayUnion(
          item.category
        ),
        availableItemCategories: firebase.firestore.FieldValue.arrayUnion(
          item.category
        ),
        allItemTypes: firebase.firestore.FieldValue.arrayUnion(item.type),
        availableItemTypes: firebase.firestore.FieldValue.arrayUnion(item.type),
      });
      // Commit the batch
      batch
        .commit()
        .then(() => {
          //console.log("Success. Batch is on firestore now :)");
          vm.$router.push({ path: `/user/${vm.user.data.uid}/products` });
          vm.$set(vm.alert, "type", "success");
          vm.$set(
            vm.alert,
            "msg",
            'You have successfully listed your product "' +
              vm.item.name +
              '". Refresh in a few seconds to see your product!'
          );
          vm.showForm = false;
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            'Error listing item "' + vm.item.name + '". Error: ' + error
          );
        });
    },

    //returns true if in db, meaning user should change name of item
    async checkItemNameInDb(name) {
      // Create a reference to the cities collection
      var inDb = false;
      var itemsRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("items");

      // Create a query against the collection.
      var query = itemsRef
        .where("name", "==", name)
        .where("availability", "!=", "deleted");

      await query
        .get()
        .then(async (querySnapshot) => {
          // eslint-disable-next-line no-unused-vars
          await querySnapshot.forEach(async (doc) => {
            inDb = true;
          });
        })
        .catch(function (error) {
          vm.$set(
            vm.alert,
            "msg",
            'Error checking item name in the database. "' +
              vm.item.name +
              '". Error: ' +
              error
          );
        });
      return inDb;
    },
    async validateFields() {
      var item = this.item;
      var invalidFields = [];
      for (var property in item) {
        if (item[property] === null) {
          await invalidFields.push(property);
        }
      }
      if (item.images.length < 1) {
        // await invalidFields.push("images"); //this line forces farmers to have an image
      }
      if (item.price < 0) {
        await invalidFields.push("price");
      }
      return invalidFields;
    },
    uploadImage(e) {
      var uuid = require("uuid");
      var id = uuid.v4();

      if (e.target.files[0] && this.item.images.length < 4) {
        var file = e.target.files[0];
        var vm = this;
        //console.log(file);

        var storageRef = firebase
          .storage()
          .ref("items/" + this.user.data.uid + "/" + id);

        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg",
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.put(file, metadata);
        //console.log(uploadTask);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                //console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                //console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              //...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                //console.log("File available at", downloadURL);
                vm.item.images.push(downloadURL);
                console.log(vm.item.images);
              });
          }
        );
      }
    },
    deleteImage(img, index) {
      let image = firebase.storage().refFromURL(img);

      this.item.images.splice(index, 1);
      this.$refs.imageToUpload.value = "";

      image
        .delete()
        .then(() => {
          //console.log("image deleted");
        })
        .catch((error) => {
          //console.log("error deleting image: " + error);
        });
      this.$refs.imageToUpload.value = "";
    },
    imagePreviewSelect(action) {
      var maxIndex = this.item.images.length - 1;
      if (action == "prev" && this.imagePreviewIndex == 0) {
        this.imagePreviewIndex == maxIndex;
      } else if (action == "next" && this.imagePreviewIndex == maxIndex) {
        this.imagePreviewIndex == 0;
      } else {
        if (action == "prev") {
          this.imagePreviewIndex--;
        } else {
          this.imagePreviewIndex++;
        }
      }
    },
  },

  mounted() {
    store.dispatch("fetchItemFilters");

    // let jQuery = document.createElement('script')
    // jQuery.setAttribute('src', 'https://code.jquery.com/jquery-3.5.1.min.js')
    // document.head.appendChild(jQuery)
    // Le Javascript Pre-loads
    let ajaxJquery = document.createElement("script");
    ajaxJquery.setAttribute(
      "src",
      "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"
    );
    document.head.appendChild(ajaxJquery);

    // Le Javascript
    let jQuery = document.createElement("script");
    jQuery.setAttribute(
      "src",
      "https://code.jquery.com/ui/1.12.0/jquery-ui.min.js"
    );
    document.head.appendChild(jQuery);

    let cloudFlarePopper = document.createElement("script");
    cloudFlarePopper.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
    );
    document.head.appendChild(cloudFlarePopper);

    let bootAdminBootstrap = document.createElement("script");
    bootAdminBootstrap.setAttribute(
      "src",
      "https://bootadmin.org/scripts/vendor/bootstrap.min.js"
    );
    document.head.appendChild(bootAdminBootstrap);

    let bootAdminLibrary = document.createElement("script");
    bootAdminLibrary.setAttribute(
      "src",
      "https://bootadmin.org/scripts/vendor/library.min.js"
    );
    document.head.appendChild(bootAdminLibrary);

    let bootAdminCore = document.createElement("script");
    bootAdminCore.setAttribute(
      "src",
      "https://bootadmin.org/scripts/core/main.js"
    );
    document.head.appendChild(bootAdminCore);

    let bootAdminOpenCloseSidebar = document.createElement("script");
    bootAdminOpenCloseSidebar.setAttribute(
      "src",
      "https://raw.githubusercontent.com/iamshipon1988/bootadmin/master/scripts/core/main.js"
    );
    document.head.appendChild(bootAdminOpenCloseSidebar);
    // end of Le Javascript
  },
};
</script>


<style scoped>
/* start of createevent style */
@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.min.css";
/* start of accordian css : https://codepen.io/OGOGOG/pen/YzGdreM */
.accordion > input[type="checkbox"] {
  position: absolute;
  /* left: -100vw; */
  display: none;
}

.accordion .content {
  overflow-y: hidden;
  height: 0px;
  transition: height 0.3s ease;
  border: 0px solid #ddd;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  height: auto;
  overflow: visible;
  border: 1px solid #ddd;
}

.accordion label {
  /* display: block;
  font-size: 25px; */
  height: 40px;
}

.accordion {
  margin-bottom: 1em;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  padding: 15px;
  padding-top: 0px;
  /* border: 1px solid #e8e8e8; */
  border-top: 0;
}

.accordion .handle {
  margin: 0px;
  font-size: 18px;
  /* line-height: 1.2em; */
  background-color: rgba(255, 255, 255, 0);
  z-index: -10;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.accordion label {
  /* color: #757575;
  cursor: pointer;
  font-weight: normal;
  padding: 15px;
  margin-left:auto;
  width: 100%;
  background: #ffffff; */
}

.accordion label:hover,
.accordion label:focus {
  /* background: #f7f7f7; */
}

.accordion .handle label:before {
  font-family: "fontawesome";
  font-family: FontAwesome;
  content: "\25B7";
  display: inline-block;
  /* margin-right: 10px; */
  line-height: 1.556em;
  vertical-align: middle;
  margin-top: -3px;
  color: rgb(95, 95, 95);
}

.accordion > input[type="checkbox"]:checked ~ .handle label:before {
  content: "\25BD";
  font-size: 17px;
  line-height: 1.556em;
  /* color: black; */
  margin-top: -3px;
  color: rgb(128, 128, 128);
}

/* end of accordian css */

.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  position: sticky;
  top: 55px;
  z-index: 1;
}

.alert:hover {
  cursor: pointer;
}

.alert:before {
  padding-right: 12px;
}

.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
}

.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

.btn-primary {
  font-size: 17px;
  background-color: #42b983;
  border-style: none;
}
.btn-primary:hover {
  background-color: #369a6d;
}
.card {
  max-width: 445px;
  width: calc(100% - 15px);
  margin: auto;
  /* height: 0px; */
  border: 0px solid #ddd;
  font-family: Google Sans;
}
.card-body {
  padding-top: 0px;
}
.card-subtitle {
  margin-bottom: 8px;
  color: #757575;
  font-size: 15px;
}
.title {
  margin: auto;
  color: #757575;
  cursor: pointer;
  font-weight: normal;
  padding: 10px;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
}
.btn-primary {
  font-family: Google Sans;
}
.form-group {
  margin-bottom: 20px;
}

.preview-images-form-group {
  /* new gray bar stuff */
  height: 65px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: rgb(243, 242, 242);
  /* height: 77px; */
  width: 329px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.preview-images-row {
  margin-bottom: 50px;
}
.preview-images-margins {
  margin-right: 15px;
  margin-bottom: 20px;
  /* new gray bar stuff */
  margin-top: 0px;
  margin-left: 15.2px;
  outline: 15px solid white;
}
.upload-wrapper {
  width: 100px;
}
.img-wrap {
  position: relative;
}
.upload-image {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  margin: 0%;
  border-radius: 3.5px;
}
.else-upload-image {
  margin: auto;
  filter: grayscale;
  opacity: 0.7;
  /* height: 100%; */
  position: absolute;
  top: -20px;
}

.preview-images-wrapper {
  /* border: 2px solid #ddd; */
  width: 56px;
  height: 64px;
  align-content: center;
  border-radius: 4px;
}
.x-icon {
  z-index: 2;
  margin-top: -8px;
  margin-right: -8px;
  opacity: 0.85;
  height: 13pt;
}

@media (max-width: 470px) {
  .preview-images-wrapper {
    width: 58px;
    height: 63px;
  }
  .preview-images-form-group {
    /* new gray bar stuff */
    margin-bottom: 20px;
    height: 64px;
    width: 290px;
  }
  .preview-images-margins {
    margin-right: 3px;
    /* new gray bar stuff */
    margin-left: 15.2px;
    outline: 10px solid white;
  }
  .x-icon {
    margin-top: -6px;
    margin-right: -6px;
    height: 12pt;
  }

  .form-group {
    margin-top: -5px;
  }
}

.preview-images {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* margin: 1%; */
  border-radius: 4px;
}

.img-wrap span.delete-img {
  position: absolute;
  top: 0px;
  right: 0px;
}
.img-wrap span.delete-img:hover {
  cursor: pointer;
}

.upload-wrapper {
  border: 2px solid #ddd;
  border-radius: 5px;
  width: 250px;
  margin: 0 auto;
  height: 230px;
  position: relative;
}
@media (max-width: 430px) {
  .accordion > input[type="checkbox"]:checked ~ .content {
    padding: 5px;
  }
  .upload-wrapper {
    height: 230px;
    width: 70vw;
    max-width: 250px;
    margin-bottom: 25px;
  }
  .else-upload-image {
    top: -13px;
  }
  .x-icon {
    margin-top: -6.5px;
    margin-right: -6.5px;
    height: 11.5pt;
  }

  .preview-images-wrapper {
    width: 60px;
    height: 65px;
  }
  .preview-images-form-group {
    /* new gray bar stuff */
    margin-bottom: 20px;
    margin-top: -5px;
    height: 54px;
    width: 100%;
    height: 65px;
    width: 280px;
  }

  .preview-images-margins {
    margin-right: -0px;
    /* new gray bar stuff */
    margin-left: 15.2px;
    outline: 10px solid white;
  }

  .form-group {
    margin-top: -10px;
  }
}

.upload-wrapper span.delete-img {
  position: absolute;
  top: 0px;
  right: 1px;
}

/* for the image upload plus sign button */
.file-input__input {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: 10;
  position: absolute;
  top: 0px;
}
/* for the image upload plus sign button */
.file-input__label {
  cursor: pointer;
  text-align: center;
  outline: 2px solid #ddd;
  color: #ddd;
  width: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin: 0px;
}
.label-plus {
  font-size: 65px;
  font-weight: 100;
  position: absolute;
  bottom: 14px;
  right: 12px;
}

.form-check-input {
  margin-left: -5px;
  padding-left: 0px;
}
.form-check-label {
  padding: 0px;
  margin-left: 20px;
  margin-bottom: -15px;
  font-weight: 400;
  font-size: 12px;
}
.form-control {
  font-size: 13px;
  padding: 4px;
  padding-left: 8px;
  color: rgb(167, 167, 167);
}
input::placeholder {
  color: rgb(167, 167, 167);
}

.description {
  height: 4em;
}

.expansion-spacer {
  height: 0px;
}

@media (min-width: 800px) {
  .card {
    max-width: 100%;
  }
  .form-group-bottom {
    width: 30%;
    margin-right: 3%;
    float: left;
  }
  .form-group-bottom-last {
    width: 30%;
    margin-right: 0%;
    float: right;
  }
  .description {
    height: 10em;
  }
  .upload-wrapper {
    width: 35%;
    float: left;
    margin-right: 5%;
  }
  .preview-images-wrapper {
    width: 65px;
    height: 76px;
  }
  .preview-images-form-group {
    margin-top: -20px;
    margin-bottom: 18px;
    height: 77px;
    width: 329px;
    border: 1px solid rgba(0, 0, 0, 0);
    margin-left: 220px;
    margin-right: 0px;
    border-radius: 5px;
    background-color: rgb(243, 242, 242);
  }
  .preview-images-margins {
    margin-left: 15.2px;
    margin-right: 9px;
    outline: 15px solid white;
  }

  .form-control {
    width: 100%;
    display: inline-block;
    padding: 4px;
    padding-left: 8px;
  }
  .form-control-short {
    width: 60%;
    display: inline-block;
    padding: 4px;
    padding-left: 8px;
    margin-bottom: -4.3px;
  }
  .expansion-spacer {
    height: 13px;
  }
  .else-upload-image {
    top: 10px;
  }
  
  .form-group-min-order {
    width: 47%;
    margin-top: -2px;
  }

  .form-group-unit {
    width: 47%;
  }

}

@media (max-width: 374px) {
  .preview-images-wrapper {
    width: 50px;
    height: 55px;
  }
  .preview-images-form-group {
    width: 250px;
  }
}

</style>