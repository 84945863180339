<template>
  <div class="sui-sorting">
    <div class="sui-sorting__label">Sort by</div>
    <select
      name="sortBy"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
      <option value="">Relevance</option>
      <option value="asc_name">Name (asc)</option>
      <option value="dec_name">Name (dec)</option>
      <option value="asc_price">Price (asc)</option>
      <option value="dec_price">Price (dec)</option>
      <option value="asc_last_updated_at">Last Updated (asc)</option>
      <option value="dec_last_updated_at">Last Updated (dec)</option>
      <option value="asc_created_at">Created At (asc)</option>
      <option value="dec_created_at">Created At (dec)</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  }
};
</script>
