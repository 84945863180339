<template>
    <div class="overall-container">
      <h1 id="alert" > {{alert.msg}} </h1>
      <!-- <h1>Account Type</h1> -->
      <div class="form-header">
        <div class="title">Sign Up</div>
        <!-- <div id="AccountTypeDiv" class="tagline" style="text-transform:capitalize;">Select Account Type</div> -->
      </div>
      <div class="tab_container">
        <input
          v-model="form.type"
          class="tab-input"
          id="tab1"
          type="radio"
          name="tabs"
          value="seller"
        />
        <label class="tab-label label-margin" for="tab1" id="MerchantLabel"
          ><i class="fa fa-store"></i><span class="tab-text">Seller</span></label
        >

        <input
          v-model="form.type"
          class="tab-input"
          id="tab2"
          type="radio"
          name="tabs"
          value="purchaser"
        />
        <label class="tab-label label-margin" for="tab2" id="PurchaserLabel"
          ><i class="fa fa-store-alt"></i><span class="tab-text">Buyer</span></label
        >

        <!-- <input
          v-model="form.type"
          class="tab-input"
          id="tab3"
          type="radio"
          value="individual"
          name="tabs"
        />
        <label class="tab-label" for="tab3" id="IndividualLabel"
          ><i class="fa fa-user"></i><span class="tab-text">Individual</span></label
        > -->

        <section id="content1" class="tab-content">
          <form action="#" @submit.prevent="submit">
            <div class="form-container">
              <div class="form-card">
                <!-- <div class="form-header">
                  <div class="title">Sign Up</div>
                  <div id="AccountTypeDiv" class="tagline" style="text-transform:capitalize;">Select Account Type</div>
                </div> -->
                <input
                  v-model="form.email"
                  class="form-input"
                  id="email"
                  type="email"
                  placeholder=" "
                />
                <label class="form-label" for="email">Email</label>
                <input
                  v-model="form.password"
                  class="form-input"
                  id="pass"
                  type="password"
                  placeholder=" "
                />
                <label class="form-label" for="pass">Password</label>
                <input
                  v-model="form.name"
                  class="form-input"
                  id="name"
                  type="text"
                  placeholder=" "
                />
                <label
                  class="form-label"
                  for="name"
                  v-if="form.type === 'seller'"
                  >Farm Name</label
                >
                <label
                  class="form-label"
                  for="name"
                  v-else-if="form.type === 'purchaser'"
                  >Business Name</label
                >
                <label
                  class="form-label"
                  for="name"
                  v-else-if="form.type === 'individual'"
                  >Name</label
                >
                <input
                  v-model="form.phone"
                  class="form-input"
                  id="number"
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='')"
                  placeholder=" "
                />
                <label
                  class="form-label"
                  for="number"
                  v-if="form.type !== 'individual'"
                  >Phone (Business)</label
                >
                <label class="form-label" for="number" v-else>Phone</label>
                <input
                  v-model.lazy="form.address"
                  ref="autocomplete"
                  class="form-input"
                  id="address"
                  type="text"
                  placeholder=" "
                />
                <label
                  class="form-label"
                  for="address"
                  v-if="form.type === 'seller'"
                  >Farm Address</label
                >
                <label
                  class="form-label"
                  for="address"
                  v-else-if="form.type === 'purchaser'"
                  >Business Address</label
                >
                <label
                  class="form-label"
                  for="address"
                  v-else-if="form.type === 'individual'"
                  >Address</label
                >
                <input
                  v-model="form.addressTwo"
                  class="form-input"
                  id="addressTwo"
                  type="text"
                  placeholder=" "
                />
                <label class="form-label" for="addressTwo">Address 2</label>
                <input
                  v-model="form.postal_code"
                  class="form-input"
                  id="postal_code"
                  type="text"
                  placeholder=" "
                />
                <label class="form-label" for="postal_code">Zip</label>
                <div class="guest">Already have an account?</div>
                <div class="links" @click="toLogin">Login</div>
                <div class="login-bar">
                  <button id="submit" type="submit" class="next">Sign Up</button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
</template>


<script>
import firebase from "firebase";
import { db } from "../main.js";
import { mapGetters } from "vuex";
import store from "../store";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.user.data) next({ path: "/" });
    else next();
  },
  data() {
    return {
      lat_lon: null,
      radius: 5000,
      addressPlaces: {},
      form: {
        name: "",
        email: "",
        password: "",
        address: "",
        addressTwo: "",
        type: "seller",
        phone: null,
        postal_code: null,
      },
      componentForm: {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "short_name",
        postal_code: "short_name",
      },
      alert: {
        type: null,
        msg: null,
      },
    };
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    },
    toLogin() {
      this.$router.push({path: "/login"})
    },
    validateRegisterFields() {
      var invalidArr = [];
      var form = this.form;

      for (var property in form) {
        if (property !== "addressTwo") {
          if (
            form[property] === null ||
            form[property] === "" ||
            typeof form[property] === "undefined"
          ) {
            invalidArr.push(property);
          }
        }
      }

      if (this.lat_lon === null || typeof this.lat_lon === "undefined") {
        invalidArr.push("lat_lon");
      }

      return invalidArr;
    },
    async submit() {
      ////console.log("Register submit got activated")
      var vm = this;
      var invalidArr = await this.validateRegisterFields();
      if (invalidArr.length > 0) {
        vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            "Can't register. You have these invalid fields: " + invalidArr
          );
        return -1;
      }
      //console.log("before sign up");
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          data.user
            .updateProfile({
              displayName: this.form.name,
            })
            .then(() => {
              firebase.auth().onAuthStateChanged(async (user) => {
                await this.$store.dispatch("fetchUser", user);
                await this.updateProfile(user.uid); 
                this.$router.replace({ name: "Settings" });
                this.$router.go(0);
              });
            });
        })
        .catch((err) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            err.message
          );
        });
    },

    async updateProfile(uid) {
      var profile = {};
      var vm = this;

      if (Object.keys(this.addressPlaces).length !== 0) {
        for (var property in this.addressPlaces) {
          profile[property] = this.addressPlaces[property];
        }
      }

      profile["radius"] = this.radius;
      profile["lat_lon"] = this.lat_lon;

      profile["name"] = this.form.name;
      profile["phone"] = this.form.phone;
      profile["addressTwo"] = this.form.addressTwo;
      profile["address"] = this.form.address;
      profile["type"] = this.form.type;

      await db
        .collection("profile")
        .doc(uid)
        .set(profile, { merge: true })
        .then(() => {
          //console.log("Document successfully written! updateProfile");
          store.dispatch("fetchProfile");
        })

        .catch(function (error) {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            "Error updating profile. Please file a report. Error Code: 74201"
          );
          
        });
    },

    async initAutocomplete() {
      try {
        // eslint-disable-next-line no-undef
        const autocomplete = new google.maps.places.Autocomplete(
          this.$refs.autocomplete,
          { types: ["geocode"] }
        );

        autocomplete.addListener("place_changed", async () => {
          let place = await autocomplete.getPlace();
          let lat = await place.geometry.location.lat();
          let lon = await place.geometry.location.lng();
          var vm = this;
          let address = "";

          vm.addressPlaces = {};
          // Get each component of the address from the place details,
          // and then fill-in the corresponding field on the form.
          for (const component of place.address_components) {
            const addressType = component.types[0];

            if (vm.componentForm[addressType]) {
              const val = component[vm.componentForm[addressType]];
              address += val + " ";
              vm.addressPlaces[addressType] = val;
            }
          }

          if (address !== "") {
            vm.lat_lon = [lat, lon];
            vm.form.address = address;
            vm.form.postal_code = vm.addressPlaces["postal_code"];
          }

          //console.log(vm.form.address);

          if (!vm.lat_lon || !vm.form.postal_code) {
            //console.log("Invalid address. Doesn't have lat_lon or postal code");
            vm.form.address = null;
            vm.addressPlaces = null;
            vm.postal_code = null;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    let jquery = document.createElement("script");
    jquery.setAttribute("src", "https://code.jquery.com/jquery-2.1.1.min.js");
    document.head.appendChild(jquery);

    let materialize = document.createElement("script");
    materialize.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.5/js/materialize.min.js"
    );
    document.head.appendChild(materialize);

    var vm = this;

    this.$loadScript(
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_MAPS_API}`
    )
      .then(() => {
        vm.initAutocomplete();
      })
      .catch(() => {
        //console.log("failed to fetch google maps script");
        // Failed to fetch script
        vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            "Failed to fetch google maps script"
          );
      });
  },
};
</script>


<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/5.4.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.alert {
	margin: 5px 0px;
	padding: 8px;
	border-radius: 5px;

	font-family: 'Open Sans', sans-serif;
	font-size: 1.1rem;
	font-weight: 300;
	letter-spacing: 1px;

  position: sticky;
  top: 55px;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.alert:hover {
  cursor: pointer;
}

.alert:before {
	padding-right: 12px;
}
  
.alert:after {
  /* content: '\f00d'; */
  font-family: 'FontAwesome';
  float: left;
  padding: 10px;
    &:hover {
      cursor: pointer;
    }
}

.alert-error {
  color: #D8000C;
  background-color: #ffe6e6;
  border: 1px solid darken(#FFBABA, 15%);
}

.alert-error:before {
  content: '\f071';
  font-family: 'FontAwesome';
  padding-right: 4px;
  margin-left: -5px;
}


.overall-container{
  padding-top: 70px;
  background: #fff;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

body {
  font-family: Google Sans;
}

h1 {
  text-align: center;
  font-size: 24px;
  color: rgb(53, 53, 53);
  font-family: Google Sans;
  font-weight: 400;
}

a {
  color: #ccc;
  text-decoration: none;
  outline: none;
}

/*Fun begins*/
.tab_container {
  max-width: 590px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 36px;
  /* position: relative; */
  height: fit-content;
}

.tab-input,
section {
  clear: both;
  padding-top: 10px;
  display: none;
}

.tab-label {
  font-weight: 400;
  font-size: 18px;
  display: block;
  float: left;
  width: 48%;
  padding: 15px;
  color: #757575;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  /* background: #f7f7f7; */
  border: 1px solid #dedede;
  border-radius: 5px;
}
.label-margin {
  margin-right: 2%;
}
/* @media (max-width: 600px) {
  .tab-label {
  width: 30%;
}
.label-margin {
  margin-right: 5%;
}
} */
.tab-text{
  margin-left: 10px;
}
@media (max-width: 600px) {
  .tab-text{
    display: block;
    margin-top: 7px;
    font-size: 80%;
    margin-left: 0px;
  }
  .tab-label {
    padding-left: 0px;
    padding-right: 0px;
  }
}

#tab1 ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4,
#tab5:checked ~ #content5 {
  display: block;
  /* padding: 20px; */
  background: #fff;
  color: #999;
  border-bottom: 2px solid #f0f0f0;
}
.tab-content {
  padding-top: 15px;
}

.tab_container .tab-content p,
.tab_container .tab-content h3 {
  -webkit-animation: fadeInScale 0.7s ease-in-out;
  -moz-animation: fadeInScale 0.7s ease-in-out;
  animation: fadeInScale 0.7s ease-in-out;
}
.tab_container .tab-content h3 {
  text-align: center;
}

.tab_container [id^="tab"]:checked + label {
  background: #fff;
  box-shadow: inset 0 3px#42b983e5;
}

.tab_container [id^="tab"]:checked + label .fa {
  color: #42b983e5;
  margin: auto;
}

label .fa {
  font-size: 1.3em;
  /* margin: 0 0.4em 0 0; */
}

/*Content Animation*/
@keyframes fadeInScale {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.no_wrap {
  text-align: center;
  color: #42b983e5;
}
.link {
  text-align: center;
}

/* start of form style */
.form-container {
  display: flex;
  /* height: 100vh; */
  font-family: Google Sans;
  user-select: none;
  margin-bottom: 50px;
}
.form-card {
  background: #fff;
  max-width: 590px;
  height: auto;
  padding: 0px 0px;
  border-radius: 8px;
  /* border: 1px solid #cacaca; */
  margin: auto;
  margin-top: 0px;
}
.form-header {
  text-align: center;
  font-weight: 400;
}
.title {
  padding: 15px 0;
  padding-top: 0px;
  margin-top: -10px;
  font-size: 24px;
  color: rgb(53, 53, 53);
}
.tagline {
  font-size: 16px;
  padding-bottom: 5px;
}
.form-label {
  display: block;
  position: absolute;
  padding: 0 5px;
  width: auto;
  color: #5f6368;
  background: #fff;
  transition: all 150ms ease;
  transform: translate(12px, -37px);
  cursor: text;
  font-weight: 200;
}
.form-input {
  padding: 16px;
  margin-top: 20px;
  font-size: 17px;
  background: #fff;
  color: black;
  width: calc(100% - 0px);
  border: 1px solid #cacaca;
  border-radius: 5px;
  font-weight: 200;
  /* Remove textarea inner shadow on Mobile Chrome & Safari (iPhone) */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none !important;
}
.form-input:focus {
  outline: none;
  /* Compensate increased border with padding */
  padding: 15px;
  border: 2px solid #42b983e5;
}
.form-input:not(:placeholder-shown) + .form-label,
.form-input:focus + .form-label {
  transform: translate(8px, -62px);
  font-size: 13px;
}
.form-input:focus + .form-label {
  color: #42b983e5;
}
.links {
  color: #42b983e5;
  font-size: 18px;
  padding-top: 10px;
  cursor: pointer;
  font-weight: 500;
}
.guest {
  margin-top: 32px;
  font-size: 18px;
  color: #5f6368;
}
.login-bar {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}
.next {
  width: 100%;
  margin-left: auto;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  background: #42b983e5;
  font-weight: 500;
  font-size: 18px;
}
.next:hover {
  background-color: #349469;
}
.links:hover{
  color: #349469;
}

@media only screen and (max-width: 600px) {
  .card {
    border: none;
  }
}

/*  end of form style */
</style>