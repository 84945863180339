<template>
  <div class="BrowseFilter2">
    <div class="filter-header">
      {{homeHeading}}
    </div>
    

    
    <div id="categories" class="scroll">
      <span
        v-for="(category, index) in user.itemFilters.categories"
        :key="index"
        @click="changeActivatedCategory(category)"
      
      >
        <span class="inner"
        >
          <BrowseFilter2 :category="category" :activatedCategory="countTypesInEachCategory[category.toLowerCase().trim()]"/>
        </span>
      </span>
    </div>

    <div class="scroll">
      <span
        v-for="(type, index) in activatedTypeArray"
        :key="index"
        @click="addOrRemoveItemTypeFilter(type, true)"
      >
        <span class="inner">
          <BrowseFilter2 :type="type" :activatedType="selectedTypes[type]"/>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BrowseFilter2 from "../../BrowseFilter2";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
    activatedTypeArray() {
      var typesOfCategory = this.activatedCategory + "Types";
      return this.user.itemFilters.types[typesOfCategory];
    },
  },
  components: {
    BrowseFilter2,
  },
  props: {
    available_item_types: {
      required: true,
      type: Array,
    },
    available_services: {
      required: true,
      type: Array,
    },
    available_event_types: {
      required: true,
      type: Array,
    }
  },
  created() {
    this.restoreUIfromURL(this.available_item_types);
    this.restoreUIfromURL(this.available_services);
    this.restoreUIfromURL(this.available_event_types);
  },
  data() {
    return {
      selectedTypes: {},
      selectedCategories: {},
      activatedCategory: null,
      categoryCount: null,
      countTypesInEachCategory: {},
      mappedTypes: {},
      homeHeading: null,
    };
  },
  methods: {
    mapCategoriesToTypes() {
      var itemFilters = null;
      if (this.user) {
        if (this.user.itemFilters) itemFilters = this.user.itemFilters.types;
      }
      if (!itemFilters) {
        return -1;
      }
      for (var categoryAndSeperator in itemFilters) {
        var category = categoryAndSeperator.split("Types");
        category = category[0];
        for (var type in itemFilters[categoryAndSeperator]) {
          this.mappedTypes[itemFilters[categoryAndSeperator][type]] = category;
        }
      }
    },
    async restoreUIfromURL(filters) {
      if (Object.keys(this.mappedTypes).length === 0) {
        await this.mapCategoriesToTypes();
      }

      for (var value in filters) {
        var category = this.mappedTypes[filters[value]];
        var type = filters[value];

        if (this.countTypesInEachCategory[category]) {

          this.$set(
            this.$data.countTypesInEachCategory,
            category,
            this.$data.countTypesInEachCategory[category] + 1
          );
        } else {
          this.$set(this.$data.countTypesInEachCategory, category, 1);
        }
        this.$set(this.$data.selectedTypes, type, true);

      }

    },
    changeActivatedCategory(category) {
      var lowerCase = category.toLowerCase()
      if (this.activatedCategory === lowerCase) {
        this.activatedCategory = null
      } else {
        this.activatedCategory = lowerCase;
      }
       
    },
    addOrRemoveItemTypeFilter(type, applyParent) {
      var operation, payload, filterField;
      var vm = this;

      if (this.activatedCategory === "services") {
        filterField = "available_services";
      } else if (this.activatedCategory === "event") {
        filterField = "available_event_types";
      } else {
        filterField = "available_item_types";
      }

      if (type in this.selectedTypes) {
        operation = "remove";
        this.$set(this.$data.countTypesInEachCategory, this.activatedCategory, this.countTypesInEachCategory[this.activatedCategory] -1);
      } else {
        operation = "add";
        if (!this.countTypesInEachCategory[this.activatedCategory]) {
          this.$set(this.$data.countTypesInEachCategory, this.activatedCategory, 1);
        } else {
          this.$set(this.$data.countTypesInEachCategory, this.activatedCategory, this.countTypesInEachCategory[this.activatedCategory] + 1);
        }
      }

      //Get the field we're filtering on to input it into the payload.
      //If remove, check if selectedTypes has any more items from the item's category. If not,
      //remove the category from selectedCategories.
      //Regardless, remove the itemType from selectedTypes, and remove the filter.
      if (operation === "remove") {
        if (this.countTypesInEachCategory[this.activatedCategory] === 0) {
          vm.$delete(vm.$data.countTypesInEachCategory, vm.activatedCategory);
        }
        vm.$delete(vm.$data.selectedTypes, type);
        payload = {
          operation: "remove",
          filterField: filterField,
          productType: type,
          filterType: "all",
        };
      } else {
        //If addition, add the activatedcategory, the activatedtype, and add filter.
        vm.$set(this.$data.selectedTypes, type, true);
        payload = {
          operation: "add",
          filterField: filterField,
          productType: type,
          filterType: "all",
        };
      }
      if (applyParent) {
        this.$emit("productTypeFilter", payload);
      }
    },
  },

  mounted() {
    // https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
    // This created watches for SET_HOME_HEADING state changes, and updates the homeHeading whenever SET_HOME_HEADING is triggered. 
    var vm = this;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_HOME_HEADING') {
        vm.homeHeading = vm.user.homeHeading;
      }
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
.BrowseFilter2 {
  width: 100%;
  white-space: nowrap;
  padding: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
}
.scroll{
  overflow-x: auto;
}

div.inner {
  display: flex !important;
  text-align: center;
  overflow-x: auto;
}
div.selected {
  background-color: green;
}
.selected {
  background-color: green;
  width: 10px;
}
.filter-header{
  color: rgba(0, 0, 0, 0.63);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  margin-top: 5px;
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.144);
  margin-bottom: 15px;
  overflow: auto;
}
@media (max-width: 850px){
  .filter-header{
  margin-top: -3px;
}
}

@media (min-width: 425px) {
  .filter-header{
    text-align: left;
    padding-left: 10px;
  }
}
.pickup-date-filter{
  text-align: left;
  font-size: 20px;
  padding-bottom: 15px;
  color: rgba(0, 0, 0, 0.486);
}




.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 
    0 5px 10px 0 rgba(29, 29, 29, 0.15);
  padding: 0.4em;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 0px;
}

.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 100%;
  font-size: 19px;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  text-align: center;
  min-width: 100px;
  margin-top: 0.5em;
  color: rgb(100, 100, 100);
}

input[type="radio"]:checked + label {
  color: #42b983;
}

input[type="radio"]:checked + label > .notification {
  background-color: #42b983;
  color: #fff;
}

input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}

input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

input[id="radio-3"]:checked ~ .glider {
  transform: translateX(199%);
}

.glider {
  position: absolute;
  display: flex;
  height: 70%;
  width: 33%;
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 99em;
  transition: 0.25s ease-out;
  margin-top: 0.06em;
}
</style>