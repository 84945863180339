<template>
  <div class="container">
    <div class="app">
        <div class="wrapper">
            <div class="conversation-area">
              <div v-if="messages">
                <div v-for="(message, index) in messages" :key="index" class="">
                  <div>
                    <router-link :to="'/inbox/' + message.othersId" class="" style="color: black;">
                      <div class="msg ">
                        <img v-if="message.othersDp" class="msg-profile" :src="message.othersDp" alt="https://www.seekpng.com/png/small/8-85136_cartoon-people-png-cartoon-people-with-speaker.png" />
                        <img v-if="!message.othersDp" class="msg-profile" src="https://www.seekpng.com/png/small/8-85136_cartoon-people-png-cartoon-people-with-speaker.png" alt="" />
                        <div class="msg-detail">
                        <div class="msg-username">
                          <span v-if="message.othersName"> 
                          {{ message.othersName }}
                          </span>
                          <span v-else>User has no profile name</span>
                          </div>
                        <div class="msg-content">
                        <span class="msg-message">{{ message.lastMessageText }}</span>
                        <span class="msg-date">{{ message.datetime }}</span>
                        </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <InfiniteLoading1 @infinite="infiniteHandler1" style="padding-top: 20px;"></InfiniteLoading1>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../main.js";
import InfiniteLoading1 from "vue-infinite-loading";
import firebase from "firebase";
import store from "../store"
import moment from "moment";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeRouteEnter (to, from, next) {
    if (!store.state.user.data) next({ name: 'Login' })
    else next()
  },
  
  components: {
    'InfiniteLoading1': InfiniteLoading1,
  },
  props: {

  },
  data() {
    return {
      messages: [],
      realtimeMessages: [],
      //infiniteId: "noFilter",
      numberOfLoaded: 0,
      loadedRealtimeQuery: false,
    };
  },
  methods: {
    async fetchMessages() {
      var vm = this;

      const messagesRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("inbox");

      const query = await messagesRef
      .where('lastMessageAt', '!=', null)
        .orderBy("lastMessageAt", "desc")
        .limit(this.numberOfLoaded);

      await query.get().then(async function (querySnapshot) {
        ////console.log(vm.messages);
        (vm.realtimeMessages = []), (vm.messages = []);
        await querySnapshot.forEach(async function (doc) {
          var message = await doc.data();
          message.datetime = moment
              .unix(message.lastMessageAt.seconds)
              .format("DD/MM/YY");
              // Rohin got rid ^ of the HH:mm temporarliy until we get it in AM/PM
            //Add property datetime to extract the date and time from timestamps and add to order object
              try {
                // console.log(message);
                await vm.messages.push(message);
              } catch (error) {
                //insert alert here
              }
        });
      });
    },

    queryRealtimeMessages(latestTimestamp) {
      var vm = this;
      this.loadedRealtimeQuery = true;
      const messagesRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("inbox");

      messagesRef
        .where("lastMessageAt", ">", latestTimestamp)
        .onSnapshot(function (qSnapshot) {
          qSnapshot.forEach(async function (doc) {
            var message = await doc.data();
          message.datetime = moment
              .unix(message.lastMessageAt.seconds)
              .format("DD/MM/YY HH:mm");

            if (vm.realtimeMessages.length > 0) {
              var arrTimestamp =
                vm.realtimeMessages[vm.realtimeMessages.length - 1]
                  .lastMessageAt.seconds +
                "." +
                vm.realtimeMessages[vm.realtimeMessages.length - 1]
                  .lastMessageAt.nanoseconds;
              var docTimestamp =
                doc.data().lastMessageAt.seconds +
                "." +
                doc.data().lastMessageAt.nanoseconds;
              if (arrTimestamp !== docTimestamp) {
                try {
                vm.realtimeMessages.push(message);
                // console.log(message);
                await vm.fetchMessages();
              } catch (error) {
                //insert alert here
              }
              }
            } else {
              try {
                vm.realtimeMessages.push(message);
                // console.log(message);
                await vm.fetchMessages();
              } catch (error) {
                //insert alert here
              }
            }
          });
        });
    },
    async infiniteHandler1($state) {
      var lengthBeforeFetch = this.messages.length;
      var latestTimestampFromNonRealtimeQuery

      this.numberOfLoaded += 15;
      await this.fetchMessages();
      if (this.messages.length !== lengthBeforeFetch) {
        $state.loaded();
      } else {
        $state.complete();
      }
      if (this.messages.length > 0) {
      latestTimestampFromNonRealtimeQuery  = this.messages[0].lastMessageAt;
      } else {
        latestTimestampFromNonRealtimeQuery = firebase.firestore.Timestamp.now();
      }


      if (this.loadedRealtimeQuery === false) {
              this.queryRealtimeMessages(latestTimestampFromNonRealtimeQuery);
      }
      
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.container{
  margin: 0px;
  padding: 0px;
  padding-top: 64px;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgb(252, 252, 252);
}
.header-title{
  font-family: Google Sans;
  font-size: 25px;
  height: fit-content;
  margin-bottom: 15px;
  margin-left: 15px;
  color:rgb(133, 133, 133);
}


// start of ChatComponent style

// @charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Manrope:300,400,500,600,700&display=swap&subset=latin-ext");

[data-theme=purple] {
  --theme-color: #9f7aea;
  --button-color: #9f7aea;
  --button-bg-color: rgba(159, 122, 234, 0.12);
}

[data-theme=green] {
  --theme-color: #38b2ac;
  --button-color: #38b2ac;
  --button-bg-color: rgba(56, 178, 171, 0.15);
}

[data-theme=orange] {
  --theme-color: #ed8936;
  --button-color: #ed8936;
  --button-bg-color: rgba(237, 137, 54, 0.12);
}

.blue {
  background-color: #0086ff;
}

.purple {
  background-color: #9f7aea;
}

.green {
  background-color: #38b2ac;
}

.orange {
  background-color: #ed8936;
}

* {
  outline: none;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  background-color: #e5ecef;
  font-family: Google Sans;
  color: #273346;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.app {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100vw;
  margin: 0 auto;
  // overflow: hidden;
  position: absolute;
  bottom: 0px;
  top: 45px;
}

.header {
  height: 50px;
  width: 100%;
  // border-bottom: 1px solid #eef2f4;
  border: 1px solid #eef2f4;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.conversation-area,
.detail-area {
  width: 450px;
  flex-shrink: 0;
  height: 100%;
}

.detail-area {
  border-left: 1px solid #eef2f4;
  margin-left: auto;
  padding: 30px 30px 0 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.chat-area {
  flex-grow: 1;
}

.search-bar {
  height: 40px;
  z-index: 3;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #eef2f4;
  border: 1px solid #eef2f4;
  position: sticky;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
}
.search-bar input {
  height: 100%;
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  color: #273346;
  padding: 0 54px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23c1c7cd'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 25px 48%;
  font-family: Google Sans;
  font-size: 17px;
}
.search-bar input::placeholder {
  color: #a2a2a2;
}
.active-chatter{
  height: 40px;
  z-index: 3;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #eef2f4;
  border-top: 1px solid #eef2f4;
  position: sticky;
  display: flex;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: #808080;
  font-size: 20px;
  font-family: Google Sans;
  position: absolute;
  top: 0;
}
.active-chatter span{
  margin-top: 10px;
  margin-left: 20px;
}

.logo {
  color: #0086ff;
  width: 38px;
  flex-shrink: 0;
  opacity: 1;
}
.logo svg {
  width: 100%;
}

.chat-bubble-logo {
  color: #0086ff;
  width: 38px;
  flex-shrink: 0;
  opacity: 1;
  margin-left: 10px;
}
.chat-bubble-logo svg {
  width: 100%;
}

.user-settings {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  flex-shrink: 0;
}
.user-settings > * + * {
  margin-left: 14px;
}

.dark-light {
  width: 22px;
  height: 22px;
  color: #9fa7ac;
  flex-shrink: 0;
}
.dark-light svg {
  width: 100%;
  fill: transparent;
  transition: 0.5s;
}

.user-profile {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.settings {
  color: #9fa7ac;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
}

.conversation-area {
  border-right: 1px solid #eef2f4;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
}

.msg-profile {
  min-width: 60px;
  min-height: 50px;
  max-width: 50px;
  max-height: 60px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 15px;
}
.msg-profile.group {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef2f4;
}
.msg-profile.group svg {
  width: 60%;
}

.msg {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  border: 1px solid rgb(241, 241, 241);
  border-top: none;
  font-family: Google Sans;
}
.msg:hover {
  background-color: rgba(238, 242, 244, 0.4);
}
.msg.active {
  background: linear-gradient(
   to right,
   rgba(238, 242, 244, 0.4) 0%,
   rgba(238, 242, 244, 0) 100%
  );;
  border-left: 4px solid #0086ff;
}
.msg.online:before {
  content: "";
  position: absolute;
  background-color: #23be7e;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 2px solid #fff;
  left: 50px;
  bottom: 19px;
}

.msg-username {
  margin-bottom: 4px;
  // font-weight: 600;
  font-size: 16px;
}

.msg-detail {
  overflow: hidden;
}

.msg-content {
  // font-weight: 500;
  font-size: 13px;
  display: flex;
}

.msg-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #969eaa;
}

.msg-date {
  font-size: 14px;
  color: #c0c7d2;
  margin-left: 3px;
}
.msg-date:before {
  content: "•";
  margin-right: 2px;
}

.add {
  position: sticky;
  bottom: 25px;
  background-color: #0086ff;
  width: 60px;
  height: 60px;
  border: 0;
  border-radius: 50%;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'%3e%3cpath d='M12 5v14M5 12h14'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 28px;
  box-shadow: 0 0 16px #0086ff;
  margin: auto auto -55px;
  flex-shrink: 0;
  z-index: 1;
  cursor: pointer;
}

.overlay {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 340px;
  flex-shrink: 0;
  background: linear-gradient(
   to bottom,
   rgba(255, 255, 255, 0) 0%,
   rgba(255, 255, 255, 1) 65%,
   rgba(255, 255, 255, 1) 100%
  );;
  height: 80px;
}

.chat-area {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.chat-area-header {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;

  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: linear-gradient(
   to bottom,
   rgba(255, 255, 255, 1) 0%,
   rgba(255, 255, 255, 1) 78%,
   rgba(255, 255, 255, 0) 100%
  );;

}
.chat-area-profile {
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.chat-area-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 280px;
}


@media (max-width: 780px) {
  .chat-area-title {
    margin-left: 0px;
  }
}

.chat-area-main {
  flex-grow: 1;
}

.chat-msg-img {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.chat-msg-profile {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: -20px;
  position: relative;
}

.chat-msg-date {
  position: absolute;
  left: calc(100% + 12px);
  bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: #c0c7d2;
  white-space: nowrap;
}

.chat-msg {
  display: flex;
  padding: 0 20px 45px;
}
.chat-msg-content {
  margin-left: 12px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.chat-msg-text {
  background-color: #f1f2f6;
  padding: 10px;
  border-radius: 10px 10px 10px 0;
  line-height: 1.5;
  font-size: 14px;
  font-family: Google Sans;
  // font-weight: 500;
}
.chat-msg-text + .chat-msg-text {
  margin-top: 10px;
}

.chat-msg-text {
  color: var(--chat-text-color);
}

.owner {
  flex-direction: row-reverse;
}
.owner .chat-msg-content {
  margin-left: 0;
  margin-right: 12px;
  align-items: flex-end;
}
.owner .chat-msg-text {
  background-color: #0086ff;
  color: #fff;
  border-radius: 10px 10px 0 10px;
}
.owner .chat-msg-date {
  left: auto;
  right: calc(100% + 12px);
}

.chat-msg-text img {
  max-width: 300px;
  width: 100%;
}

.chat-area-footer {
  display: flex;
  border-top: 1px solid #eef2f4;
  width: 100%;
  padding: 10px 20px;
  align-items: center;
  background-color: white;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  left: 0;
}

.chat-area-footer svg {
  color: #9fa7ac;
  width: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.chat-area-footer svg:hover {
  color: var(--#9fa7ac);
}
.chat-area-footer svg + svg {
  margin-left: 12px;
}

.chat-area-footer input {
  border: none;
  color: #273346;
  background-color: #f8f8fa;
  padding: 12px;
  border-radius: 6px;
  font-size: 15px;
  margin: 0 12px;
  width: 100%;
}
.chat-area-footer input::placeholder {
  color: #a2a2a2;
}

.detail-area-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detail-area-header .msg-profile {
  margin-right: 0;
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.detail-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.detail-subtitle {
  font-size: 12px;
  font-weight: 600;
  color: #c0c7d2;
}

.detail-button {
  border: 0;
  background-color: #f0f7ff;
  padding: 10px 14px;
  border-radius: 5px;
  color: #0086ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
}
.detail-button svg {
  width: 18px;
  margin-right: 10px;
}
.detail-button:last-child {
  margin-left: 8px;
}

.detail-buttons {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.detail-area input {
  background-color: transparent;
  border: none;
  width: 100%;
  color: #273346;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23c1c7cd'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 100%;
  font-family: Google Sans;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #eef2f4;
  padding: 14px 0;
}
.detail-area input::placeholder {
  color: #919ca2;
}

.detail-changes {
  margin-top: 40px;
}

.detail-change {
  color: #919ca2;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #eef2f4;
  padding: 14px 0;
  display: flex;
}
.detail-change svg {
  width: 16px;
  margin-left: auto;
}

.colors {
  display: flex;
  margin-left: auto;
}

.color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
}
.color.selected {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='%23fff' stroke-width='3' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.color:not(:last-child) {
  margin-right: 4px;
}

.detail-photo-title {
  display: flex;
  align-items: center;
}
.detail-photo-title svg {
  width: 16px;
}

.detail-photos {
  margin-top: 30px;
  text-align: center;
}

.detail-photo-title {
  color: #919ca2;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}
.detail-photo-title svg {
  margin-right: 8px;
}

.detail-photo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  grid-template-rows: repeat(3, 60px);
}
.detail-photo-grid img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  object-position: center;
}

.view-more {
  color: #0086ff;
  font-weight: 600;
  font-size: 15px;
  margin: 25px 0;
}

.follow-me {
  text-decoration: none;
  font-size: 14px;
  width: calc(100% + 60px);
  margin-left: -30px;
  display: flex;
  align-items: center;
  margin-top: auto;
  overflow: hidden;
  color: #9c9cab;
  padding: 0 20px;
  height: 52px;
  flex-shrink: 0;
  position: relative;
  justify-content: center;
}
.follow-me svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.follow-text {
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.follow-me:hover .follow-text {
  transform: translateY(100%);
}
.follow-me:hover .developer {
  top: 0;
}

.developer {
  position: absolute;
  color: #919ca2;
  font-weight: 600;
  left: 0;
  top: -100%;
  display: flex;
  transition: 0.3s;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
  width: 100%;
  height: 100%;
}

.developer img {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  object-fit: cover;
  margin-right: 10px;
}

.dark-mode .search-bar input,
.dark-mode .detail-area input {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%236f7073'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
}
.dark-mode .dark-light svg {
  fill: #ffce45;
  stroke: #ffce45;
}
.dark-mode .chat-area-group span {
  color: #d1d1d2;
}

.chat-area-group {
  flex-shrink: 0;
  display: flex;
}
.chat-area-group * {
  border: 2px solid #fff;
}
.chat-area-group * + * {
  margin-left: -5px;
}
.chat-area-group span {
  width: 32px;
  height: 32px;
  background-color: #f0f7ff;
  color: #0086ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}


//Changed from 780 to 3000 to make inbox its own page always. Rohins implementation 780
@media (max-width: 3000px) {
  .detail-area {
    display: none;
  }
  .chat-area{
    display: none;
  }
  .conversation-area{
    width: 100vw;
  }
}

// @media (max-width: 780px) {
//   .conversation-area{
//     width: 100%;
//   }

//   .detail-area {
//     display: none;
//   }

//   .search-bar {
//     margin-left: 0;
//     flex-grow: 1;
//   }
//   .search-bar input {
//     padding-right: 10px;
//   }
// }

// end of ChatComponent style
</style>