<template>
  <div>
    <div v-if="results.length > 0">
      <ul class="results-container search-section__search-results">
        <li
          v-for="result in results"
          :key="result.id.raw"
          class="search-section__search-result"
        >
          <SearchResult :result="result" />
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="no-items">No items listed</div>
    </div>
  </div>
</template>

<script>
import SearchResult from "./SearchResult";

export default {
  components: {
    SearchResult,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
  methods: {
    log() {
      console.log(this.results);
    }
  }
};
</script>

<style>
.search-section__search-results {
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
}

.search-section__search-result {
  width: 100vw;
  background: white;
  margin: 10px auto !important;
  /* min-width: 450px; */
  max-width: 450px;
  /* min-width: 450px; */
}

@media (min-width: 450px){
  .search-section__search-result {
    min-width: 450px;
  }
}

.no-items{
  font-size: 16px;
  margin-top: 40px;
  text-align: center;

  text-shadow: 0px 0px 10px rgb(255, 255, 255);
  color: #3bcd8eea;
  font-family: Google Sans;
  font-weight: 500;
}
</style>
