<template>
  <div>
    <div class="form-container">
      <div class="form-card">
        <div @click="disableAlert" class="alert alert-error" v-if="alert.type ==='error'">{{ alert.msg }}</div>
        <div class="form-header">
          <div class="title">Login</div>
          <!-- <div class="tagline">Sign in to your Urbangrow</div> -->
        </div>
        <form action="#" @submit.prevent="submit">
          <input
            class="form-input"
            id="name"
            placeholder=" "
            type="email"
            v-model="form.email"
            required
            autofocus
          />
          <label class="form-label" for="name">Email</label>
          <input
            class="form-input"
            id="pass"
            type="password"
            placeholder=" "
            required
            v-model="form.password"
          />
          <label class="form-label" for="pass">Password</label>
          <div class="guest">
            Don't have an account?
            <span class="links" @click="toRegister">Sign Up</span>
          </div>

          <div class="links" @click="toPasswordRecovery">Forgot password?</div>
          <div class="login-bar">
            <button type="submit" class="next">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import store from "../store";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.user.data) next({ path: "/" });
    else next();
  },
  data() {
    return {
      alert: {
        type: null,
        msg: null,
      },
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    },
    submit() {
      var vm = this;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.$router.replace({ path: "/browsefarms" });
          this.$router.go(0);
        })
        .catch((err) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            
              err.message
          );
        });
    },
    toPasswordRecovery() {
      this.$router.push({ name: "RecoverPassword" });
    },
    toRegister() {
      this.$router.push({ path: "/register" });
    },
  },
  mounted() {
    let jquery = document.createElement("script");
    jquery.setAttribute("src", "https://code.jquery.com/jquery-2.1.1.min.js");
    document.head.appendChild(jquery);

    let materialize = document.createElement("script");
    materialize.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.5/js/materialize.min.js"
    );
    document.head.appendChild(materialize);
  },
};
</script>



<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/5.4.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  position: sticky;
  top: 55px;
}

.alert:hover {
  cursor: pointer;
}

.alert:before {
  padding-right: 12px;
}

.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
}

.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 7px;
}

/* start of form style */
.form-container {
  display: flex;
  height: 100vh;
  font-family: Google Sans;
  user-select: none;
  background: #fff;
}
.form-card {
  background: #fff;
  width: 412px;
  height: auto;
  padding: 20px 36px;
  border-radius: 8px;
  /* border: 1px solid #cacaca; */
  margin: auto;
  margin-top: 60px;
}
.form-header {
  text-align: center;
  font-weight: 400;
}
.title {
  padding: 15px 0;
  font-size: 24px;
}
.tagline {
  font-size: 16px;
  padding-bottom: 18px;
}
.form-label {
  display: block;
  position: absolute;
  padding: 0 5px;
  width: auto;
  color: #5f6368;
  background: #fff;
  transition: all 150ms ease;
  transform: translate(12px, -37px);
  cursor: text;
  font-weight: 200;
}
.form-input {
  padding: 16px;
  margin-top: 20px;
  font-size: 17px;
  background: #fff;
  width: calc(100% - 0px);
  border: 1px solid #cacaca;
  border-radius: 5px;
  font-weight: 200;
  /* Remove textarea inner shadow on Mobile Chrome & Safari (iPhone) */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none !important;
}
.form-input:focus {
  outline: none;
  /* Compensate increased border with padding */
  padding: 15px;
  border: 2px solid #42b983e5;
}
.form-input:not(:placeholder-shown) + .form-label,
.form-input:focus + .form-label {
  transform: translate(8px, -62px);
  font-size: 13px;
}
.form-input:focus + .form-label {
  color: #42b983e5;
}
.links {
  color: #42b983e5;
  font-size: 16px;
  padding-top: 10px;
  cursor: pointer;
  font-weight: 500;
}
.guest {
  margin-top: 32px;
  font-size: 16px;
  color: #5f6368;
}
.login-bar {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}
.next {
  width: 100%;
  margin-left: auto;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  background: #42b983e5;
  font-weight: 500;
  font-size: 16px;
}
.next:hover {
  background-color: #349469;
}
.links:hover {
  color: #349469;
}

@media only screen and (max-width: 600px) {
  .card {
    border: none;
  }
}

/*  end of form style */
</style>