 /* eslint-disable */
 
import Vue from "vue";
import Vuex from "vuex";
import { db } from "./main.js";
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex);
export default new Vuex.Store({
  //strict: true,
  plugins: [createPersistedState()],
  state: {
    user: {
      loggedIn: false,
      data: null,
      cart: {},
      cities: [],
      counters: {},
      eventListenerExists: false,
      prevRoute: null,
      homeHeading: null,
      globalCityOrUser: "user",
      itemFilters: {
      },
      totalPrice: 0,
      profile: {
        lat_lon: [],
        name: "",
        phone: "",
        address: "",
        addressTwo: "",
        country: "",
        postal_code: "",
        dpUrl: "",
        type: "",
        radius: 150,
        stripeAccountId: null,
        savedPaymentMethodsAndBanks: null,
      },
      sections: {
        about: null,
        events: {
          activeEvents: null,
          allEvents: null,
          allEventTypes: {},
          availableEventTypes: {},
        },
        contact: {
          hours: null,
        },
        services: {},
        jobs: {}
      },
    }
  },
  getters: {
    user(state) {
      return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_PROFILE(state, data) {
      state.user.profile = data;
    },
    SET_DP(state, url) {
      state.user.profile.dpUrl = url;
    },

    SET_SECTIONS(state, data) {
      state.user.sections = data;
    },
    SET_GLOBALCITYORUSER(state, data) {
      state.user.globalCityOrUser = data;
    },
    SET_EVENTS(state, data) {
      state.user.sections.events = data;
    },
    SET_CONTACT(state, data) {
      state.user.sections.contact = data;
    },
    SET_SERVICES(state, data) {
      state.user.sections.services = data;
    },
    SET_JOBS(state, data) {
      state.user.sections.jobs = data;
    },
    SET_CITIES(state, data) {
      state.user.cities = data;
    },
    SET_TOTAL_PRICE(state, data) {
      state.user.totalPrice = data;
    },
    SET_COUNTERS(state, data) {
      state.user.counters = data;
    },
    SET_HOME_HEADING(state, data) {
      state.user.homeHeading = data;
    },
    ADD_TO_COUNTER(state, data) {
      if (data.operation === "add") {
        state.user.counters[data.counter][data.categoryOrType] += 1
      } else {
        state.user.counters[data.counter][data.categoryOrType] = 1
      }
    },

    //CART MUTATIONS
    ADD_ITEM(state, item) {
      console.log(item);
      if (item !== undefined && item !== null) {
        //if (item.category !== "event") {
        if (!state.user.cart[item.user_id]
          && !state.user.cart[item.userID]) {
          if (item.user_id) {
            state.user.cart[item.user_id] = {}
          } else if (item.userID) {
            state.user.cart[item.userID] = {}
          }
        }

        if (state.user.cart[item.userID] !== null
          && state.user.cart[item.userID] !== undefined) {
          if (item.category === "event") {
            state.user.cart[item.userID][item.eventId] = item;
          } else {
            state.user.cart[item.userID][item.docId] = item
          }
        } else if (state.user.cart[item.user_id] !== null
          && state.user.cart[item.user_id] !== undefined) {
          state.user.cart[item.user_id][item.id] = item;
        } else {
          //console.log("item trying to add to cart is undefined or null");

        }


        //This is for elastic search items (from browseFarmProducts)
        //} 
        /*else {
          if (state.user.cart[item.userID] === null) {
            state.user.cart[item.userID] = {}
          }
          state.user.cart[item.userID][item.eventId] = item;
        } */
      } else {
        //console.log("product trying to add to cart is undefined or null");
      }



    },

    REMOVE_ITEM(state, item) {
      var userId, itemId;
      //Items are either added from the single page (firebase) or through a browse page (elastic). so their properties differ.
      // eslint-disable-next-line no-unused-vars
      var source;
      if (item.category !== "event") {
        if (item.docId) {
          source = "firebase";
          userId = item.userID;
          itemId = item.docId
        } else {
          source = "elastic"
          userId = item.user_id;
          itemId = item.id;
        }
        delete state.user.cart[userId][itemId];
      } else {
        userId = item.userID;
        delete state.user.cart[userId][item.eventId];
      }

      if (Object.keys(state.user.cart[userId]).length === 0) {
        delete state.user.cart[userId];
      }

    },
    SET_CART(state, data) {
      state.user.cart = data;
    },
    CALC_CART_TOTAL(state, data) {
      state.user.totalPrice = data;
    },
    SET_ITEM_FILTERS(state, data) {
      state.user.itemFilters = data;
    },
    SET_PAYMENT_METHODS(state, data) {
      state.user.savedPaymentMethodsAndBanks = data;
    },
  },
  actions: {
    //Reset vueX. Not doing so results in bugs when switching between accounts
    resetStore({ commit }) {
      
      //console.log("after logged in")
      commit("SET_USER", null);
      commit("SET_CART", {});
      commit("SET_SECTIONS", null);
      commit("SET_TOTAL_PRICE", 0);
      //commit("SET_ITEM_FILTERS", null);
      commit("SET_COUNTERS", {});
      commit("SET_CITIES", []);
      commit("SET_GLOBALCITYORUSER", "user");
      commit("SET_HOME_HEADING", "Please select a valid address in User Settings. Till then, default map shows farms within 100000 miles of USA.")
      commit("SET_PROFILE", {
        lat_lon: [],
        name: "",
        phone: "",
        address: "",
        addressTwo: "",
        country: "",
        postal_code: "",
        dpUrl: "",
        type: "",
        radius: 150,
        stripeAccountId: null,
        savedPaymentMethodsAndBanks: null,
      });
      //console.log("before logged in")
      commit("SET_LOGGED_IN", false);
    },


    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
        });
      } else {
        commit("SET_USER", null);
      }
    },

    setGlobalCityOrUser({commit}, cityOrUser) {
      commit("SET_GLOBALCITYORUSER", cityOrUser);
    },


    fetchProfile({ commit }) {
      var docRef = db.collection("profile").doc(this.state.user.data.uid);

      docRef.get()
        .then((doc) => {
          if (doc.exists) {
            var profile = {
              name: doc.data().name,
              address: doc.data().address,
              phone: doc.data().phone,
              postal_code: doc.data().postal_code,
              type: doc.data().type,
              addressTwo: doc.data().addressTwo,
              lat_lon: doc.data().lat_lon,
              radius: parseFloat(doc.data().radius),
              allEventNames: doc.data().allEventNames,
              availableEventNames: doc.data().availableEventNames,
              allItemNames: doc.data().allItemNames,
              availableItemNames: doc.data().availableItemNames,
              pickupInstructions: doc.data().pickupInstructions,
              stripeAccountId: doc.data().stripeAccountId,
            }
            commit("SET_PROFILE", profile);
            // console.log(doc.data().dpUrl);
            if (doc.data().dpUrl !== null && typeof doc.data().dpUrl !== "undefined") {
              commit("SET_DP", doc.data().dpUrl)
            }


          } else {
            console.log("No such document!");
          }
        }).catch(error => {
          console.log("Error getting document:", error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchSection({ commit, dispatch }, IDSectionArr) {
      //var vm = this;
      var userID = IDSectionArr[0];
      var section = IDSectionArr[1];
      var docRef;
      var doc;
      var shorthand = db.collection("profile").doc(userID).collection("sections").doc(section);


      if (section === "about") {
        docRef = shorthand;

        await docRef.get()
          .then(function (doc) {
            if (doc.exists) {
              if (section === "about") { commit("SET_SECTIONS", { about: doc.data() }); }
            } else {
              //console.log("No such document!");
            }
          }).catch((error) => {
            //console.log("Error getting document:", error);
          });
      }

      //events and jobs
      //else if (section === "events" || section === "jobs") {
        else if (section === "events" ) {

        docRef = shorthand.collection('allEvents');

        var allEvents = [];
        var activeEvents = [];
        var allEventTypes = {};
        var availableEventTypes = {};



        var snapshot = await docRef.where("availability", "!=", "deleted").get();
        snapshot.forEach(async (doc) => {
          allEvents.push(doc.data());

          if (typeof allEventTypes[doc.data().type] !== "number") {
            allEventTypes[doc.data().type] = 1;
          } else {
            allEventTypes[doc.data().type] += 1;
            if (allEventTypes[doc.data().type] <= 0) {
              delete allEventTypes[doc.data().type]
            }
          }
          ////console.log(doc.data());
          if (doc.data().availability === "available") {
            ////console.log(doc.data());
            activeEvents.push(doc.data());
            ////console.log(activeEvents);

            if (typeof availableEventTypes[doc.data().type] !== "number") {
              availableEventTypes[doc.data().type] = 1;
            } else {
              availableEventTypes[doc.data().type] += 1;
              if (availableEventTypes[doc.data().type] <= 0) {
                delete availableEventTypes[doc.data().type]
              }
            }
          }
        })

        if (section === "events") {
          commit("SET_SECTIONS", {
            events: {
              allEvents: allEvents,
            activeEvents: activeEvents,
            allEventTypes: allEventTypes,
            availableEventTypes: availableEventTypes,
            }
            
          });
        }



        

      }

      else if (section === "jobs") {
        docRef = shorthand;
        ////console.log(docRef);
        doc = await docRef.get();

        if (doc.exists) {
          commit("SET_SECTIONS", {
            jobs: {
              volunteeringDescription: doc.data().volunteeringDescription,
            }
          }
          );
        } else {
          //console.log("No Volunteering document!");
          commit("SET_SECTIONS", {
            jobs: {
              volunteeringDescription: null,
            }

          });
        }


      }


      //contact and hours
      else if (section === "contact") {
        docRef = shorthand;
        //console.log(docRef);
        doc = await docRef.get();
        ////console.log(doc.data());

        if (doc.exists) {
          { commit("SET_SECTIONS", { contact: doc.data() }); }
          //commit("SET_CONTACT", doc.data());
        } else {
          //console.log("No contact document!");
          { commit("SET_SECTIONS", { contact: null }); }
        }

      }

      //Services
      else if (section === "services") {
        docRef = shorthand;
        doc = await docRef.get();

        if (doc.exists) {
          { commit("SET_SECTIONS", { services: doc.data() }); }
        } else {
          { commit("SET_SECTIONS", { services: null }); }
          //console.log("No services document!");
        }

      }



    },

    fetchCities({ commit }) {
      if (Object.keys(this.state.user.cities).length > 0 ) {
        return -1
      }
      var docRef = db.collection("cities").doc("listOfCities");

      docRef.get().then((doc) => {
        if (doc.exists) {
          commit("SET_CITIES", doc.data().cities);
        } else {
          //console.log("No such document!");
        }
      })
        .catch((error) => {
          //console.log("Error getting document:", error);
        })

    },

    fetchItemFilters({ commit }) {
      
      commit("SET_ITEM_FILTERS", {
        tags: [
          "Organic",
          "Free Range",
          "Raw",
          // "Vegan",
          "All Natural",
          "Hormone Free",
          "Non GMO",
          
        ],
        categories: [
          //"Event",
          "Vegetable",
          "Meat",
          "Fruit",
          "Dairy",
          "Eggs",
          "Seafood",
          "Commodity",
          "Crop",
          "N/A"
        ],
        types: {
          dairyTypes: [
            'Raw Cows Milk',
            'Raw Goat Milk',

          ],
          eggsTypes: [
            'chicken eggs',
            'ostrich eggs',
          ],

          vegetableTypes: [
          'Artichokes', 
          'Cabbage',
          'Eggplant',
          'Onions',
          'Sweetpotato',
          'Cucumbers',
          'Asparagus',
          'Carrots',
          'Green peas',
          'Peppers',
          'Spinach',
          'Tomatoes',
          'Broccoli',
          'Cauliflower',
          'Lettuce',
          'Potatoes',
          'Squash',
          'Beans',
          'Brussel sprts',
          'Cucumbers',
          'Mushrooms',
          'Pumpkins',
          'Sweet Corn',
          'Beets', "N/A"],

          meatTypes: ['Chicken',
          'Shrimp',
          'Polluck',
          'Bison',
          'Turkey',
          'Salmon',
          'Lamb/Mutton',
          'Lobster',
          'Beef',
          'Tuna',
          'Crab',
          'Rabbit',
          'Pork',
          'Tilapia',
          'Duck',
          'Deli Meats', 
          "N/A"],

          seafoodTypes: ['Shrimp',
          'AL Polluck',
          'Pangasius',
          'Lobster',
          'Haddock',
          'Kingfish',
          'Tuna',
          'Catfish',
          'Clams',
          'Grouper',
          'Yellowtail',
          'Sole',
          'Salmon',
          'Crab',
          'Mussels',
          'Flounder',
          'Marlin',
          'Snapper',
          'Tilapia',
          'Cod',
          'Scallops',
          'Bass',
          'Halibut',
          'Mahi Mahi',
          "N/A"],

          commodityTypes: [
          'Corn',
          'Cattle/calves',
          'Wheat',
          'Dairy/milk',
          'Aluminium',
          'Soybeans',
          'Hogs',
          'Coffee',
          'Cotton/lint',
          'Chicken Eggs',
          'Misc. crops',
          "Tobacco", 
          "Oil", 
          "Gas", 
          'Crude Petroleum',
          'Brent Petroleum',
          'Gasoline',
          'Diesel',
          "N/A"],

          cropTypes: [
          'Wheat',
          'Corn & Other Feedgrains',
          'Sugar & Sweeteners',
          'Tabacco',
          'Hay',
          'Fruit & Tree Nuts',
          'Cotton & Wool',
          'Vegetables & Pulses',
          'Greenhouse/Nursery',
          'Soybeans & Oil Crops',
          'Rice',
          'Aquaculture',
          "N/A"],

          fruitTypes: [
          'Apples',
          'Blackberries',
          'Grapefruit',
          'Lemons',
          'Nectarines',
          'Pears',
          'Strawberries',
          'Apricots',
          'Blueberries',
          'Grapes',
          'Limes',
          'Oranges',
          'Pineapples',
          'Tangerines',
          'Avacados',
          'Cherries',
          'Guavas',
          'Mandarins',
          'Papayas',
          'Plums',
          'Cantaloupe',
          'Bananas',
          'Cranberries',
          'Kiwifruit',
          'Mangoes',
          'Peaches',
          'Raspberries',
          'Watermelon', 
          "N/A"],
        },
        services: ["volunteering", "privateEvents", "consulting", "garden"]
      }
      )
    },

    selectHomeHeading({ commit }, homeHeading) {
      commit("SET_HOME_HEADING", homeHeading);
    },
    




    /* ----------------- START CART FUNCTIONS --------------------------*/
    addItem({ commit, dispatch }, item) {
      commit("ADD_ITEM", item);
      dispatch("calcTotal");
    },

    removeItem({ commit, dispatch }, item) {
      commit("REMOVE_ITEM", item);
      dispatch("calcTotal");
    },
    setCart({commit, dispatch}, cart) {
      commit("SET_CART", cart);
      dispatch("calcTotal");
    },
    calcTotal({ commit }) {
      var totalPrice = 0;
      var cart = this.state.user.cart;
      for (const seller in cart) {
        for (const item in cart[seller]) {
          totalPrice += parseFloat(cart[seller][item].price) * parseFloat(cart[seller][item].quantity);
        }
      }
      commit("CALC_CART_TOTAL", totalPrice)
    },

    retrievePaymentMethods({commit}) {
        var customerRef = db
          .collection("profile")
          .doc(this.state.user.data.uid)
          .collection("stripe")
          .doc("customer");
        
        var savedPaymentMethodsAndBanks = {};
  
        customerRef
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const docData = await doc.data();
              if (docData.cardInfoArr) {
                if (docData.cardInfoArr.length > 0) {
                  savedPaymentMethodsAndBanks.cardInfoArr = docData.cardInfoArr;
                }
              }
              if (docData.bankAccountArr) {
                if (docData.bankAccountArr.length > 0) {
                  savedPaymentMethodsAndBanks.bankAccountArr = docData.bankAccountArr;
                }
              }
              
              //console.log("Document data:", docData);
            } else {
              console.log("No such document!");
            }
          }).then(() => {
              commit("SET_PAYMENT_METHODS", savedPaymentMethodsAndBanks)
            
          }) 
          .catch((error) => {
            console.log("error:", error);
          });
      
    },

    /* ----------------- END CART FUNCTIONS --------------------------*/



  },
});


