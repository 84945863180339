<template>
  <span :class="{ 'selected-category': (activatedCategory > 0 && activatedCategory !== undefined), 'selected-type': activatedType }">
    <button v-show="category" class="btn btn-lg btn-toggle category" type="button" 
     data-toggle="button" aria-pressed="false" autocomplete="off">
      {{ computedCategory }}
    </button>
    <span>
      <button v-show="type" class="btn">{{ type }}</button>
    </span>
  </span>
</template>

<script>
//import firebase from "firebase";
//import store from "../store";
//import { mapGetters } from "vuex";
//import { db } from "../main.js";

export default {
  methods: {

    // Add active class to the current button (highlight it)

    // var btns = document.getElementsByClassName("btn");
    // for (var i = 0; i < btns.length; i++) {
    //   btns[i].addEventListener("click", function() {
    //   var current = document.getElementsByClassName("active");
    //   current[0].className = current[0].className.replace(" active", "");
    //   this.className += " active";
    //   });
    // }

  },
  mounted() {
    let jquery = document.createElement('script')
    jquery.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js')
    document.head.appendChild(jquery)

    let bootstrapJS = document.createElement('script')
    bootstrapJS.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha.6/js/bootstrap.min.js')
    document.head.appendChild(bootstrapJS)
  },
  computed: {
    computedCategory() {
      if (this.category === "Event") {
        return "Events";
      }
      return this.category;
    },
  },
  watch: {
    activated() {
      //console.log(this.activated);
    }
  },  
  props: {
    category: {
      required: false,
    },
    type: {
      required: false,
    },
    activatedCategory: {
      required: false,
    },
    activatedType: {
      required: false,
    }
  },
};
var btns = document.getElementsByClassName("btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function() {
      var current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active", "");
      this.className += " active";
      });
    }
</script>


<style scoped>
@import "https://fonts.googleapis.com/css?family=Quantico";

/* Button (defaults) */
.btn {
  color: rgb(95, 95, 95);
  font-family: Google Sans;
  padding: 0px 4px 0px 4px;
  border-radius: 3px;
  border-color: #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  width: calc((100%/8) - (6px));
  min-width: fit-content;
  margin: 3px 3px;
  /* Hover */
  /* Pressed */
  font-size: 13px;
  font-weight: normal;
  line-height: 13px;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0);
  height: 25px;
}

.selected-category .btn {
background-color: #42b983 !important;
color: white;
box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0);
}

.selected-type .btn {
background-color: #42b983 !important;
color: rgb(255, 255, 255);
box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0);
}

.category:active,
.category:focus,
.category:visited {
  /* border-bottom: 4px solid#ff5353; */
  background-color: #42b98377;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0);
}



@import "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha.6/css/bootstrap.min.css";

/* .btn-toggle {
  position: relative;
}
.btn-toggle.active {
  transition: background-color 0.1s;
}
.btn-toggle.active {
  background-color: #29b5a8;
}
.btn-toggle.btn-lg {
  position: relative;
} */
</style>