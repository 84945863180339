<template>
  <div class="BrowseFilter2">
    <div class="center">
      <span>View items by status: </span>

      <span v-if="filter === 'add-available' || filter === 'add-unavailable'">
        <BrowseCityPillButtons :city="'All'" :activated="true" class="buttons"/>
      </span>
      <span v-else class="buttons">
        <BrowseCityPillButtons :city="'All'" :activated="false" />
      </span>

      <span v-if="filter === null || filter === 'rm-available' || filter === 'add-unavailable' || filter === 'rm-unavailable'" @click="filter = 'add-available'">
        <BrowseCityPillButtons :city="'Available'" :activated="true" class="buttons"/>
      </span>
      <span v-else @click="filter = 'rm-available'" class="buttons">
        <BrowseCityPillButtons :city="'Available'" :activated="false" />
      </span>
      <span v-if=" filter === null || filter === 'rm-unavailable'  || filter === 'add-available' || filter === 'rm-available' " @click="filter = 'add-unavailable'">
        <BrowseCityPillButtons :city="'Unavailable'" :activated="true" class="buttons"/>
      </span>
      <span v-else @click="filter = 'rm-unavailable'" class="buttons">
        <BrowseCityPillButtons :city="'Unavailable'" :activated="false" class="buttons"/>
      </span>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BrowseCityPillButtons from "../../BrowseCityPillButtons";

export default {
  components: {
    BrowseCityPillButtons,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      filter: null,
    };
  },
  watch: {
    filter() {
      if (this.filter !== null) {
        var splitFilter = this.filter.split("-");
        var payload = {
          operation: splitFilter[0],
          filterField: "availability",
          filterVal: splitFilter[1],
          filterType: "all",
        };
        this.$emit("setAvailabilityFilter", payload);
      }
    },
  },
};
</script>

<style scoped>

.BrowseFilter2{
  /* outline: 1px solid black; */
  max-width: 445px;
  width: calc(100% - 15px);
  margin: auto;
  padding: 0 auto;
  margin-bottom: -9px;
  margin-top: 15px;
}

.center{
  width: fit-content;
  margin: auto;
}

.buttons{
  margin: 0px 3px;
}

</style>