<template>
  <button id="element" class="btn btn-border" :class="{ 'double-select': activated }">{{city}}</button>
</template>

<script>

export default {
  props: {
    city: {
      required: true,
      type: String
    },
    activated: {
      required: true,
      type: Boolean
    }
  },
  mounted() {
  }
};
</script>


<style scoped>
@import "https://fonts.googleapis.com/css?family=Quantico";


.double-select {
  background-color: rgba(255, 255, 255, 0.8) !important; 
  border: 1px solid rgb(218, 218, 218) !important; 
  color: rgb(90, 90, 90) !important;
  text-shadow: none !important;
  box-shadow: none !important;
}
/* Button (defaults) */
.btn {
  /* font-family: Google Sans; */
  padding: 0px;
  cursor: pointer;
  width: fit-content;
  margin: 3px 3px;
  padding-left: 8px;
  padding-right: 8px;

  font-size: 14px;
  height: 22px;
  line-height: 14px;

  text-align: center;
  border-radius: 3px;

  color: #ffffff;
  background-color: #42b983c0;
  border-width: 2px;
}
</style>