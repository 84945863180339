<template>
<div class="row">
  <div class="card">
    <div class="accordion">
    <input type="checkbox" name="collapse" id="handle1">
    <h2 class="handle">
        <span class="confirm-deny-button">
            <button class="confirm-button"><i class="fas fa-check"></i></button>
        </span>
        <label class="title" for="handle1"> Raspberries - 100 pieces</label>
        <span class="confirm-deny-button">
            <button class="deny-button"><i class="fas fa-ban"></i></button>
        </span>
    </h2>
      <div class="content">
        <div style="height: 100%;" class="card-body">
          <div class="form-group">
              <span class="order-subtitle">Buyer: </span>
              <span class="order-info">Husain the BBC</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Item: </span>
              <span class="order-info">Raspberries</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Quantity: </span>
              <span class="order-info">100 pieces</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Price: </span>
              <span class="order-info">$1/piece</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Total Earnings: </span>
              <span class="order-info">$100.0</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Delivery or Pickup: </span>
              <span class="order-info">Pickup</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Pickup Date: </span>
              <span class="order-info">1/29/2021</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Pickup Time: </span>
              <span class="order-info">1:30 PM</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Details: </span>
              <span class="order-info">details details details details details details details details details details details details</span>
          </div>
          <div class="form-group">
              <span class="order-subtitle">Message Buyer: </span>
              <span class="order-info"><img style="height: 20px;" src="https://static.thenounproject.com/png/423449-200.png"></span>
          </div>
        </div>
        <!-- <div class="">
            <input
            type="submit"
            @click="listPartnership"
            value="Send Counter Offer"
            class="btn btn-primary w-100"
            />
        </div> -->
      </div>
    </div> 
  </div>
</div>
</template>

<script>
import firebase from "firebase";
//import store from "../store";
import { mapGetters } from "vuex";
import { db } from "../main.js";
// import { db } from "../../../main.js"
// import store from "../../../store";

export default {
  mounted() {
  },
  components: {},
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      alertMsg: {
        isActive: false,
        msg: "",
        type: "",
      },
      imageIcon: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcROzjZULCGkCo2TTbq1S1IgNoiNSzJvHgW_Rw&usqp=CAU",
      imagePreviewIndex: 0,
      placeholder:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcROzjZULCGkCo2TTbq1S1IgNoiNSzJvHgW_Rw&usqp=CAU",
      tags: [
        "USDA Organic",
        "Free Range",
        "Vegan",
        "All Natural",
        "Hormone Free",
        "Non GMO",
      ],
      categories: [
        "Vegetable",
        "Meat",
        "Aquaculture",
        "Commodity",
        "Crop",
        "Fruit",
      ],
      item: {
        title: "",
        images: [],
        price: {
          small: null,
          medium: null,
          large: null,
        },
        quantity: {
          small: null,
          medium: null,
          large: null,
          approvalMin: null,
        },
        category: "",
        type: "",
        description: "",
        availability: "",
        tags: [],
      },
    };
  },
  methods: {

    listPartnership() {
      //Fix itemRef so this.numberOfItems gets the actual number of items this user
      //has
      var alertMsg = this.alertMsg;
      var itemRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("items")
        .doc(this.item.title);

      itemRef
        .set(this.item)
        .then(function () {
          console.log("Document successfully written!");
          alertMsg.msg = "Your profile has been updated!";
          alertMsg.type = "success";
          alertMsg.isActive = true;
          //store.dispatch("fetchProfile");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
          alertMsg = "Error updating your profile :(";
          alertMsg.type = "error";
          alertMsg.isActive = true;
          //store.dispatch("fetchProfile");
        });
    },
    uploadImage(e) {
      if (e.target.files[0] && this.item.images.length < 6) {
        var file = e.target.files[0];
        var itemNameInFirebaseStorage = this.item.title;
        var vm = this;
        console.log(file);
        if (itemNameInFirebaseStorage == "") {
          itemNameInFirebaseStorage = file.name;
        }

        var storageRef = firebase
          .storage()
          .ref("items/" + this.user.data.uid + "/" + itemNameInFirebaseStorage);

        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg",
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.put(file, metadata);
        console.log(uploadTask);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              //...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                console.log("File available at", downloadURL);
                vm.item.images.push(downloadURL);
              });
          }
        );
      }
    },
    deleteImage(img, index) {
      let image = firebase.storage().refFromURL(img);

      this.item.images.splice(index, 1);

      image
        .delete()
        .then(() => {
          console.log("image deleted");
        })
        .catch((error) => {
          console.log("error deleting image: " + error);
        });
    },
    imagePreviewSelect(action) {
      var maxIndex = this.item.images.length - 1
      if (action == "prev" && this.imagePreviewIndex == 0) {
        this.imagePreviewIndex == maxIndex;
      } else if (action == "next" && this.imagePreviewIndex == maxIndex){
        this.imagePreviewIndex == 0;
      } else {
        if (action =="prev") {
          this.imagePreviewIndex--
        } else {
          this.imagePreviewIndex++
        }
      }
    }
    
  },

};
</script>


<style scoped>
/* start of createevent style */
@import 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.min.css';
.confirm-deny-button{
    min-height: 40px;
    height: 100%;
    /* border: 1px solid red; */
    /* float: right; */
    font-size: 20px;
    padding-top: 9px;
    max-width: 13%;
}
.confirm-button{
    color: rgb(36, 187, 124);
    padding-left: 10px;
}
.confirm-button :hover{
    color: rgb(15, 131, 83);
}
.deny-button{
    color: rgb(255, 137, 137);
    padding-right: 10px;
}
.deny-button :hover{
    color: rgb(219, 57, 57);
}
.btn-primary{
  font-size: 17px;
  background-color: #42b983;
  border-style: none;
}
.btn-primary:hover{
  background-color: #369a6d;
}
.card{
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
  border: 0px solid #ddd;
  font-family: Google Sans;
}
.order-subtitle{
  margin-bottom: 8px;
  color: #757575;
  font-size: 12px;
}
.order-info{
    margin-bottom: 8px;
  color: #757575;
  font-size: 12px;
  font-weight: 500;
}
.title{
  margin: auto;
  color: #757575;
  cursor: pointer;
  font-weight: normal;
  padding: 10px;
  margin-left: 10px;
  width: 100%;
  max-width: 85%;
  height: fit-content;
  font-size: 14px;
  display: inline-block;
}
.gray-part{
  width: 400px;
  padding: 1%;
  display: inline-block;
  background-color: rgba(250,250,250,0.9);
  height: 1650px;
  margin: auto;
  margin-top: 5px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.btn-primary{
  font-family: Google Sans;
}
.card-body{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
}
.form-group{
    padding: 12px;
  border-bottom: 1px solid rgb(233, 233, 233);
  margin: 0px;
}

.preview-images-form-group{
  margin: 0px 20px;
}

.preview-images-row{
  margin-bottom: 20px;
}

.img-wrap {
  position: relative;
}
.preview-images-wrapper{
  /* border: 2px solid #ddd; */
  width: 70px;
  height: 70px;
  align-content: center;
  border-radius: 4px;
}
.preview-images{
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* margin: 1%; */
  border-radius: 4px;
}

.img-wrap span.delete-img {
  position: absolute;
  top: 0px;
  right: 0px;
}
.img-wrap span.delete-img:hover {
  cursor: pointer;
}

/* Next & previous buttons  */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

 /*On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(91, 227, 111, 0.8);
}


.upload-wrapper {
  border: 2px solid #ddd;
  border-radius: 5px;
  width: 85%;
  margin: 0 auto;
  height: 300px; 
  position: relative;
}

.upload-wrapper span.delete-img {
  position: absolute;
  top: 0px;
  right: 1px;
}

/* div for the image upload plus sign button */
.add-image-button {
  height: 60px;
  width: 60px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin: auto;
  padding:auto;
  align-content: center;
  z-index: 4;
  background-color: #42b983a8;
}

.add-image-button :hover {
 background-color: white;
 color: #42b983;
 border-color: #42b983;
}

/* for the image upload plus sign button */
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: 10;
}
/* for the image upload plus sign button */
.file-input__label {
  cursor: pointer;
  /* z-index: 999;  */
  text-align: center;
  border-radius: 4px;
  border-color: black;
  border-style: solid;
  font-size: 50px;
  background-color: rgba(255,255,255,1);
  color: #ddd;
  border: 2px solid #ddd;
  border-radius: 5px;
  /* height: 70px;
  width: 70px; */
  /* padding-top: 5px; */
  /* z-index: 10; */
  height: 60px;
  width: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}





.form-check-input{
  margin-left: -5px;
  padding-left: 0px;
}
.form-check-label{
  padding: 0px;
  margin-left: 20px;
  margin-bottom: -15px;
  font-weight: 400;
}
.form-control{
  font-size: 16px;
  padding: 4px;
  padding-left: 8px;
  color: rgb(167, 167, 167);
}
input::placeholder{
  color: rgb(167, 167, 167);
}


/* start of accordian css : https://codepen.io/OGOGOG/pen/YzGdreM */
.accordion > input[type="checkbox"] {
  position: absolute;
  /* left: -100vw; */
  display: none;
}

.accordion .content {
  overflow-y: hidden;
  height: 0px;
  transition: height 0.3s ease;
  border: 0px solid #ddd;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  height: auto;
  overflow: visible;
  border: 1px solid rgb(221, 221, 221);
}

.accordion label {
  /* display: block;
  font-size: 25px; */
  height: 40px;
}


.accordion {
  margin-bottom: 1em;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  padding: 0px;
  padding-top: 0px;
  /* border: 1px solid #e8e8e8; */
  border-top: 0;
}

.accordion .handle {
  margin: 0px;
  font-size: 14px;
  padding-top: 5px;
  /* line-height: 1.2em; */
  background-color: rgba(255, 255, 255, 0);
  z-index: -10;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  overflow-y: auto;

}

.accordion label {
  /* color: #757575;
  cursor: pointer;
  font-weight: normal;
  padding: 15px;
  margin-left:auto;
  width: 100%;
  background: #ffffff; */
}

.accordion label:hover,
.accordion label:focus {
  /* background: #f7f7f7; */
}

.accordion .handle label:before {
  font-family: 'fontawesome';
  font-family: FontAwesome; 
  content: "\25B3";
  display: inline-block;
  /* margin-right: 10px; */
  line-height: 1.556em;
  vertical-align: middle;
  margin-top: -3px;
}

.accordion > input[type="checkbox"]:checked ~ .handle label:before {
  content: "\25BD";
  font-size: 14px;
  line-height: 1.556em;
  /* color: black; */
  margin-top: 1px;
  color: #008fee;
}
.accordion > input[type="checkbox"]:checked ~ .handle label {
  content: "\25BD";
  font-size: 14px;
  line-height: 1.556em;
  /* color: black; */
  margin-top: -3px;
  color: #008fee;
  font-weight: 500;
}


/* end of accordian css */


/* end of createevent style */

</style>