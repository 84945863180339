<template>
  <div>
    <div id="overlay" v-if="isSidebarOn" @click="toggleSidebar"></div>
    <div class="nav-container">
      <div
        id="nav"
        class="side-nav"
        :class="{ 'side-nav-isSidebarOn': isSidebarOn }"
      >
        <div class="" @click="emptySearch">
          <div class="account-row" v-if="user.loggedIn" @click="toggleSidebar">
            <div class="profile-pic-col" v-if="user.profile.type === 'seller'">
              <router-link :to="'/user/' + user.data.uid + '/products'">
                <img
                  v-if="user.profile.dpUrl"
                  class="profile-pic"
                  :src="user.profile.dpUrl"
                />
                <img
                  v-else-if="user.profile.type === 'buyer'"
                  class="profile-pic"
                  src="https://i.ibb.co/hg8mjMr/unnamed.png"
                />
                <img
                  v-else
                  class="profile-pic"
                  src="https://xforgeassets002.xboxlive.com/pf-title-b63a0803d3653643-20ca2/77ea09af-16fc-47e1-a31d-f6b0367cb00e/farmlife_screenshot_1.jpg"
                />
              </router-link>
            </div>
            <div class="profile-pic-col" v-else>
              <router-link :to="'/settings'">
                <img
                  v-if="user.profile.dpUrl"
                  class="profile-pic"
                  :src="user.profile.dpUrl"
                />
                <img
                  v-else
                  class="profile-pic"
                  src="https://i.ibb.co/hg8mjMr/unnamed.png"
                />
              </router-link>
            </div>

            <div class="account-col">
              <div v-if="user.profile.type === 'seller'">
                <div>
                  <router-link :to="'/user/' + user.data.uid + '/products'">
                    <h6 class="account-name" v-if="user.profile.name">
                      {{ user.profile.name }}
                    </h6>
                    <h6 class="account-name" v-else>
                      "Change Name At Settings"
                    </h6>
                  </router-link>
                </div>
              </div>
              <div v-else>
                <router-link :to="'/settings'">
                  <h6 class="account-name" v-if="user.profile.name">
                    {{ user.profile.name }}
                  </h6>
                  <h6 class="account-name" v-else>Change Name At Settings</h6>
                </router-link>
              </div>
              <div>
                <router-link :to="'/settings'">
                  <h6 class="view-account">Account Settings</h6>
                </router-link>
              </div>
            </div>
          </div>

          <div
            class="menu-row"
            v-else
            @click="toggleSidebarAndRouter('/login', false)"
          >
            <i class="fas fa-sign-in-alt menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Login</span>
          </div>

          <div
            class="menu-row"
            v-if="!user.loggedIn"
            @click="toggleSidebarAndRouter('/register', false)"
          >
            <i class="fas fa-user-plus menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Sign Up</span>
          </div>

          <div
            class="menu-row"
            @click="
              toggleSidebarAndRouter(
                '/user/' + user.data.uid + '/products',
                true
              )
            "
            v-if="user.loggedIn && user.profile.type === 'seller'"
          >
            <i class="fas fa-store menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Sell</span>
          </div>

          <div
            class="menu-row"
            @click="toggleSidebarAndRouter('/browsefarms', true)"
          >
            <i class="fa fa-handshake menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Market </span>
          </div>

          <div
            class="menu-row"
            @click="toggleSidebarAndRouter('/orders', false)"
            v-if="user.loggedIn"
          >
            <i class="fas fa-dolly menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Orders &amp; Pickup</span>
          </div>

          <div
            class="menu-row"
            @click="toggleSidebarAndRouter('/inbox', false)"
            v-if="user.loggedIn"
          >
            <i class="fa fa-comment-alt menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Messages</span>
          </div>

          <div
            class="menu-row"
            @click="toggleSidebarAndRouter('/paymentMethods', true)"
            v-if="user.loggedIn"
          >
            <i class="fas fa-dollar-sign menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Payment</span>
          </div>
          <div
            class="menu-row"
            @click="toggleSidebarAndRouter('/paymentMethods', true)"
            v-if="user.loggedIn && user.profile.type === 'buyer'"
          >
            <i class="fas fa-user-group menu-icon" aria-hidden="true"></i>
            <span class="menu-title">Invite your Suppliers</span>
          </div>

          <div class="menu-row" v-if="user.loggedIn" @click.prevent="signOut">
            <span class="log-out-title">Log Out</span>
          </div>

          <div
            class="bottom-menu-row"
            @click="toggleSidebarAndRouter('/browsefarms', true)"
          >
            <!-- <img class="sidebar-logo" src="https://i.ibb.co/chtz2kY/Screen-Shot-2021-10-26-at-4-45-15-PM.png"> -->
            <span class="logo">farmbrah</span>
            <!-- <span class="bottom-menu-title">FarmBrah</span> -->
          </div>
        </div>
      </div>

      <div class="main" :class="{ 'side-nav-main-isSidebarOn': isSidebarOn }">
        <Cart class="cart" />
        <div class="open">
          <svg
            :class="{ 'hide-burger': isSidebarOn }"
            viewBox="0 0 448 512"
            width="18"
            title="bars"
            @click="toggleSidebar"
          >
            <path
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
          <!-- deleted this at the end of the follwong div tag:  v-if="!isSidebarOn"-->
          <div @click="toggleSidebarAndRouter('/browsefarms', true)">
            <div @click="changeSIV('')" to="/browsefarms" class="logo">
              farm brah
              <!-- <img class="logo-image" src="https://i.ibb.co/chtz2kY/Screen-Shot-2021-10-26-at-4-45-15-PM.png"> -->
            </div>
          </div>
          <div class="searchbar-section">
            <SearchBar
              v-model="searchInputValue"
              @selectSuggestion="changeSIV"
              @submit="handleFormSubmit"
              :autocompletedResults="autocompletedResults"
              :autocompletedSuggestions="autocompletedSuggestions"
              class="searchbar"
            />
          </div>
        </div>

        <a class="close">
          <svg class="svg-icon" viewBox="0 0 20 20">
            <path
              fill="none"
              d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
            ></path>
          </svg>
        </a>
      </div>
      <div v-if="user">
        <div v-if="user.profile">
          <div @click="stripeAlertActive = false"
            v-if="
              user.profile.type === 'seller' &&
              !user.profile.stripeAccountId &&
              stripeAlertActive 
            "
          >
          <router-link to="/paymentMethods"> Click text to connect stripe. Elsewhere to dismiss alert.</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import store from "../store";

import Cart from "./Cart";
import SearchBar from "./search/browseFarms/SearchBar";

import "regenerator-runtime/runtime";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { SearchDriver } from "@elastic/search-ui";

export default {
  components: {
    Cart,
    SearchBar,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    thereAreResults() {
      return this.searchState.totalResults && this.searchState.totalResults > 0;
    },
  },
  data() {
    return {
      isSidebarOn: false,
      stripeAlertActive: true,

      searchInputValue: "",
      sivToSearchResultProducts: "",
      searchState: {},
      resultsPerPage: 1000,
      sortBy: "relevance",
      driver: null,
      driverAvailable: false,
      lastEventWasRouterGo: false,
      arrMoves: [],
      linkFilter: null,
      lat_lon_str: null,
      autocompletedSuggestions: null,
      autocompletedResults: null,
      available_item_types: [],
      available_services: [],
      available_event_types: [],
    };
  },
  watch: {
    async driverAvailable() {
      var vm = this;

      this.driver.subscribeToStateChanges((state) => {
        this.searchState = state;
        ////console.log(state.filters);
        if (state.filters.length > 0) {
          this.$emit("lastFilteredUrl", window.location.href);
        }
        ////console.log(state.filters);
        ////console.log(state.autocompletedSuggestions);
        ////console.log(state.autocompletedResults);
        if (state.autocompletedResults.length > 3) {
          if (vm.searchInputValue !== "") {
            this.autocompletedResults = state.autocompletedResults.slice(0, 3);
          } else {
            this.autocompletedResults = null;
          }
        } else {
          this.autocompletedResults = state.autocompletedResults;
        }

        var suggestions = [];
        if (state.autocompletedSuggestions.documents) {
          for (var document of state.autocompletedSuggestions.documents) {
            ////console.log(document);
            if (document.suggestion.includes("http")) {
              //do nothing
              ////console.log("contains http");
            } else {
              suggestions.push(document);
              if (suggestions.length === 5) {
                break;
              }
            }
          }

          this.autocompletedSuggestions = suggestions;
        }

        ////console.log(this.searchState);
      });
      await this.restoreUIFromURL();
    },
    async searchInputValue() {
      await this.driver.setSearchTerm(this.searchInputValue, {
        shouldClearFilters: false,
        autocompleteSuggestions: true,
        autocompleteResults: true,

        refresh: false,
        //debounce: 500,
      });
      ////console.log("SIV watcher")
    },
  },
  methods: {
    emptySearch() {
      this.searchInputValue = "";
    },
    changeSIV(payload) {
      this.searchInputValue = payload;
      ////console.log(this.$router.currentRoute);
      ////console.log("changeSIV");
      this.handleFormSubmit();
    },

    async handleFormSubmit() {
      this.user.searchInputValue = this.searchInputValue;
      //console.log(this.$router.currentRoute);
      if (this.$router.currentRoute.name !== "Market") {
        await this.$router.replace("/loading/");
        await this.$router.replace("/browsefarms/");
      } else {
        await this.$router.replace("/loading/");
        await this.$router.replace("/browsefarms/");

        //console.log("couldn't replace");
      }
    },
    toggleSidebar() {
      this.isSidebarOn = !this.isSidebarOn;
    },
    toggleSidebarAndRouter(path, replace) {
      this.isSidebarOn = false;
      this.$router.push({ path: path });
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(async () => {
          await store.dispatch("resetStore");

          this.$router.replace({ path: "/browsefarms" });
          this.$router.go(0);
        });
    },
    async initDriver() {
      const connector = new AppSearchAPIConnector({
        endpointBase:
          "https://9418f3a3b99042b3991649e2c5fec3df.ent-search.us-central1.gcp.cloud.es.io",
        searchKey: "search-zyftvpix7rftzf7ocirgacdk",
        engineName: "profiles",
        hostIdentifier: "host-fuckyou",
      });

      const config = {
        debug: false,
        apiConnector: connector,
        urlPushDebounceLength: 1000000000,
        initialState: {
          resultsPerPage: this.resultsPerPage,
        },
      };
      this.driver = await new SearchDriver(config);
      this.driverAvailable = true;
    },
    async restoreUIFromURL() {
      const { searchTerm, sortField, resultsPerPage, filters } =
        this.driver.getState();

      //console.log(filters);

      if (this.user.searchInputValue !== "") {
        this.sivToSearchResultProducts = this.user.searchInputValue;
        this.searchInputValue = this.user.searchInputValue;
        this.user.searchInputValue = "";
      } else {
        //My addition
        this.sivToSearchResultProducts = searchTerm;
        this.searchInputValue = searchTerm;
      }

      // restoring UI from url query
      this.sortBy = sortField;
      this.resultsPerPage = resultsPerPage;
      await filters.forEach((filter) => {
        this[filter.field] = filter.values;
      });
    },
  },

  mounted() {
    this.initDriver();
  },
};
</script>



<style scoped>
@import "https://fonts.googleapis.com/css?family=Quantico";
@import url("https://fonts.googleapis.com/css2?family=Quattrocento+Sans:wght@700&display=swap");
@font-face {
  font-family: "Coco Gothic Regular";
  src: URL("https://storage.cloudconvert.com/tasks/f38e779c-4268-4797-8da9-d1ea9c2e58fc/Coco-Gothic-Bold-trial.woff2?AWSAccessKeyId=cloudconvert-production&Expires=1633932986&Signature=hX1%2BLPeRwHHhqLF1FqePa6SoKww%3D&response-content-disposition=attachment%3B%20filename%3D%22Coco-Gothic-Bold-trial.woff2%22&response-content-type=font%2Fwoff2")
    format("truetype");
}

/* font-awesoeme css */
@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";

@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

/* style for the menu inside the sidebar */

a:hover,
a:any-link:hover,
a:-webkit-any-link:hover {
  text-decoration: none;
}

.hide-burger {
  visibility: hidden;
}

.logo {
  margin-left: 10px;
  margin-right: -12px;
  margin-top: 0px;
  color: #3bcd8e;
  font-size: 15.5px;
  font-family: Google Sans;
  /* font-family: 'Coco Gothic Regular'; */
  /* font-family: 'Quattrocento Sans', sans-serif; */
  text-decoration: none;
  font-weight: 900;
  /* image logo */
  width: 55px;
  letter-spacing: 1px;
  line-height: 14px;
}
.logo-image {
  width: 40px;
}
.sidebar-logo {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -5.5px;
  color: #3bcd8e;
  font-size: 20px;
  font-family: Google Sans;
  /* font-family: 'Quattrocento Sans', sans-serif; */
  text-decoration: none;
  font-weight: 600;
  /* image logo */
  width: 32px;
  display: inline-block;
}
.searchbar-section {
  margin-left: 5px;
  width: calc(100vw - 140px);
}
.searchbar {
  width: calc(100vw - 133px);
  margin-left: 0px;
  position: fixed;
  margin-top: -15px;
}

.cart {
  float: right;
}

.account-row {
  width: 100%;
  border-bottom-style: solid;
  border-color: rgb(227, 227, 227);
  border-width: 1px;
  border: none;
  padding: 15px;
  padding-bottom: 10px;
}
.account-row:hover {
  /* border-color: rgb(191, 191, 191); */
  text-decoration: none;
}
.profile-pic-col {
  width: 100%;
}
.profile-pic {
  float: left;
  margin-right: 10px;
  margin-top: 0px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.account-col {
  margin-left: 1em;
  white-space: nowrap;
  overflow-x: auto;
}
.account-name {
  color: black;
  font-size: 14px;
  font-family: Google Sans;
  font-weight: 300;
  text-decoration: none;
  margin-top: 3px;
}

.account-name:hover {
  text-decoration: none;
}
.view-account {
  color: #42b983;
  font-size: 14px;
  margin-top: -2px;
  text-decoration: none;
}

.menu-row {
  width: 100%;
  border-bottom-style: solid;
  border-color: rgb(148, 137, 137);
  border-width: 1px;
  border: none;
  padding-left: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
  position: relative;
}
.menu-row:hover {
  /* border-color: rgb(191, 191, 191); */
  cursor: pointer;
}

.menu-icon {
  font-size: 18px;
  color: rgb(55, 55, 55);
  vertical-align: center;
  text-align: center;
  margin: 0px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu-title {
  color: rgb(55, 55, 55);
  font-size: 15px;
  font-weight: 350;
  font-family: Google Sans;
  text-align: center;
  margin-left: 40px;
}
.log-out-title {
  color: rgb(138, 138, 138);
  font-size: 15px;
  font-weight: 500;
  font-family: Google Sans;
  text-align: left;
  margin-left: 0px;
}

.bottom-menu-row {
  width: 100%;
  border-top-style: solid;
  border-color: rgb(227, 227, 227);
  border-width: 1px;
  border: none;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 0px;
}

.bottom-menu-row:hover {
  cursor: pointer;
}
.bottom-menu-icon {
  font-size: 20px;
  color: #42b983;
  vertical-align: center;
  text-align: center;
  margin: 0px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bottom-menu-title {
  color: #42b983;
  font-size: 23px;
  font-weight: 400;
  text-align: center;
  margin-left: 30px;
  font-family: Google Sans;
}

/* style for sidebar container and functionality of coming in and out */

.sidebar {
  width: 100%;
}

.nav-container {
  position: fixed;
  z-index: 11;
  background-color: white;
  width: 100vw;
  box-shadow: 0 0 7px rgba(174, 174, 174, 0.425);
}

.fa-file-invoice {
  z-index: 999999;
}
.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #000000;
}

.svg-icon circle {
  stroke: #4691f6;
  stroke-width: 1;
}

.main {
  padding: 0rem 0rem 0 1rem;
  transition: margin 0s;
  /* float: left; */
  background-color: #00000000;
  z-index: 1;
  max-width: 100vw;
}
/* @media (max-width: 376px) {
  .main {
    max-width: 375px;
  }
} */

.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  transform: translatex(-100%);
  transition: transform 0s;
  height: 100%;
  /*min-height: 550px; */
  width: 300px;
  z-index: 2000;
  /* overflow-y: auto; */
}
.side-nav-isSidebarOn {
  transform: translatex(0);
}
.side-nav-main-isSidebarOn {
  /* margin-left: 130px; */
  /* display: none; */
}

.side-nav:target ~ .main .open {
  display: none;
  z-index: 1;
}
.side-nav:not(:target) ~ .main .close {
  display: none;
  z-index: 1;
}
.side-nav > .links {
  display: flex;
  flex-direction: column;
}
.side-nav > .links > .link {
  font-size: 1.5rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid silver;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
}

.open,
.close {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background: none;
  font-size: 3rem;
  padding: 0.5rem 0rem;
  /* text-transform: uppercase; */
  text-decoration: none;
  cursor: pointer;
  height: 45px;
}
.open > svg,
.close > svg {
  /* margin-right: 0rem; */
  fill: #c2c2c2;
}

#overlay {
  position: fixed; /* Sit on top of the page content */

  width: 3000px; /* Full width (cover the whole page) */
  min-height: 100%; /* Full height (cover the whole page)  */
  top: 0;
  left: 300.1px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 100; /* Specify a stack order in case you're using a different order for other elements */
}

@media (max-width: 300px) {
  .side-nav {
    width: 80%;
  }
  #overlay {
    left: 80%;
  }

  .account-name {
    font-size: 13px;
  }
  .view-account {
    font-size: 12px;
  }
  .menu-icon {
    font-size: 13px;
  }
  .menu-title {
    font-size: 13px;
    margin-left: 30px;
  }
}
</style>