<template>
  <div class="container">
    <h1
      class="alert"
      v-bind:class="{
        'alert-success': alert.type == 'success',
        'alert-error': alert.type == 'error',
      }"
      @click="disableAlert"
    >
      {{ alert.msg }}"
    </h1>

    <div class="non-tabs" v-if="otherProfile">
      <img
        v-if="otherProfile.dpUrl"
        class="display-image"
        :src="otherProfile['dpUrl']"
        alt="https://kajabi-storefronts-production.global.ssl.fastly.net/kajabi-storefronts-production/themes/1247804/settings_images/8zVNrll6SumYIuiDpHAQ_file.jpg"
      />

      <!-- this is the combined image and title -->
      <div class="image-title" 
          v-bind:class="{
          'image-title': otherProfile.dpUrl,
          'title-no-image': !otherProfile.dpUrl
          }"
      >
        <span v-if="otherProfile.dpUrl" class="shadow">
          {{ otherProfile["name"] }}
        </span>

        <h5 v-if="!otherProfile.dpUrl" class="shadow-no-image">{{ otherProfile["name"] }}</h5>
        
        <span>
          <i class="fas fa-badge-check verified"></i>
        </span>
      </div>


      <!-- just image if they have uploaded image -->
      <!-- <div v-if="otherProfile.dpUrl" class="image-title">
        <span v-if="otherProfile.dpUrl" class="shadow">
        </span>
        <span>
          <i class="fas fa-badge-check verified"></i>
        </span>
      </div> -->

      <!-- text title for everybody -->
      <!-- <div class="image-title title-no-image">
        <h5 class="shadow-no-image">{{ otherProfile["name"] }}</h5>
        <span>
          <i class="fas fa-badge-check verified"></i>
        </span>
      </div> -->

    </div>

    <div v-if="otherProfile">
      <SearchSection />
    </div>
    
  </div>
</template>

<script>
import { db } from "../main.js";
import { mapGetters } from "vuex";
import store from "../store";

import SearchSection from "./search/browseFarmProducts/SearchSection.vue";

export default {
  components: {
    SearchSection,
  },

  beforeRouteLeave(to, from, next) {
    var vm = this;
    window.removeEventListener("popstate", vm.listenerHelperFunction, false);
    next();
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    loadedCounters() {
      var vm = this;
      var counters = {
        availableCategoryCounter: vm.availableCategoryCounter,
        availableTypeCounter: vm.availableTypeCounter,
        allCategoryCounter: vm.allCategoryCounter,
        allTypeCounter: vm.allTypeCounter,
      };
      store.commit("SET_COUNTERS", counters);
    },
  },
  data() {
    return {
      alert: {
        type: null,
        msg: null,
      },

      allItems: [],
      userID: this.$route.params.userID,

      availableCategoryCounter: {},
      allCategoryCounter: {},
      availableTypeCounter: {},
      allTypeCounter: {},
      loadedCounters: false,
      loadedOtherProfile: false,
      otherProfile: null,
    };
  },

  methods: {
    disableAlert() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    },
    goToChat() {
      this.$router.push({ path: `/inbox/${this.userID}` });
    },
    getProfile(userID) {
      var docRef = db.collection("profile").doc(userID);
      var vm = this;
      docRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            vm.otherProfile = await doc.data();
            vm.loadedOtherProfile = true;
            //console.log(vm.otherProfile);
          } else {
            vm.$set(vm.alert, "type", "error");
            vm.$set(vm.alert, "msg", "This user does not exist.");
          }
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "This user does not exist. Error: " + error);
        });
    },

    //This is a category counter function. Elastic does the actual fetching in search.
    async getItems() {
      var vm = this;
      if (!this.user.data) {
        return -1;
      }

      if (this.user.data.uid !== this.userID) {
        return -1;
      }

      var snapshot = await db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("items")
        .where("availability", "!=", "deleted");

      await snapshot.get().then(async function (querySnapshot) {
        vm.user.counters = {};
        vm.user.counters.availableCategoryCounter = vm.availableCategoryCounter;
        vm.user.counters.availableTypeCounter = vm.availableTypeCounter;
        vm.user.counters.allCategoryCounter = vm.allCategoryCounter;
        vm.user.counters.allTypeCounter = vm.allTypeCounter;
        await querySnapshot.forEach(async function (doc) {
          var item = doc.data();

          vm.categoryTypeCounter(1, item, vm.allCategoryCounter, "category");
          vm.categoryTypeCounter(1, item, vm.allTypeCounter, "type");

          if (item.availability === "available") {
            vm.categoryTypeCounter(
              1,
              item,
              vm.availableCategoryCounter,
              "category"
            );
            vm.categoryTypeCounter(1, item, vm.availableTypeCounter, "type");
          }
        });
      });
      vm.loadedCounters = true;
    },

    categoryTypeCounter(incValue, item, counterObj, typeOrCategory) {
      if (typeof counterObj[item[typeOrCategory]] !== "number") {
        if (item.availability === "available") {
          counterObj[item[typeOrCategory]] = incValue;
        }
      } else {
        counterObj[item[typeOrCategory]] += incValue;
        if (counterObj[item[typeOrCategory]] <= 0) {
          delete counterObj[item[typeOrCategory]];
        }
      }
    },

    //This listens for backwards navigation on the products page, if back is triggered, it does 1 extra back navigation.
    async listenerHelperFunction() {
      //console.log("listener triggered");
      var vm = this;

      vm.$router.go(-1);
      window.removeEventListener("popstate", vm.listenerHelperFunction, false);
    },

    async addEventListener() {
      var vm = this;
      window.addEventListener("popstate", vm.listenerHelperFunction, false);
    },
  },
  mounted() {
    this.getProfile(this.userID);
    this.getItems();
    this.addEventListener();
  },
};
</script>

<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/5.4.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  top: 20px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
  padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

.container {
  width: 100%;
  padding-top: 55px;
  max-width: 650px;
}

.non-tabs {
  height: fit-content;
}
.fa-heart {
  color: white;
  font-size: 25px;
  position: absolute;
  right: 35px;
  top: 65px;
}
.display-image {
  height: 100%;
  width: 97.5%;
  min-height: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin: auto;
  background-color: rgb(244, 244, 244);
}
.image-title {
  color: white;
  /*
  margin-top: -65px;
  margin-bottom: 35px;
  */
  margin-bottom: 25px;
  margin-left: 20px;
  margin-top: -30px;
  font-size: 24px;
  font-weight: 450;
  font-family: Google Sans;
  z-index: 9999;
}
.title-no-image {
  margin-top: 10px;
  padding-top: 8px;
  margin-bottom: 10px;
  margin-left: 5px;
  padding-left: 5px;
  height: 44px;
  border-radius: 5px;
  background-image: linear-gradient(to bottom right, rgb(241, 241, 241) , #6f6f6f12);
  margin-right: 10px;
  box-shadow: #0000001b 0px 0px 110px 0px;
}
.shadow {
  text-shadow: 0px 0px 10px rgb(0, 0, 0);
  border-radius: 5px;
}
.shadow-no-image {
  text-shadow: 0px 0px 10px rgb(255, 255, 255);
  color: #3bcd8eea;
  padding-left: 10px;
  font-size: 25px;
  margin: 0px auto;
  font-family: Google Sans;
  font-weight: 700;
}
.verified {
  font-size: 24px;
  color: #42b983cb;
  text-shadow: 0px 0px 1px rgb(156, 156, 156);
}
.image-subtitle {
  color: white;
  margin-top: -20px;
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: Google Sans;
  z-index: 9999;
  text-shadow: 0px 0px 10px rgb(63, 63, 63);
}
@media (max-width: 600px) {
  .image-title {
    font-size: 20px;
    margin-left: 10px;
    margin-top: -55px;
    margin-bottom: 25px;
    margin-top: -30px;
    margin-bottom: 5px;
    margin-left: 20px;
  }
  .image-subtitle {
    font-size: 14px;
    margin-left: 10px;
    margin-top: -20px;
    margin-bottom: 5px;
  }
  .title-no-image {
    margin-top: 0px;
    padding-top: 7px;
    margin-bottom: -7px;
    margin-left: 5px;
    padding-left: 5px;
    height: 41px;
    margin-right: 10px;
    background-image: linear-gradient(to bottom right, rgb(241, 241, 241) , #6f6f6f12);
  }
  .shadow-no-image {
    font-size: 21px;
    padding-top: 2px;
  }
}
.map-image {
  width: 97.5%;
  min-height: 50px;
  max-height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin: auto;
  margin-top: 15px;
  background-color: black;
}
.tabs {
  display: flex;
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  /* box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15); */
  /* padding: 0.4em; */
  border-radius: 99px;
  /* margin-right: 2%;
  margin-left: 2%; */
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8em;
  width: 100%;
  font-size: 1em;
  font-weight: normal;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  text-align: center;
  min-width: fit-content;
  margin-top: 0.5em;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid #dedede;
  font-family: Google Sans;
  color: rgba(0, 0, 0, 0.664);
  padding: 4px;
}

input[type="radio"]:checked + label {
  /* color: #185ee0; */
  box-shadow: inset 0 3px#42b983e5;
}

.map-container{
  position: relative;
}
/* Set the size of the div element that contains the map */
#map {
  height: 300px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
  z-index:1000000;
}
</style>






