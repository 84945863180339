import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from "vue";
import './plugins/bootstrap-vue'
import App from "./App.vue";
import router from "./routes/index";
import * as firebase from "firebase";
import store from "./store";
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import LoadScript from 'vue-plugin-load-script';
import VCalendar from 'v-calendar';
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

Vue.component("vue-timepicker", VueTimepicker);


Vue.config.productionTip = false;


var firebaseConfig = {
  apiKey: "AIzaSyCscI6mwDxajLNWFNDNwvIySvh7IPgk4IM",
  authDomain: "opengrow-71307.firebaseapp.com",
  databaseURL: "https://opengrow-71307.firebaseio.com",
  projectId: "opengrow-71307",
  storageBucket: "opengrow-71307.appspot.com",
  messagingSenderId: "831642686356",
  appId: "1:831642686356:web:daf9f7704f8e0cf18db581",
  measurementId: "G-BP943BR6VK"
};

//Initialize the Firebase SDK
firebase.initializeApp(firebaseConfig);

//Create a Firestore reference to store information
export const db = firebase.firestore();

// Initialize Cloud Functions through Firebase
export var cloudFunctions = firebase.functions();

// Initialize Firebase Analytics
firebase.analytics();

// Create a Firebase reference where GeoFire will store its information
export var sellerLocationsRef = firebase.database().ref('locations');


store.dispatch("fetchCities");
store.dispatch("fetchItemFilters");

firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    await store.dispatch("fetchUser", user);
    await store.dispatch("fetchProfile");
    await store.dispatch("retrievePaymentMethods");
  }
});

store.state.user.eventListenerExists = false;

Vue.use(LoadScript);
Vue.use(VueMaterial);
Vue.use(VCalendar);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
