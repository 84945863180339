<template>
  <div v-if="availability !== 'deleted'" >
    <div class="container card">
      <div class="spacer"></div>
      <h1
      @click="disableAlert"
        class="alert"
        v-bind:class="{
          'alert-success': alert.type == 'success',
          'alert-error': alert.type == 'error',
        }"
      >
        {{ alert.msg }}"
      </h1>

      <div class="row overall-row">
        <div class="non-images-col">
          <div class="row description-col">
            <div class="title">
              {{ result.name.raw }}
            </div>
            <div class="spacer"></div>
            <div class="sub-title-1" v-if="result.description">
              {{ result.description.raw }}
            </div>
            <div class="badge-col">
              <div v-if="result.tags.raw">
                <div v-if="result.tags.raw.length > 0">
                  <button
                    v-for="(tag, index) in result.tags.raw.slice(0, 3)"
                    :key="index"
                    disabled
                    style="border-color: #42b983"
                    class="btn"
                  >
                    {{ tag }}
                  </button>
                </div>
              </div>

              <div v-else>
                <button
                  disabled
                  style="border-color: #42b983; visibility: hidden"
                  class="btn"
                >
                  Placeholder 1
                </button>
              </div>
            </div>
          </div>
          <div class="price-col">
            <div class="price"><span class="price-number">${{ result.price.raw }}</span>/{{result.unit.raw}}</div>
          </div>

          <!-- new quantity selector start -->
          <div class="quantity-container">
            <div class="number-input">
              <button
                @click="changeNewQuantity('dec', $event)"
                class="plus-minus"
              >
                <i class="fas fa-minus"></i>
              </button>

              <div v-if="user.cart[result.user_id.raw]" class="">
                <div class="">
                  <input
                    v-if="user.cart[result.user_id.raw][result.id.raw]"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='')"
                    v-model="newQuantity"
                    class="quantity edit-quantity"
                    placeholder="Select"
                  />
                  <input
                    v-else
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='')"
                    v-model="newQuantity"
                    class=""
                    placeholder="Select"
                  />
                </div>
              </div>

              <input
                v-else
                type="number"
                min="0"
                oninput="validity.valid||(value='')"
                v-model="newQuantity"
                class=""
              />
              <button
                @click="changeNewQuantity('inc', $event)"
                class="plus-minus"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>

          <div class="button-col">
            <button v-if="!newQuantity" class="add-to-cart">Add to cart</button>
            <button v-else class="add-to-cart" @click="addToCart(result)">
              Add {{ newQuantity }} to cart ${{
                newQuantity * result.price.raw
              }}
            </button>
          </div>
        </div>

        <div class="images-col" v-if="result.images.raw">
          <div
            v-if="result.images.raw.length > 0"
            class="image-row"
            :style="{
              'background-image': 'url(' + selectedImg + ')',
            }"
          ></div>

          <div class="preview-images-form-group">
            <div class="lil-image-row" v-if="result.images.raw">
              <div v-if="result.images.raw.length > 0">
                <img
                  @click="changeSelectedImg(0)"
                  class="lil-image preview-images-margins"
                  :src="result.images.raw[0]"
                />
              </div>
              <div v-if="result.images.raw">
                <div v-if="result.images.raw.length > 1">
                  <img
                    v-for="(image, index) in result.images.raw"
                    :key="index"
                    @click="changeSelectedImg(index + 1)"
                    class="lil-image lil-image-with-margin preview-images-margins"
                    :src="result.images.raw[index + 1]"
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-else class="images-col">
          <div
            class="image-row-default"
          >
          </div>
        </div>
      </div>

      <div v-if="user.data">
        <div
          v-if="result.user_id.raw === user.data.uid"
          style="
            padding-top: 5px;
            margin-bottom: 40px;
            margin-top: 10px;
            border-top: 1px solid #d3d3d3;
          "
        >
          <button
            v-if="availability === 'unavailable'"
            class="mark-available"
            @click="markProductAs(result, 'available')"
          >
            Mark Available
          </button>
          <button
            style="float: left"
            v-if="availability === 'available'"
            class="mark-unavailable"
            @click="markProductAs(result, 'unavailable')"
          >
            Mark Unavailable
          </button>
          <button
            style="float: left"
            class="mark-removed"
            @click="markProductAs(result, 'deleted')"
          >
            Remove Listing
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { db } from "../../../main.js";
import store from "../../../store";
import { mapGetters } from "vuex";

export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    newQuantity() {
      if (this.newQuantity === 0) {
        this.newQuantity = null;
      }
    },
  },
  data() {
    return {
      alert: {
        type: null,
        msg: null,
      },
      newQuantity: null,
      availability: null,
      userID: this.$route.params.userID,
      selectedImg: null,
    };
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    },
    changeSelectedImg(index) {
      //console.log(index);
      this.selectedImg = this.result.images.raw[index];
    },
    changeNewQuantity(operation, event) {
      //console.log(event.target);
      //console.log();
      if (operation === "inc") {
        if (this.newQuantity === null) {
          this.newQuantity = 1;
        } else {
          this.newQuantity += 1;
        }
      } else {
        if (this.newQuantity <= 0) {
          this.newQuantity = 0;
        } else {
          this.newQuantity -= 1;
        }
      }
    },
    async markProductAs(result, newStatus) {
      ////console.log(result);
      ////console.log(newStatus);

      const timestamp = firebase.firestore.Timestamp.now();
      var batch = db.batch();
      var newStatusAtString = newStatus + "At";
      var oldAvailability = result.availability.raw;
      var docId = result.id.raw;
      var item;
      var vm = this;

      var itemRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("items")
        .doc(docId);

      var profileRef = db.collection("profile").doc(this.user.data.uid);

      try{
        await itemRef.get().then(async function (doc) {
        if (await doc.exists) {
          item = await doc.data();
        } else {
          // doc.data() will be undefined in this case
          //console.log("Oops. This page does not exist. Error: user id invalid");
          //add alert here
          vm.$set(vm.alert, "type", "error");
        vm.$set(
          vm.alert,
          "msg",
          "Oops. This page does not exist. Error: user id invalid"
        );
        }
      });
      } catch (error) {
        //add alert here
        vm.$set(vm.alert, "type", "error");
        vm.$set(
          vm.alert,
          "msg",
          "Could not fetch profile. Error:" + error
        );
      }

      //console.log(item);
      /* START PROTECT AGAINST DUPLICATE NEWSTATUS */

      if (await !item.lastStatusUpdate) {
        item.lastStatusUpdate = newStatus;
      } else if ((await item.lastStatusUpdate) === newStatus) {
        //console.log("duplicate status. Ended function")
        return -1;
      } else {
        item.lastStatusUpdate = newStatus;
      }

      /* END PROTECT AGAINST DUPLICATE NEWSTATUS */

      item.availability = newStatus;
      item[newStatusAtString] = timestamp;
      item.lastUpdatedAt = timestamp;

      //console.log(item);
      batch.set(itemRef, item, { merge: true });

      if (newStatus === "available") {
        await this.categoryTypeCounter(
          1,
          item,
          this.user.counters.availableCategoryCounter,
          "category"
        );
        await this.categoryTypeCounter(
          1,
          item,
          this.user.counters.availableTypeCounter,
          "type"
        );

        batch.update(profileRef, {
          availableItemNames: firebase.firestore.FieldValue.arrayUnion(
            item.name
          ),
          allItemNames: firebase.firestore.FieldValue.arrayUnion(item.name),
          allItemCategories: firebase.firestore.FieldValue.arrayUnion(
            item.category
          ),
          availableItemCategories: firebase.firestore.FieldValue.arrayUnion(
            item.category
          ),
          allItemTypes: firebase.firestore.FieldValue.arrayUnion(item.type),
          availableItemTypes: firebase.firestore.FieldValue.arrayUnion(
            item.type
          ),
        });
      } else {
        await this.categoryTypeCounter(
          -1,
          item,
          this.user.counters.availableCategoryCounter,
          "category"
        );

        await this.categoryTypeCounter(
          -1,
          item,
          this.user.counters.availableTypeCounter,
          "type"
        );

        var availableTypes = await Object.keys(
          this.user.counters.availableTypeCounter
        );
        var availableCategories = await Object.keys(
          this.user.counters.availableCategoryCounter
        );

        batch.set(
          profileRef,
          {
            availableItemCategories: availableCategories,
            availableItemTypes: availableTypes,
          },
          { merge: true }
        );

        if (newStatus === "unavailable") {
          batch.update(profileRef, {
            availableItemNames: firebase.firestore.FieldValue.arrayRemove(
              item.name
            ),
            allItemNames: firebase.firestore.FieldValue.arrayUnion(item.name),
          });
        }
        if (newStatus === "deleted") {
          await this.categoryTypeCounter(
            -1,
            item,
            this.user.counters.allCategoryCounter,
            "category"
          );
          await this.categoryTypeCounter(
            -1,
            item,
            this.user.counters.allTypeCounter,
            "type"
          );
          var allCategories = await Object.keys(
            this.user.counters.allCategoryCounter
          );
          var allTypes = await Object.keys(this.user.counters.allTypeCounter);

          if (oldAvailability === "available") {
            batch.update(profileRef, {
              availableItemNames: firebase.firestore.FieldValue.arrayRemove(
                item.name
              ),
            });
          }
          batch.update(profileRef, {
            allItemNames: firebase.firestore.FieldValue.arrayRemove(item.name),
            allItemCategories: allCategories,
            allItemTypes: allTypes,
          });
        }
      }

      batch
        .commit()
        .then(() => {
          //console.log("Success. Changed status batch :)");
          vm.availability = newStatus;
        })
        .catch((error) => {
          //add alert here
          vm.$set(vm.alert, "type", "error");
        vm.$set(
          vm.alert,
          "msg",
          "error committing changed status batch. Error: " + error
        );
          //console.log("error committing changed status batch. Error: " + error);
        });
    },

    categoryTypeCounter(incValue, item, counterObj, typeOrCategory) {
      if (typeof counterObj[item[typeOrCategory]] !== "number") {
        if (item.availability === "available") {
          counterObj[item[typeOrCategory]] = incValue;
        }
      } else {
        counterObj[item[typeOrCategory]] += incValue;
        if (counterObj[item[typeOrCategory]] <= 0) {
          delete counterObj[item[typeOrCategory]];
        }
      }
    },
    removeItem(item) {
      store.dispatch("removeItem", item);
    },
    addToCart(result) {
      var item = {};
      for (var property in result) {
        if (result[property] && property != "_meta") {
          item[property] = result[property].raw;
        }
      }
      this.$data.newQuantity = parseInt(this.$data.newQuantity, 10);

      //If new quantity is 0 remove it from cart
      if (this.$data.newQuantity === 0) {
        this.removeItem(item);
        this.$data.newQuantity = null;
        return -1;
      }

      if (
        this.$data.newQuantity > 0 &&
        Number.isInteger(this.$data.newQuantity)
      ) {
        item.quantity = this.$data.newQuantity;
        this.$data.newQuantity = null;
        store.dispatch("addItem", item);
      } else {
        console.log("Enter a valid quantity.");
      }
    },
  },

  mounted() {
    this.availability = this.result.availability.raw;
    if (this.result.images) {
      if (this.result.images.raw) {
        if (this.result.images.raw.length > 0) {
          this.selectedImg = this.result.images.raw[0];
        }
      }
    }
  },
};
</script>


<style scoped>
.row {
  margin-left: 0px;
}

.search-result__heading {
  text-align: center;
  margin: 0;
}

.search-result__body {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

.search-result__data {
  padding-top: 50px;
}

.search-result__flavor {
  margin: 0;
}

.search-result__image {
  flex: none;
  width: 256px;
  height: 387px;
}

/* browseFilter1Component.vue */
.btn {
  /* box-sizing: border-box; */
  /* display: inline; */
  /* position: relative; */
  color: rgb(52, 52, 52);
  font-family: Quantico;
  padding: 0px 1px 0px 1px;
  border-radius: 3px;
  border-color: #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  min-width: 80px;
  max-width: 80px;
  margin: 3px 0px;
  margin-right: 5px;
  /* Hover */
  /* Pressed */
  font-size: 11px;
  font-weight: normal;
  line-height: 18px;
  opacity: 1;
  display: block;
  float: left;
}

/* end */

@import url("https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.6.1/tailwind.min.css");

/* .container {
  margin: 0px;
  padding: 5px;
  width: 95vw;
  max-width: 450px;
  height: fit-content;
} */
.card {
  margin: 0px;
}
.overall-row {
}
.non-images-col {
  width: 52.5%;
  margin-right: 0 2.5%;
  margin-bottom: 2.5%;
}
.title {
  font-size: 17px;
  color: #132536;
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
}
.title-col {
  width: 100%;
  padding: 0px;
  max-height: 170px;
}
.description-col {
  width: 100%;
  padding: 0px;
  max-height: 170px;
  overflow: hidden;
  overflow-y: auto;
}
.price-col {
  width: 100%;
  padding: 0px;
  max-height: 170px;
  background-color: white;
}
.spacer {
  height: 8px;
}
.images-col {
  width: 42.5%;
  margin-left: 2.5%;
}
.image-row {
  width: 99%;
  height: 72%;
  max-height: 210px;
  margin-bottom: 8%;
  float: left;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-size: cover;
}
.image-row-default {
  width: 99%;
  height: 72%;
  margin-bottom: 8%;
  float: left;
  border-radius: 5px;
  border: 1px solid #ddd;
  /* background: url('https://fpapi.flourpicker.com/DefaultImage/noimage.png'); */
  background: url('https://i.ibb.co/vXDynY4/Screen-Shot-2022-06-06-at-11-52-32-PM.png');
  background-size: contain;
  /* background-repeat: no-repeat; */
}

.preview-images-form-group {
  /* new gray bar stuff */
  /* margin-bottom: 10px; */
  height: 19%;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: rgb(243, 242, 242);
  float: left;
  width: 100%;
  max-height: 47px;
}
.lil-image-row {
  width: 103%;
  /* width: 175px; */
  /* float: left; */
  background-size: cover;
  margin-right: 0%;
  max-height: fit-content;
}
.lil-image {
  width: 24%;
  height: fit-content;
  min-height: 47px;
  max-height: 47px;
  height: 19%;
  background-size: cover;
  border: 1px solid rgb(243, 242, 242);
  border-radius: 3px;
  float: left;
}
.lil-image-with-margin {
  margin-left: 2%;
  float: left;
}
.preview-images-margins {
  margin-right: 1.081px;
  margin-bottom: 20px;
  /* new gray bar stuff */
  margin-top: -1.5px;
  margin-left: 0px;
  outline: 2px solid white;
}

.sub-title-1 {
  font-size: 12px;
  color: #3e4348;
  width: 100%;
  display: block;
}
.badge-col {
  min-height: 80px;
  max-height: 80px;
  margin-top: 5px;
}
.price {
  font-size: 15px;
  color: #000000;
  text-align: right;
  margin-right: 10px;
}
.price-number {
  text-shadow: 0px 0px 10px rgb(255, 255, 255);
  color: #36bd83;
  font-family: Google Sans;
  font-weight: 700;
}

.button-col {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-left: 0.1em;
}
.add-to-cart {
  float: left;
  width: 100%;
  font-size: 1em;
  background-color: #42b983da;
  border-radius: 0.2em;
  border-width: 0.1em;
  border-color: #42b983da;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  padding: 0em;
  height: 23px;
  line-height: 17px;
  margin-left: -2px;
}
.add-to-cart:hover {
  border-color: #349469;
  background-color: #349469;
}
.see-more {
  float: left;
  width: 48%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 255);
  border-radius: 0.4em;
  border-width: 0.1em;
  border-color: #42b983;
  color: #42b983;
  font-weight: 500;
  padding: 0.1em;
  margin-top: 0.4em;
  height: 23px;
  line-height: 17px;
}
.mark-available {
  float: left;
  width: 48%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 255);
  border-radius: 0.3em;
  border-width: 0.1em;
  border-color: #42b983;
  color: #42b983;
  font-weight: 500;
  padding: 0.1em;
  margin-top: 0.4em;
  margin-right: 4%;
  height: 23px;
  line-height: 17px;
}
.mark-available:hover {
  border-color: #349469;
  color: #349469;
}
.mark-unavailable {
  float: left;
  width: 48%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 255);
  border-radius: 0.3em;
  border-width: 0.1em;
  border-color: #42b983;
  color: #42b983;
  font-weight: 500;
  padding: 0.1em;
  margin-top: 0.4em;
  margin-right: 4%;
  height: 23px;
  line-height: 17px;
}
.mark-unavailable:hover {
  border-color: #349469;
  color: #349469;
}
.mark-removed {
  float: left;
  width: 48%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 255);
  border-radius: 0.3em;
  border-width: 0.1em;
  border-color: #ff0048c8;
  color: #ff0048c8;
  font-weight: 500;
  padding: 0.1em;
  margin-top: 0.4em;
  height: 23px;
  line-height: 17px;
}
.mark-removed:hover {
  border-color: #d5003cc8;
  color: #d5003cc8;
}

/*  quantity selector start */
.quantity-container {
  font-size: 14px;
  height: 35px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 8px;
  margin-left: -5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  /* border: 1px solid #42b983; */
  border: none;
  display: inline-flex;
  border-radius: 5px;
  height: 25px;
  background-color: white;
}
.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 25px;
  cursor: pointer;
  position: relative;
}
.plus-minus {
  color: #42b983;
  font-size: 15px;
  margin: 0 5px;
}
.number-input input[type="number"] {
  width: 100%;
  padding: 10px;
  border: solid #ddd;
  border-width: 0 2px;
  font-size: 15px;
  height: 25px;
  font-weight: 400;
  text-align: center;
}
/*  quantity selector end */


.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  top: 20px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
  padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}
</style>
