<template>
  <div class="searchmap-container">
    <div v-show="false" id="renderLink">
      <router-link
        id="farmRouterLink"
        :to="'/user/' + markerFarmId + '/miniWebsite'"
        class="nav-link"
        >{{ markerFarmName }}</router-link
      >
    </div>
    <div class="BrowseCityPillButtons">
      <div class="inner overflow-x">
        <div v-if="user.cities">
          
          <div class="scroll">
            <div
              class="cities-tabs"
              v-for="(city, index) in user.cities"
              :key="index"
            >
            
            <div v-if="globalCityOrUser[3] === city[3]" @click="globalCityOrUser = 'user'">
              <BrowseCityPillButtons :city="city[3]" :activated="false" />
            </div>
            <div v-else @click="globalCityOrUser = city">
              <BrowseCityPillButtons :city="city[3]" :activated="true" />
            </div>
                
            </div>
          </div>
          
        </div>
      </div>
    </div>

    <div class="citiesAndMap">
      <div class="cities"></div>
      <div id="map" class="map"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import store from "../../../store"
import BrowseCityPillButtons from "../../BrowseCityPillButtons";
import store from "../../../store";



export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    BrowseCityPillButtons 
  },
  props: {
    results: {
      type: Array,
      required: false,
    },
    linkFilter: {
      type: Array,
      required: false,
    },
  },
  watch: {
    results() {
    ////console.log("results watcher", this.linkFilter );
    this.initMap(this.globalCityOrUser, true, "globalCityOrUser");
    },
    globalCityOrUser() {
      ////console.log("globalCityOrUser watcher");
      ////console.log(this.globalCityOrUser);
      store.dispatch("setGlobalCityOrUser", this.globalCityOrUser);
      this.initMap(this.globalCityOrUser, true, "globalCityOrUser");
    },
    linkFilter() {
      ////console.log("linkFilter  watcher");
      this.initMap(this.linkFilter, false, "linkFilter");
    },
  },
  data() {
    return {
      markerFarmId: "",
      markerFarmName: "",
      globalCityOrUser: "user",
      lat: null,
      lng: null,
      radius: null,
    };
  },
  methods: {
    //sets search location
    async setSearchLocationAndFilter(cityOrUser, emit) {
      // console.log("setSearchLocationAndFilter");
      var city = await cityOrUser;
      // console.log(city);
      var homeHeading; //This refers to the map filter heading for example farms within 500 mi of philadelphia

      if (this.user.profile.radius === null || this.user.profile.radius < 0) {
        this.user.profile.radius = 150; 
      }
      var condition;
      // console.log(this.globalCityOrUser);
      // console.log(this.user.profile.lat_lon);
      // console.log(this.user.profile.lat_lon.length > 1);
      if (this.globalCityOrUser === "user") {
        if (typeof this.user.profile.lat_lon === "object") {
          // console.log("issa object");
          condition = this.user.profile.lat_lon.length > 1;
        } else if (
          typeof this.user.profile.lat_lon === "undefined" ||
          this.user.profile.lat_lon === null
        ) {
          condition = false;
        }

        if (condition) {
          // console.log("valid address");
          this.lat = await this.user.profile.lat_lon[0];
          this.lng = await this.user.profile.lat_lon[1];
          this.radius = await this.user.profile.radius;
          homeHeading = "Farms within " + this.radius + " miles of " + this.user.profile.address.substring(0, 45) + "..."; 
        } else {
          

          // console.log(
          //   "Please select a valid address in User Settings. Till then, default map is USA"
          // );
          
          
          
          //This fires on user logout. Hard code lat, lng, and radius to consistently display results
          this.lat = 44.967243;
          this.lng = -103.771556;
          this.radius = 100000.6942069;
          homeHeading = "Login to see farms near you"
        }
      } else {
        // console.log("fourth");
        // console.log(city);
        this.lat = city[0];
        this.lng = city[1];
        this.radius = city[2];
        //console.log(city[3]);
        homeHeading = "Farms within " + parseInt(this.radius) + " miles of " + city[3] + ""; 
      }

      store.dispatch("selectHomeHeading", homeHeading);



      const payload = {
        center: this.lat + ", " + this.lng,
        distance: this.radius,
        unit: "mi",
      };
      if (emit === true) {
        this.$emit("locationFilter", payload);
      }
    },
    async initMap(cityOrUser, emit, parentFunction) {
      // console.log("initMap")
      if (parentFunction === "globalCityOrUser") {
        // console.log("parentFunction === 'globalCityOrUser'")
        await this.setSearchLocationAndFilter(cityOrUser, emit);
      } else {
        // console.log(this.linkFilter);
        this.radius = this.linkFilter[0].distance;
        var splitLinkFilter = this.linkFilter[0].center.split(", ");
        this.lat = parseFloat(splitLinkFilter[0]);
        this.lng = parseFloat(splitLinkFilter[1]);

        for (var city in this.user.cities ) {
          if (this.lat === this.user.cities[city][0] && 
          this.lng === this.user.cities[city][1]) {
            this.globalCityOrUser = this.user.cities[city];
          }
        }

        /*
        //console.log(
          "linkFilter changed lat lng",
          splitLinkFilter[0],
          splitLinkFilter[1]
        );
        */
      }

      ////console.log(this.lat);

      var farms = await this.results;
      //await //console.log(farms);

      var mapCenter = {
        lat: this.lat,
        lng: this.lng,
      };

      var mapzoom = 8;

      if (this.lat === 44.967243 &&
          this.lng === -103.771556 &&
          this.radius === 100000.6942069) {
            mapzoom = 3;
          }

      // console.log(this.lat, this.lng, this.radius);


      var mapStyle = [
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d6e2e6"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#cfd4d5"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7492a8"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "lightness": 25
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dde2e3"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#cfd4d5"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dde2e3"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7492a8"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dde2e3"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#588ca4"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a9de83"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#bae6a1"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c6e8b3"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#bae6a1"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#41626b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "saturation": -45
            },
            {
                "lightness": 10
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c1d1d6"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#a6b5bb"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#9fb6bd"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
            {
                "saturation": -70
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#b4cbd4"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#588ca4"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#008cb5"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit.station.airport",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": -5
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a6cbe3"
            }
        ]
    }
];

      

      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: mapCenter,
        zoom: mapzoom,
        styles: mapStyle,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,

      });

      var icon = {
        url: "https://i.ibb.co/YW55yBt/output-onlinepngtools-3.png",
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(48, 48),
    };

      for (var farm in farms) {
        ////console.log(        farms[farm].lat_lon_str.raw);
        var lat_lon = farms[farm].lat_lon_str.raw.split(",");
        ////console.log(farm.raw);
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(lat_lon[0]),
            lng: parseFloat(lat_lon[1]),
          },
          icon: icon,
          map: this.map,
          // icon: 'https://www.lowryservices.com/wp-content/uploads/2020/03/service-area.png',
        });

        this.attachFarmProfile(marker, farms[farm]);
      }
    },

    //await on console log makes the router-link get thr right markerFarmId for some reason
    //await //console.log(this.markerFarmId);

    async attachFarmProfile(marker, farm) {
      var vm = this;
      var farmRouteHTML = document.getElementById("farmRouterLink");
      
      // eslint-disable-next-line no-undef
      const infowindow = new google.maps.InfoWindow({
        content: farmRouteHTML,
      });

      marker.addListener("click", () => {
        ////console.log("You clicked on place:" +  farm.farmId);

        vm.markerFarmId = farm.id.raw;
        vm.markerFarmName = farm.name.raw;
        infowindow.open(marker.get("map"), marker);
      });
    },
  },
  created() {
    var vm = this;
        this.$loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAPS_API}`
    )
      .then(() => {
        this.globalCityOrUser = this.user.globalCityOrUser;

        if (!this.linkFilter || typeof this.linkFilter === "undefined") {
          if (this.globalCityOrUser === "user") {
              vm.initMap("user", true, "globalCityOrUser");
          }  else {
            //vm.$set(this.globalCityOrUser, 3, this.user.globalCityOrUser[3])
            vm.initMap(this.globalCityOrUser, true, "globalCityOrUser");
          }
        } else {
          vm.initMap("doesnt matter", "doesnt matter", "linkFilter");
        }
      })
      .catch(() => {
        //console.log("failed to fetch google maps script");
      });
  },
};
</script>

<style lang="scss" scoped>
.searchmap-container{
  height: 100%;
}
.inner {
  display: flex !important;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  width: fit-content;
  position: absolute;
  left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%) ;
    transform: translateX(-50%);
}

.BrowseCityPillButtons {
  width: 100%;
  height: 0px;
  padding: 0px;
  /* text-align: center; */
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 9;
}

.scroll{
  overflow-x: auto;
  // width: 100vw;
  // max-width: 100vw;
  background-color: rgba(255, 255, 255, 0.411);
}

.cities-tabs {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
  margin: 5px;
  padding-top: 6px;
}
.product {
  height: 80px;
}
.img-wrap {
  max-width: 100px;
  height: 100px;
}

#map {
  height: 100%;
  width: 100%;
}
.overflow-x {
  overflow-x: auto;
  white-space: nowrap;
}

.citiesAndMap{
  height: 100%;
}
</style>