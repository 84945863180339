<template>
  <div class="overall-container">
    <h1
      @click="disableAlert"
      class="alert"
      v-bind:class="{
        'alert-success': alert.type == 'success',
        'alert-error': alert.type == 'error',
        'alert-warning': alert.type == 'warning',
      }"
    >
      {{ alert.msg }}"
    </h1>
    <div v-if="user">
      <div v-if="user.data && user.profile">
        <div
          v-if="user.data.uid === userID && user.profile.type === 'seller'"
        ></div>
      </div>
    </div>

    <div v-show="editOrView === 'view'">
      <div v-if="loadedOtherProfile">
        <div class="address">
          <i class="fas fa-phone"></i>
          <a href="tel:"> {{ otherProfile["phone"] }} </a>
          <span class="prefers">
            text or
            <button
              @click="goToChat()"
              v-if="loadedOtherProfile"
              class="chat-link"
            >
              opengrow chat
            </button>
          </span>
        </div>
        <div class="title-row"></div>

        <div class="address">
          <i class="fa-solid fa-location-dot"></i>
          {{ otherProfile["address"] }}
        </div>
      </div>

      <div class="title-row"></div>

      <div id="map"></div>

      <div class="title-row"></div>
      <div v-if="user.data">
        <div v-if="userID === user.data.uid">
          <div class="title-row">
            <div class="days-times-col">
              <i class="fas fa-clock"></i>
            </div>
            <div class="hours-title">Pickup Hours</div>
          </div>
          <!-- start of edit hours transplant -->
          <div class="form-group days-times">
            <span v-for="(day, index) in daysArr" :key="index">
              <div v-if="day !== null">
                <span class="day-span">
                  {{ day }}:
                  <span class="times-span">
                    <input
                      class="times-input bg-green-200 hover:bg-green-300"
                      type="time"
                      v-model="newHoursStartArr[index]"
                    />
                    –
                    <input
                      class="times-input bg-green-200 hover:bg-green-300"
                      type="time"
                      v-model="newHoursEndArr[index]"
                    />
                  </span>
                </span>
              </div>
            </span>

            <input
              type="submit"
              @click="saveHours"
              value="Save new hours"
              class="btn btn-primary save-contact"
            />
          </div>

          <br />

          <div class="title-row">
            <div class="days-times-col">
              <img
                class="closed-sign-img"
                src="https://cdn-icons-png.flaticon.com/512/2949/2949701.png"
              />
            </div>
            <div class="hours-title">Holidays & one-off closed dates</div>
          </div>

          <button class="closed-dates-button" @click.stop="addDate">
            + Add Closed Dates
          </button>

          <div class="bg-white p-2 w-full border rounded">
            <v-date-picker
              v-model="selected.date"
              :min-date="new Date()"
              :max-date="maxDate"
              :disabled-dates="[
                { weekdays: recurringOffDays },
                ...selectedOffDates,
              ]"
            >
              <template #default="{ inputValue, togglePopover, hidePopover }">
                <div class="flex flex-wrap">
                  <template v-for="(date, i) in dates">
                    <button
                      v-if="i >= datesExclExpiredSplitIndex"
                      :key="date.date.getTime()"
                      class="flex items-center bg-green-200 hover:bg-green-300 closed-date-badges"
                      @click.stop="dateSelected($event, date, togglePopover)"
                      ref="button"
                    >
                      {{ date.date.toLocaleDateString() }}
                      <svg
                        class="w-6 h-6 text-gray-600 hover:text-indigo-600 ml-1 -mr-1"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        @click.stop="removeDate(date, hidePopover)"
                      >
                        <path d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>
                  </template>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="form-group">
            <input
              type="submit"
              @click="saveOffDays"
              value="Save closed dates"
              class="btn btn-primary save-contact"
            />
          </div>

          <br />

          <div class="title-row">
            <div class="days-times-col">
              <img
                class="instructions-img"
                src="https://cdn-icons-png.flaticon.com/512/2618/2618630.png"
              />
            </div>
            <div class="hours-title">Default Pickup Instructions</div>
            <div class="hours-sub-title">
              (customizable in item listing form)
            </div>
          </div>

          <textarea
            placeholder="Set default customer pickup instructions"
            class="form-control description"
            v-model="pickupInstructions"
          ></textarea>

          <div class="form-group">
            <input
              type="submit"
              @click="saveInstructions"
              value="Save instructions"
              class="btn btn-primary save-contact"
            />
          </div>
        </div>

        <div v-else>
          <div class="title-row">
            <div class="days-times-col">
              <i class="fas fa-clock"></i>
            </div>
            <div class="hours-title">Pickup Hours</div>
          </div>
          <div class="form-group days-times">
            <span v-for="(day, index) in daysArr" :key="index">
              <div v-if="day !== null">
                <span class="day-span">
                  {{ day }}:
                  <span
                    class="times-span-view bg-green-200"
                    v-if="newHoursStartArr[index] !== newHoursEndArr[index]"
                  >
                    {{ toAmPmNewHoursStartArr[index] }} -
                    {{ toAmPmNewHoursEndArr[index] }}</span
                  >
                  <span v-else class="times-span-view bg-green-200">
                    Closed
                  </span>
                </span>
              </div>
            </span>
          </div>
        </div>

      </div>

      
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import store from "../store";
import { db } from "../main.js";
import * as moment from 'moment'

export default {
  watch: {
    editOrView() {
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    //recurringOffDays is an object designed to show what recurring days of the week the farm is closed.
    recurringOffDays() {
      //weekday 1 is Sunday, weekday 7 is saturday
      var arr = [];
      for (var day in this.newHoursStartArr) {
        if (this.newHoursStartArr[day]) {
          this.newHoursStartArr[day] === this.newHoursEndArr[day]
            ? arr.push(parseInt(day))
            : null;
        }
      }
      return arr;
    },
    maxDate() {
      var currentDate = new Date();
      var maxYear = currentDate.getFullYear() + 1;
      currentDate.setFullYear(maxYear);
      return currentDate;
    },
    //This computed property gets us the index of the first non-expired date in the dates arr. Using a computed property 
    //here makes sense so that our selectedOffDaysArr in firestore reflects all off the selected dates for the company that 
    //weren't removed before they expired.
    datesExclExpiredSplitIndex() {
      var now = new Date();
      var count = 0;
      for (var date in this.dates) {
        if (this.dates[date].date < now) {
          count += 1;
        }
      }
      return count;
    },
    toAmPmNewHoursStartArr() {
      var arr = [];
      var startOrEndArr = this.newHoursStartArr;
      for (var time in startOrEndArr) {
        if (startOrEndArr[time] !== null) {
          arr.push(moment(startOrEndArr[time], 'HH:mm').format('h:mm A'))
        } else {
          arr.push(null);
        }
      }
      return arr
    },
    toAmPmNewHoursEndArr() {
      var startOrEndArr = this.newHoursEndArr;
      var arr = [];
      for (var time in startOrEndArr) {
        if (startOrEndArr[time] !== null) {
          arr.push(moment(startOrEndArr[time], 'HH:mm').format('h:mm A'))
        } else {
          arr.push(null);
        }
      }
      return arr
    },


  },
  data() {
    return {
      daysArr: [
        null,
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      dates: [],

      selectedOffDates: [],

      selected: {},
      pickupInstructions: "",

      alert: {
        type: null,
        msg: null,
      },

      userID: this.$route.params.userID,
      editOrView: "view",
      otherProfile: null,
      loadedOtherProfile: false,

      //1 is sunday, 7 is saturday
      newHoursStartArr: [
        null,
        "08:00",
        "08:00",
        "08:00",
        "08:00",
        "08:00",
        "08:00",
        "08:00",
      ],

      //1 is sunday, 7 is saturday
      newHoursEndArr: [
        null,
        "15:00",
        "15:00",
        "15:00",
        "15:00",
        "15:00",
        "15:00",
        "15:00",
      ],
    };
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
    },
    goToChat() {
      this.$router.push({ path: `/inbox/${this.userID}` });
    },

    /* START These functions help with the off days */

    //saveOffDays converts the date array to an object based on month and pushes it to the database

    saveInstructions() {
      var profileRef = db.collection("profile").doc(this.user.data.uid);
      var vm = this;
      console.log(this.pickupInstructions);
      profileRef
        .update({
          pickupInstructions: vm.pickupInstructions,
        })
        .then(() => {
          vm.$set(vm.alert, "type", "success");
          vm.$set(vm.alert, "msg", "Successfully updated pickup instructions");
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "Failed to update pickup instructions");
        });
    },
    saveOffDays() {
      var vm = this;
      vm.selectedOffDates = [];
      for (var date in vm.dates) {
        console.log(date);
        console.log(vm.dates)
        console.log(vm.dates[date]);
        // vm.dates[date].date.setHours(0, 0, 0, 0);
        vm.$set(
          vm.selectedOffDates,
          vm.selectedOffDates.length,
          vm.dates[date].date
        );
      }

      vm.dates.sort(function (a, b) {
        console.log(a);
        return a.date.getTime() - b.date.getTime();
      });

      var profileRef = db.collection("profile").doc(this.user.data.uid);

      profileRef
        .update({
          selectedOffDates: vm.selectedOffDates,
        })
        .then(() => {
          vm.$set(vm.alert, "type", "success");
          vm.$set(vm.alert, "msg", "Successfully updated off days");
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "Failed to update off days");
        });
    },
    async addDate() {
      //this.$set(this.$data.dates, this.$data.dates.length, date);

      this.dates.push({
        date: new Date(),
      });

      await this.$nextTick(() => {
        const btn = this.$refs.button[this.$refs.button.length - 1];
        btn.click();
      });
    },
    removeDate(date, hide) {
      this.dates = this.dates.filter((d) => d !== date);
      hide();
    },
    dateSelected(e, date, toggle) {
      this.selected = date;
      toggle({ ref: e.target });
    },
    /* END These functions help with the off days */

    async saveHours() {
      const IDContactArr = [this.user.data.uid, "contact"];
      var batch = db.batch();
      var vm = this;

      var sectionRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("sections")
        .doc("contact");

      var profileRef = db.collection("profile").doc(this.user.data.uid);

      batch.set(
        sectionRef,
        {
          hoursStartArr: this.newHoursStartArr,
          hoursEndArr: this.newHoursEndArr,
        },
        { merge: true }
      );
      batch.set(
        profileRef,
        {
          hoursStartArr: this.newHoursStartArr,
          hoursEndArr: this.newHoursEndArr,
          recurringOffDays: this.recurringOffDays,
        },
        { merge: true }
      );

      //Create array and save invalid ranges days to it
      var invalidDaysArr = [];
      for (var index in this.newHoursStartArr) {
        if (this.newHoursStartArr[index] > this.newHoursEndArr[index]) {
          invalidDaysArr.push(" " + this.daysArr[index]);
        }
      }

      if (invalidDaysArr.length > 0) {
        vm.$set(vm.alert, "type", "error");
        vm.$set(
          vm.alert,
          "msg",
          "Error updating hours. Please make sure you have a valid time range on" +
            invalidDaysArr.toString() +
            "."
        );
      } else {
        await batch
          .commit()
          .then(async () => {
            console.log("saved");
            console.log(vm.alert);
            vm.$set(vm.alert, "type", "success");
            vm.$set(
              vm.alert,
              "msg",
              "Successfully  updated hours and recurring off days."
            );
            console.log(vm.alert);
            await store.dispatch("fetchSection", IDContactArr);
          })
          .catch((error) => {
            vm.$set(vm.alert, "type", "error");
            vm.$set(vm.alert, "msg", "Error updating hours. Error" + error);
          });
      }
    },

    async getSections(userID) {
      const IDContactArr = [userID, "contact"];
      var vm = this;
      await store.dispatch("fetchSection", IDContactArr);

      const sections = this.user.sections;
      // if user does not have hours section
      if (!sections || typeof sections === "undefined") {
        // vm.$set(vm.alert, "type", "error");
        vm.$set(vm.alert, "type", "warning");
        vm.$set(vm.alert, "msg", 'This farm has not confirmed its hours of operation');
        return -1;
      }

      //if user does not have hours
      if (!sections.contact || typeof sections.contact === "undefined") {
        // vm.$set(vm.alert, "type", "error");
        vm.$set(vm.alert, "type", "warning");
        vm.$set(vm.alert, "msg", 'This farm has not confirmed its hours of operation');
        return -1;
      }

      if (sections.contact.hoursStartArr && sections.contact.hoursEndArr) {
        this.newHoursStartArr = this.user.sections.contact.hoursStartArr;
        this.newHoursEndArr = this.user.sections.contact.hoursEndArr;
      }
    },
    //contains initmap

    sortDbSelectedOffDates(dbArr) {
      var vm = this;
      for (var timestamp in dbArr) {
        var timestampSeconds = dbArr[timestamp].seconds * 1000;
        var date = new Date(timestampSeconds);
        // date.setHours(0);
        // date.setMinutes(0);
        // date.setSeconds(0);
        vm.$set(dbArr, dbArr.length, timestampSeconds);
        var dateObj = { date: date };
        vm.$set(vm.dates, vm.dates.length, dateObj);
      }
      vm.dates = [
        ...new Map(
          vm.dates.map((item) => [item["date"].getTime(), item])
        ).values(),
      ];

      vm.dates.sort(function (a, b) {
        return a.date.getTime() - b.date.getTime();
      });
    },
    getProfile(userID) {
      var docRef = db.collection("profile").doc(userID);
      var vm = this;
      docRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            vm.otherProfile = await doc.data();
            await vm.initMap();
            vm.loadedOtherProfile = true;
            vm.sortDbSelectedOffDates(vm.otherProfile.selectedOffDates);
            vm.pickupInstructions = doc.data().pickupInstructions;

            //Get all the selected off dates from the db and convert to Date object\
            //This needs refactoring to its own function
          } else {
            vm.$set(vm.alert, "type", "error");
            vm.$set(
              vm.alert,
              "msg",
              "Error Fetching profile. No such document in the database."
            );
          }
        })
        .catch(function (error) {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "Error Fetching profile. Error" + error);
        });
    },

    initMap() {
      if (
        !this.otherProfile.lat_lon ||
        typeof this.otherProfile.lat_lon === "undefined"
      ) {
        return -1;
      }
      var lat = this.otherProfile.lat_lon[0];
      var lng = this.otherProfile.lat_lon[1];

      var icon = {
        url: "https://i.ibb.co/YW55yBt/output-onlinepngtools-3.png",
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(65, 65),
      };

      var mapCenter = {
        lat: lat,
        lng: lng,
      };

      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: mapCenter,
        zoom: 12,
      });

      // eslint-disable-next-line no-unused-vars, no-undef
      const marker = new google.maps.Marker({
        position: mapCenter,
        map: this.map,
        icon: icon,
      });
    },
  },

  directives: {
    focus: {
      inserted() {
        focus();
      },
    },
  },

  mounted() {
    var vm = this;
    this.$loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAPS_API}`
    )
      .then(() => {
        vm.getProfile(this.userID); //contains initmap
        vm.getSections(this.userID);
      })
      .catch(() => {
        vm.$set(vm.alert, "type", "error");
        vm.$set(vm.alert, "msg", "Failed to fetch google maps script");
      });
  },
};
</script>


<style scoped>
@import url("//use.fontawesome.com/releases/v5.0.7/css/all.css");
@import url("https://pro.fontawesome.com/releases/v6.0.0/css/all.css");

.overall-container {
  margin: auto;
  width: 98%;
  font-family: Google Sans;
  color: rgb(75, 75, 75);
}
.title-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.location-title {
  font-size: 20px;
  float: left;
}
.hours-title {
  font-size: 20px;
}
.hours-sub-title {
  font-size: 16px;
}
.times-span {
  float: right;
}
.times-input {
  border-radius: 5px;
  padding: 0 5px;
}
.times-span-view {
  float: right;
  padding: 0.2vw 2vw;
  border-radius: 5px;
}
.closed-sign-img {
  width: 45px;
  margin-top: -13px;
  margin-right: 10px;
}
.instructions-img {
  width: 35px;
  margin-top: -5px;
  margin-right: 10px;
}
.address {
  font-size: 17px;
  font-weight: 500;
}
.prefers {
  font-size: 16px;
}
@media (max-width: 400px) {
  .hours-title {
    font-size: 18px;
  }
  .closed-sign-img {
    width: 40px;
    margin-top: -13px;
    margin-right: 10px;
  }
  .times-input {
    font-size: 15.5px;
  }
  .day-span {
    font-size: 15.5px;
  }
  .address {
    font-size: 13.5px;
  }
  .prefers {
    font-size: 13.5px;
  }
}

.chat-link-text {
  font-size: 20px;
  font-weight: 400;
  float: right;
}
@media (max-width: 500px) {
  .chat-link-text {
    font-size: 16px;
  }
}
.chat-link {
  color: #42b983;
}
.chat-link:hover {
  color: #369a6d;
}
.days-times {
  font-size: 17px;
  width: 100%;
}
.days-times-row {
  width: 100%;
  font-size: 17px;
  margin-left: 20px;
}
.days-times-col {
  float: left;
  height: 100%;
}
.fa-clock {
  margin-right: 5px;
  margin-top: 1px;
  font-size: 18px;
}
.fa-map-marker-alt {
  margin-right: 5px;
  font-weight: 500;
}
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.img-wrap {
  position: relative;
}
.img-wrap span.delete-img {
  position: absolute;
  top: -5px;
  right: 1px;
}
.img-wrap span.delete-img:hover {
  cursor: pointer;
}

/* Next & previous buttons  */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/*On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

div {
  margin-bottom: 10px;
  position: relative;
}

input[type="number"] {
  width: 100px;
}

input + span {
  padding-right: 30px;
}

input:invalid + span:after {
  position: absolute;
  content: "✖";
  padding-left: 5px;
}

input:valid + span:after {
  position: absolute;
  content: "✓";
  padding-left: 5px;
}

/* Set the size of the div element that contains the map */
#map {
  height: 250px;
  /* The height is 400 pixels */
  width: 100%;
  border-radius: 4px;
  /* The width is the width of the web page */
}

.save-contact {
  background-color: #42b983;
  font-size: 16px;
  border: none;
  width: 100%;
  margin-top: 10px;
}
.save-contact:hover {
  background-color: #369a6d;
}
.description {
  margin-bottom: 10px;
}
.closed-date-badges {
  font-size: 16px;
  font-weight: 500;
  color: rgb(92, 92, 92);
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 4px;
  margin-left: 8px;
  padding: 3px 8px;
}
.closed-date-badges:focus {
  border: 1px solid #42b983;
  outline: none;
}
.closed-dates-button {
  font-size: 18px;
  font-weight: 500;
  color: #369a6d;
  padding: 0 4px;
  margin-bottom: 15px;
}
.closed-dates-button:hover {
  color: #42b983;
}

.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  top: 20px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
  padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

.alert-warning {
  top: 365px;
  color: #b68200;
  background-color: #fff4c0;
  border: 1px solid darken(#b49000, 15%);
  display: block;
}
.alert-warning:before {
  content: "x";
  font-family: "FontAwesome";
  padding-right: 3px;
}
</style>