<template>
  <div class="container">
    <img
      class="image"
      v-if="result.dp_url"
      :src="result.dp_url.raw"
      @click="goToProfile"
    />
    <img
      @click="goToProfile"
      class="image"
      v-else
      src="https://xforgeassets002.xboxlive.com/pf-title-b63a0803d3653643-20ca2/77ea09af-16fc-47e1-a31d-f6b0367cb00e/farmlife_screenshot_1.jpg"
    />

    <div class="row row-margin-top" @click="goToProfile">
      <div class="spacer"></div>
      <div class="col">
        <div class="title-col">
          <div class="title">
            {{ result.name.raw }}
            <span> <i class="fas fa-badge-check verified"></i></span>
          </div>
        </div>
        <div class="badge-col-1">
          <span v-if="openNow" class="badge-1"
            ><span class="open-dot">&#x2B22;</span> open now</span
          >
        </div>
      </div>
    </div>
    <div class="row second-row" @click="goToProfile">
      <div class="spacer"></div>
      <div class="col">
        <div class="distance-col">
          <div class="distance" v-if="result.locality">
            {{ result.locality.raw }}
            <span v-if="result.administrative_area_level_1">, {{ result.administrative_area_level_1.raw }}</span>
          </div>
        </div>

        <div class="badge-col-2" v-if="result.all_item_categories">
          <div  v-if="result.all_item_categories.raw"> 
          <span v-for="(category, index) in result.all_item_categories.raw.slice(0,3)" :key="index" class="badge-2">{{category}}</span>
        </div>
        
      </div>
        </div>
    </div>
    <div class="row">
      <div class="col fourth-row">
        <SearchResultProducts
          :result="result"
          :searchInputValue="searchInputValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import SearchResultProducts from "./SearchResultProducts";

export default {
  components: {
    SearchResultProducts,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    searchInputValue: {
      type: String,
      required: true,
    },
    today: {
      required: true,
    },
  },
  data() {
    return {
      openNow: false,
    };
  },
  methods: {
    async goToProfile() {
      await this.$router.push({ path: "/loading" });
      await this.$router.replace({
        path: `/user/` + this.result.id.raw + "/products",
      });
    },
    //This function checks whether the farm is open now to add a style that indicates farm is currently open.
    async validateOpenNow(pickupTime) {
      if (!this.result.hours_start_arr.raw || !this.result.hours_end_arr.raw) {
        return -1;
      }

      var dateObj = pickupTime;
      var hoursOnDayStart = this.result.hours_start_arr.raw[this.today + 1];
      var hoursOnDayEnd = this.result.hours_end_arr.raw[this.today + 1];

      var splitStart = hoursOnDayStart.split(":");
      var splitEnd = hoursOnDayEnd.split(":");

      if (splitEnd[0] === "00") {
        splitEnd[0] = "24";
      }
      if (
        parseFloat(splitStart[0]) <= dateObj.getHours() &&
        dateObj.getHours() <= parseFloat(splitEnd[0])
      ) {
        if (dateObj.getHours() === parseFloat(splitStart[0])) {
          //If same hour as start hour check minutes
          if (dateObj.getMinutes() < parseFloat(splitStart[1])) {
            pickupTime = null;
          }
        }
        if (dateObj.getHours() === parseFloat(splitEnd[0])) {
          //If same hour as start hour check minutes
          if (dateObj.getMinutes() > parseFloat(splitEnd[1])) {
            pickupTime = null;
          }
        }
      } else {
        pickupTime = null;
      }

      if (pickupTime) {
        this.openNow = true;
      }

      return pickupTime;
    },
  },
  mounted() {
    if (this.result.hours_start_arr) {
      var now = new Date();
      if (now.getHours) this.validateOpenNow(now);
    }
  },
};
</script>

<style scoped>
@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";
@import "https://fonts.googleapis.com/css?family=Quantico";
@import "https://fonts.googleapis.com/css2?family=Nunito&display=swap";
.container {
  margin: auto;
  padding: 0px;
  box-shadow: 0 0 1px rgba(58, 58, 58, 0.6);
  border-radius: 4px;
  max-width: 98%;
}
@media (max-width: 376px) {
  .container {
    width: 95vw;
    /* width: 100vw; */
  }
}
.row {
  margin-bottom: 2px;
}
.row-margin-top {
  margin: 5px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.second-row {
  margin: 5px;
  margin-top: 16px;
  margin-bottom: 15px;
}
.fourth-row {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}
.third-row {
  display: block;
  align-content: center;
  padding-right: 0px;
  /* padding-left: 4px; */
}
.third-row-div {
  background-color: #cecece62;
  border-radius: 4px;
  font-size: 16px;
  width: fit-content;
  padding: 7px;
  float: left;
  margin-right: 18px;
  line-height: 16px;
}

.open-dot {
  color: #42b983e3;
}
.rating-star {
  font-size: 9px;
}
.verified {
  color: #42b9839a;
  font-size: 22px;
}

.image {
  height: 100%;
  width: 100vw;
  min-height: 130px;
  min-width: 250px;
  max-height: 130px;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.title {
  font-size: 24px;
  color: rgb(58, 58, 58);
  float: left;
  font-family: Google Sans;
  /* font-family: 'Nunito', sans-serif; */
}
.title-col {
  /* width: 80%; */
  float: left;
}
.spacer {
  height: 0.5em;
}
.badge-col {
  /* width: 20%; */
  float: right;
  padding: 0px;
}
.badge-1 {
  float: right;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.2px;
  text-transform: unset;
  line-height: 14px;
  height: 20px;
  text-align: center;
  font-family: Google Sans;
  padding: 6px;
  padding-top: 2px;
  margin-left: 6px;
  color: rgb(129, 129, 129);
  background-color: rgba(255, 255, 255, 0.473);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.226);
}
.distance {
  font-size: 15px;
  color: #132536;
  float: left;
}
.badge-2 {
  float: right;
  font-weight: 300;
  letter-spacing: 0px;
  text-transform: unset;
  text-align: center;
  font-family: Quantico;
  color: rgb(0, 0, 0, 0.6);
  background-color: rgba(0, 211, 180, 0.2);
  font-size: 11px;
  line-height: 12px;
  height: 20px;
  padding: 4px;
  margin-left: 4px;
  margin-top: 2px;
}
@media (min-width: 450px) {
  .badge-2 {
    letter-spacing: 0.1px;
    font-size: 12px;
    line-height: 11px;
    height: 20px;
    padding: 5px;
    margin-left: 4px;
    margin-top: 2px;
  }
}
@media (max-width: 450px) {
  .row {
    margin-bottom: 2px;
    margin-left: -5px;
    margin-right: -5px;
  }
  .second-row {
    margin-bottom: 20px;
  }
}

.distance-col {
  width: 38%;
  float: left;
}
.badge-col-2 {
  width: 62%;
  display: nowrap;
  float: right;
  overflow: hidden;
  margin-top: -1px;
}

.open-dot {
  color: rgba(0, 211, 179, 0.747);
}
</style>
