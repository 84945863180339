<template>
  <article @click="log" class="BrowseFilter2">
      <div  v-if="products.length > 0" class="inner">
        <div v-for="(product, index) in products" :key="index">
          <div class="item-container" @click="goToStore">
            <div class="item-info">
              
                <div class="item-title">{{product.name.raw}}</div>
                <div class="item-price">${{product.price.raw}}/unit</div>
            </div>
            <div class="item-image-box">
                <img class="item-image" v-if="product.images" :src="product.images.raw[0]">
                <img class="item-image" v-else src="https://wtop.com/wp-content/uploads/2017/06/raspberries-1880x1254.jpg">
            </div>
          </div>
        </div>
        <div class="item-container inner" @click="goToStore">
            <div class="item-info">
              
                <div class="see-all-products">See All Products</div>
            </div>
            <div class="item-image-box">
                <i class="fas fa-stream see-all-products" style="font-size: 25px;"></i>
            </div>
        </div>
      </div>
        
  </article>
</template>

<script>
import { SearchDriver } from "@elastic/search-ui";
import "regenerator-runtime/runtime";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    searchInputValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      driver: null,
      driverAvailable: false,
      searchState: {},
      products: [],
      sortBy: "relevance",
      user_id: null,
    };
  },
  watch: {
    driverAvailable() {
      this.getProducts();
    },
    sortBy(newSortBy) {
      // eslint-disable-next-line no-undef
      driver.setSort(newSortBy, "asc");
    },
    searchInputValue() {
      this.getProducts();
    },
  },

  methods: {
    log() {
      //console.log(this.products);
    },
    goToStore() {
      this.$router.push({path: '/user/' + this.result.id.raw + '/products'})
    },
    goToItem(product) {
      this.$router.push({path: '/user/' + this.result.id.raw + '/items/' + product.id.raw})
    },
    async getProducts() {
      this.products = [];
      this.driver.reset();
      this.driver.addFilter("user_id", this.result.id.raw, "all");
      ////console.log(this.searchInputValue);
      await this.driver.setSearchTerm(this.searchInputValue, {
        shouldClearFilters: false,
        debounce: 1,
      });
    },
    initDriver() {
      //Configure and create driver
      const hostIdentifier = "host-" + this.result.id.raw;
      const connectorProducts = new AppSearchAPIConnector({
        endpointBase:
          "https://9418f3a3b99042b3991649e2c5fec3df.ent-search.us-central1.gcp.cloud.es.io",
        searchKey: "search-zyftvpix7rftzf7ocirgacdk",
        engineName: "products",
        hostIdentifier: hostIdentifier,
      });

      const configProducts = {
        debug: false,
        apiConnector: connectorProducts,
        trackUrlState: false,
        initialState: {
          resultsPerPage: 10,
          filters: [
            { field: "availability", values: ["available"], type: "all" },
          ],
        },
      };
      this.driver = new SearchDriver(configProducts);

      this.driver.subscribeToStateChanges((state) => {
        if (
          !state.isLoading &&
          state.resultSearchTerm !== "" &&
          state.resultSearchTerm !== null
        ) {
          this.products = state.results;
          ////console.log(this.products);
        }
      });

      this.driverAvailable = true;
    },
  },
  beforeMount() {
    this.initDriver();
  },
};
</script>


<style scoped>
.BrowseFilter2 {
  width: 100%;
  padding: 0px;
  /* text-align: center; */
  background-color: #fff;
  /* box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 5px rgba(0, 0, 0, 0.18); */
  /* border-radius: 5em; */
  overflow-x: auto;
  white-space: nowrap;
}
.search-result {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  align-items: center;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-result__heading {
  text-align: center;
  margin: 0;
}

.search-result__body {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

.search-result__data {
  padding-top: 50px;
}

.search-result__flavor {
  margin: 0;
}

.search-result__image {
  flex: none;
  width: 256px;
  height: 387px;
}


.item-container{
    /* border-color:rgb(0, 0, 0, 0.6);
    border-style: solid;
    border-width: 1px; */
    width: 200px;
    height: 60px;
    /* height: fit-content; */
    margin-right: 10px;
    margin-left: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.338);
    margin-bottom: 15px;
    margin-top: 5px;
    display: flex !important;
    
}
.item-info{
    /* border-color:rgb(0, 0, 0, 0.6);
    border-style: solid;
    border-width: 1px; */
    float: left;
    width: 140px;
    height: 100%;
    padding: 7px;
    color: rgb(83, 83, 83);
    font-family: Google Sans;
    font-weight: 100;
}
.item-title{
    /* border-color:rgb(0, 0, 0, 0.6);
    border-style: solid;
    border-width: 1px; */
    text-align: left;
    height: 60%;
    width: 100%;
    white-space:normal;
    line-height: 14px;
    font-size: 13px;
}
.item-price{
    /* border-color:rgb(0, 0, 0, 0.6);
    border-style: solid;
    border-width: 1px; */
    height: 20px;
    width: fit-content;
    font-size: 12px;
}
.item-image-box{
    /* border-color:rgb(0, 0, 0, 0.6);
    border-style: solid;
    border-width: 1px; */
    width: 48px;
    height: 48px;
    float: right;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
}
.item-image{
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
}
.see-all-products{
  padding: auto;
}
div.inner {
  display: inline-flex !important;
  /* text-align: center; */
  float: left;
}
.see-all-products{
    text-align: left;
    height: fit-content;
    width: 100%;
    white-space:normal;
    line-height: 49px;
    font-size: 14px;
}
</style>
