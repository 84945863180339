<template>
  <!-- https://codepen.io/OGOGOG/pen/rNMgpGq -->
  <div class="search-bar-container">
    <form class="search" @submit.prevent="$emit('submit', $event.target.value)">
      <div class="search-box">
        <button type="submit" class="search-btn">
          <img class="search-img" src="https://www.freeiconspng.com/thumbs/search-icon-png/search-icon-png-21.png">
        </button>
        <input 
          type="search"
          placeholder="Search products"
          :value="value"
          @input="$emit('input', $event.target.value)"
          @blur="handleBlur"
          @focus="isSearchOnFocus = true"
          @click="isSearchOnFocus = true"
          style="z-index: 9;"
        />
      </div>
    </form>
  </div>
</template>



<script>
import { directive as onClickaway } from 'vue-clickaway';



export default {
  directives: {
    onClickaway: onClickaway,
  },
  methods: {
    handleBlur() {
      var vm = this;
      
      setTimeout(function(){
      vm.isSearchOnFocus = false;
      }, 150)
      

    },
    
    async goToWebsite(id) {
      var path = '/user/' + id + '/miniWebsite';
      this.$router.push({path: path});
      },
    
    selectSuggestion(suggestion) {
      this.isSearchOnFocus = false;
      
      this.$emit('selectSuggestion', suggestion);
      
    },
  },
    data() {
      return {
      isSearchOnFocus: false,
      }
    },

  props: {
    value: {
      required: true,
      type: String,
    },
    
  },
};
</script>


<style scoped>
@import "https://fonts.googleapis.com/css?family=Quantico";
.search-bar-container{
  box-shadow: 0 0 2.5px rgba(58, 58, 58, 0.5);
  width: calc(100% - 20px);
  max-width: 435px;
  min-width: fit-content;
  z-index: 9;
  margin: 0 auto;
  margin-bottom: 10px;
}
@media (min-width: 930px) {
  .search-bar-container{
    max-width: none;
  }
}
.image {
  border-radius: 0%;
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  max-height: 36px;
  max-width: 36px;
  margin-bottom: 2px;
  margin-top: 2px;
  float: left;
  z-index: 11;
}

.search-row {
  height: 40px;
  font-weight: 500;
  background-color: white;
  z-index: 15;
  line-height: 40px;
  border-bottom: 1px solid #eeeeee;
}

.search-image {
  border-radius: 0%;
  height: 40px;
  width: 40px;
  margin-bottom: 0px;
  float: left;
  z-index: 11;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
}

.search-text {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  color: black;
  text-decoration: none;
  font-size: 12px;
  margin-left: 5px;
  /* text-align: right; */
}

.search-text:focus , .search-text:hover{
  text-decoration: none;
  cursor: pointer;
}


.search {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.search input:focus {
  margin-left: -35px;
}

.results-indent{
  margin-left: 0px;
  min-width: fit-content;
}
.search input:focus + .results {
  display: block;
}

.search .results, .search.results {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 15;
  padding: 2px;
  margin: 0;
  margin-left: -2px;
}

.search .results li {
  display: block;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  border-bottom-color: #cbcfe200;
  cursor: pointer;
  
}

ul {
  cursor: pointer;
  list-style-type:none;
}

.search .results li:first-child {
  margin-top: -1px;
}

.search .results li:first-child:before,
.search .results li:first-child:after {
  /* display: block; */
  display: none;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  /* border: 5px outset transparent; */
}

.search .results li:first-child:before {
  /* border-bottom: 5px solid #c4c7d7; */
  top: -11px;
}

.search .results li:first-child:after {
  /* border-bottom: 5px solid #fdfdfd; */
  top: -10px;
}

.search .results li:first-child:hover:before,
.search .results li:first-child:hover:after {
  display: none;
}

.search .results li:last-child {
  margin-bottom: -1px;
}

.search .results a {
  display: block;
  position: relative;
  /* margin: 0 -1px; */
  padding: 6px 40px 6px 10px;
  color: #727272;
  font-weight: 200;
  text-shadow: 0 1px #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: Quantico;
  font-size: 1.1em;
  /*
  margin-left: 51px;
  */
}

.search .results a span {
  font-weight: 150;
}

.search .results a:before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -9px;
  background: url("https://cssdeck.com/uploads/media/items/7/7BNkBjd.png") 0 0
    no-repeat;
}

.search .results a:hover {
  text-decoration: none;
  color: black;
  border-bottom-color: #000000;
  /* background-color: white; */
}

:-moz-placeholder {
  color: #a7aabc;
  font-weight: 200;
}

::-webkit-input-placeholder {
  color: #a7aabc;
  font-weight: 200;
}

.lt-ie9 .search input {
  line-height: 26px;
}

/* purple and green basic search style below */

@import url("https://fonts.googleapis.com/css?family=Lato:300,700");
.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
}

input[type="search"] {
  background: #ffffff;
  border: none;
  outline: none;
  width: 100%;
  height: 30px;
  border-radius: 0px 0px 0px 0px;
  padding: 0px 10px;
  font-family: Google Sans;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 2px;
  background-color: #ffffff;
  color: rgb(104, 104, 104);
}

::placeholder {
  color: #a3a3a3;
  font-size: 14px;
}

.search-btn {
  height: 30px;
  width: 35px;
  outline: none;
  /* border-radius: 15px 0px 0px 15px; */
  border-radius: 0px 0px 0px 0px;
  /* background: #a7aabc; */
  background: #ffffff00;
  color: #ffffff;
  border: none;
  /* transition: all 0.3s ease; */
}

.search-btn i {
  font-size: 15px;
  font-weight: 400;
  color: #b4b4b4;
}

.search-img{
  width: 18px;
  opacity: 0.3;
  margin-left: 7px;
  margin-top: 1px;
  z-index: 1;
}

/* footer {
  position: absolute;
  bottom: 0;
  right: 0;
  text-transform: uppercase;
  padding: 10px;
  font-family: "Lato", sans-serif;
  color: #e7693b;
}
footer p {
  letter-spacing: 3px;
  font-size: 10px;
  color: #ffffff;
}
footer a {
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 3px;
}
footer a:hover {
  background: #e7693b;
  color: #ffffff;
  padding: 3px;
  transition: all 0.3s ease;
} */
</style>