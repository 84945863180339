<template>
  <div>loading... this page is to avoid redundant navigation error on search bar</div>
</template>

<script>
export default {
    data(){
        return{

        };
    }
};
</script>

<style>
</style>