<template>
    <div class="container">
        <div>
            The requested URL was cannot be accessed. 
            <div> That’s all we know. </div> 
        </div>
        <div>
            The requested URL cannot be accessed. 
            <div> That’s all we know. </div> 
        </div>
        <div>
            The requested URL was cannot be accessed. 
            <div> That’s all we know. </div> 
        </div>
        <div>
            The requested URL was cannot be accessed. 
            <div> That’s all we know. </div> 
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
    ...mapGetters({
      user: "user",
    }),
  },    
}
</script>

<style scoped>

</style>