<template>
  <div class="container">
    <h1
    @click="disableAlert"
      class="alert"
      v-bind:class="{
        'alert-success': alert.type == 'success',
        'alert-error': alert.type == 'error',
      }"
    >
      {{ alert.msg }}"
    </h1>
    <div class="tab_container">
      <input
        class="tab-input"
        id="tab1"
        type="radio"
        name="settings-tabs"
        checked
      />
      <label class="tab-label-1" for="tab1" @click="tab = 'profile'" 
        ><i class="fas fa-user tab-icon"></i><span> Profile</span></label
      >
      <input class="tab-input" id="tab2" type="radio" name="settings-tabs" />
      <label class="tab-label-2" for="tab2"  @click="tab = 'account'" 
        ><i class="fas fa-cogs tab-icon"></i><span> Account</span></label
      >

      <section id="content1" class="tab-content" v-show="tab === 'profile'">
        <!-- <div class="card"> -->
        <div class="top">
          <h3>Profile Information</h3>

          <div class="prof-pic-container">
            <input
              type="file"
              @change="uploadImage"
              id="fileinput"
              style="display: none"
              ref="imageToUpload"
              accept="image/*"
            />
            <label for="fileinput">
              <img v-if="dpUrl" class="prof-pic" :src="dpUrl" />
              <img
                v-else-if="user.profile.type == 'seller'"
                class="prof-pic"
                src="https://xforgeassets002.xboxlive.com/pf-title-b63a0803d3653643-20ca2/77ea09af-16fc-47e1-a31d-f6b0367cb00e/farmlife_screenshot_1.jpg"
              />
              <img
                  v-else
                  class="profile-pic"
                  src="https://i.ibb.co/hg8mjMr/unnamed.png"
                />
            </label>
            <i class="fa fa-file-image-o" aria-hidden="true"></i>
          </div>
        </div>

        <div class="content">
          <form>
            <div class="input-container">
              <div class="row input-container-row">
                <p class="input-title">Name</p>
                <input
                  type="text"
                  v-model="name"
                  placeholder="Full name"
                  class="form-control"
                />
              </div>
              <div class="row input-container-row">
                <p class="input-title">Address</p>
                <input
                  type="text"
                  v-model.lazy="address"
                  placeholder="Address"
                  class="form-control"
                  ref="autocomplete"
                />
              </div>
              <div class="row input-container-row">
                <p class="input-title">Address 2 (optional)</p>
                <input
                  type="text"
                  v-model="addressTwo"
                  placeholder="Apartment #"
                  class="form-control"
                />
              </div>
              <div class="row input-container-row">
                <p class="input-title">Zip Code</p>
                <input
                  type="string"
                  oninput="validity.valid||(value='')"
                  min="0"
                  v-model="postal_code"
                  placeholder="postal_code"
                  class="form-control"
                />
              </div>
              <div class="row input-container-row">
                <p class="input-title">Phone</p>
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='')"
                  v-model="phone"
                  placeholder="Phone"
                  class="form-control"
                />
              </div>

              <!-- <div class="row input-container-row">
                <p class="input-title">Radius (not displayed on profile)</p>
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='')"
                  v-model="radius"
                  placeholder="Only see farms within this radius around your location"
                  class="form-control"
                />
              </div> -->

              
              <!-- <div class="row input-container-row" v-if="user.profile.type === 'seller'">
                <p class="input-title">Pickup Instructions (shown in order details)</p>
                <input
                  type="text"
                  v-model="pickupInstructions"
                  placeholder="Pickup Instructions"
                  class="form-control"
                />
              </div>         -->
            </div>
            <div class="row input-container-row">
              <button class="bottom-content">
                <p @click.prevent="updateProfile" class="ripple">
                  Save Changes
                </p>
              </button>
            </div>
          </form>
        </div>
      </section>

      <section id="content2" class="tab-content" v-if="tab === 'account'">
        <div class="top" style="height: 50px">
          <div class="header">
            <img
              src="https://icons-for-free.com/iconfiles/png/512/x-1321215629555778185.png"
            />
          </div>

          <h3>Account Settings</h3>
        </div>

        <div class="content">
          <form>
            <div class="input-container">
              <div class="row input-container-row">
                <p class="input-title">New Email</p>
                <input
                  type="text"
                  v-model="account.email"
                  :disabled="emailDisabled"
                  placeholder="New email address"
                  class="form-control"
                />
              </div>

              <div class="row input-container-row">
                <p class="input-title">Confirm New Email</p>
                <input
                  type="text"
                  v-model="account.confirmEmail"
                  :disabled="emailDisabled"
                  placeholder="Confirm new email address"
                  class="form-control"
                />
              </div>
              <br />
              <div class="row input-container-row">
                <p class="input-title">New Password</p>
                <input
                  type="text"
                  v-model="account.password"
                  :disabled="passwordDisabled"
                  placeholder="New password"
                  class="form-control"
                />
              </div>

              <div class="row input-container-row">
                <p class="input-title">Confirm New Password</p>
                <input
                  type="text"
                  v-model="account.confirmPassword"
                  :disabled="passwordDisabled"
                  placeholder="Confirm new password"
                  class="form-control"
                />
              </div>
              <br />
            </div>
            <div class="row input-container-row">
              <button class="bottom-content">
                <p @click.prevent="updateAccount" class="ripple">Submit</p>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import store from "../store";
import { mapGetters } from "vuex";
import { db, cloudFunctions } from "../main.js";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.state.user.data) next({ name: "Login" });
    else next();
  },
  watch: {
    tab() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    emailDisabled() {
      if (this.account.password !== "" || this.account.confirmPassword !== "") {
        return true;
      } else {
        return false;
      }
    },
    passwordDisabled() {
      if (this.account.email !== "" || this.account.confirmEmail !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      tab: "profile",
      radius: 5000,
      name: null,
      phone: null,
      address: null,
      addressTwo: null,
      postal_code: null,
      dpUrl: null,
      lat_lon: null,
      pickupInstructions: null,

      addressPlaces: {},

      componentForm: {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "short_name",
        postal_code: "short_name",
      },

      alert: {
        msg: "",
        type: "",
      },
      profile: {},
      account: {
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    },
    async updateProfile() {
      var profile = {};
      var vm = this;

      /* Set radius */
      if (this.radius) {
        this.radius = parseInt(this.radius, 10);
        if (this.radius < 0) {
          vm.$set(vm.alert, 'type', 'error');
          vm.$set(vm.alert, 'msg', 'Your radius has to be bigger than 0');
          return -1;
        }
      }  else if (this.user.profile.radius) {
        this.radius = this.user.profile.radius;
      }
      else {
        this.radius = 25;
      }

      /* Set address by getting the properties from the google autocomplete address */
      if (Object.keys(this.addressPlaces).length !== 0) {
        for (var property in this.addressPlaces) {
          profile[property] = this.addressPlaces[property];
          console.log(profile[property]);
          console.log(profile)
        }
      }

      /* Set local profile variable properties to properties from form */ 
      profile["name"] = this.name;
      profile["phone"] = this.phone;
      profile["radius"] = this.radius;
      profile["lat_lon"] = this.lat_lon;
      profile["address"] = this.address;
      profile["addressTwo"] = this.addressTwo;
      profile["pickupInstructions"] = this.pickupInstructions;

      if (this.dpUrl !== null && typeof this.dpUrl !== "undefined") {
        profile["dpUrl"] = this.user.profile.dpUrl;
      }

      /* Firebase request to send profile to database */
      db.collection("profile")
        .doc(this.user.data.uid)
        .set(profile, { merge: true })
        .then(() => {
          vm.$set(vm.alert, 'type', 'success');
          vm.$set(vm.alert, 'msg', "Your profile has been updated!" );
          store.dispatch("fetchProfile"); //Fetch profile updates our vue state
        })
        .catch((error) => {
          vm.$set(vm.alert, 'type', 'error');
          vm.$set(vm.alert, 'msg', "Error updating your profile. Error: " + error );
          store.dispatch("fetchProfile"); //Fetch profile updates our vue state
        });
    },
    updateAccount() {
      var user = firebase.auth().currentUser;
      var vm = this;
      var oldEmail = user.email;

      var passwordEmailChange = cloudFunctions.httpsCallable(
          "passwordEmailChange"
        );

      if (this.account.email !== this.account.confirmEmail) {
        vm.$set(vm.alert, 'type', 'error');
        vm.$set(vm.alert, 'msg', "Error updating your account, email does not match confirmation email" );
      } else if (this.account.password !== this.account.confirmPassword) {
        vm.$set(vm.alert, 'type', 'error');
        vm.$set(vm.alert, 'msg', "Error updating your account, password does not match confirmation password" );
      } else if (this.account.email == "" && this.account.password !== "") {
        user
          .updatePassword(this.account.password)
          .then( async () => {
            vm.$set(vm.alert, 'type', 'success');
            vm.$set(vm.alert, 'msg', "Your password has been updated!" );
            try {
          var emailRes = await passwordEmailChange({
          event: "passwordChange"
        });
        } catch (error) {
          vm.$set(vm.alert, 'type', 'error');
            vm.$set(vm.alert, 'msg', error );
        }
          })
          .catch( (error) => {
            vm.$set(vm.alert, 'type', 'error');
            vm.$set(vm.alert, 'msg', "Your password could not be updated. Error: " + error );
          });
      } else if (this.account.email != "" && this.account.password == "") {
        user
          .updateEmail(this.account.email)
          .then(async function () {
            vm.$set(vm.alert, 'type', 'success');
            vm.$set(vm.alert, 'msg', "Your email has been updated!" );
            try {
          var emailRes = await passwordEmailChange({
          event: "emailChange",
          oldEmail: oldEmail,
        });
        } catch (error) {
          vm.$set(vm.alert, 'type', 'error');
            vm.$set(vm.alert, 'msg', "Your email could not be updated." + error );
        }
          })
          .catch(function (error) {
            vm.$set(vm.alert, 'type', 'error');
            vm.$set(vm.alert, 'msg', "Your email could not be updated." + error );
          });
      }
    },
    uploadImage(e) {
      // File or Blob
      if (e.target.files[0]) {
        var file = e.target.files[0];
        var vm = this;

        var storageRef = firebase
          .storage()
          .ref("profilepictures/" + this.user.data.uid);

        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg",
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.put(file, metadata);
        //console.log(uploadTask);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                //console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                //console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              //...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(async (downloadURL) => {
                //console.log("File available at", downloadURL);
                store.commit("SET_DP", downloadURL);
                vm.dpUrl = null;
                vm.dpUrl = await downloadURL;
                vm.profile.dpUrl = downloadURL;
                vm.$refs.imageToUpload.value = '';
              });
          }
        );
      }
    },
    /* initAutocomplete initializes the google autocomplete feature */ 
    async initAutocomplete() {
      try {
        // eslint-disable-next-line no-undef
        const autocomplete = new google.maps.places.Autocomplete(
          this.$refs.autocomplete,
          { types: ["geocode"] }
        );

        

        autocomplete.addListener("place_changed", async () => {
          let place = await autocomplete.getPlace();
          let lat = await place.geometry.location.lat();
          let lon = await place.geometry.location.lng();
          var vm = this;
          let address = "";

          vm.addressPlaces = {};
          
          // Get each component of the address from the place details, and then fill-in the corresponding field on the form.
          for (const component of place.address_components) {
            const addressType = component.types[0];

            if (vm.componentForm[addressType]) {
              const val = component[vm.componentForm[addressType]];
              address += val + " ";
              vm.addressPlaces[addressType] = val;
            }
          }

          if (address !== "") {
            vm.lat_lon = [lat, lon];
            vm.address = address;
            vm.postal_code = vm.addressPlaces["postal_code"];
          }


          if (!vm.lat_lon || !vm.postal_code) {
            vm.$set(vm.alert, 'type', 'error');
            vm.$set(vm.alert, 'msg', "Error fetching the address, doesn't have a postal_code or a lat_lon. Error: " + error );
            vm.address = null;
            vm.addressPlaces = null;
            vm.postal_code = null;
          }
        });
      } catch (error) {
        vm.$set(vm.alert, 'type', 'error');
        vm.$set(vm.alert, 'msg', "Error fetching the address. Error: " + error );
      }
    },

    /* fetchProfile prepopulates the form in the page using the vue state properties under user.profile */ 
    fetchProfile() {
      
      if (this.user.profile.radius) {
        this.radius = this.user.profile.radius;
      }
      if (this.user.profile.name) {
        this.name = this.user.profile.name;
      }
      if (this.user.profile.phone) {
        this.phone = this.user.profile.phone;
      }
      if (this.user.profile.address) {
        this.address = this.user.profile.address;
      }
      if (this.user.profile.addressTwo) {
        this.addressTwo = this.user.profile.addressTwo;
      }
      if (this.user.profile.postal_code) {
        this.postal_code = this.user.profile.postal_code;
      }
      if (this.user.profile.lat_lon) {
        this.lat_lon = this.user.profile.lat_lon;
      }
      if (this.user.profile.dpUrl) {
        this.dpUrl = this.user.profile.dpUrl;
      }
      if (this.user.profile.pickupInstructions) {
        this.pickupInstructions = this.user.profile.pickupInstructions;
      }
    },
  },
  mounted() {
    var vm = this;
    vm.fetchProfile();
    this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been rendered
    vm.$loadScript(
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_MAPS_API}`
    )
      .then(() => {
        vm.initAutocomplete();
      })
      .catch((error) => {
        vm.$set(vm.alert, 'type', 'error');
        vm.$set(vm.alert, 'msg', "Google autocomplete has not loaded correctly. Error: " + error);

      });
  })
    
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/5.4.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  top: 20px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
  padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

.fa-file-image-o {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.868);
  z-index: 9;
  position: absolute;
  left: 46%;
  top: 46%;
  pointer-events: none;
  text-shadow: 0px 0px 10px black;
}
.container {
  padding-top: 40px;
  padding-bottom: 30px;
  overflow-y: auto;
  height: fit-content;
  margin: auto;
  width: 97%;
  max-width: fit-content;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

body {
  font-family: Google Sans;
}

h1 {
  text-align: center;
  font-size: 24px;
  color: rgb(53, 53, 53);
  font-family: Google Sans;
  font-weight: 400;
}

a {
  color: #ccc;
  text-decoration: none;
  outline: none;
}

/*Fun begins*/
.tab_container {
  /* width: 100%; */
  margin: 0 auto;
  padding: 0px;
  padding-top: 20px;
  height: fit-content;
  border-radius: 5px;
  max-width: 500px;
  overflow-y: auto;
}

.tab-input,
section {
  clear: both;
  padding-top: 10px;
  display: none;
}

.tab-icon {
  font-size: 14px;
  margin-bottom: -1px;
  margin-right: 5px;
}

.tab-label-1 {
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  width: 47.5%;
  padding: 10px;
  color: #757575;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-left: 0%;
}

.tab-label-2 {
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  width: 47.5%;
  padding: 10px;
  color: #757575;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-left: 5%;
  margin-right: -5%;
}

#tab1 ~ #content1,
#tab2 ~ #content2 {
  display: block;
  padding: 0px;
  background: #fff;
  color: #999;
}
.tab-content {
  padding: 0px;
  margin: 0px;
}

input[type="radio"]:checked + label {
  box-shadow: inset 0 -3px 0px#42b983e5;
}

.tab_container .tab-content p,
.tab_container .tab-content h3 {
  -webkit-animation: fadeInScale 0.7s ease-in-out;
  -moz-animation: fadeInScale 0.7s ease-in-out;
  animation: fadeInScale 0.7s ease-in-out;
}

/*Content Animation*/
@keyframes fadeInScale {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.prof-pic-container {
  width: 100%;
  margin: auto;
  text-align: center;
}

.prof-pic {
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin: auto;
  /* background-color:black; */
  position: relative;
}

@import "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i";
@import "https://fonts.googleapis.com/css?family=Quantico";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  overflow: hidden;
}

.state-form {
  font-family: Google Sans;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: -4px;
}
.edit-prof-pic {
  margin: 20px;
  margin-top: 10px;
  display: block;
  color: #42b983;
  font-weight: 600;
}

.header {
  height: 10px;
  width: 100%;
  /* border-bottom: 1px solid rgb(206, 206, 206); */
  padding: 0 20px;
}

.top {
  position: relative;
  width: 100%;
  height: fit-content;
  /* border-bottom: 1px solid rgb(206, 206, 206); */
}
.top h3 {
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 4px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.content form .input-container {
  width: 100%;
  height: fit-content;
  padding: 0px 10px;
  /* overflow-y: scroll; */
  border-bottom: 1px solid rgb(206, 206, 206);
}
.content form .input-container:not(:last-child) {
  border-bottom: none;
}
.content form .input-container input {
  /* width: 90%; */
  font-family: Google Sans;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 0px;
  letter-spacing: 2px;
  color: black;
}
.input-container-row {
  height: 70px;
  min-height: 70px;
  margin: 10px 0px;
  /* border-bottom: 1px solid rgb(206, 206, 206); */
}
.input-title {
  font-size: 16px;
  width: 100%;
  margin-bottom: 5px;
  margin-left: 0px;
}

.bottom-content {
  text-align: center;
  background-color: #42b983;
  height: 50px;
  width: 100%;
  line-height: 50px;
  color: white;
  font-size: 14px;
  /* position: relative;
  bottom: 0px; */
  z-index: 5;
  border-radius: 8px;
}
.bottom-content:hover {
  background-color: #349469;
}

.ripple {
  font-size: 18px;
  font-family: Google Sans;
  font-weight: 500;
}

/* start of form style */
.form-container {
  display: flex;
  /* height: 100vh; */
  font-family: Google Sans;
  user-select: none;
  margin-bottom: 50px;
}
.form-card {
  background: #fff;
  max-width: 590px;
  height: auto;
  padding: 0px 0px;
  border-radius: 8px;
  /* border: 1px solid #cacaca; */
  margin: auto;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
.form-header {
  text-align: center;
  font-weight: 400;
}
.title {
  padding: 15px 0;
  font-size: 24px;
  color: rgb(53, 53, 53);
}
.tagline {
  font-size: 16px;
  padding-bottom: 18px;
}
.form-label {
  /* display: block;
  position: absolute;
  padding: 0 5px;
  width: auto;
  color: #5f6368;
  background: #fff;
  transition: all 150ms ease;
  transform: translate(12px, -37px);
  cursor: text;
  font-weight: 500; */
  transform: translate(5px, -65px);
  font-size: 13px;
}
.form-control {
  margin-top: 10px;
  border: 1px solid rgb(207, 207, 207);
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
}
.form-input {
  padding: 10px;
  margin-top: 20px;
  font-size: 17px;
  background: #fff;
  color: rgb(73, 73, 73);
  width: calc(100% - 0px);
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.form-input:focus {
  outline: none;
  /* Compensate increased border with padding */
  padding: 10px;
  border: 1px solid #42b983e5;
}
.form-input:not(:placeholder-shown) + .form-label,
.form-input:focus + .form-label {
  transform: translate(5px, -65px);
  font-size: 13px;
}
.form-input:focus + .form-label {
  color: #42b983e5;
}
.links {
  color: #42b983e5;
  font-size: 18px;
  padding-top: 10px;
  cursor: pointer;
  font-weight: 500;
}
.guest {
  margin-top: 32px;
  font-size: 18px;
  color: #5f6368;
}
.login-bar {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}
.next {
  width: 100%;
  margin-left: auto;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  background: #42b983e5;
  font-weight: 500;
  font-size: 18px;
}
.next:hover {
  background: #42b983e5;
}

.section-title {
  font-size: 1.5em;
}
.section-subtitle {
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: -10px;
  margin-left: 5px;
}
/*  end of form style */
</style>

