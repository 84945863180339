<template>
  <div>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>

    <navbar></navbar>
    <main class="padding">
      <router-view></router-view>
    </main>
  </div>
</template>


<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.padding{
  padding: 0px !important;
  margin: 0px !important;
  background-color: white;
}
iframe[id^="plaid"] {
  height: 100vh !important;
}
</style>
