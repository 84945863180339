<template>
  <div class="nav-container">
    <input
      class="cart-input"
      type="checkbox"
      name="toggle"
      id="toggle"
      @click="forceUpdateCart"
    />
    <label class="" for="toggle">
      <i class="fad fa-file-invoice"></i>
    </label>

    <div class="cart-sidebar">
      <h1
        @click="setCustomAlert(null, null)"
        class="alert"
        v-bind:class="{
          'alert-success': alert.type == 'success',
          'alert-error': alert.type == 'error',
        }"
      >
        {{ alert.msg }}"
      </h1>
      <table>
        <tr>
          <td valign="top">
            <div class="top-div">
              <span class="inside-order"
                >Order <i class="fad fa-file-invoice invoice-inside"></i
              ></span>
              <input
                class="cart-input"
                type="checkbox"
                name="toggle"
                id="toggle"
                checked
                @click="forceUpdateCart"
              />
              <label class="" for="toggle"></label>
            </div>
            <div
              class="order-div"
              v-for="(seller, sellerKey, sellerIndex) in cart"
              :key="sellerKey"
            >
              <span v-for="(item, key, index) in seller" :key="index">
                <div v-if="index === 0">
                  <div class="order-title">
                    <span>
                      {{ item.seller_name }}</span
                    >
                  </div>
                </div>
                <div class="order-item">
                  <div class="item-quantity">
                    <div class="number-input">
                      <button
                        @click="
                          changeQuantity(
                            sellerIndex * 100 + index,
                            -1,
                            item,
                            sellerKey
                          )
                        "
                        class="plus-minus"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                      <input
                        class="quantity edit-quantity"
                        type="number"
                        min="0"
                        name="quantity"
                        oninput="validity.valid||(value='')"
                        v-model.lazy="newQuantityArr[sellerIndex * 100 + index]"
                        :placeholder="[[item.quantity]]"
                        v-on:keyup.enter="
                          addToCart(item, sellerIndex * 100 + index, sellerKey)
                        "
                      />
                      <button
                        @click="
                          changeQuantity(
                            sellerIndex * 100 + index,
                            1,
                            item,
                            sellerKey
                          )
                        "
                        class="plus-minus"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="item-title">
                    <span>{{ item.name }}</span>
                    <span
                      v-if="
                        deliveryOrPickup[sellerKey] === 'pickup' &&
                        item.pickup_or_delivery === 'pickup'
                      "
                    >
                      <span class="badge-2">pickup only</span>
                    </span>
                  </div>
                  <div class="item-unit-price">
                    <span>${{ item.price }}/unit</span>
                  </div>
                  <div class="item-price">
                    <!-- this needs to be fixed -->
                    <span>${{ item.price * item.quantity }}</span>
                  </div>
                  <div class="item-delete">
                    <i
                      class="far fa-trash-alt"
                      @click="
                        removeItem(item, sellerIndex * 100 + index, sellerKey)
                      "
                    ></i>
                  </div>
                </div>

                <div
                  class="fullOrderFromSeller"
                  v-if="index === Object.keys(seller).length - 1"
                >
                  <div class="order-subtotal">
                    <div
                      v-if="Object.keys(seller).length > 1"
                      class="subtotal-title"
                    >
                      <span
                        >Subtotal: {{ Object.keys(seller).length }} items</span
                      >
                    </div>
                    <div v-else class="subtotal-title">
                      <span>Subtotal: 1 item</span>
                    </div>
                    <div class="subtotal-price">
                      <span>${{ pricesObj[sellerKey] }}</span>
                    </div>
                    <div class="item-delete"></div>
                  </div>

                  <div v-if="sellerContainsItem(seller)">
                    
                    <div class="order-select-pickup">
                      <div class="tab_container">
                        <!-- start pickup start 
                        <input
                          class="tab-input"
                          id="tab-pickup"
                          type="radio"
                          name="cart-tabs"
                          value="pickup"
                          @click="setDeliveryOrPickup('pickup', sellerKey)"
                        />
                        <label class="tab-label-1" for="tab-pickup"
                          ><i class="fas fa-dolly tab-icon"></i
                          ><span class="tab-text"> Pickup</span></label
                        >
                        !-->
                        <input
                          class="tab-input"
                          id="tab-delivery"
                          type="radio"
                          name="cart-tabs"
                          value="delivery"
                        />
                        <label
                          class="tab-label-2"
                          for="tab-delivery"
                          ><i class="fas fa-truck tab-icon"></i
                          ><span class="tab-text"> Delivery</span></label
                        >
                      </div>

                      <div class="select-date">
                        <span v-if="deliveryOrPickup[sellerKey] === 'pickup'">
                          Pickup date:
                        </span>
                        <span v-if="deliveryOrPickup[sellerKey] === 'delivery'">
                          Delivery date:
                        </span>
                        <span
                          v-if="
                            deliveryOrPickup[sellerKey] !== 'delivery' &&
                            deliveryOrPickup[sellerKey] !== 'pickup'
                          "
                        >
                          Pickup date:
                        </span>
                      </div>

                      <div class="date-picker-container">
                        <v-date-picker
                        
                          class="inline-block h-full"
                          v-model="dates[sellerKey]"
                          @dayclick="
                            setItemsPickupDates(sellerKey, dates[sellerKey])
                          "
                          :min-date="minDatesObj[sellerKey]"
                          :max-date="maxDatesObj[sellerKey]"
                          :disabled-dates="[
                            { weekdays: item['seller_recurring_off_days'] },
                             ...item['seller_selected_off_dates'],
                          ]"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <button
                                class="p-1 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none date-picker-button"
                                @click="togglePopover()"
                              >
                                <i class="fas fa-calendar"></i>
                              </button>
                              <input
                                :value="inputValue"
                                class="date-picker-input text-gray-700 w-full py-1 px-2 appearance-none focus:outline-none focus:border-blue-500"
                                readonly
                                placeholder="mm/dd/yyyy"
                              />
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <br />

                      <!-- START TIME PICKER START -->

                      <!--

                      <div class="select-time">
                        <span v-if="deliveryOrPickup[sellerKey] === 'pickup'">
                          Pickup time (optional):
                        </span>
                        <span v-if="deliveryOrPickup[sellerKey] === 'delivery'">
                          Delivery time:
                        </span>
                        <span
                          v-if="
                            deliveryOrPickup[sellerKey] !== 'delivery' &&
                            deliveryOrPickup[sellerKey] !== 'pickup'
                          "
                        >
                          Pickup time:
                        </span>

                        !-->

                        <!-- Auto Drop Direction -->
                        <!--

                        <div
                          id="auto-dropdown-containter"
                          class="time-picker-container"
                        >
                          <!-- <button class="time-picker-button" @click="trigger()">
                            <i class="fas fa-clock"></i>
                          </button>
                          <span style="font-size: 11px; margin-top: 4px;
                                        width: 50px;
                                        border: 1px solid #dadada;
                                        border: none;
                                        position: absolute;
                                        margin-left: -57px;">(optional)</span>
                          
                          <div
                            v-if="!dates[sellerKey]"
                            class="timepicker-unavailable-white"
                          >
                            <div class="timepicker-unavailable-grey">
                              <span class="timepicker-unavailable-text"
                                >select date first</span
                              >
                            </div>
                          </div>

                          -->

                          <!-- Defined Container -->
                          <!-- 

                          <vue-timepicker
                            drop-direction="auto"
                            container-id="auto-dropdown-containter"
                            :minute-interval="10"
                            format="h:mm A"
                            @change="timeChange($event, sellerKey)"
                            v-model="timeValues[sellerKey]"
                            :hour-range="[enabledHours[sellerKey]]"
                            :minute-range="[enabledMins[sellerKey]]"
                            hide-disabled-items
                            @error="errorHandler($event, sellerKey)"
                            :disabled="!dates[sellerKey]"
                            ref="timePick"
                          >
                          </vue-timepicker>
                          
                        </div>
                        
                      </div>
                      !-->
                      

                      <!-- TIME PICKER END -->

                      <div class="row pickup-details-address">
                        <div class="col-6 pickup-details-address-title">
                          <span v-if="deliveryOrPickup[sellerKey] === 'pickup'">
                            Seller address:
                          </span>
                          <span
                            v-if="deliveryOrPickup[sellerKey] === 'delivery'"
                          >
                            Delivery address:
                          </span>
                        </div>
                        <div class="col-6 pickup-details-address-result">
                          <span v-if="deliveryOrPickup[sellerKey] === 'pickup'">
                            {{ item.seller_address }}
                          </span>
                          <span
                            v-if="deliveryOrPickup[sellerKey] === 'delivery'"
                          >
                            {{ user.profile.address }}
                            <span v-if="user.profile.addressTwo !== ''"
                              >, {{ user.profile.addressTwo }}
                            </span>
                          </span>
                        </div>
                      </div>

                      <div class="row pickup-details-instructions">
                        <div class="col-6 pickup-details-instructions-title">
                          <span v-if="deliveryOrPickup[sellerKey] === 'pickup'">
                            Seller instructions:
                          </span>
                          <span
                            v-if="deliveryOrPickup[sellerKey] === 'delivery'"
                          >
                          </span>
                        </div>
                        <div class="col-6 pickup-details-instructions-result">
                          <span v-if="deliveryOrPickup[sellerKey] === 'pickup'">
                            {{ item.seller_pickup_instructions }}
                          </span>
                          <span
                            v-if="deliveryOrPickup[sellerKey] === 'delivery'"
                          >
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="order-notes">
                      <div class="notes-header">
                        <span v-if="deliveryOrPickup[sellerKey] === 'pickup'">
                          Leave note to seller for pickup (optional)
                        </span>
                        <span v-if="deliveryOrPickup[sellerKey] === 'delivery'">
                          Leave note to seller for delivery (optional)
                        </span>
                      </div>
                      <textarea
                        class="notes-input"
                        placeholder="Add note for seller"
                        rows="2"
                        v-model="notes[sellerKey]"
                        @change="
                          setItemsPickupNotes(sellerKey, notes[sellerKey])
                        "
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </td>
        </tr>
        <div v-if="cartLength > 0 || Object.keys(cart).length > 0">
          <div class="order-buy">
            <!-- loader icon comes up -->
            <div class="loader loader--style3" title="2" v-if="spinnerOn">
              <svg
                version="1.1"
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="100px"
                height="100px"
                viewBox="0 0 100 10"
                style="enable-background: new 0 0 50 50"
                xml:space="preserve"
              >
                <path
                  fill="#000"
                  d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                >
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="0.6s"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
            <div>
              <button
                v-if="!spinnerOn && user.loggedIn"
                @click="checkout"
                class="buy-button"
                :class="{ 'disable-delivery': !validOrder }"
                :disabled="!validOrder"
              >
                <!-- Submit Order: ${{ total }} -->
                Place Order
              </button>
              <button
                v-else-if="!user.loggedIn"
                class="buy-button"
                style="background-color: grey"
              >
                Login To Submit Order: ${{ total }}
              </button>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="order-empty" v-else>
          <span>No items in cart</span>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { doc, getDoc } from "firebase/firestore";
import { db, cloudFunctions } from "../main.js";
import { mapGetters } from "vuex";
import store from "../store";
import VCalendar from "../main.js";
import { invalid } from "moment";

export default {
  components: {
    VCalendar,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    total() {
      return store.state.user.totalPrice;
    },
  },
  watch: {
    newQuantityArr() {
      store.dispatch("calcTotal");
    },
  },
  data() {
    return {
      enabledHours: {},
      enabledMins: {},
      inputValue: {},
      modelConfig: {
        type: "string",
        mask: 'iso',
      },
      alert: {
        msg: "",
        type: "",
      },
      spinnerOn: false,

      invalid_dates: {},
      invalid_times: {},
      invalid_delivery_items: {},
      validOrder: false,

      timeValues: {},
      dates: {},
      times: {},
      notes: {},
      sellerPickupInstructions: {},
      deliveryOrPickup: {},
      newQuantityArr: [],
      pricesObj: {},
      minDatesObj: {},
      maxDatesObj: {},
      loaded: false,
      cartLength: 0,
      cart: {},
      idempotencyKey: null,
    };
  },
  methods: {
    hasDefinedValue(arg) {
      return arg && arg !== undefined && arg !== "" && arg !== null;
    },

    setDeliveryOrPickup(deliveryOrPickup, sellerKey) {
      this.$set(this.deliveryOrPickup, sellerKey, deliveryOrPickup);
    },

    setCustomAlert(type, msg, spinnerOn = false) {
      var vm = this;
      vm.$set(vm.alert, "type", type);
      vm.$set(vm.alert, "msg", msg);
      vm.spinnerOn = spinnerOn;
    },

    setTimePlaceholder(seller, item) {
      var vm = this;
      var cart = this.cart;

      var time = {
        h: "4",
        mm: "44",
        ss: "44",
        A: "AM",
      };

      vm.$set(vm.timeValues, seller, time);
      return time;
    },

    setDeliveryAddress(seller, item) {
      var vm = this;
      var cart = this.cart;
      var deliveryAddress = {};

      //Condition if the customer is asking for pickup but some items are marked delivery only
      if (
        this.deliveryOrPickup[seller] === "delivery" &&
        cart[seller][item]["pickup_or_delivery"] !== "pickup"
      ) {
        if (this.hasDefinedValue(this.user.profile.addressTwo)) {
          deliveryAddress[seller] =
            this.user.profile.address + "," + this.user.profile.addressTwo;
        } else {
          deliveryAddress[seller] = this.user.profile.address;
        }
      }
      return deliveryAddress[seller];
    },

    forceUpdateCart() {
      var vm = this;
      vm.cart = vm.user.cart;
      var cart = vm.cart;
      this.cartLength = Object.keys(vm.cart).length;
      for (var seller in cart) {
        this.calculateOrderPrice(cart[seller], seller);
        this.calculateMinMaxDates(cart[seller], seller);
        this.setDeliveryOrPickup("delivery", seller)
        // 
      }
      this.validateAllFields();
      
      

      store.dispatch("calcTotal");
      this.setCustomAlert(null, null);
      
    },

    changeQuantity(index, addition, item, sellerKey) {
      var vm = this;

      if (!vm.$data.newQuantityArr[index]) {
        vm.$set(
          vm.$data.newQuantityArr,
          index,
          parseInt(item.quantity) + addition
        );
      } else {
        vm.$set(
          vm.$data.newQuantityArr,
          index,
          parseInt(vm.$data.newQuantityArr[index]) + addition
        );
      }

      this.addToCart(item, index, sellerKey);
    },

    async removeItem(item, index, sellerKey) {
      var vm = this;

      vm.$set(vm.$data.newQuantityArr, index, null);
      store.dispatch("removeItem", item);
      vm.forceUpdateCart();

      await this.calculateOrderPrice(this.cart[sellerKey], sellerKey);
      await this.calculateMinMaxDates(this.cart[sellerKey], sellerKey);
    },

    async addToCart(item, index, sellerKey) {
      var vm = this;
      console.log(item);

      this.$data.newQuantityArr[index] = parseInt(
        this.$data.newQuantityArr[index],
        10
      );

      //If new quantity is 0 remove it from cart
      if (this.$data.newQuantityArr[index] === 0) {
        store.dispatch("removeItem", item);
        vm.$set(vm.$data.newQuantityArr, index, null);
        await this.calculateOrderPrice(this.cart[sellerKey], sellerKey);
        await this.calculateMinMaxDates(this.cart[sellerKey], sellerKey);
        return -1;
      }

      if (
        this.$data.newQuantityArr[index] > 0 &&
        Number.isInteger(this.$data.newQuantityArr[index])
      ) {
        item.quantity = this.$data.newQuantityArr[index];
        vm.$set(vm.$data.newQuantityArr, index, null);
        store.dispatch("addItem", item);
      } else {
        vm.setCustomAlert("error", "Enter a valid quantity");
      }

      await this.calculateOrderPrice(this.cart[sellerKey], sellerKey);
      await this.calculateMinMaxDates(this.cart[sellerKey], sellerKey);
    },

    sellerContainsItem(seller) {
      var containsItem = false;
      for (var item in seller) {
        if (seller[item].category !== "event") {
          containsItem = true;
          break;
        }
      }
      return containsItem;
    },

    async validateAllFields() {
      var vm = this;
      var cart = this.cart;
      var invalid_dates = {};
      var invalid_times = {};
      var invalid_delivery_items = {};
      var categories = {};
      var types = {};
      var pricePerUnit = {};
      var deliveryAddress = {};
      var seller_name = {};
      var invalid_delivery_items_current_selection_delivery = {};

      var unavailableProducts = await this.checkAvailabilityAllCartItems();

      if (unavailableProducts.length > 0) {
        var msg =
          "The following products are unavailable:" + unavailableProducts;
        setCustomAlert("error", msg, false);
        return -1;
      }

      for (const seller in cart) {
        categories[seller] = [];
        types[seller] = [];
        pricePerUnit[seller] = [];
        deliveryAddress[seller] = "delivery";

        for (var item in cart[seller]) {
                    console.log( "cart[seller][item]");
          console.log( cart[seller][item]);
          seller_name[seller] = cart[seller][item].seller_name;
        
        if (!this.hasDefinedValue(cart[seller][item].pickup_date)) {
        invalid_dates[seller] = seller;
      }
        deliveryAddress[seller] = this.setDeliveryAddress(seller, item);
        categories[seller].push(cart[seller][item].category);
        types[seller].push(cart[seller][item].type);
        pricePerUnit[seller].push(cart[seller][item].price);
        }
      }

      var validateFieldsReturn = {
        categories: categories,
        types: types,
        pricePerUnit: pricePerUnit,
        deliveryAddress: deliveryAddress,
        invalid_times: invalid_times,
        invalid_dates: invalid_dates,
        invalid_delivery_items: invalid_delivery_items,
        seller_name: seller_name,
      };


      if (
        Object.keys(invalid_dates).length === 0
      ) {
        this.validOrder = true;
      } else {
        this.validOrder = false;
      }


      return validateFieldsReturn;
    },

    async checkout() {
      var batch = db.batch();
      var vm = this;
      const checkoutTimestamp = firebase.firestore.Timestamp.now();
      var orders = {};
      var cart = this.cart;
      var msg;
      vm.spinnerOn = true;

      var validateFieldsReturn = await this.validateAllFields();

      console.log("validateFieldsReturn")
      console.log(validateFieldsReturn)

      for (const seller in cart) {
        console.log(seller)
        var orderPrice = await this.calculateOrderPrice(cart[seller], seller);
        if (orderPrice < 0) {
          msg =
            "Order total is less than 0. Issue from calculateOrderPrice function (maybe item negative quantity or price)";
          this.setCustomAlert("error", msg);
          return -1;
        }

        if (validateFieldsReturn["invalid_dates"][seller]) {
          msg =
            "You have invalid  dates for these sellers" +
            validateFieldsReturn["invalid_dates"][seller];
          this.setCustomAlert("error", msg);
          return -1;
        }

        if (validateFieldsReturn["invalid_times"][seller]) {
          msg =
            "You have invalid times for these sellers" +
            validateFieldsReturn["invalid_times"][seller];
          this.setCustomAlert("error", msg);
          return -1;
        }

        if (
          this.deliveryOrPickup[seller] === "delivery" &&
          validateFieldsReturn["invalid_delivery_items"][seller]
        ) {
          msg =
            "You have invalid delivery items (only pickup items) for these sellers" +
            validateFieldsReturn["invalid_delivery_items"][seller];
          this.setCustomAlert("error", msg);
          return -1;
        }

        var buyerOrderRef = db
          .collection("profile")
          .doc(this.user.data.uid)
          .collection("buyerOrders")
          .doc();

        var newId = buyerOrderRef.id;
        console.log(validateFieldsReturn)
        console.log( validateFieldsReturn["seller_name"])
        await console.log( validateFieldsReturn["seller_name"][seller])

        orders[newId] = {
          purchasedProducts: cart[seller],
          checkoutTimestamp: checkoutTimestamp,
          orderStatus: "pending",
          buyerName: this.user.profile.name,
          buyerPhone: this.user.profile.phone,
          buyerEmail: this.user.data.email,
          buyerId: this.user.data.uid,
          sellerId: seller,
          buyer_lat_lon: this.user.profile.lat_lon,
          buyer_lat_lon_str: this.user.profile.lat_lon[0] + "," + this.user.profile.lat_lon[1],
          productCategories: validateFieldsReturn["categories"][seller],
          productTypes: validateFieldsReturn["types"][seller],
          productPricePerUnit: validateFieldsReturn["pricePerUnit"][seller],
          docId: newId,
          seller_name: validateFieldsReturn["seller_name"][seller],
          deliveryAddress: validateFieldsReturn["deliveryAddress"][seller],
        };


        try {
          batch.set(buyerOrderRef, orders[newId]);
          await batch.commit();
        } catch (error) {
          msg =
            "Error: Failed to place order from" +
            validateFieldsReturn["seller_name"][seller] +
            "Firebase error: " +
            error.message;
          console.log(msg)
          vm.setCustomAlert("error", msg);
        }

        try {
          var backend = await vm.calculatePriceMaybeGenerateInvoiceBackend(orders);
        } catch (error) {
          msg =
            "Failed to calculate price on backend for:" +
            orders[orderId].sellerId +
            "Error: " +
            error;
          vm.setCustomAlert("error", msg);
        }

        if (backend.oneSuccessAtLeast) {
          vm.forceUpdateCart();
          vm.validOrder = false;
          store.dispatch("setCart", vm.cart);
          await vm.$router.push({ path: `/loading` });
          await vm.$router.replace({ path: `/orders` });
        } else {
          msg =
            "Error: All orders failed. Possible reason a seller hasn't set up their stripe account yet";
          vm.setCustomAlert("error", msg);
        }
      }
    },

    async calculatePriceMaybeGenerateInvoiceBackend(orders) {
      var failedOrders = []; //this array holds any orders whose prices couldnt be calculated on the backend
      var oneSuccessAtLeast = false; //If we have 1 success, we can move to the /orders page
      var vm = this;
      var msg;

      for (var orderId in orders) {
        try {
          var calculatePriceMaybeGenerateInvoice = cloudFunctions.httpsCallable(
            "calculatePriceMaybeGenerateInvoice"
          );

          var calculatedPrice = await calculatePriceMaybeGenerateInvoice({
            buyerOrderDocId: orderId,
          });

          if (calculatedPrice.data.status === "success") {
            var sellerId = orders[orderId].sellerId;
            await vm.$set(this.notes, sellerId, null);
            await vm.$set(this.dates, sellerId, null);
            await vm.$set(this.timeValues, sellerId, null);
            await vm.$set(this.cart, sellerId, null);

            delete this.cart[sellerId];
            oneSuccessAtLeast = true;
          } else {
            failedOrders.push({
              order: orders[orderId],
              note: this.notes[sellerId],
            });
            msg =
              "Failed to calculate price on backend for:" +
              orders[orderId].sellerId;
            vm.setCustomAlert("error", msg);
          }
        } catch (error) {
          msg =
            "Failed to calculate price on backend for:" +
            orders[orderId].sellerId;
          vm.setCustomAlert("error", msg);
        }
      }
      return {
        failedOrders: failedOrders,
        oneSuccessAtLeast: oneSuccessAtLeast,
      };
    },

    async checkAvailabilityAllCartItems() {
      var unavailableProducts = [];
      var cart = this.cart;
      var product, msg;
      for (const seller in await cart) {
        for (var item in await cart[seller]) {
          await db
            .collection("profile")
            .doc(seller)
            .collection("items")
            .doc(item)
            .get()
            .then((doc) => {
              if (doc.exists) {
                product = doc.data();
                if (product.availability !== "available") {
                  msg =
                    product.name +
                    "is no longer sold by " +
                    seller +
                    ". error: product no longer available";
                  vm.setCustomAlert("error", msg);
                  unavailableProducts.push(cart[seller][item]);
                }
              } else {
                msg = "Error: doc does not exist";
                vm.setCustomAlert("error", msg);
                unavailableProducts.push(cart[seller][item]);
              }
            })
            .catch(function (error) {
              unavailableProducts.push(cart[seller][item]);
              msg =
                "Error getting document: " +
                item +
                " from " +
                seller +
                "error: " +
                error;
              vm.setCustomAlert("error", msg);
            });
        }
      }
      return unavailableProducts;
    },

    calculateOrderPrice(order, sellerKey) {
      var price = 0;
      var vm = this;
      var msg;
      for (var item in order) {
        price += order[item].price * order[item].quantity;
      }
      vm.$set(this.pricesObj, sellerKey, price);
      return price;
    },

    calculateMinMaxDates(order, sellerKey) {
      var vm = this;
      var minArr = [];
      var maxArr = [];

      var oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

      minArr.push(new Date());
      maxArr.push(oneYearFromNow);

      //Add range_start to minArr, and range_end to maxArr
      for (var item in order) {
        if (order[item].range_start && order[item].range_end) {
          minArr.push(new Date(order[item].range_start));
          maxArr.push(new Date(order[item].range_end));
        }
      }

      if (minArr.length !== 0 && maxArr.length !== 0) {
        //Extract the max date from minArr, and the min date from maxArr
        var earliest_pickup_date_start = new Date(Math.max.apply(null, minArr));
        var earliest_pickup_date_end = new Date(Math.min.apply(null, maxArr));

        //This checks whether our selected date is within a  valid  range, updates to null if not.
        if (
          earliest_pickup_date_start <= this.dates[sellerKey] &&
          earliest_pickup_date_end >= this.dates[sellerKey]
        ) {
          //do nothing
        } else {
          vm.$set(this.dates, sellerKey, null);
        }

        vm.$set(this.minDatesObj, sellerKey, earliest_pickup_date_start);
        vm.$set(this.maxDatesObj, sellerKey, earliest_pickup_date_end);
      } else {
        vm.$set(this.minDatesObj, sellerKey, null);
        vm.$set(this.maxDatesObj, sellerKey, null);
      }
    },

    setItemsPickupNotes(sellerKey, note) {
      var cart = this.cart;
      for (var item in cart[sellerKey]) {
        cart[sellerKey][item].pickup_note = note;
      }
    },

    setItemsPickupDates(sellerKey, date) {
      var cart = this.cart;
      var vm = this;
      // var nullTimeValueObj = {
      //   h: null,
      //   mm: null,
      //   ss: null,
      //   A: null,
      // };

      date.setHours(0, 0, 0, 0);

      // vm.$set(vm.dates, sellerKey, null);


      for (var item in cart[sellerKey]) {
        cart[sellerKey][item].pickup_date = date;
        cart[sellerKey][item].timeValue = this.setTimePlaceholder(sellerKey, item);
        // vm.$set(this.timeValues, sellerKey, nullTimeValueObj);
        vm.$set(this.maxDatesObj, sellerKey, null);
        vm.$set(this.enabledMins, sellerKey, [0, 0]);
      }
      

      this.forceUpdateCart();
      // this.getEnabledDates(sellerKey, date);
    },

    resetSellerItemsDatesTimesNotes(sellerKey) {
      var cart = this.cart;
      var vm = this;
      var nullTimeValueObj = {
        h: null,
        mm: null,
        ss: null,
        A: null,
      };

      for (var item in cart[sellerKey]) {
        cart[sellerKey][item].pickup_date = null;
        cart[sellerKey][item].timeValue = nullTimeValueObj;
        vm.$set(this.timeValues, sellerKey, nullTimeValueObj);
        vm.$set(this.maxDatesObj, sellerKey, null);
        vm.$set(this.enabledMins, sellerKey, [0, 0]);
        cart[sellerKey][item].pickup_note = null;
      }
    },

   
   
 /* START TIMEPICKER SPECIFIC FUNCTIONS START 
    getEnabledDates(sellerKey, date) {
      var vm = this;
      var cart = vm.cart;
      var day = date.getDay() + 1; //We have +1 because the hours start arr starts from 0 and getDay starts from 1

      var item = Object.keys(cart[sellerKey])[0];
      console.log(cart);
      console.log(cart[sellerKey][0]);

      //Convert 24h strings (store's open times) to 12h strings
      var hoursStartInt24 = parseInt(
        cart[sellerKey][item]["seller_hours_start_arr"][day].substring(0, 2)
      );
      var hoursEndInt24 = parseInt(
        cart[sellerKey][item]["seller_hours_end_arr"][day].substring(0, 2)
      );
      var hoursStartInt12 = ((hoursStartInt24 + 11) % 12) + 1;
      var hoursEndInt12 = ((hoursEndInt24 + 11) % 12) + 1;

      var hoursStartStr12, hoursEndStr12;
      if (hoursStartInt24 >= 12) {
        hoursStartStr12 = hoursStartInt12.toString() + "p";
      } else {
        hoursStartStr12 = hoursStartInt12.toString() + "a";
      }

      if (hoursEndInt24 >= 12) {
        hoursEndStr12 = hoursEndInt12.toString() + "p";
      } else {
        hoursEndStr12 = hoursEndInt12.toString() + "a";
      }

      //Convert 12a to 0a to work with vue-timepicker
      if (hoursStartStr12 === "12a") {
        hoursStartStr12 = "0a";
      }
      if (hoursEndStr12 === "12a") {
        hoursEndStr12 = "0a";
      }

      vm.$set(this.enabledHours, sellerKey, [hoursStartStr12, hoursEndStr12]);
    },

 
        validateTime(seller, itemId) {
      var cart = this.cart;

      //Validate timeValue has a defined value
      if (cart[seller][item].timeValue) {
        if (cart[seller][item].timeValue.data) {
          var timeData = cart[seller][item].timeValue.data;
          if (
            !timeData.H ||
            timeData.H === undefined ||
            timeData.H === "" ||
            !timeData.m ||
            timeData.mm === undefined ||
            timeData.mm === "" ||
            !timeData.A ||
            timeData.A === undefined ||
            timeData.A === ""
          ) {
            invalid_times[seller] = seller;
          }
        } else {
          invalid_times[seller] = seller;
        }
      } else {
        invalid_times[seller] = seller;
      }
    },

    errorHandler(eventData, sellerKey) {
      var vm = this;

      var nullTimeValueObj = {
        h: null,
        mm: null,
        ss: null,
        A: null,
      };

      vm.$set(this.timeValues, sellerKey, nullTimeValueObj);
    },

     trigger() {
      this.$refs.timePick.click();
    },

    timeChange(eventData, sellerKey) {
      var vm = this;
      var cart = this.cart;
      var item = Object.keys(cart[sellerKey])[0];

      if (!cart[sellerKey][item].pickup_date) {
        vm.$set(vm.alert, "type", "error");
        vm.$set(
          vm.alert,
          "msg",
          "Please select a valid pickup date for seller:" +
            cart[sellerKey][item].seller_name
        );
        this.spinnerOn = false;
        return -1;
      } else {
        var day = cart[sellerKey][item].pickup_date.getDay() + 1;
      }

      // Set min and max minute based on hour
      var startHourString, startAmPmString, endHourString, endAmPmString;

      //Extract the start hour and end hour strings, and store them in a variable with a similar format to what we get from the eventData.data
      if (this.enabledHours[sellerKey]) {
        if (this.enabledHours[sellerKey][0].length === 2) {
          startHourString = this.enabledHours[sellerKey][0][0].toString();
          startAmPmString =
            this.enabledHours[sellerKey][0][1].toString().toUpperCase() + "M";
        } else if (this.enabledHours[sellerKey][0].length === 3) {
          startHourString = this.enabledHours[sellerKey][0].substring(0, 2);
          startAmPmString =
            this.enabledHours[sellerKey][0][2].toString().toUpperCase() + "M";
        }

        if (this.enabledHours[sellerKey][1].length === 2) {
          endHourString = this.enabledHours[sellerKey][1][0].toString();
          endAmPmString =
            this.enabledHours[sellerKey][1][1].toString().toUpperCase() + "M";
        } else if (this.enabledHours[sellerKey][1].length === 3) {
          endHourString = this.enabledHours[sellerKey][1].substring(0, 2);
          endAmPmString =
            this.enabledHours[sellerKey][1][2].toString().toUpperCase() + "M";
        }
      }

      var startMins, endMins;
      startMins = -1;
      endMins = 0;
      if (eventData.data.h.length > 0) {
        startMins = 0;
        endMins = 60;
      }

      var item = Object.keys(cart[sellerKey])[0];
      var day = cart[sellerKey][item].pickup_date.getDay() + 1;
      // Case if start hour and end hour are the same, and they are selected
      if (
        eventData.data.h === startHourString &&
        eventData.data.A === startAmPmString &&
        eventData.data.h === endHourString &&
        eventData.data.A === endAmPmString
      ) {
        startMins = parseInt(
          cart[sellerKey][item]["seller_hours_start_arr"][day].substring(3)
        );
        endMins = parseInt(
          cart[sellerKey][item]["seller_hours_end_arr"][day].substring(3)
        );
      }
      //Case if start hour is selected
      else if (
        eventData.data.h === startHourString &&
        eventData.data.A === startAmPmString
      ) {
        startMins = parseInt(
          cart[sellerKey][item]["seller_hours_start_arr"][day].substring(3)
        );
        endMins = 60;
      } else if (
        eventData.data.h === endHourString &&
        eventData.data.A === endAmPmString
      ) {
        startMins = 0;
        endMins = parseInt(
          cart[sellerKey][item]["seller_hours_end_arr"][day].substring(3)
        );
      }

      vm.$set(this.enabledMins, sellerKey, [startMins, endMins]);

      for (var item in cart[sellerKey]) {
        cart[sellerKey][item].timeValue = eventData;
      }

      console.log(eventData);

      this.forceUpdateCart();
    },

    /* END TIMEPICKER SPECIFIC FUNCTIONS END  */
  },
  created() {
    // https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
    // This created watches for ADD_ITEM state changes, and updates the cart whenever ADD_ITEM is triggered. Helps in synchronizing the cart with the vue cart state.
    var vm = this;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "ADD_ITEM" ||
        mutation.type === "SET_PAYMENT_METHODS" ||
        mutation.type === "REMOVE_ITEM"
      ) {
        //console.log(`Updating to ${state.user.cart}`);
        vm.forceUpdateCart();
      }
    });
  },
  mounted() {
    var vm = this;
    vm.cart = vm.user.cart;
    var cart = vm.cart;
    for (var seller in cart) {
      this.resetSellerItemsDatesTimesNotes(seller);
      this.calculateOrderPrice(cart[seller], seller);
      this.calculateMinMaxDates(cart[seller], seller);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
/* @import '~vue2-timepicker/dist/VueTimepicker.css'; */

@import "https://fonts.googleapis.com/css?family=Quantico";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
/* font-awesoeme css */
@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";

@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";

@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");
.nav-container {
  float: right;
}
@media (max-width: 376px) {
  .nav-container {
    max-width: 375px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  /* border: 1px solid #42b983; */
  border: none;
  display: inline-flex;
  border-radius: 5px;
  height: 23px;
  background-color: white;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 22px;
  cursor: pointer;
  margin: 0;
  position: relative;
}

.plus-minus {
  color: #42b983;
  font-size: 12px;
  font-weight: 500;
}

.number-input input[type="number"] {
  /* max-width: 5rem; */
  width: 50px;
  padding: 0.5rem;
  border: solid #ddd;
  border-width: 0 2px;
  font-size: 14px;
  height: 21px;
  /* height: 3rem; */
  font-weight: 400;
  text-align: center;
}

.top-div {
  height: 42.5px;
  font-size: 19px;
  padding: 13px;
  font-family: Google Sans;
  z-index: 100;
  padding-top: 10px;
}
.order-div {
  font-family: Google Sans;
  min-height: 100%;
  overflow-y: auto;
  border-top: 1px solid rgb(227, 227, 227);
}
.order-title {
  height: fit-content;
  font-size: 17px;
  padding: 12px;
  font-family: Google Sans;
  margin-top: 0px;
  font-weight: 450;
  color: #545454;
  border-bottom: 1px dashed #bfbfbf;
}
.order-number {
  font-weight: 800;
  margin-right: 10px;
  font-size: 19px;
}
.order-item {
  padding: 10px;
  padding-left: 5px;
  font-size: 14px;
  height: 60px;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.item-quantity {
  width: 100px;
  float: left;
  margin-top: -2px;
}
.item-title {
  width: 200px;
  float: left;
  margin-right: 5px;
}
.item-unit-price {
  width: 75px;
  float: left;
  color: gray;
}
.item-price {
  width: 50px;
  float: left;
}
.item-delete {
  width: 20px;
  color: rgba(255, 95, 95, 0.877);
  float: left;
  text-align: right;
}
.order-subtotal {
  padding: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  margin-bottom: 10px;
}
.subtotal-title {
  margin-right: 5px;
  width: 370px;
  float: left;
}
.subtotal-price {
  /* float: left; */
}

.tab_container {
  /* width: 100%; */
  margin: 0 auto;
  margin-bottom: 15px;
  height: fit-content;
  border-radius: 5px;
  max-width: 500px;
  overflow-y: auto;
}

.tab-input,
section {
  clear: both;
  padding-top: 10px;
  display: none;
}

.tab-icon {
  font-size: 14px;
  margin-bottom: -1px;
  margin-right: 5px;
  /* color: #42b983; */
}

.tab-label-1 {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  width: 49%;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
}

.tab-label-2 {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  width: 49%;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-left: 2%;
}
.delivery-unavailable {
  opacity: 1;
  background-image: url("https://www.xmple.com/wallpaper/white-lines-stripes-streaks-grey-1920x1080-c2-ffffff-dcdcdc-l2-54-108-a-30-f-1.svg");
  background-size: cover;
}

.tab_container [id^="tab"] + label {
  background: #fff;
  box-shadow: inset 0 -2px 0px#42b983b6;
  color: #42b983dc;
}
.tab-text {
  color: rgb(75, 75, 75);
}

.order-select-pickup {
  padding: 10px;
  padding-left: 10px;
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px dashed #bfbfbf;
  border-bottom: 1px dashed #bfbfbf;
}
.select-date {
  float: left;
  font-size: 13px;
  color: #8c8c8c;
  margin-bottom: 10px;
  font-weight: 900;
  /* border: 1px solid black; */
}
.select-time {
  margin-top: 25px;
  font-size: 13px;
  color: #8c8c8c;
  font-weight: 900;
  margin-bottom: 25px;
  width: 100%;
}
.time-picker-container {
  float: right;
  margin-top: -2px;

  /* outline: 1px solid black; */
  width: fit-content;
}
.time-picker-button {
  height: 28.5px;
  width: 50px;
  background-color: #42b98334;
  color: #42b983;
  border: 1px solid #dadada;
  border-right: none;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 5px 0px 0px 5px;
  position: absolute;
  margin-left: -50px;
}
.time-picker-button:hover {
  background-color: #42b98375;
  color: #3ba575;
}
.fa-clock {
  position: absolute;
  top: 7px;
  margin-left: -5px;
}
.timepicker-unavailable-white {
  position: absolute;
  width: 130px;
  height: 28.5px;
  background-color: rgb(255, 255, 255);
  z-index: 9;
}
.timepicker-unavailable-grey {
  position: absolute;
  width: 130px;
  height: 28.5px;
  background-color: rgba(0, 0, 0, 0.11);
  background-image: url("https://www.xmple.com/wallpaper/white-lines-stripes-streaks-grey-1920x1080-c2-ffffff-dcdcdc-l2-54-108-a-30-f-1.svg");
  background-size: cover;
  opacity: 0.7;
}
.timepicker-unavailable-text {
  position: absolute;
  top: 4px;
  left: 7px;
  font-size: 12px;
  font-weight: 450;
  color: rgb(62, 62, 62);
}

.date-picker-container {
  float: right;
  border-right: 1px solid #dadada;
  /* border: 1px solid black; */
}

.date-picker-button {
  height: 29px;
  width: 50px;
  z-index: 99;
  background-color: #42b98334;
  color: #42b983;
  border: 1px solid #dadada;
  border-right: none;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 5px 0px 0px 5px;
}
.date-picker-button:hover {
  background-color: #42b98375;
  color: #3ba575;
}

.date-picker-input {
  height: 29px;
  z-index: 9;
  width: 130px;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  z-index: 1;
  background-color: #fcfcfc;
}
@media (min-width: 475px) {
  .date-picker-input {
    width: 130px;
  }
}

.date-time-input {
  border: none;
  float: left;
  /* text-align: left; */
  color: rgb(75, 75, 75);
  background-color: white;
  /*width: 98vw;  this was the width when it worked for phone*/

  /*height:100px; */
  z-index: 5000;
}

.order-pickup-details {
  padding: 10px;
  padding-right: 0px;
  border-top: 1px dashed #bfbfbf;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px dashed #bfbfbf;
  margin-top: 15px;
  margin-bottom: 15px;
}
.pickup-details-header {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}
.pickup-details-farm {
  font-size: 14px;
  margin-bottom: 10px;
}
.pickup-details-title {
  font-weight: 900;
  color: #8c8c8c;
  float: left;
}
.row {
  margin: 0px;
}
.col-3 {
  padding: 0px;
}
.col-4 {
  padding: 0px;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}
.col-5 {
  padding: 0px;
}
.col-6 {
  padding: 0px;
}
.col-7 {
  padding: 0px;
  /* border: 1px solid black; */
  /* width: 380px; */
}
.col-8 {
  padding: 0px;
}
.pickup-details-address {
  font-size: 13px;
  color: #8c8c8c;
  margin-bottom: 10px;
  font-weight: 500;
  width: 100%;
}
.pickup-details-address-title {
  font-weight: 900;
  color: #8c8c8c;
  /* border: 1px solid black; */
}
.pickup-details-address-result {
  padding-left: 0px;
  color: rgb(75, 75, 75);
  float: right;
  padding-right: 0px;
  text-align: right;
}
.pickup-details-instructions {
  font-size: 13px;
  color: #8c8c8c;
  margin-bottom: 0x;
  font-weight: 500;
}
.pickup-details-instructions-title {
  font-weight: 900;
  color: #8c8c8c;
}
.pickup-details-instructions-result {
  padding-left: 0px;
  color: rgb(75, 75, 75);
  text-align: right;
}

.pickup-details-date {
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
}
.pickup-details-time {
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
}
.pickup-details-result {
  margin-left: 20px;
  margin-right: 20px;
}
.check-container-1 {
  /* height: 29px; */
  width: 10px;
  margin-right: 10px;
  padding-top: 0px;
  display: inline;
  float: left;
}
.check-container-2 {
  /* height: 29px; */
  width: 10px;
  margin-right: 10px;
  margin-left: 0px;
  padding-top: 0px;
  display: inline;
  float: left;
  margin-top: 25px;
}
.fa-check {
  color: #42b983;
}
.order-notes {
  padding: 10px;
  padding-left: 10px;
  padding-top: 0px;
  margin-top: 15px;
}

.notes-header {
  font-size: 14px;
  margin-bottom: 10px;
}
.notes-input {
  border: 1px solid #ddd;
  border-radius: 5px;
  /* border: none; */
  margin-bottom: 10px;
  width: 405px;
  font-size: 14px;
  padding-left: 5px;
  padding-top: 2px;
  font-family: Google Sans;
}
.payment-method {
  /* border: 1px solid #00347438; */
  border-radius: 10px;
  height: 50px;
  width: 300px;
  margin: 85px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Google Sans;
}
.image-col {
  float: left;
  margin-right: 20px;
  padding: 12.5px;
}
.info-col {
  float: left;
  padding: 12.5px;
  padding-bottom: 0px;
}
.change-col {
  float: right;
  text-align: right;
  padding: 12.5px;
  padding-top: 9px;
  color: #42b983;
  /* border-left: 1px solid #00347438; */
  border-radius: 10px;
  height: 58px;
}
.change-col-text {
  font-size: 13px;
}
.payment-method-card-image {
  width: 30px;
}
.payment-method-card-title {
  margin-top: -1px;
  margin-bottom: -5px;
  font-size: 12px;
  font-weight: 400;
}
.payment-method-card-subtitle {
  font-size: 11px;
  font-weight: 400;
}
.order-buy {
  /* position: absolute; */
  background-color: white;
  /* bottom: 0px; */
  width: 100%;
  border-top: 1px solid rgb(227, 227, 227);
}

.buy-button {
  margin: 5%;
  margin-top: 30px;
  margin-bottom: 0px;
  width: 90%;
  height: 80px;
  background-color: #42b983;
  color: white;
  font-weight: 500;
  font-family: Google Sans;
  border-radius: 5px;
  padding: 5px;
  font-size: 17px;
  border: none;
  display: block;
}
.buy-button:hover {
  background-color: #349469;
}
.order-empty {
  padding: 12.5px;
  width: 485px;
  overflow-x: none;
  font-size: 17px;
  margin-bottom: 100px;
  font-weight: 350;
  border-top: 1px solid #ddd;
}

@media (max-width: 475px) {
  .item-quantity {
    width: 22vw;
  }
  .number-input button {
    width: 18px;
  }
  .plus-minus {
    font-size: 11px;
  }
  .number-input input[type="number"] {
    width: 35px;
    font-size: 13px;
  }

  .item-title {
    width: 33vw;
  }
  .item-unit-price {
    width: 20vw;
  }
  .item-price {
    width: 15vw;
  }
  .item-delete {
    width: 5vw;
    text-align: left;
  }

  .subtotal-title {
    width: 73vw;
  }

  .order-pickup-details {
    max-width: 99vw;
  }
  .notes-input {
    width: 95vw;
  }
  .payment-method {
    height: 50px;
    width: 90vw;
    margin: 5vw;
  }

  .order-buy {
    width: 100vw;
  }
}

.cart-sidebar {
  background: #ffffff;
  color: rgb(75, 75, 75);
  position: absolute;
  right: -485px;
  width: 100vw;
  max-width: 475px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  margin-right: 10px;
  padding-top: 2px;
}

#toggle {
  position: absolute;
  appearance: none;
  cursor: pointer;
  left: -100%;
  top: -100%;
  z-index: 1;
}

#toggle + label {
  position: absolute;
  cursor: pointer;
  width: 30px;
  border-radius: 3px;
  color: #42b98300;
  line-height: 23px;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  margin-top: 12px;
  transition: all 100ms ease;
  right: -5px;
  top: 0px;
  margin-right: 25px;
  z-index: 1;
}
#toggle + label:after {
  /* content: "\f07a"; */
  font-family: FontAwesome;
  font-size: 29px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #a8a8a8de;
}

#toggle ~ .cart-sidebar {
  display: none;
}

#toggle:checked ~ .cart-sidebar {
  top: 0px;
  right: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.961);
  display: unset;
  z-index: 1;
}

#toggle:checked + label:after {
  content: "\00D7";
  font-size: 27px;
  color: #8a8a8a;
  -webkit-text-stroke-width: 0px;
  margin-right: -25px;
}

.fa-file-invoice {
  margin-top: -4px;
  margin-left: 5px;
  color: #42b983;
  font-size: 30px;
}

.invoice-inside {
  font-size: 23px;
  margin-top: 0px;
}

.loader {
  height: 80px;
  width: 10%;
  text-align: center;
  display: inline-block;
  margin-left: 45%;
  margin-right: 45%;
  margin-top: -30px;
}
/*
  Set the color of the icon
*/
svg path,
svg rect {
  fill: #42b983e5;
}

.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  top: 20px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
  padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

.no-delivery {
  pointer-events: none;
  color: red;
}

.disable-delivery {
  background-color: gray;
}

.badge-2 {
  /* float: left; */
  display: block;
  font-weight: 300;
  letter-spacing: 0px;
  text-transform: unset;
  text-align: center;
  font-family: Quantico;
  color: rgb(0, 0, 0, 0.6);
  background-color: rgba(0, 211, 180, 0.2);
  background-color: rgba(255, 119, 0, 0.293);
  font-size: 12px;
  line-height: 8.5px;
  height: 18px;
  padding: 4px;
  /* margin-left: 4px; */
  margin-top: 2px;
  border-radius: 3px;
}
</style>