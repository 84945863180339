<template>
  <div class="container" v-if="loaded === true">
    <div v-if="user.data && user.profile">
      <div v-if="user.data.uid === userID && user.profile.type === 'seller'" >
        <div>
          <input type="radio" id="edit" value="edit" v-model="editOrView" />
          <label for="edit">Edit</label>
        </div>
        <div>
          <input type="radio" id="view" value="view" v-model="editOrView" />
          <label for="view">View</label>
        </div>
      </div>
    </div>

    <div v-if="editOrView === 'view'">

      <div class="about-title">
        Story
        <div class="about-details"> {{sections.about.story}} </div>
      </div>
      
      <div class="about-title">
        Mission
        <div id="mission" class="about-details"> {{sections.about.mission}} </div>
      </div>

      <div class="about-title">
        Meet our Staff
        <div class="about-details">names and pics</div>
      </div>

      <div class="about-title">
        <!-- Gallery -->
        Gallery
        <div class="gallery-images">
          <span v-for="(image, index) in sections.about.gallery" :key="index" >
          <img class="gallery-image" :src="image" alt="https://i.pinimg.com/originals/e1/75/b3/e175b334ec8fe3b33fbfeb3308961f9b.jpg">

          </span>
          
        </div>
      </div>

    </div>
    
    <div v-else-if="editOrView === 'edit' && sections.about">
      <div>
        Our story/history
        <div class="form-group">
          <textarea
            placeholder="A little about your story"
            class="form-control"
            rows="5"
            v-model="sections.about.story"
          ></textarea>
        </div>
      </div>
      <div>
        Our Mission
        <div class="form-group">
          <textarea
            placeholder="A little about your story"
            class="form-control"
            rows="5"
            v-model="sections.about.mission"
          ></textarea>
        </div>
      </div>
      <div>
        Gallery
        <div class="form-group">
          <input
            type="file"
            @click="staffOrGallery = 'gallery'"
            @change="uploadImage"
            id="fileinput"
            class="form-control"
            ref="imageToUpload"
            accept="image/*"

          />
        </div>
        <div class="form-group container">
          <div class="row">
            <div
              v-for="(image, index) in sections.about.gallery"
              :key="image"
              class="p-1"
            >
              <div class="img-wrap preview-img-wrap">
                <img :src="image" width="80px" />
                <span
                  class="delete-img"
                  @click="deleteImage(image, index, sections.about.gallery)"
                >
                  X
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <input
            type="submit"
            @click="saveAbout"
            value="Save Changes"
            class="btn btn-primary w-100"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../store";
import firebase from "firebase";
import { db } from "../main.js";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    editOrView() {
      this.$set(this.alert, 'type', null);
      this.$set(this.alert, 'msg', null);
    }
  },
  data() {
    return {
      alert: {
        type: null,
        msg: null,
      },
      
      userID: this.$route.params.userID,
      editOrView: "view",
      staffOrGallery: null,
      deletedImagesURLS: [],
      loaded: false,
      sections: {
        about: {
          story: "",
          staff: {
            text: "",
            images: [],
          },
          mission: "",
          gallery: [],
        },
      },
    };
  },
  methods: {
    uploadImage(e) {
      var imgArr;
      var vm = this;
      var uuid = require("uuid");
      var id = uuid.v4();

      if (this.staffOrGallery === "staff") {
        imgArr = vm.sections.about.staff.images;
      } else if (this.staffOrGallery === "gallery") {
        imgArr = vm.sections.about.gallery;
      }

      if (e.target.files[0]) {
        var file = e.target.files[0];
        //console.log(file);

        var storageRef = firebase
          .storage()
          .ref("items/" + this.user.data.uid + "/" + id);

        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg",
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.put(file, metadata);
        //console.log(uploadTask);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                //console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                //console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              //...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                //console.log("File available at", downloadURL);

                if (vm.staffOrGallery === "event") {
                  vm.newEvent.image = downloadURL;
                } else {
                  imgArr.push(downloadURL);
                }
              });
          }
        );
      }
    },

    deleteImage(img, index, imgArr) {
      var image = firebase.storage().refFromURL(img);
      this.deletedImagesURLS.push(firebase.storage().refFromURL(img));
      imgArr.splice(index, 1);
      this.$refs.imageToUpload.value = '';
    },
    saveAbout() {
      var vm = this;

      //Deleting any discarded images before about save from storage.
      const IDAboutArr = [this.user.data.uid, "about"];
      for (var image in this.deletedImagesURLS) {
        this.deletedImagesURLS[image]
          .delete()
          .then(() => {
          })
          .catch((error) => {
          });
      }

      var sectionRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("sections")
        .doc("about");

      sectionRef
        .set(this.sections["about"], { merge: true })
        .then(() => {
          //console.log("Document successfully written! save section");
          store.dispatch("fetchSection", IDAboutArr);
          vm.$set(vm.alert, 'type', 'success');
          vm.$set(vm.alert, 'msg', "You have succesfully updated your about page.");
        })
        .catch(function (error) {
          store.dispatch("fetchSection", IDAboutArr);
          vm.$set(vm.alert, 'type', 'error');
          vm.$set(vm.alert, 'msg', "Error updating your about page. Error: " + error );
        });
    },
    async getSections(userID) {
      const IDAboutArr = [userID, "about"];
      await store.dispatch("fetchSection", IDAboutArr);
      this.sections = await this.user.sections;
      this.loaded = true;
    },
  },
  mounted() {
    var vm = this;
    vm.getSections(this.userID);
  },
};
</script>


<style scoped>
.about-title{
  font-family: Google Sans;
  font-size: 22px;
  margin: 50px auto;
  height: fit-content;
}
.about-details{
  font-size: 17px;
  margin-top: 10px;
}
.gallery-images{
  margin-top: 15px;
  overflow-x: auto;
  width: fit-content;
  white-space: nowrap;
}
.gallery-image{
  height: 150px;
  width: 220px;
  border-radius: 5px;
  margin-right: 20px;
  display: inline-block;
}



h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.img-wrap {
  position: relative;
}
.img-wrap span.delete-img {
  position: absolute;
  top: -5px;
  right: 1px;
}
.img-wrap span.delete-img:hover {
  cursor: pointer;
}

/* Next & previous buttons  */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/*On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

div {
  margin-bottom: 10px;
  position: relative;
}

input[type="number"] {
  width: 100px;
}

input + span {
  padding-right: 30px;
}

input:invalid + span:after {
  position: absolute;
  content: "✖";
  padding-left: 5px;
}

input:valid + span:after {
  position: absolute;
  content: "✓";
  padding-left: 5px;
}

/* Set the size of the div element that contains the map */
#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
</style>