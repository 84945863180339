<template>
  <div class="container">
    <div class="tab_container">
      <h1
        @click="disableAlert"
        class="alert"
        v-bind:class="{
          'alert-success': alert.type == 'success',
          'alert-error': alert.type == 'error',
        }"
      >
        {{ alert.msg }}
      </h1>

      <div v-if="user">
        <div v-if="user.data && user.profile">
          <div v-if="user.data.uid">
            <div v-if="user.profile.type === 'seller'">
              <input
                class="tab-input"
                id="tab1"
                type="radio"
                name="payment-tabs"
                value="stripe"
                v-model="tab"
                checked
              />
              <label class="tab-label-1" for="tab1">
                <div>
                  <img
                    class="stripe-tab-image"
                    src="https://wpforms.com/wp-content/uploads/2016/03/addon-icon-stripe-1.png"
                  />
                  Stripe
                </div>
              </label>

              <input
                class="tab-input"
                id="tab2"
                type="radio"
                name="payment-tabs"
                value="paymentMethods"
                v-model="tab"
              />
              <label class="tab-label-2" for="tab2"
                ><i class="fas fa-credit-card tab-icon"></i
                ><span> Payment Methods</span></label
              >

              <div v-if="tab === 'stripe'">
                <div v-if="loadedStripe">
                  <a
                    v-if="!stripeId && requested_accountLink === false"
                    @click.once="triggerCloudFunctionStripeOnboarding"
                    >Connect to Stripe to start accepting payments directly from
                    your customers. Please ensure that the Stripe account you
                    sign up/login with uses the same email address as your
                    farmbrah account. It is crucial to be to able to accept
                    online payments. You can change your email in
                    <a href="http://localhost:8080/settings"
                      >Account Settings</a
                    >
                    to match your stripe account before or after connecting.
                    <!-- <img src="https://i.ibb.co/t3d6hHr/Connect-social-card-removebg-preview.jpg"> -->
                    <button class="bottom-content">
                      <p class="ripple">Connect a Stripe Account</p>
                    </button>
                  </a>
                  <a v-else-if="stripeId" @click="openDashboard">
                    <h5 class="card-title">
                      Stripe account {{ user.stripeId }} is accepting payments:
                      <i class="fas fa-check-double"></i>
                    </h5>
                    <button class="bottom-content">
                      <p class="ripple">Go to my Stripe Dashboard</p>
                    </button>
                    <!-- <button class="unlink">
                      <p>Unlink this Stripe Account from Opengrow</p>
                    </button> -->
                  </a>
                  <a v-else
                    >Redirecting you to stripe. Please fill out the form to
                    register and be able to accept payments. Please ensure that
                    you sign up/login with on Stripe with your same farmbrah
                    email. It is crucial to be to able to accept online
                    payments. You can change your email in
                    <a href="http://localhost:8080/settings"
                      >Account Settings</a
                    >
                    to match your stripe account before or after connecting.
                    <!-- loader icon for stripe link before pop-up comes up -->
                    <div
                      v-if="tab === 'stripe'"
                      class="loader loader--style3"
                      title="2"
                    >
                      <svg
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="100px"
                        height="100px"
                        viewBox="0 0 100 10"
                        style="enable-background: new 0 0 50 50"
                        xml:space="preserve"
                      >
                        <path
                          fill="#000"
                          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                        >
                          <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    </div>
                    <button
                      @click="reloadPage()"
                      class="bottom-content-red-stripetab"
                    >
                      <p class="ripple-red">Cancel Stripe Connection Process</p>
                    </button></a
                  >
                </div>
              </div>

              <div v-show="tab === 'paymentMethods'">
                <div id="cards">
                  <!-- <div id="redirect-message"></div> -->
                  <div
                    v-if="loadedNewCardElements === false"
                    @click.once="addNewCardElements"
                  >
                    <button @click="isShow = !isShow" class="bottom-content">
                      <p class="ripple">Add new credit card</p>
                    </button>

                    <!-- loader icon for add card button before stripe form comes up -->
                    <div
                      v-show="!isShow"
                      class="loader loader--style3"
                      title="2"
                    >
                      <svg
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="100px"
                        height="100px"
                        viewBox="0 0 100 10"
                        style="enable-background: new 0 0 50 50"
                        xml:space="preserve"
                      >
                        <path
                          fill="#000"
                          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                        >
                          <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    </div>
                  </div>

                  <div
                    v-show="tab === 'paymentMethods' && loadedNewCardElements"
                  >
                    <form
                      @submit.prevent="stripeElementsSubmit"
                      id="payment-form"
                      class="payment-form-style"
                    >
                      <div id="payment-element">
                        <!-- Elements will create form elements here -->
                      </div>
                      <button id="submit">Save Payment Method</button>
                      <div id="error-message">
                        <!-- Display error message to your customers here -->
                      </div>
                    </form>
                    <div class="save-payment-method-underline"></div>
                    <button @click="reloadPage()" class="bottom-content-red">
                      <p class="ripple-red">Cancel</p>
                    </button>
                  </div>

                  <div v-show="tab === 'paymentMethods'" class="cards-wrap">
                    <div
                      v-for="(paymentMethod, index) in cardInfoArr"
                      :key="index"
                      class="card-container"
                    >
                      <div class="payment-method-image-col">
                        <img
                          v-if="paymentMethod.card.brand === 'amex'"
                          class="payment-method-image-amex"
                          src="https://biotrade.gr/wp-content/uploads/2019/01/logo-american-express-png-3.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'cartes_bancaires'"
                          class="payment-method-image"
                          src="https://toppng.com/uploads/preview/carte-bancaire-11550711493a8xtpmy7d1.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'diners'"
                          class="payment-method-image-diners"
                          src="https://1000logos.net/wp-content/uploads/2021/05/Diners-Club-International-logo.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'discover'"
                          class="payment-method-image"
                          src="https://cdn4.iconfinder.com/data/icons/payment-method/160/payment_method_discover_network_card-512.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'jcb'"
                          class="payment-method-image-jcb"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/JCB_logo.svg/2560px-JCB_logo.svg.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'mastercard'"
                          class="payment-method-image"
                          src="https://toppng.com/uploads/preview/mastercard-logo-11530966761nhfzlt6qu8.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'visa'"
                          class="payment-method-image-visa"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Visa.svg/1280px-Visa.svg.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'unionpay'"
                          class="payment-method-image-jcb"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/UnionPay_logo.svg/1280px-UnionPay_logo.svg.png"
                        />
                      </div>
                      <h6
                        class="payment-method-title card-payment-method-title"
                      >
                        {{ paymentMethod.card.brand }} &nbsp; &#183; &#183;
                        &#183; &#183; {{ paymentMethod.card.last4 }} &nbsp;
                        <!-- <i class="fa fa-check-circle"></i> -->
                      </h6>
                      <h8 class="payment-method-subtitle"
                        >{{ paymentMethod.card.exp_month }} /
                        {{ paymentMethod.card.exp_year }}
                        <a href="#openModal-delete-card">
                          <i class="fa fa-window-close remove"></i>
                        </a>

                        <!-- start of dialog box -->
                        <div id="openModal-delete-card" class="modalDialog">
                          <div>
                            <a href="#close" class="close">x</a>
                            <div
                              class="row"
                              style="margin-left: 5px; margin-right: 5px"
                            >
                              <div
                                class="card payment-method-modal-box"
                                style="padding-bottom: 10px"
                              >
                                <h6
                                  class="payment-method-title"
                                  style="margin-bottom: -3px"
                                >
                                  {{ paymentMethod.card.brand }} &nbsp; &#183;
                                  &#183; &#183; &#183;
                                  {{ paymentMethod.card.last4 }} &nbsp;
                                </h6>
                              </div>
                            </div>

                            <div
                              class="row"
                              style="margin-left: 5px; margin-right: 5px"
                            >
                              <div class="remove-button-container">
                                <button
                                  @click.once="deleteCard(index)"
                                  class="btn btn-reject"
                                >
                                  Delete Card
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end of dialog box -->
                      </h8>
                    </div>
                  </div>
                </div>

                <div id="bank-accounts">
                  <div>
                    <button
                      @click.prevent="addPlaidBankAccount"
                      id="link-button"
                      class="bottom-content"
                    >
                      <p class="ripple">Link Bank Account</p>
                    </button>
                  </div>
                  <div v-show="tab === 'paymentMethods'" class="cards-wrap">
                    <div
                      v-for="(bankAccount, index) in bankAccountArr"
                      :key="index"
                      class="card-container"
                    >
                      <div class="payment-method-image-col">
                        <img
                          v-if="bankAccount.bank_name === 'wells fargo'"
                          class="payment-method-image-visa"
                          src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Wells_Fargo_Logo_%282020%29.png"
                        />
                        <img
                          v-else
                          class="payment-method-image-bank-general"
                          src="https://cdn-icons-png.flaticon.com/512/1728/1728987.png"
                        />
                      </div>
                      <h6 class="payment-method-title">
                        {{ bankAccount.bank_name }} &nbsp; &#183; &#183; &#183;
                        &#183; {{ bankAccount.last4 }} &nbsp;
                        <!-- <i class="fa fa-check-circle"></i> -->
                      </h6>
                      <h8 class="payment-method-subtitle"
                        >{{ bankAccount.country }}
                        <a href="#openModal-delete-bank">
                          <i class="fa fa-window-close remove"> </i>
                        </a>
                        <!-- start of dialog box -->
                        <div id="openModal-delete-bank" class="modalDialog">
                          <div>
                            <a href="#close" class="close">x</a>
                            <div
                              class="row"
                              style="margin-left: 5px; margin-right: 5px"
                            >
                              <div class="card payment-method-modal-box">
                                <h6
                                  class="payment-method-title"
                                  style="
                                    margin-left: 10px;
                                    margin-right: 5px;
                                    font-size: 13px;
                                    margin-bottom: -3px;
                                  "
                                >
                                  {{ bankAccount.bank_name }} &nbsp; &#183;
                                  &#183; &#183; &#183;
                                  {{ bankAccount.last4 }} &nbsp;
                                </h6>
                              </div>
                            </div>

                            <div
                              class="row"
                              style="margin-left: 5px; margin-right: 5px"
                            >
                              <div class="remove-button-container">
                                <button
                                  @click.once="deleteBankAccount(index)"
                                  class="btn btn-reject"
                                >
                                  Delete Bank Account
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end of dialog box -->
                      </h8>
                    </div>
                  </div>
                </div>
                <br /><br /><br />
              </div>
            </div>

            <div v-if="user.profile.type != 'seller'">
              <input
                class="tab-input"
                id="tab2"
                type="radio"
                name="payment-tabs"
                value="paymentMethods"
                v-model="tab"
              />
              <label class="tab-label-2-buyer" for="tab2"
                ><i class="fas fa-credit-card tab-icon"></i
                ><span> Payment Methods</span></label
              >

              <div v-show="tab === 'paymentMethods'">
                <div id="cards">
                  <div id="redirect-message"></div>
                  <div
                    v-if="loadedNewCardElements === false"
                    @click.once="addNewCardElements"
                  >
                    <button @click="isShow = !isShow" class="bottom-content">
                      <p class="ripple">Add new credit card</p>
                    </button>

                    <!-- loader icon for add card button before stripe form comes up -->
                    <div
                      v-show="!isShow"
                      class="loader loader--style3"
                      title="2"
                    >
                      <svg
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="100px"
                        height="100px"
                        viewBox="0 0 100 10"
                        style="enable-background: new 0 0 50 50"
                        xml:space="preserve"
                      >
                        <path
                          fill="#000"
                          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                        >
                          <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    </div>
                  </div>

                  <div
                    v-show="tab === 'paymentMethods' && loadedNewCardElements"
                  >
                    <form
                      @submit.prevent="stripeElementsSubmit"
                      id="payment-form"
                      class="payment-form-style"
                    >
                      <div id="payment-element">
                        <!-- Elements will create form elements here -->
                      </div>
                      <button id="submit">Save Payment Method</button>
                      <div id="error-message">
                        <!-- Display error message to your customers here -->
                      </div>
                    </form>
                    <div class="save-payment-method-underline"></div>
                    <button @click="reloadPage()" class="bottom-content-red">
                      <p class="ripple-red">Cancel</p>
                    </button>
                  </div>

                  <div v-show="tab === 'paymentMethods'" class="cards-wrap">
                    <div
                      v-for="(paymentMethod, index) in cardInfoArr"
                      :key="index"
                      class="card-container"
                    >
                      <div class="payment-method-image-col">
                        <img
                          v-if="paymentMethod.card.brand === 'amex'"
                          class="payment-method-image-amex"
                          src="https://biotrade.gr/wp-content/uploads/2019/01/logo-american-express-png-3.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'cartes_bancaires'"
                          class="payment-method-image"
                          src="https://toppng.com/uploads/preview/carte-bancaire-11550711493a8xtpmy7d1.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'diners'"
                          class="payment-method-image-diners"
                          src="https://1000logos.net/wp-content/uploads/2021/05/Diners-Club-International-logo.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'discover'"
                          class="payment-method-image"
                          src="https://cdn4.iconfinder.com/data/icons/payment-method/160/payment_method_discover_network_card-512.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'jcb'"
                          class="payment-method-image-jcb"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/JCB_logo.svg/2560px-JCB_logo.svg.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'mastercard'"
                          class="payment-method-image"
                          src="https://toppng.com/uploads/preview/mastercard-logo-11530966761nhfzlt6qu8.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'visa'"
                          class="payment-method-image-visa"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Visa.svg/1280px-Visa.svg.png"
                        />
                        <img
                          v-if="paymentMethod.card.brand === 'unionpay'"
                          class="payment-method-image-jcb"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/UnionPay_logo.svg/1280px-UnionPay_logo.svg.png"
                        />
                      </div>
                      <h6 class="payment-method-title">
                        {{ paymentMethod.card.brand }} &nbsp; &#183; &#183;
                        &#183; &#183; {{ paymentMethod.card.last4 }} &nbsp;
                        <!-- <i class="fa fa-check-circle"></i> -->
                      </h6>
                      <h8 class="payment-method-subtitle"
                        >{{ paymentMethod.card.exp_month }} /
                        {{ paymentMethod.card.exp_year }}
                        <i
                          @click.once="deleteCard(index)"
                          class="fa fa-window-close remove"
                        ></i
                      ></h8>
                    </div>
                  </div>
                </div>

                <div id="bank-accounts">
                  <div>
                    <button
                      @click.prevent="addPlaidBankAccount"
                      id="link-button"
                      class="bottom-content"
                    >
                      <p class="ripple">Link Bank Account</p>
                    </button>
                  </div>
                  <div v-show="tab === 'paymentMethods'" class="cards-wrap">
                    <div
                      v-for="(bankAccount, index) in bankAccountArr"
                      :key="index"
                      class="card-container"
                    >
                      <div class="payment-method-image-col">
                        <img
                          v-if="bankAccount.bank_name === 'wells fargo'"
                          class="payment-method-image-visa"
                          src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Wells_Fargo_Logo_%282020%29.png"
                        />
                        <img
                          v-else
                          class="payment-method-image-bank-general"
                          src="https://cdn-icons-png.flaticon.com/512/1728/1728987.png"
                        />
                      </div>
                      <h6 class="payment-method-title">
                        {{ bankAccount.bank_name }} &nbsp; &#183; &#183; &#183;
                        &#183; {{ bankAccount.last4 }} &nbsp;
                      </h6>
                      <h8 class="payment-method-subtitle"
                        >{{ bankAccount.country }}
                        <i
                          @click.once="deleteBankAccount(index)"
                          class="fa fa-window-close remove"
                        ></i
                      ></h8>
                    </div>
                  </div>
                </div>
                <br /><br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import firebase, { firestore } from "firebase";
import store from "../store";
import { mapGetters } from "vuex";
import { db, cloudFunctions } from "../main.js";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.state.user.data) next({ name: "Login" });
    else next();
  },
  components: {},
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      tab: null,
      accountLink: null,
      stripeId: null,
      requested_accountLink: false,
      loadedStripe: false,
      loadedNewCardElements: false,
      stripe: null, //stripe api key
      setupIntentClientSecret: null,
      customerId: null,
      elements: null,
      paymentElement: null,
      cardInfoArr: [],
      bankAccountArr: [],
      isShow: true,
      alert: {
        msg: "",
        type: "",
      },
    };
  },

  methods: {
    disableAlert() {
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
    },
    openDashboard() {
      window.open("https://dashboard.stripe.com/dashboard");
    },

    async addPlaidBankAccount() {
      var configs;
      var vm = this;
      var plaidFetchLinkToken = firebase
        .functions()
        .httpsCallable("plaidFetchLinkToken");

      var plaidBankAccountOperation = firebase
        .functions()
        .httpsCallable("plaidBankAccountOperation");

      plaidFetchLinkToken({})
        .then((result) => {
          // Read result of the Cloud Function.
          var linkToken = result.data.linkToken.link_token;

          const configs = {
            // Pass the link_token generated in step 2.
            token: linkToken,
            onLoad: function () {
              // The Link module finished loading.
            },
            onSuccess: async function (public_token, metadata) {
              // The onSuccess function is called when the user has
              // successfully authenticated and selected an account to
              // use.
              //
              // When called, you will send the public_token
              // and the selected account ID, metadata.accounts,
              // to your backend app server.
              switch (metadata.accounts.length) {
                case 0:
                  // Select Account is disabled: https://dashboard.plaid.com/link/account-select
                  break;
                case 1:
                  // console.log(
                  //   "Customer-selected account ID: " + metadata.accounts[0].id
                  // );
                  var bankCloudOp = await plaidBankAccountOperation({
                    operation: "add",
                    public_token: public_token,
                    account_id: metadata.accounts[0].id,
                  });
                  await store.dispatch("retrievePaymentMethods");
                  vm.bankAccountArr = bankCloudOp.data.bankAccountArr;
                  vm.$set(vm.alert, "type", "success");
                  vm.$set(
                    vm.alert,
                    "msg",
                    "Your bank account has been successfully added!"
                  );
                default:
                // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
              }
            },
            onExit: async function (err, metadata) {
              // The user exited the Link flow.
              if (err != null) {
                // The user encountered a Plaid API error
                // prior to exiting.
                vm.$set(vm.alert, "type", "error");
                vm.$set(vm.alert, "msg", "Plaid error: " + err);
              }
              // metadata contains information about the institution
              // that the user selected and the most recent
              // API request IDs.

              // Storing this information can be helpful for support.
              // console.log("Error:", err);
              // console.log("Metadata", metadata);

              vm.$set(vm.alert, "type", "error");
              vm.$set(vm.alert, "msg", "Error:" + err);
            },
          };

          var linkHandler = Plaid.create(configs);
          linkHandler.open();
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            "Failed to add bank account. Error: " + error.message
          );
        });
    },

    deleteCard(cardIndex) {
      var dbCardOperations = cloudFunctions.httpsCallable("dbCardOperations");
      var vm = this;

      dbCardOperations({
        operation: "delete",
        cardIndex: cardIndex,
        paymentMethodId: vm.cardInfoArr[cardIndex].id,
      })
        .then(async (result) => {
          vm.cardInfoArr = result.data.cardInfoArr;
          await store.dispatch("retrievePaymentMethods");
          vm.$set(vm.alert, "type", "success");
          vm.$set(vm.alert, "msg", "Your card has been succesfully removed.");
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            "Failed to remove your card. Error:" + error
          );
        });
    },

    deleteBankAccount(bankIndex) {
      var plaidBankAccountOperation = cloudFunctions.httpsCallable(
        "plaidBankAccountOperation"
      );
      var vm = this;

      plaidBankAccountOperation({
        operation: "delete",
        bankIndex: bankIndex,
        paymentMethodId: vm.bankAccountArr[bankIndex].id,
      })
        .then(async (result) => {
          vm.bankAccountArr = result.data.bankAccountArr;
          await store.dispatch("retrievePaymentMethods");
          vm.$set(vm.alert, "type", "success");
          vm.$set(vm.alert, "msg", "Succesfully removed bank account");
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "Failed to remove bank account" + error);
        });
    },

    async retrievePaymentMethods() {
      try {
        await store.dispatch("retrievePaymentMethods");
        this.bankAccountArr = this.user.savedPaymentMethodsAndBanks.bankAccountArr;
        this.cardInfoArr = this.user.savedPaymentMethodsAndBanks.cardInfoArr;
      } catch (error) {
        vm.$set(vm.alert, "type", "error");
        vm.$set(
          vm.alert,
          "msg",
          "Failed to retrieve payment methods. Error: " + error
        );
      }
    },

    addNewCardElements() {
      var vm = this;
      // Set your publishable key: remember to change this to your live publishable key in production
      // See your keys here: https://dashboard.stripe.com/apikeys

      var options = {
        clientSecret: "{{CLIENT_SECRET}}",
        // Fully customizable with appearance API.
        appearance: {
          /*...*/
        },
      };

      var customerRef = db
        .collection("profile")
        .doc(vm.user.data.uid)
        .collection("stripe")
        .doc("customer");

      var stripeCreateSetupIntent = cloudFunctions.httpsCallable(
        "stripeCreateSetupIntent"
      );

      stripeCreateSetupIntent()
        .then((result) => {
          // Read result of the Cloud Function.
          // console.log(result);
          // console.log(result.data);
          vm.customerId = result.data.customerId;
          options.clientSecret = result.data.newClientSecret;
          // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
          vm.elements = vm.stripe.elements(options);
          // Create and mount the Payment Element
          vm.paymentElement = vm.elements.create("payment");
          vm.paymentElement.mount("#payment-element");
        })
        .then(() => {
          vm.loadedNewCardElements = true;
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "Error getting document:" + error);
        });
    },

    async stripeElementsSubmit() {
      var vm = this;
      const { error } = await vm.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements: vm.elements,
        //redirect: "if_required",
        confirmParams: {
          return_url: "http://localhost:8080/paymentMethods",
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector("#error-message");
        // messageContainer.textContent = error.message;
        vm.$set(vm.alert, "type", "error");
        vm.$set(vm.alert, "msg", error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        vm.$set(vm.alert, "type", "success");
        vm.$set(vm.alert, "msg", "Successfully added card.");
      }
    },

    //Handle cases after redirect. We add cards in the success part of this function.
    async stripeElementsAfterRedirect() {
      var vm = this;
      var dbCardOperations = cloudFunctions.httpsCallable("dbCardOperations");
      const message = document.querySelector("#redirect-message");

      var paymentMethodId = null;

      // Retrieve the "setup_intent_client_secret" query parameter appended to
      // your return_url by Stripe.js
      const clientSecret = new URLSearchParams(window.location.search).get(
        "setup_intent_client_secret"
      );

      // End the function if this is not a redirect
      if (clientSecret === null) {
        return -1;
      }

      // Retrieve the SetupIntent
      this.stripe
        .retrieveSetupIntent(clientSecret)
        .then(({ setupIntent }) => {
          paymentMethodId = setupIntent.payment_method;
          // console.log(setupIntent);

          // Inspect the SetupIntent `status` to indicate the status of the payment
          // to your customer.
          //
          // Some payment methods will [immediately succeed or fail][0] upon
          // confirmation, while others will first enter a `processing` state.
          //
          // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
          switch (setupIntent.status) {
            case "succeeded": {
              this.$router.replace("/paymentMethods"); //This line is a (soft) bug fix as it gets rid of the setupintent client secret from the url, preventing the user from clicking the back button in order to add the card again without going through strip elements.

              //add the setupintent to the setupIntentArr and the paymentMethod card object (last 4 digits, brand, date) to the cardsArr to match setupIntentArr's positions
              return dbCardOperations({
                operation: "add",
                paymentMethodId: paymentMethodId,
                clientSecret: clientSecret,
              });
            }

            case "processing": {
              message.innerText =
                "Processing payment details. We'll update you when processing is complete.";

              vm.$set(vm.alert, "type", "error");
              vm.$set(
                vm.alert,
                "msg",
                "Processing payment details. We'll update you when processing is complete."
              );
              throw "Processing payment details. We'll update you when processing is complete.";
            }

            case "requires_payment_method": {
              message.innerText =
                "Failed to process payment details. Please try another payment method.";

              vm.$set(vm.alert, "type", "error");
              vm.$set(
                vm.alert,
                "msg",
                "Failed to process payment details. Please try another payment method."
              );

              throw "Failed to process payment details. Please try another payment method.";
              // Redirect your user back to your payment page to attempt collecting
              // payment again
            }
          }
        })
        .then(async (result) => {
          // if (result) {
          //   message.innerText = "Success! Your payment method has been saved.";
          // }
          vm.cardInfoArr = result.data.cardInfoArr;
          await store.dispatch("retrievePaymentMethods");
          vm.$set(vm.alert, "type", "success");
          vm.$set(
            vm.alert,
            "msg",
            "Success! Your payment method has been saved."
          );
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(
            vm.alert,
            "msg",
            "Your payment method could not be saved. Error: " +
              error.code +
              " - " +
              error.message +
              error.details
          );
        });
    },

    //This switches up the onboarding document so that the cloud function responsible for generating the account link triggers.
    triggerCloudFunctionStripeOnboarding() {
      var vm = this;
      var uuid = require("uuid");
      var id = uuid.v4();

      var stripeOnboardingRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("stripe")
        .doc("onboarding");

      stripeOnboardingRef
        .set(
          {
            id: id,
          },
          { merge: true }
        )
        .then(() => {
          vm.requested_accountLink = true;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },

    //This function listens for changes in database profile/{userid}/stripe/accountLink
    //If a change is triggered, we change the global accountLink object and redirect the user to stripe onboarding
    listenAccountLinkChange() {
      var vm = this;
      var firstIteration = true; //This variable will serve to ensure that the first accountLink snapshot is ignored, so we only capture changes.
      var accountLinkRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("stripe")
        .doc("accountLink");

      var profileRef = db.collection("profile").doc(this.user.data.uid);

      var unsubscribeAccountLink = accountLinkRef.onSnapshot((doc) => {
        if (firstIteration === true) {
          firstIteration = false;
        } else {
          vm.accountLink = doc.data().url;
          window.open(vm.accountLink);
        }
      });

      var unsubscribeProfile = profileRef.onSnapshot((doc) => {
        vm.stripeId = doc.data().stripeAccountId;
        // console.log(doc.data().stripeAccountId);
        vm.loadedStripe = true;

        if (vm.stripeId) {
          if (vm.stripeId.length() > 0) {
            unsubscribeProfile();
            unsubscribeAccountLink();
          }
        }
      });
    },
    reloadPage() {
      window.location.reload();
    },
  },
  created() {
    var vm = this;
    if (this.user.profile.type === "seller") {
      this.tab = "stripe";
      this.listenAccountLinkChange();
    } else {
      this.tab = "paymentMethods";
    }

    vm.retrievePaymentMethods();
  },
  mounted() {
    var vm = this;
    vm.$loadScript("https://js.stripe.com/v3/")
      .then(() => {
        vm.stripe = Stripe(
          "pk_test_51I8XDLL0lowoixyPfGs6FMk7f1MJKHqdEN76in8LOyoIY2xGn94iEly8L7CF4oqnzVBDwoprLK2eBImAwFEs7mEb00gkpzppjo"
        );
        vm.$loadScript(
          "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
        );
      })
      .then(() => {
        vm.stripeElementsAfterRedirect();
      })
      .catch((error) => {
        vm.$set(vm.alert, "type", "error");
        vm.$set(
          vm.alert,
          "msg",
          "Error loading stripe script error. Error: " + error
        );
      });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.tab-input {
  clear: both;
  padding-top: 10px;
  display: none;
}
.tab-icon {
  font-size: 18px;
  margin-right: 5px;
}
.stripe-tab-image {
  height: 25px;
  display: inline;
  filter: grayscale(10%);
  margin-top: -8px;
  margin-bottom: -5px;
  z-index: -2;
}

.tab-label-1 {
  font-weight: 400;
  font-size: 16px;
  font-family: Quantico;
  color: #464646;
  letter-spacing: 1px;
  display: inline-block;
  float: left;
  width: 47%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  color: #757575;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-left: 2%;
}

.tab-label-2 {
  font-weight: 400;
  font-size: 16px;
  font-family: Quantico;
  color: #464646;
  letter-spacing: 1px;
  display: inline-block;
  float: none;
  overflow: hidden;
  width: 47%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  color: #757575;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-left: 2%;
  margin-right: -0%;
}

.tab-label-2-buyer {
  font-weight: 400;
  font-size: 16px;
  font-family: Quantico;
  color: #464646;
  letter-spacing: 1px;
  display: inline-block;
  float: none;
  overflow: hidden;
  width: 80%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  color: #757575;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-left: 10%;
  margin-right: -0%;
}

.remove-button-container {
  margin: auto;
  padding-right: 20px;
  margin-top: 15px;
}

@media (max-width: 440px) {
  .tab-icon {
    font-size: 16px;
    margin-right: 3px;
    display: none;
  }

  .tab-label-1 {
    font-size: 14px;
    letter-spacing: 0.3px;
  }

  .tab-label-2 {
    font-size: 14px;
    letter-spacing: 0.3px;
  }

  .stripe-tab-image {
    height: 23px;
    margin-top: -8px;
    margin-bottom: -5px;
  }
}

.tab_container [id^="tab"]:checked + label {
  background: #fff;
  box-shadow: inset 0 -3px 0px#42b983e5;
}

.tab_container [id^="tab"]:checked + label .fa {
  color: #8c9c95e5;
}
.container {
  width: 100%;
  padding-top: 10px;
  max-width: 650px;
}

.bottom-content {
  text-align: center;
  background-color: #42b983;
  height: 60px;
  width: 90%;
  line-height: 60px;
  color: white;
  font-size: 14px;
  /* position: relative;
  bottom: 0px; */
  z-index: 5;
  border-radius: 8px;
  margin-top: 35px;
  margin-left: 5%;
}
.bottom-content:hover {
  background-color: #349469;
}
.bottom-content-red {
  text-align: center;
  border: 1px solid #f15a5acb;
  height: 25px;
  width: 23%;
  line-height: 25px;
  color: white;
  color: #f15a5acb;
  /* position: relative;
  bottom: 0px; */
  z-index: 5;
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 5%;
}
.bottom-content-red:hover {
  border: 1px solid rgba(214, 92, 92, 0.774);
  color: rgba(214, 92, 92, 0.774);
}
.bottom-content-red-stripetab {
  text-align: center;
  border: 1px solid #f15a5acb;
  height: 25px;
  width: 70%;
  line-height: 25px;
  color: white;
  color: #f15a5acb;
  /* position: relative;
  bottom: 0px; */
  z-index: 5;
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 15%;
}
.bottom-content-red-stripetab:hover {
  border: 1px solid rgba(214, 92, 92, 0.774);
  color: rgba(214, 92, 92, 0.774);
}
.payment-form-style {
  width: 90%;
  margin-left: 5%;
  margin-top: 25px;
}
.save-payment-method-underline {
  background-color: #42b983e5;
  height: 3px;
  width: 140px;
  margin-left: 5%;
}

.ripple {
  font-size: 17px;
  font-family: Google Sans;
  font-weight: 500;
}
.ripple-red {
  font-size: 13px;
  font-family: Google Sans;
  font-weight: 500;
}

.unlink {
  text-align: center;
  height: 40px;
  width: 85%;
  line-height: 40px;
  color: #464646;
  font-size: 13px;
  z-index: 5;
  font-family: Google Sans;
  font-weight: 500;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0 10%;
  background-color: #f7f7f7;
  margin-top: 50px;
}
.unlink:hover {
  background-color: #ffffff;
}

.fa-check-double {
  color: #42b983;
}

.card-title {
  font-family: Google Sans;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 25px;
  text-align: center;
  font-size: 17px;
}

@media (max-width: 700px) {
}

@import "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i";
@import "https://fonts.googleapis.com/css?family=Quantico";
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.fa-plus-square {
  color: rgb(0, 110, 255);
  font-size: 11px;
  float: right;
  margin-right: 0.1em;
  margin-top: 0.4em;
}
.fa-check-circle {
  color: #42b983bb;
  font-size: 12px;
  float: right;
  margin-right: 1em;
  margin-top: 0.4em;
}

.remove {
  color: rgb(255, 101, 101);
  margin: auto;
  text-align: center;
  float: right;
  margin-right: 10px;
}
.top-card-brand-image-container {
  width: 100%;
  margin: auto;
  padding: 5px;
  text-align: center;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.top-card-brand-image {
  max-height: 25px;
  display: inline;
  padding: 3px;
}

.cards-wrap {
  margin-bottom: 20px;
  padding-top: 20px;
  margin-top: 25px;
}

.card-container {
  width: 90%;
  margin-left: 5%;
  padding-bottom: 80px;
  padding-top: 10px;
  background-color: rgb(245, 245, 245);
}

.card-container:not(:last-child) {
  border-bottom: 1px solid #d4d4d4;
  margin-top: 0px;
  padding-bottom: 80px;
  padding-top: 10px;
}

.payment-method-title {
  float: left;
  width: 80%;
  height: 22px;
  font-family: Quantico;
  padding-left: 0px;
  margin-left: 20px;
  letter-spacing: 1px;
  color: #464646;
  padding-top: 5px;
}
.card-payment-method-title {
  font-size: 14.5px;
}

.payment-method-subtitle {
  float: left;
  vertical-align: top;
  width: 80%;
  height: 22px;
  font-family: Quantico;
  font-size: 16px;
  padding-left: 0px;
  margin-left: 20px;
  color: #757575;
}
.fa-check {
  color: #7ab446;
  line-height: 10px;
}

.payment-method-image-col {
  float: left;
  height: 90px;
  margin-top: -10px;
  width: 12%;
}
.payment-method-image {
  float: left;
  height: 45%;
  margin-right: 0px;
  margin-top: 20px;
  margin-left: 25%;
}
.payment-method-image-amex {
  float: left;
  height: 20%;
  margin-right: 0px;
  margin-top: 30px;
  margin-left: 15%;
}
.payment-method-image-visa {
  float: left;
  height: 28%;
  margin-right: 0px;
  margin-top: 30px;
  margin-left: 30%;
}
.payment-method-image-jcb {
  float: left;
  height: 30%;
  margin-right: 0px;
  margin-top: 30px;
  margin-left: 30%;
}
.payment-method-image-diners {
  float: left;
  height: 35%;
  margin-right: 0px;
  margin-top: 25px;
  margin-left: 15%;
}

.payment-method-image-bank-general {
  float: left;
  height: 55%;
  margin-right: 0px;
  margin-top: 20px;
  margin-left: 20%;
}

.loader {
  height: 100%;
  width: 10%;
  text-align: center;
  padding: 0;
  margin: 5% 45%;
  display: inline-block;
}
/*
  Set the color of the icon
*/
svg path,
svg rect {
  fill: #42b983e5;
}

.addNewCardElements {
  color: #4f8a10;
  border: 1px solid darken(#dff2bf, 15%);
}
.addNewCardElements:before {
  content: "\f058";
  font-family: "FontAwesome";
}

.alert {
  margin: 35px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  position: sticky;
  top: 55px;
  text-align: center;
}

.alert:hover {
  cursor: pointer;
}

.alert:before {
  padding-right: 12px;
}

.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

/* start of modal style */
.modalDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  pointer-events: none;
}
.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}
.modalDialog > div {
  max-width: 800px;
  width: 90%;
  position: relative;
  margin: 10% auto;
  padding: 16px;
  border-radius: 3px;
  background: #fff;
}
.close {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -15px;
  width: 34px;
  height: 34px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
  padding-top: 3px;
  z-index: 999;
  background: #fa3f3f;
}
.close:hover {
  background: #fa3f6f;
  text-decoration: none;
}
/* end of modal style */

.btn-reject {
  background-color: #e352522b;
  color: #e35252;
  margin: auto;
  width: fit-content;
}

.btn-reject:hover {
  background-color: #e35252;
  color: white;
}

.payment-method-modal-box {
  padding-bottom: 15px;
  margin: auto;
  border-bottom: 1px solid #ddd;
  background-color: #fafafa;
  width: 290px;
}

/* start of alerts modal style */
.close-alerts {
  font-size: 13px;
  padding-left: 2px;
  color: #fff;
  line-height: 21px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 0px;
  width: 22px;
  height: 65%;
  margin-top: 5px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5%;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
  background: #6378c389;
}
.close-alerts:hover {
  background: #3b57bcbe;
  text-decoration: none;
}
/* end of alerts modal style */
</style> 

