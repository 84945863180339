
<template>
  <div id="app">
    <SearchSection />
  </div>
</template>

<script>
import SearchSection from "./search/browseFarms/SearchSection.vue";
import { mapGetters } from "vuex";
import store from "../store";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    SearchSection,
  },
  data() {
    return {
      lastFilteredUrl: null,
    };
  },
};
</script>