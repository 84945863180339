<template>
  <div class="container">
    <div class="scroll-container">
      <h1
        @click="disableAlert"
        class="alert"
        v-bind:class="{
          'alert-success': alert.type == 'success',
          'alert-error': alert.type == 'error',
        }"
      >
        {{ alert.msg }}"
      </h1>

      <div class="row">
        <div class="col-sm-12">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h4 class="orders-header">Orders</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="buyer-or-seller-filter" v-if="user.profile.type === 'seller'">
        <input
          type="radio"
          id="buyerOrders"
          value="buyerOrders"
          v-model="buyerOrSeller"
        />
        <label
          for="buyerOrders"
          class="purchases-sales-label purchases-sales-label-btn"
          >Purchases
        </label>
        <span> | </span>
        <input
          type="radio"
          id="sellerOrders"
          value="sellerOrders"
          v-model="buyerOrSeller"
        />
        <label
          for="sellerOrders"
          class="purchases-sales-label purchases-sales-label-btn"
          >Sales
        </label>
      </div>

      <div class="tabs mt-0">
        <input type="radio" id="pending" value="pending" v-model="filter" />
        <label class="tab tab-no-left-margin" for="pending">Pending</label>

        <input type="radio" id="accepted" value="accepted" v-model="filter" />
        <label class="tab tab-no-right-margin" for="accepted">Accepted</label>

        <input
          type="radio"
          id="completedAwaitingPayment"
          value="completedAwaitingPayment"
          v-model="filter"
        />
        <label class="tab tab-no-right-margin" for="completedAwaitingPayment"
          >Complete: Awaiting Payment</label
        >

        <input type="radio" id="completed" value="completed" v-model="filter" />
        <label class="tab tab-no-right-margin" for="completed">Complete</label>

        <input type="radio" id="cancelled" value="cancelled" v-model="filter" />
        <label class="tab tab-no-right-margin" for="cancelled">Cancelled</label>
      </div>

      <div class="row sub-header">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h5 v-if="this.filter === 'pending'" class="card-title">
                  <span v-if="buyerOrSeller === 'sellerOrders'">
                    New Orders – Accept or Reject:
                  </span>
                  <span v-if="buyerOrSeller === 'buyerOrders'">
                    New Orders: Pending Confirmation
                  </span>
                </h5>
                <h5 v-if="this.filter === 'accepted'" class="card-title">
                  <span v-if="buyerOrSeller === 'sellerOrders'">
                    Accepted Orders – Complete or Cancel:
                  </span>
                  <span v-if="buyerOrSeller === 'buyerOrders'">
                    Accepted Orders – Awaiting Completion:
                  </span>
                </h5>
                <h5
                  v-if="this.filter === 'completedAwaitingPayment'"
                  class="card-title"
                >
                  <span v-if="buyerOrSeller === 'sellerOrders'">
                    Awaiting Payment or Mark as Cash Sale:
                  </span>
                  <span v-if="buyerOrSeller === 'buyerOrders'">
                    Completed Orders – Send Payment
                  </span>
                </h5>
                <h5 v-if="this.filter === 'completed'" class="card-title">
                  <span v-if="buyerOrSeller === 'sellerOrders'">
                    View Completed Orders
                  </span>
                  <span v-if="buyerOrSeller === 'buyerOrders'">
                    View Past Orders
                  </span>
                </h5>
                <h5 v-if="this.filter === 'cancelled'" class="card-title">
                  View Cancelled Orders
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="orderSummaryItemComponent my-seller-orders"
        v-if="buyerOrSeller === 'sellerOrders'"
      >
        <div class="row" v-for="(order, index) in orders" :key="index">
          <!-- start of dialog box -->
          <div id="openModal-cancel" class="modalDialog" v-if="modalOrder">
            <div>
              <a href="#close" class="close">x</a>
              <div
                class="row"
                v-if="
                  order.orderStatus !== 'pending' &&
                  order.orderStatus !== 'completed' &&
                  order.orderStatus !== 'cancelled'
                "
              >
                <div
                  class="card"
                  style="padding: 0; margin: 0; border-bottom: 1px solid #ddd"
                >
                  <div class="accordion">
                    <label
                      class="title"
                      for="handle1"
                      :class="{
                        active_order_label: viewDetailsArr[index] === true,
                      }"
                    >
                      {{ modalOrder.buyerName }}:
                      #{{ modalOrder.docId }}
                    </label>
                  </div>
                </div>
                <button
                  @click="capturePayment(modalOrder, 'void', null)"
                  class="btn btn-cash-sale btn-reject"
                  style="margin: auto; margin-top: 15px; width: fit-content"
                >
                  Cancel Order
                </button>
              </div>
            </div>
          </div>
          <!-- end of dialog box -->

          <div class="card" @click="activateOrder(order, index)">
            <div class="accordion">
              <!--
            <input type="checkbox" name="collapse" id="handle1" />
            !-->
              <h2 class="handle">
                <label
                  class="title"
                  for="handle1"
                  :class="{
                    active_order_label: viewDetailsArr[index] === true,
                  }"
                >
                  <i class="fas fa-chevron-down"></i>
                  {{ order.buyerName }}: #{{ order.docId }}
                </label>
                <span class="confirm-deny-button">
                  <button class="deny-button" @click="modalOrder = order">
                    <a href="#openModal-cancel" class="cancelled">
                      <i
                        v-if="
                          order.orderStatus !== 'pending' &&
                          order.orderStatus !== 'completed' &&
                          order.orderStatus !== 'cancelled'
                        "
                        class="far fa-window-close"
                      ></i>
                    </a>
                  </button>
                </span>
              </h2>
              <div class="show_content" v-if="viewDetailsArr[index] === true">
                <div class="card-body">
                  <div class="form-group">
                    <span class="order-subtitle">Buyer: </span>
                    <span class="order-info">{{ order.buyerName }}</span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Items: </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                       {{ product.name }} &lpar;{{ product.quantity }} {{ product.unit }}, 
                       ${{ product.price }}/{{ product.unit }}&rpar;
                      <span
                        v-if="
                          index !==
                          Object.keys(order.purchasedProducts).length - 1
                        "
                      >
                        ;
                      </span>
                    </span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Total Price: </span>
                    <span class="order-info">${{ order.orderPrice }}</span>
                  </div>
                  <div class="form-group">
                    <span class="order-subtitle">Delivery or Pickup: </span>
                    <span
                      class="order-info"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup
                    </span>
                    <span
                      class="order-info"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery
                    </span>
                  </div>
                  <div class="form-group">
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup Date:
                    </span>
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery Date:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0 && product.pickup_date">
                        {{ product.pickup_date_visual }}
                      </span>
                    </span>
                  </div>
                  <!-- <div class="form-group">
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup Time:
                    </span>
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery Time:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0 && product.timeValue">
                        {{ product.timeValue.displayTime }}
                      </span>
                    </span>
                  </div> -->

                  <div class="form-group">
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup Address:
                    </span>
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery Address:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0">
                        <span v-if="order.deliveryAddress === 'pickup'">
                          {{ product.seller_address }}
                        </span>
                        <span v-if="order.deliveryAddress !== 'pickup'">
                          {{ order.deliveryAddress }}
                        </span>
                      </span>
                    </span>
                  </div>

                  <div class="form-group">
                    <span
                      v-if="order.deliveryAddress === 'pickup'"
                      class="order-subtitle"
                      >Buyer Pickup note:
                    </span>
                    <span v-else class="order-subtitle"
                      >Buyer Delivery note:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0">
                        {{ product.pickup_note }}
                      </span>
                    </span>
                  </div>

                  <div
                    class="form-group"
                    v-if="order.deliveryAddress === 'pickup'"
                  >
                    <span class="order-subtitle"
                      >Seller Pickup Instructions:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0">
                        {{ product.seller_pickup_instructions }}
                      </span>
                    </span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Buyer Phone: </span>
                    <span class="order-info">{{ order.buyerPhone }}</span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Buyer Email: </span>
                    <span class="order-info">{{ order.buyerEmail }}</span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Order Placed: </span>
                    <span class="order-info">{{ order.date }}</span>
                  </div>

                  <div
                    class="form-group"
                    v-if="order.orderStatus !== 'pending'"
                  >
                    <span class="order-subtitle">Order Invoice: </span>
                    <span v-if="order.invoice">
                      <span v-if="order.invoice.invoice_pdf">
                        <span @click="goToInvoicePdf(order)" class="order-info">
                          <button class="btn btn-cash-sale btn-message">
                            Download
                          </button>
                        </span>
                      </span>
                    </span>
                    <div
                      v-else-if="
                        order.seller_connected_account_id && !order.invoice
                      "
                    >
                      <span
                        @click="generateInvoiceBackend(order)"
                        class="order-info"
                      >
                        <button class="btn btn-cash-sale btn-message">
                          Generate Invoice
                        </button>
                      </span>
                    </div>
                    <div v-else>
                      <span class="order-info">
                            Seller must connect stripe account to enable invoices and online payments
                        </span>
                    </div>
                  </div>

                  <div class="form-group form-group-bottom">
                    <span class="order-subtitle">Message Buyer: </span>
                    <span class="order-info">
                      <button
                        @click="contact(order.buyerId)"
                        class="btn btn-cash-sale btn-message"
                      >
                        Message
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row payment-method"
            v-if="order.orderStatus === 'pending'"
          >
            <div class="col-payment-status-container">
              <div class="new-order-and-accept-button-container">
                <span>New Order:</span>
                <div
                  class="accept-button-container"
                  @click="modalOrder = order"
                >
                  <a
                    class="btn btn-cash-sale btn-accept"
                    href="#openModal-accept"
                  >
                    Accept
                  </a>
                </div>
              </div>

              <!-- start of dialog box -->
              <div id="openModal-accept" class="modalDialog">
                <div>
                  <a href="#close" class="close">x</a>
                  <div class="row" v-if="order.orderStatus == 'pending'">
                    <div
                      class="card"
                      style="
                        padding: 0;
                        margin: 0;
                        border-bottom: 1px solid #ddd;
                      "
                    >
                      <div class="accordion" v-if="modalOrder">
                        <label
                          class="title"
                          for="handle1"
                          :class="{
                            active_order_label: viewDetailsArr[index] === true,
                          }"
                        >
                          {{ modalOrder.buyerName }}: 
                          #{{ modalOrder.docId }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="markOrderAs(modalOrder, 'accepted', index)"
                      class="btn btn-cash-sale btn-accept"
                      style="margin: auto; margin-top: 15px"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
              <!-- end of dialog box -->

              <!-- Start of reject button -->
              <div class="reject-button-container" @click="modalOrder = order">
                <a
                  class="btn btn-cash-sale btn-reject"
                  href="#openModal-reject"
                >
                  Reject
                </a>
              </div>

              <!-- End of reject container -->

              <!-- start of dialog box -->
              <div id="openModal-reject" class="modalDialog">
                <div>
                  <a href="#close" class="close">x</a>
                  <div class="row" v-if="order.orderStatus === 'pending'">
                    <div
                      class="card"
                      style="
                        padding: 0;
                        margin: 0;
                        border-bottom: 1px solid #ddd;
                      "
                    >
                      <div class="accordion" v-if="modalOrder">
                        <label
                          class="title"
                          for="handle1"
                          :class="{
                            active_order_label: viewDetailsArr[index] === true,
                          }"
                        >
                          {{ modalOrder.buyerName }}:
                          #{{ modalOrder.docId }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="capturePayment(modalOrder, 'void', null)"
                      class="btn btn-cash-sale btn-reject"
                      style="margin: auto; margin-top: 15px"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
              <!-- end of dialog box -->
            </div>
          </div>

          <div
            class="row payment-method"
            v-if="order.orderStatus === 'accepted'"
          >
            <div class="col-payment-status-container">
              <!-- <span>Payment Status: </span> -->
              <button
                v-if="order.paymentStatus === 'pending'"
                class="btn-unpaid awaiting"
              >
                Bank payment initiated
              </button>
              <button
                v-if="order.paymentStatus === 'succeeded'"
                class="btn-unpaid"
              >
                Paid
              </button>
              <button v-else class="btn-unpaid awaiting">Awaiting Payment...</button>
              <!-- <span>Order Status:</span> -->
              <div>
                <a
                  class="btn btn-cash-sale"
                  href="#openModal-mark-complete"
                  @click="modalOrder = order"
                >
                  Mark Complete
                </a>
              </div>

              <!-- start of dialog box -->
              <div id="openModal-mark-complete" class="modalDialog">
                <div>
                  <a href="#close" class="close">x</a>
                  <div class="row" v-if="order.orderStatus === 'accepted'">
                    <div
                      class="card"
                      style="
                        padding: 0;
                        margin: 0;
                        border-bottom: 1px solid #ddd;
                      "
                    >
                      <div class="accordion" v-if="modalOrder">
                        <label
                          class="title"
                          for="handle1"
                          :class="{
                            active_order_label: viewDetailsArr[index] === true,
                          }"
                        >
                          {{ modalOrder.buyerName }}:
                          #{{ modalOrder.docId }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="
                        markOrderAs(
                          modalOrder,
                          'completedAwaitingPayment',
                          index
                        )
                      "
                      class="btn btn-cash-sale"
                      style="margin: auto; margin-top: 15px"
                    >
                      <span> Mark Complete</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- end of dialog box -->
            </div>
          </div>

          <div
            class="row payment-method"
            v-if="order.orderStatus === 'completedAwaitingPayment'"
          >
            <div class="col-payment-status-container">
              Payment Status:
              <button
                v-if="order.paymentStatus === 'pending'"
                class="btn-unpaid awaiting"
              >
                Bank payment initiated
              </button>
              <button
                v-else-if="order.paymentStatus === 'succeeded'"
                class="btn-unpaid"
              >
                Paid
              </button>
              <button v-else class="btn-unpaid awaiting">Awaiting</button>
              <div v-if="order.paymentStatus !== 'succeeded'">
                <a
                  for="outOfPlatform"
                  id="element"
                  class="btn btn-cash-sale"
                  href="#openModal-mark-cash"
                  @click="modalOrder = order"
                >
                  Cash sale?
                </a>

                <!-- start of dialog box -->
                <div id="openModal-mark-cash" class="modalDialog">
                  <div>
                    <a href="#close" class="close">x</a>
                    <div
                      class="row"
                      v-if="order.orderStatus === 'completedAwaitingPayment'"
                    >
                      <div
                        class="card"
                        style="
                          padding: 0;
                          margin: 0;
                          border-bottom: 1px solid #ddd;
                        "
                      >
                        <div class="accordion" v-if="modalOrder">
                          <label
                            class="title"
                            for="handle1"
                            :class="{
                              active_order_label:
                                viewDetailsArr[index] === true,
                            }"
                          >
                            {{ modalOrder.buyerName }}:
                            #{{ modalOrder.docId }}
                          </label>
                        </div>
                      </div>
                      <button
                        @click="
                          capturePayment(modalOrder, 'outOfPlatform', null)
                        "
                        class="btn btn-cash-sale"
                        style="
                          margin: auto;
                          margin-top: 15px;
                          width: fit-content;
                        "
                      >
                        Mark as Settled in Cash
                      </button>
                    </div>
                  </div>
                </div>
                <!-- end of dialog box -->
              </div>
            </div>
          </div>

          <div
            class="row payment-method"
            v-if="order.orderStatus === 'completed'"
          >
            <div class="col-payment-status-container">
              Payment Status:
              <button class="btn-unpaid">Paid</button>
            </div>
          </div>

          <div
            class="row payment-method"
            v-if="order.orderStatus === 'cancelled'"
          >
            <div class="col-payment-status-container">
              Order Status:
              <button class="btn-unpaid cancelled">Cancelled</button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="orderSummaryItemComponent my-seller-orders"
        v-if="buyerOrSeller === 'buyerOrders'"
      >
        <div class="row" v-for="(order, index) in orders" :key="index">
          <!-- start of dialog box -->
          <div id="openModal-cancel" class="modalDialog">
            <div>
              <a href="#close" class="close">x</a>
              <div
                class="row"
                v-if="
                  order.orderStatus === 'pending' ||
                  order.orderStatus === 'accepted'
                "
              >
                <div
                  class="card"
                  style="padding: 0; margin: 0; border-bottom: 1px solid #ddd"
                >
                  <div class="accordion" v-if="modalOrder">
                    <label
                      class="title"
                      for="handle1"
                      :class="{
                        active_order_label: viewDetailsArr[index] === true,
                      }"
                    >
                      {{ modalOrder.seller_name }}:
                      #{{ modalOrder.docId }}
                    </label>
                  </div>
                </div>
                <button
                  @click="capturePayment(modalOrder, 'void', null)"
                  class="btn btn-cash-sale btn-reject"
                  style="margin: auto; margin-top: 15px; width: fit-content"
                >
                  Cancel Order
                </button>
              </div>
            </div>
          </div>
          <!-- end of dialog box -->

          <div
            v-if="order.orderPrice && !processing"
            class="card"
            @click="activateOrder(order, index)"
          >
            <div class="accordion">
              <h2 class="handle">
                <label
                  class="title"
                  for="handle1"
                  :class="{
                    active_order_label: viewDetailsArr[index] === true,
                  }"
                >
                  <i class="fas fa-chevron-down"></i>
                  {{ order.seller_name }}: #{{ order.docId }}
                </label>

                <span class="confirm-deny-button">
                  <a
                    v-if="
                      order.orderStatus === 'pending' ||
                      order.orderStatus === 'accepted'
                    "
                    class="deny-button"
                    href="#openModal-cancel"
                    @click="modalOrder = order"
                  >
                    <i
                      class="fas fa-ban"
                    ></i>
                  </a>
                </span>
                <span
                  class="confirm-deny-button"
                  v-if="
                    buyerOrSeller === 'buyerOrders' &&
                    (order.orderStatus === 'pending' ||
                      order.orderStatus === 'accepted' ||
                      order.orderStatus === 'completedAwaitingPayment')
                  "
                >
                </span>
              </h2>

              <div class="show_content" v-if="viewDetailsArr[index] === true">
                <div style="height: 100%" class="card-body">
                  <div class="form-group">
                    <span class="order-subtitle">Seller: </span>
                    <span class="order-info">{{ order.seller_name }}</span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Items: </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      {{ product.name }} &lpar;{{ product.quantity }} {{ product.unit }}, 
                       ${{ product.price }}/{{ product.unit }}&rpar;
                      <span
                        v-if="
                          index !==
                          Object.keys(order.purchasedProducts).length - 1
                        "
                      >
                        ;
                      </span>
                    </span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Total Cost: </span>
                    <span class="order-info">${{ order.orderPrice }}</span>
                  </div>
                  <div class="form-group">
                    <span class="order-subtitle">Delivery or Pickup: </span>
                    <span
                      class="order-info"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup
                    </span>
                    <span
                      class="order-info"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery
                    </span>
                  </div>
                  <div class="form-group">
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup Date:
                    </span>
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery Date:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0 && product.pickup_date">
                        {{ product.pickup_date_visual }}
                      </span>
                    </span>
                  </div>
                  <!-- <div class="form-group">
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup Time:
                    </span>
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery Time:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0 && product.timeValue">
                        {{ product.timeValue.displayTime }}
                      </span>
                    </span>
                  </div> -->

                  <div class="form-group">
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress === 'pickup'"
                      >Pickup Address:
                    </span>
                    <span
                      class="order-subtitle"
                      v-if="order.deliveryAddress !== 'pickup'"
                      >Delivery Address:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0">
                        <span v-if="order.deliveryAddress === 'pickup'">
                          {{ product.seller_address }}
                        </span>
                        <span v-if="order.deliveryAddress !== 'pickup'">
                          {{ order.deliveryAddress }}
                        </span>
                      </span>
                    </span>
                  </div>

                  <div class="form-group">
                    <span
                      v-if="order.deliveryAddress === 'pickup'"
                      class="order-subtitle"
                      >Buyer Pickup note:
                    </span>
                    <span v-else class="order-subtitle"
                      >Buyer Delivery note:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0">
                        {{ product.pickup_note }}
                      </span>
                    </span>
                  </div>

                  <div
                    class="form-group"
                    v-if="order.deliveryAddress === 'pickup'"
                  >
                    <span class="order-subtitle"
                      >Seller Pickup Instructions:
                    </span>
                    <span
                      class="order-info"
                      v-for="(product, key, index) in order.purchasedProducts"
                      :key="index"
                    >
                      <span v-if="index === 0">
                        {{ product.seller_pickup_instructions }}
                      </span>
                    </span>
                  </div>

                  <div class="form-group">
                    <span class="order-subtitle">Order Placed: </span>
                    <span class="order-info">{{ order.date }}</span>
                  </div>

                  <div
                    class="form-group"
                    v-if="order.orderStatus !== 'pending'"
                  >
                    <span class="order-subtitle">Order Invoice: </span>
                    <span v-if="order.invoice">
                      <span v-if="order.invoice.invoice_pdf">
                        <span @click="goToInvoicePdf(order)" class="order-info">
                          <button class="btn btn-cash-sale btn-message">
                            Download
                          </button>
                        </span>
                      </span>
                    </span>
                    <div
                      v-else-if="
                        order.seller_connected_account_id && !order.invoice
                      "
                    >
                      <span
                        @click="generateInvoiceBackend(order)"
                        class="order-info"
                      >
                        <button class="btn btn-cash-sale btn-message">
                          Generate Invoice
                        </button>
                      </span>
                    </div>
                    <div v-else>
                      <span class="order-info">
                            Seller needs to connect Stripe to enable invoices and online payments
                        </span>
                    </div>
                  </div>

                  <div class="form-group form-group-bottom">
                    <span class="order-subtitle">Message Seller: </span>
                    <span class="order-info">
                      <button
                        @click="contact(order.sellerId)"
                        class="btn btn-cash-sale btn-message"
                      >
                        Message
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row payment-method"
            v-if="
              order.paymentStatus === 'succeeded' ||
              order.paymentStatus === 'pending'
            "
          >
            <div
              class="col-payment-status-container"
              v-if="order.paymentStatus === 'succeeded'"
            >
              Payment Status:
              <button class="btn-unpaid">Paid</button>
            </div>

            <div
              class="col-payment-status-container"
              v-if="order.paymentStatus === 'pending'"
            >
              Payment Status:
              <button class="btn-unpaid">Bank Transfer Initiated</button>
            </div>
          </div>

          <div
            class="row payment-method"
            v-if="
              order.orderStatus !== 'pending' &&
              order.orderStatus !== 'completed' &&
              order.orderStatus !== 'cancelled' &&
              order.paymentStatus !== 'pending' &&
              order.paymentStatus !== 'succeeded' &&
              order.orderStatus !== 'accepted'
            "
          >
            <div class="col-payment-method-buttons-container">
              <div
                v-for="(bankAccount, index) in bankAccountArr"
                :key="'bankAccount-' + index"
                class="confirm-button"
              >
                <label
                  v-if="order.seller_connected_account_id"
                  class="bank-label"
                  for="bankAccounts"
                  :class="{
                    activePaymentMethod:
                      selectedPaymentMethod.order === order &&
                      selectedPaymentMethod.paymentType === 'bankAccount' &&
                      selectedPaymentMethod.paymentIndex === index,
                  }"
                  @click="selectPaymentMethod(order, 'bankAccount', index)"
                >
                  <div style="margin-bottom: -2px; font-size: 10px">
                    {{ bankAccount.bank_name }}
                  </div>
                  <div style="margin-left: 30px">
                    &#183; &#183; &#183; &#183; {{ bankAccount.last4 }}
                  </div>
                </label>
              </div>
              <div
                v-for="(paymentMethod, index) in cardInfoArr"
                :key="'card-' + index"
                class="confirm-button"
              >
                <label
                  v-if="order.seller_connected_account_id"
                  class="card-label"
                  for="card"
                  @click="selectPaymentMethod(order, 'card', index)"
                  :class="{
                    activePaymentMethod:
                      selectedPaymentMethod.order === order &&
                      selectedPaymentMethod.paymentType === 'card' &&
                      selectedPaymentMethod.paymentIndex === index,
                  }"
                  ><div style="margin-bottom: -2px">
                    {{ paymentMethod.card.brand }}
                  </div>
                  <div style="margin-left: 13px">
                    &#183; &#183; &#183; &#183; {{ paymentMethod.card.last4 }}
                  </div>
                </label
                >
              </div>
              <div class="confirm-button">
                <a
                  @click="selectPaymentMethod(null, null, null)"
                  class="out-of-platform-label"
                  href="#openModal-buyercash"
                  >Cash
                </a>
              </div>
            </div>
            <div v-if="order.invoice" class="col-send-payment-button-container">
              <button
                :disabled="selectedPaymentMethod.order !== order"
                class="send-payment-button send-payment-link"
                href="#openModal-about"
                :class="{
                  activePaymentMethod: selectedPaymentMethod.order === order,
                }"
                @click="
                  capturePayment(
                    selectedPaymentMethod.order,
                    selectedPaymentMethod.paymentType,
                    selectedPaymentMethod.paymentIndex
                  )
                "
              >
                <span
                  v-if="selectedPaymentMethod.order === order && processing"
                >
                  Processing...</span
                >
                <span
                  v-else-if="
                    selectedPaymentMethod.order !== order && processing
                  "
                >
                  Processing other order</span
                >
                <span v-else-if="selectedPaymentMethod.order !== order">
                  Send Payment
                </span>
                <span v-else>Confirm Payment </span>
              </button>
            </div>

            <div v-else class="col-send-payment-button-container">
              <!-- Click on this order to view order details and then click generate invoice to send online payment. -->
            </div>

            <div id="openModal-buyercash" class="modalDialog">
              <div>
                <a href="#close" class="close">x</a>
                <div
                  class="row"
                  v-if="
                    order.orderStatus !== 'pending' &&
                    order.orderStatus !== 'completed' &&
                    order.orderStatus !== 'cancelled'
                  "
                >
                  <label class="buyercash-modal-message">
                    Seller must mark order as a cash transaction
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteHandler"
      ></infinite-loading>
    </div>

    <div class="height-stabilizer"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import store from "../store";
import { db, cloudFunctions } from "../main.js";
import InfiniteLoading from "vue-infinite-loading";
import OrderSummaryItemComponent from "./OrderSummaryItemComponent";
import moment from "moment";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.state.user.data) next({ name: "Login" });
    else next();
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    InfiniteLoading,
    OrderSummaryItemComponent,
  },
  watch: {
    filter() {
      this.infiniteId += 1;
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
      this.fetchOrders();
    },
    sort() {
      this.infiniteId += 1;
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
      this.fetchOrders();
    },
    buyerOrSeller() {
      this.infiniteId += 1;
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
      this.fetchOrders();
    },
  },

  data() {
    return {
      processing: false, //This key is used to show that at least one order payment is getting captured
      idempotencyKey: null,
      alert: {
        type: null,
        msg: null,
      },
      filter: "pending",
      sort: "desc-checkoutTimestamp",
      buyerOrSeller: "buyerOrders",
      infiniteId: 0,
      numberOfLoaded: 1,
      orders: [],
      orderStatusArr: [],
      viewDetailsArr: [],
      picked: "pending",
      cardInfoArr: [],
      bankAccountArr: [],
      selectedPaymentMethod: {
        order: null,
        paymentIndex: null,
        paymentType: null,
      },

      modalOrder: null,
    };
  },
  methods: {
    disableAlert() {
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
    },
    goToInvoicePdf(order) {
      window.open(order.invoice.invoice_pdf);
    },
    async generateInvoiceBackend(order) {
      var buyerId = order.buyerId;
      var orderId = order.docId;
      var generateInvoice = cloudFunctions.httpsCallable("generateInvoice");

      var invoice = await generateInvoice({
        orderId: orderId,
        buyerId: buyerId,
      });

      if (invoice.data.status === "success") {
        this.refreshOrders(true, "success", "Invoice generated successfuly!");
      } else {
        this.refreshOrders(false, "error", "Failed to generate invoice");
      }
    },
    async refreshOrders(async, alertType, alertMsg) {
      if (async === false) {
        //Fetch orders anyway
        this.infiniteId += 1;
        this.$set(this.alert, "type", alertType);
        this.$set(this.alert, "msg", alertMsg);
        this.fetchOrders();
      } else {
        var query = await this.generateQuery();
        var unsubscribe = query.onSnapshot((querySnapshot) => {
          // Respond to each document
          // querySnapshot.forEach((doc) => {
          //   console.log("querysnapshot for each");
          // });

          // Respond to data
          this.infiniteId += 1;
          this.$set(this.alert, "type", alertType);
          this.$set(this.alert, "msg", alertMsg);
          this.fetchOrders();
        });

        // // Later ...
        // // Stop listening to changes
        // unsubscribe();
      }
    },
    activateOrder(order, index) {
      var vm = this;
      vm.$set(vm.viewDetailsArr, index, !vm.viewDetailsArr[index]);
    },
    selectPaymentMethod(order, paymentType, paymentIndex) {
      var vm = this;
      vm.$set(vm.selectedPaymentMethod, "order", order);
      vm.$set(vm.selectedPaymentMethod, "paymentIndex", paymentIndex);
      vm.$set(vm.selectedPaymentMethod, "paymentType", paymentType);
    },
    contact(id) {
      var path = "/inbox/" + id;
      this.$router.push({ path: path });
    },

    generateQuery() {
      var deconstructSort = this.sort.split("-");

      var ordersRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection(this.buyerOrSeller);

      if (this.filter !== "none") {
        ordersRef = ordersRef.where("orderStatus", "==", this.filter);
      }

      const query = ordersRef
        .orderBy(deconstructSort[1], deconstructSort[0])
        .limit(this.numberOfLoaded);
      return query;
    },
    async fetchOrders() {
      var vm = this;
      var query = this.generateQuery();

      await query
        .get()
        .then(async function (querySnapshot) {
          vm.orders = [];
          vm.orderStatusArr = [];
          vm.viewDetailsArr = [];
          await querySnapshot.forEach(async function (doc) {
            var order = await doc.data();
            //Add property datetime to extract the date and time from timestamps and add to order object
            order.datetime = moment
              .unix(order.checkoutTimestamp.seconds)
              .format("DD/MM/YY hh:mm A");
            order.date = moment
              .unix(order.checkoutTimestamp.seconds)
              .format("MM/DD/YY");

            for (var product in order.purchasedProducts) {
              //Create a new field called pickup_date_visual to remove the property on order status change
              //as a --security -- database rule prevents users from changing the purchasedProducts column.
              order.purchasedProducts[product].pickup_date_visual = moment
                .unix(order.purchasedProducts[product].pickup_date.seconds)
                .format("MM/DD/YY");
            }

            vm.orders.push(order);
            vm.orderStatusArr.push(order.orderStatus);
            vm.viewDetailsArr.push(false);
          });
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", error);
        });
    },

    async infiniteHandler($state) {
      var lengthBeforeFetch = this.orders.length;

      this.numberOfLoaded += 5;
      await this.fetchOrders();
      if (this.orders.length !== lengthBeforeFetch) {
        $state.loaded();
      } else {
        $state.complete();
      }
      console.log(this.orders);
    },

    viewDetails(order, index) {
      var vm = this;
      if (order.sellerProfile || vm.buyerOrSeller === "sellerOrders") {
        vm.$set(vm.viewDetailsArr, index, !vm.viewDetailsArr[index]);
        return -1;
      }

      var sellerProfileRef = db.collection("profile").doc(order.sellerId);
      sellerProfileRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            order.sellerProfile = {
              name: doc.data().name,
              address: doc.data().address,
              addressTwo: doc.data().addressTwo,
              dpUrl: doc.data().dpUrl,
              phone: doc.data().phone,
              seller_connected_account_id:
                doc.data().seller_connected_account_id,
            };
          } else {
            vm.$set(vm.alert, "type", "error");
            vm.$set(vm.alert, "msg", "No such document in the database.");
          }
        })
        .then(() => {
          vm.$set(vm.viewDetailsArr, index, !vm.viewDetailsArr[index]);
        })
        .catch(function (error) {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "Error grabbing document. " + error);
        });
    },

    async capturePayment(order, paymentType, paymentIndex) {
      var vm = this;
      var batch = db.batch();

      //Invoice link case
      if (paymentType === "bankTransfer") {
        window.open(order.invoice.hosted_invoice_url);
        return -1;
      }

      if (
        paymentType === "bankAccount" ||
        paymentType === "card" ||
        paymentType === "outOfPlatform" ||
        paymentType === "void"
      ) {
        var capturePaymentBackend =
          cloudFunctions.httpsCallable("capturePayment");
        var payment;

        if (paymentType === "card") {
          payment = this.cardInfoArr[paymentIndex];
        } else if (paymentType === "bankAccount") {
          payment = this.bankAccountArr[paymentIndex];
        }

        try {
          var orderDuplicate = Object.assign({}, order);
          this.processing = true;

          var charge = await capturePaymentBackend({
            order: orderDuplicate,
            paymentType: paymentType,
            payment: payment,
            idempotencyKey: this.idempotencyKey,
          });

          if (paymentType === "void") {
            await vm.refreshOrders(
              true,
              "success",
              "Order Cancellation successful!"
            );
          } else {
            await vm.refreshOrders(true, "success", "Payment successful!");
          }
        } catch (error) {
          if (paymentType === "void") {
            await vm.refreshOrders(
              true,
              "error",
              "Order cancellation failed! Error: " + error.message
            );
          } else {
            await vm.refreshOrders(false, "error", "Payment failed");
          }
        }
        this.processing = false;
      }
    },

    async markOrderAs(order, newStatus, index) {
      var vm = this;
      const timestamp = firebase.firestore.Timestamp.now();
      var batch = db.batch();
      var paymentStatus;

      var newStatusAtString = newStatus + "At";

      //Create a shallow copy of order. This prevents the visual transitioning of the order into a new status
      //before a successful batch commit.
      var orderDuplicate = Object.assign({}, order);
      orderDuplicate.orderStatus = newStatus;

      //Remove pickup_date_visual from all purchased products
      for (var product in orderDuplicate.purchasedProducts) {
        delete orderDuplicate.purchasedProducts[product].pickup_date_visual;
      }

      if (this.buyerOrSeller === "buyerOrders") {
        var orderRef = db
          .collection("profile")
          .doc(order.buyerId)
          .collection(this.buyerOrSeller)
          .doc(order.docId);
                orderDuplicate[newStatusAtString] = timestamp;

        batch.set(orderRef, orderDuplicate, { merge: true });
      } else if (this.buyerOrSeller === "sellerOrders") {
        var sellerOrderRef = db
          .collection("profile")
          .doc(order.sellerId)
          .collection("sellerOrders")
          .doc(order.docId);

        await sellerOrderRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              paymentStatus = doc.data().paymentStatus;
              if (paymentStatus === "succeeded") {
                newStatusAtString = "completedAt";
                orderDuplicate.orderStatus = "completed";
              }
              orderDuplicate[newStatusAtString] = timestamp;
              batch.set(sellerOrderRef, orderDuplicate, { merge: true });
            } else {
              vm.$set(vm.alert, "type", "error");
              vm.$set(vm.alert, "msg", "No such document in the database.");
            }
          })
          .catch(function (error) {
            vm.$set(vm.alert, "type", "error");
            vm.$set(
              vm.alert,
              "msg",
              "Error reaching document in the database."
            );
          });
      }

      batch
        .commit()
        .then(() => {
          vm.refreshOrders(
            false,
            "success",
            "You have succesfully changed the order's status to " + newStatus
          );
          order.status = newStatus;
          order[newStatusAtString] = timestamp;
        })
        .catch((error) => {
          vm.refreshOrders(
            false,
            "error",
            "Error changing order status. " + error
          );
        });
    },
  },

  created() {
    var uuid = require("uuid");

    this.idempotencyKey = uuid.v4();
    if (this.user.profile.type === "seller") {
      this.buyerOrSeller = "sellerOrders";
    }
    if (this.user.savedPaymentMethodsAndBanks) {
      this.bankAccountArr =
        this.user.savedPaymentMethodsAndBanks.bankAccountArr;
      this.cardInfoArr = this.user.savedPaymentMethodsAndBanks.cardInfoArr;
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");
.container {
  margin-top: 0px;
  padding-top: 70px;
  font-family: Google Sans;
  margin: auto;
  margin-bottom: 5px;
  overflow-y: hidden;
  width: 100%;
  max-width: 1160px;
}
.height-stabilizer {
  height: 90vh;
}
.selected {
  color: rgba(0, 70, 223, 0.705);
}
.date-header {
  font-size: 20px;
  font-weight: 200;
}
th {
  font-weight: 400;
  width: 100%;
}
tr {
  font-size: 13px;
  vertical-align: middle;
  text-align: middle;
}
.card-title {
  font-family: Google Sans;
  font-weight: 400;
  font-size: 18px;
}

.confirm-deny-button {
  font-size: 16px;
  vertical-align: middle;
  stroke-width: 1;
  font-weight: thin;
  color: rgb(138, 138, 138);
}

.confirm-button {
  border-right: 1px solid rgb(180, 180, 180);
  padding-right: 10px;
}
.deny-button {
  padding-left: 10px;
  border-left: 1px solid rgb(180, 180, 180);
}

.tabs {
  display: flex;
  // position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 95vw;
  margin-bottom: 10px;
}

.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-height: fit-content;
  width: 100%;
  font-size: 1em;
  font-weight: normal;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  text-align: center;
  margin-top: 0.5em;
  margin-right: 5px;
  margin-left: 5px;
  border: 2px solid #dedede;
  font-family: Google Sans;
  color: rgba(0, 0, 0, 0.664);
  padding: 4px;
}

.tab-no-left-margin {
  margin-left: 0px;
  margin-right: 0px;
}
.tab-no-right-margin {
  margin-right: 0px;
}

input[type="radio"]:checked + label {
  box-shadow: inset 0 5px#42b983e3;
  cursor: pointer;
}

.purchases-sales-label {
  font-size: 16px;
  cursor: pointer;
}

input[type="radio"]:checked + .purchases-sales-label-btn {
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  border-radius: 3px;
  color: #ffffff;
  background-color: #42b983;
  border-width: 2px;
  text-shadow: none !important;
  box-shadow: none !important;
}
input[type="radio"]:checked + .double-select {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgb(218, 218, 218) !important;
  color: rgb(90, 90, 90) !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.buyer-or-seller-filter {
  margin-bottom: 10px;
}

@media (max-width: 680px) {
  .tabs {
    display: block;
  }
  input[type="radio"]:checked + label {
    box-shadow: inset 5px 0px 0px 0px#42b983;
    font-weight: 600;
  }

  .tab {
    margin-left: 0px;
    margin-right: 0px;
    height: 35px;
  }
}

/* start of createevent style */
@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.min.css";
.confirm-deny-button {
  min-height: 40px;
  height: 100%;
  /* border: 1px solid red; */
  /* float: right; */
  font-size: 20px;
  padding-top: 9px;
  max-width: 13%;
}
.confirm-button {
  color: rgb(36, 187, 124);
  padding-left: 10px;
}

.deny-button {
  color: rgb(255, 137, 137);
  padding-right: 10px;
}
.deny-button :hover {
  color: rgb(219, 57, 57);
}
.btn-primary {
  font-size: 17px;
  background-color: #42b983;
  border-style: none;
}
.btn-primary:hover {
  background-color: #369a6d;
}
.card {
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
  border: 0px solid #ddd;
  font-family: Google Sans;
  margin-top: 20px;
}
.order-subtitle {
  margin-bottom: 8px;
  color: rgb(71, 71, 71);
  font-size: 13px;
  font-weight: 500;
}
.order-info {
  margin-bottom: 8px;
  color: #757575;
  font-size: 13px;
  font-weight: 400;
}
.fa-paper-plane {
  font-size: 17px;
  width: 40px;
  margin-left: -5px;
  text-align: center;
}
.title {
  margin: auto;
  color: #757575;
  cursor: pointer;
  font-weight: normal;
  padding: 10px;
  margin-left: 5px;
  width: 100%;
  max-width: 85%;
  height: fit-content;
  font-size: 15px;
  display: inline-block;
}
.buyercash-modal-message {
  margin: auto;
  color: #757575;
  cursor: pointer;
  font-weight: normal;
  padding: 10px;
  margin-left: 5px;
  width: 100%;
  height: fit-content;
  font-size: 15px;
  display: inline-block;
  text-align: center;
}
.gray-part {
  width: 400px;
  padding: 1%;
  display: inline-block;
  background-color: rgba(250, 250, 250, 0.9);
  height: 1650px;
  margin: auto;
  margin-top: 5px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.btn-primary {
  font-family: Google Sans;
}
.card-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.form-group {
  padding: 12px;
  border-bottom: 1px solid rgb(233, 233, 233);
  margin: 0px;
}
.form-group-bottom {
  border-bottom: none;
  margin-bottom: -13px;
}

.preview-images-form-group {
  margin: 0px 20px;
}

.preview-images-row {
  margin-bottom: 20px;
}

.img-wrap {
  position: relative;
}
.preview-images-wrapper {
  /* border: 2px solid #ddd; */
  width: 70px;
  height: 70px;
  align-content: center;
  border-radius: 4px;
}
.preview-images {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* margin: 1%; */
  border-radius: 4px;
}

.img-wrap span.delete-img {
  position: absolute;
  top: 0px;
  right: 0px;
}
.img-wrap span.delete-img:hover {
  cursor: pointer;
}

/* Next & previous buttons  */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/*On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(91, 227, 111, 0.8);
}

.upload-wrapper {
  border: 2px solid #ddd;
  border-radius: 5px;
  width: 85%;
  margin: 0 auto;
  height: 300px;
  position: relative;
}

.upload-wrapper span.delete-img {
  position: absolute;
  top: 0px;
  right: 1px;
}

/* div for the image upload plus sign button */
.add-image-button {
  height: 60px;
  width: 60px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin: auto;
  padding: auto;
  align-content: center;
  z-index: 4;
  background-color: #42b983a8;
}

.add-image-button :hover {
  background-color: white;
  color: #42b983;
  border-color: #42b983;
}

/* for the image upload plus sign button */
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: 10;
}
/* for the image upload plus sign button */
.file-input__label {
  cursor: pointer;
  /* z-index: 999;  */
  text-align: center;
  border-radius: 4px;
  border-color: black;
  border-style: solid;
  font-size: 50px;
  background-color: rgba(255, 255, 255, 1);
  color: #ddd;
  border: 2px solid #ddd;
  border-radius: 5px;
  /* height: 70px;
  width: 70px; */
  /* padding-top: 5px; */
  /* z-index: 10; */
  height: 60px;
  width: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.form-check-input {
  margin-left: -5px;
  padding-left: 0px;
}
.form-check-label {
  padding: 0px;
  margin-left: 20px;
  margin-bottom: -15px;
  font-weight: 400;
}
.form-control {
  font-size: 16px;
  padding: 4px;
  padding-left: 8px;
  color: rgb(167, 167, 167);
}
input::placeholder {
  color: rgb(167, 167, 167);
}

/* start of accordian css : https://codepen.io/OGOGOG/pen/YzGdreM */
.accordion > input[type="checkbox"] {
  position: absolute;
  /* left: -100vw; */
  display: none;
}

.accordion .content {
  overflow-y: hidden;
  height: 0px;
  transition: height 0.3s ease;
  border: 0px solid #ddd;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  height: auto;
  overflow: visible;
  border: 1px solid rgb(221, 221, 221);
}

.accordion label {
  /* display: block;
  font-size: 25px; */
  height: 40px;
}

.accordion {
  margin-bottom: 0em;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  padding: 0px;
  padding-top: 0px;
  /* border: 1px solid #e8e8e8; */
  border-top: 0;
}

.accordion .handle {
  margin: 0px;
  font-size: 14px;
  /* line-height: 1.2em; */
  background-color: rgba(255, 255, 255, 0);
  z-index: -10;
  border: 2px solid #ddd;
  border-radius: 4px;
  display: flex;
  overflow-y: auto;
}
/* end of accordian css */

.active_order_label {
  color: rgb(71, 71, 71);
}
// a::before {
//   content: "\25B7";
//   font-size: 17px;
// }
// .active_order_label > a::before {
//   content: "\25BD";
//   font-size: 17px;
//   color: #757575;
// }

.show_content {
  height: auto;
  overflow: visible;
  border: 2px solid rgb(221, 221, 221);
  border-top: none;
  border-radius: 4px;
}

.payment-method {
  font-size: 12px;
  padding: 8px 0px !important;
  margin: 0 15px;
  border: 2px solid #ddd;
  border-top: none;
  border-radius: 0px 0px 5px 5px;
  height: fit-content;
  max-width: 1200px;
  width: 100%;
}

/* end of createevent style */

.col-payment-method-buttons-container {
  width: calc(80% - 20px);
}

.col-send-payment-button-container {
  width: calc(20% + 20px);
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: right;
  // border-left: 1px solid #dedede;
}

.send-payment-button {
  float: right;
  background-color: #8d8d8d;
  margin-right: 10px;
  margin-left: 10px;
  color: white;
  border-radius: 0.25em;
  font-size: 13px;
  font-weight: 500;
  width: 110px;
  max-width: 140px;
  height: 40px;
}
.send-payment-button:hover {
  background-color: #349469;
}

.send-payment-link {
  text-align: center;
  line-height: 39px;
  text-decoration: none;
}

.send-payment-link:hover {
  text-decoration: none;
}

@media (max-width: 500px) {
  .col-payment-method-buttons-container {
    width: 100%;
    border: none;
  }
  .col-send-payment-button-container {
    width: 100%;
    border-top: 1px solid #dedede;
    margin-top: 5px;
    padding-top: 10px;
  }

  .send-payment-button {
    font-size: 12px;
    font-weight: 500;
    width: 110px;
    max-width: 140px;
  }
  .card-title {
    font-size: 14px;
  }
}

.col-payment-status-container {
  font-size: 14px;
  width: 100%;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}

.processing {
  color: black;
  background-color: black;
}
.bank-label {
  outline: 1px solid #42b983;
  color: #42b983;
  padding: 0 5px;
  width: 125px;
  border-radius: 3px;
  float: left;
  margin-right: 10px;
  height: 40px;
  margin-top: 5px;
}
.bank-label:hover {
  color: #318d64;
  outline: 1px solid #318d64;
}
.selected-payment-label {
  background-color: #42b983;
}
.card-label {
  outline: 1px solid #8c8c8c;
  color: #8c8c8c;
  padding: 0 5px;
  width: 85px;
  height: 40px;
  border-radius: 3px;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}
.card-label:hover {
  color: #5d5d5d;
  outline: 1px solid #5d5d5d;
}
.out-of-platform-label {
  // outline: 1px solid #464646;
  background-color: #555555;
  color: #e3e3e3;
  padding: 0 5px;
  width: fit-content;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  font-weight: 700;
}
.out-of-platform-label:hover {
  color: #bbbbbb;
  outline: 1px solid #0a0a0a;
  text-decoration: none;
}

.details-indicator {
  color: #42b983;
}

.sub-header {
  margin-top: -15px;
  margin-bottom: -10px;
}
.btn-unpaid {
  color: #42b983;
  background-color: white;
  border: white;
  margin: 0 10px;
  padding: 0px;
  width: fit-content;
  margin: 3px 3px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  line-height: 14px;
  text-align: center;
}
.awaiting {
  color: rgb(146, 146, 146);
  font-weight: 700;
  margin-right: 20px;
}

.cancelled {
  color: rgba(255, 53, 53, 0.868);
}

.btn-cash-sale {
  background-color: #42b9831a;
  color: #42b983;
  // border: 1px solid #42b983;

  padding: 0px;
  cursor: pointer;
  width: fit-content;
  margin: 3px 3px;
  margin-left: 8px;
  padding-left: 8px;
  padding-right: 8px;

  font-size: 14px;
  font-weight: 500;
  height: 22px;
  line-height: 14px;

  text-align: center;
  border-radius: 3px;

  line-height: 20px;
  text-decoration: none;
}

.btn-cash-sale:hover {
  background-color: #42b983;
  color: white;
  text-decoration: none;
}

.btn-accept {
  width: 90px;
  // text-align: center;
  // line-height: 20px;
  // text-decoration: none;
}
.btn-accept:hover {
  // text-decoration: none;
}

.btn-message {
  color: #107fff;
  background-color: #f1f1f1;
}
.btn-message:hover {
  color: white;
  background-color: #107fff;
}

.btn-reject {
  background-color: #e352522b;
  color: #e35252;
  width: 60px;
}

.btn-reject:hover {
  background-color: #e35252;
  color: white;
}

.new-order-and-accept-button-container {
  float: left;
  margin-left: 15px;
  width: calc(100% - 95px);
}

.accept-button-container {
  display: inline-flex;
  margin-left: 5px;
}

.reject-button-container {
  width: 80px;
}

.orders-header {
  font-weight: 400;
  margin-top: -10px;
}

.modalDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  pointer-events: none;
}
.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}
.modalDialog > div {
  max-width: 800px;
  width: 90%;
  position: relative;
  margin: 10% auto;
  padding: 16px;
  border-radius: 3px;
  background: #fff;
}
.close {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -15px;
  width: 34px;
  height: 34px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
  padding-top: 3px;
  z-index: 999;
  background: #fa3f3f;
}
.close:hover {
  background: #fa3f6f;
  text-decoration: none;
}

.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  top: 0px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
  padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

.activePaymentMethod {
  background-color: #42b983;
  color: white;
}
</style>

