<template>
  <div class="container">
    
    <div @click="refreshChat" style="height: 0px;">
    <!-- <Inbox  class="inbox-container"> </Inbox > -->
    </div>

    <div class="header-title">
      <i @click="goBack()" class="fas fa-chevron-left"></i>
      <span v-if="otherUserName"> {{ otherUserName }}</span>
      <span v-else> </span>
    </div>

    <h1
      @click="disableAlert"
      v-if="alert.type === 'error'"
      class="alert"
      v-bind:class="{
        'alert-success': alert.type == 'success',
        'alert-error': alert.type == 'error',
      }"
    >
      {{ alert.msg }}"
    </h1>

    <div class="app" v-if="alert.type === 'success'">
      <div class="wrapper">
        <div class="chat-area">
          <div class="chat-area-main">
            <!-- <Inbox class="inbox-container"> </Inbox > -->

            <div class="chat-msg"></div>

            <infinite-loading
              direction="top"
              @infinite="infiniteHandler"
            ></infinite-loading>

            <div v-if="messages">
              <div v-for="(message, index) in messages" :key="index">
                <div
                  class="chat-msg"
                  :class="{
                    owner: message.uid === user.data.uid,
                  }"
                >
                  <div class="chat-msg-profile">
                    <div class="chat-msg-date">
                      {{ message.datetime }}
                    </div>
                  </div>
                  <div class="chat-msg-content">
                    <div
                      class="chat-msg-text"
                      :class="{ 'chat-msg-text-error': message.error }"
                    >
                      <div v-if="message.images || message.documents">
                        <div v-for="image in message.images" :key="image">
                          <!-- <img :src="image" @click="isLargeImage = !isLargeImage" class="file-touchscreen" :class="{'large-image': isLargeImage}" width="80px" /> -->
                          <img :src="image" @click="isLargeImage = !isLargeImage" class="file-touchscreen" width="80px" />

                          <!-- <a class="lightbox" href="#">
                            <img :src="image" @click="isLargeImage = !isLargeImage" class="file-touchscreen" :class="{'large-image': isLargeImage}" width="80px" />
                          </a> 
                          <div class="lightbox-target" id="">
                            <img :src="image" @click="isLargeImage = !isLargeImage" class="file-touchscreen" />
                            <a class="lightbox-close" href="#"></a>
                          </div> -->


                        </div>
                        <div
                          v-for="(document, index) in message.documents"
                          :key="index"
                          class="chat-msg-text document-chat-msg-text"
                        >
                          <a :href="document.downloadURL">
                            {{ document.filename }}
                          </a>
                        </div>
                      </div>
                      <div v-else>
                        <p v-if="message.error === true">
                          ERROR: MESSAGE NOT SENT -
                        </p>
                        {{ message.text }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="chat-area-footer">
            <form @submit.prevent="sendMessage" class="chat-area-footer-form">
              <div class="upload-icon-div">
                <label for="imageinput" class="fileinput far fa-image"> </label>
              </div>

                <input
                type="file"
                @change="uploadImage($event, 'jpeg')"
                title=" "
                id="imageinput"
                class="btn btn-primary w-100 small-width"
                style="display: none"
                accept="image/*"
                ref="imageToUpload"
              /> 

              <label for="fileinput" class="fileinput fa-solid fa-file-arrow-up"> </label>
                <input
                type="file"
                @change="uploadImage($event, 'document')"
                title=" "
                id="fileinput"
                class="file-upload"
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                ref="documentToUpload"
              />

              <div
                class=""
                v-if="newMessage.images.length > 0"
                style="width: 50px; height: 40px; margin-right: 10px;"
              >
                <div
                  v-for="(image, index) in newMessage.images"
                  :key="image"
                >
                  <div class="img-wrap">
                    <img :src="image" class="attached-image-icon" />
                    <span
                      class="delete-img"
                      @click="
                        deleteImage('image', image, index, newMessage.images)
                      "
                    >
                      <svg
                        style="
                          z-index: 2;
                          margin-top: -1px;
                          margin-right: -4px;
                          opacity: 0.85;
                        "
                        height="13pt"
                        viewBox="0 0 512 512"
                        width="13pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
                          fill="#f44336"
                        />
                        <path
                          d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0"
                          fill="#fafafa"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>


              <div
                class=""
                v-else-if="newMessage.documents.length > 0"
                style="width: 50px; height: 40px; margin-right: 10px;"
              >
                <div
                  v-for="(document, index) in newMessage.documents"
                  :key="index"
                >
                  <div class="img-wrap">
                    <img src="https://static.thenounproject.com/png/2401719-200.png" class="attached-file-icon"/>
                    <span
                      class="delete-img"
                      @click="
                        deleteImage(
                          'document',
                          document.downloadURL,
                          index,
                          newMessage.documents
                        )
                      "
                    >
                      <svg
                        style="
                          z-index: 2;
                          margin-top: -1px;
                          margin-right: -5px;
                          opacity: 0.85;
                        "
                        height="13pt"
                        viewBox="0 0 512 512"
                        width="13pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
                          fill="#f44336"
                        />
                        <path
                          d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0"
                          fill="#fafafa"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div> 

              <div v-if="newMessage.images.length > 0" style="width: 100vw">
                <input
                  v-model="newMessage.text"
                  type="text"
                  placeholder="send a message ..."
                  disabled=true
                />
              </div>

              <div v-else style="width: 100vw">
                <input
                  v-model="newMessage.text"
                  type="text"
                  placeholder="send a message ..."
                />
              </div>
              
              <button type="submit" class="send-button">
                <i class="far fa-paper-plane" aria-hidden="true"> </i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import { db } from "../main.js";
import store from "../store";
import InfiniteLoading from "vue-infinite-loading";
import Inbox from "./Inbox.vue";
import moment from "moment";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.state.user.data) next({ name: "Login" });
    else next();
  },
  components: {
    Inbox: Inbox,
    InfiniteLoading,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      isLargeImage: false,
      alert: {
        type: null,
        msg: null,
      },
      discardedImages: [],
      otherUserId: this.$route.params.userID,
      otherUserName: null,
      otherUserDp: null,

      lastMessageError: false, //This bool is used to check if last sent message had an error in order to apply error css classes.

      messages: [],
      realtimeMessages: [],


      //infiniteId: "noFilter",
      numberOfLoaded: 0,
      infiniteId: 0,
      loadedRealtimeQuery: false,

      newMessage: {
        text: "",
        images: [],
        documents: [],
      },
    };
  },

  methods: {
    disableAlert() {
      this.$set(this.alert, "type", null);
      this.$set(this.alert, "msg", null);
    },

    //This function needs to be cleaned up.
    async refreshChat() {
      this.otherUserId = this.$route.params.userID;
      console.log(this.otherUserId);
      this.getProfile(this.otherUserId);
      console.log("refresh chat");
      this.numberOfLoaded = 5;
      this.loadedRealtimeQuery = false;

      await this.fetchMessages(this.otherUserId);
      await this.queryRealtimeMessages(this.messages[0].timestamp);
    },
    async fetchMessages() {
      var vm = this;

      const messagesRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("inbox")
        .doc(this.otherUserId)
        .collection("messages");

      const query = await messagesRef
        .where("timestamp", "!=", null)
        .orderBy("timestamp", "desc")
        .limit(this.numberOfLoaded);

      await query.get().then(async function (querySnapshot) {
        ////console.log(vm.messages);
        (vm.realtimeMessages = []), (vm.messages = []);
        await querySnapshot.forEach(async function (doc) {
          var message = await doc.data();
          message["isLargeImage"] = false;
          message.datetime = moment
            .unix(message.timestamp.seconds)
            .format("DD/MM/YY hh:mm A");
          //Add property datetime to extract the date and time from timestamps and add to order object
          await vm.messages.push(message);
        });
      });

      //This block of code sets the property error of the last sent message to true and resets the component's lastMessageError to false.
      if (vm.lastMessageError === true) {
        vm.messages[0].error = true;
        vm.lastMessageError = false;
      }
      vm.messages.reverse();
    },

    queryRealtimeMessages(latestTimestamp) {
      var vm = this;
      this.loadedRealtimeQuery = true;
      const messagesRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("inbox")
        .doc(this.otherUserId)
        .collection("messages");

      messagesRef
        .where("timestamp", ">", latestTimestamp)
        .onSnapshot(function (qSnapshot) {
          qSnapshot.forEach(async function (doc) {
            var message = doc.data();
            message["isLargeImage"] = false;
            message.datetime = moment
              .unix(message.timestamp.seconds)
              .format("DD/MM/YY hh:mm A");
              console.log(message.datetime);

            try {
              vm.realtimeMessages.push(message);
              await vm.fetchMessages();
            } catch (error) {
              //insert alert here
            }
          });
        });
    },

    async infiniteHandler($state) {
      console.log("infiniteHandkler");
      var lengthBeforeFetch = this.messages.length;
      var latestTimestampFromNonRealtimeQuery;
      this.numberOfLoaded += 5;
      await this.fetchMessages();
      if (this.messages.length !== lengthBeforeFetch) {
        $state.loaded();
      } else {
        $state.complete();
      }

      if (this.messages.length > 0) {
        latestTimestampFromNonRealtimeQuery = this.messages[0].timestamp;
      } else {
        latestTimestampFromNonRealtimeQuery = firebase.firestore.Timestamp.now();
      }

      if (this.loadedRealtimeQuery === false) {
        this.queryRealtimeMessages(latestTimestampFromNonRealtimeQuery);
      }
    },
    async sendMessage() {
      var batch = db.batch();
      var vm = this;

      var lastMessageTextOrImg = null;
      this.newMessage.uid = this.user.data.uid;

      if (
        this.newMessage.text === "" &&
        this.newMessage.images.length === 0 &&
        this.newMessage.documents.length === 0
      ) {
        return -1;
      }

      lastMessageTextOrImg = this.newMessage.text;

      if (this.newMessage.images) {
        if (this.newMessage.images.length === 0) {
          delete this.newMessage.images;
        } else {
          if (this.newMessage.text === "") {
            lastMessageTextOrImg = "[Image]";
          }
        }
      } else {
        delete this.newMessage.images;
      }

      if (this.newMessage.documents) {
        if (this.newMessage.documents.length === 0) {
          delete this.newMessage.documents;
        } else {
          if (this.newMessage.text === "") {
            lastMessageTextOrImg = "[Document]";
          }
        }
      } else {
        delete this.newMessage.documents;
      }

      //the message that I sent that goes to my  profile db
      var createMyMessageRef = db
        .collection("profile")
        .doc(this.user.data.uid)
        .collection("inbox")
        .doc(this.otherUserId);

      var myMessageCollectionRef = createMyMessageRef
        .collection("messages")
        .doc();

      const newId = myMessageCollectionRef.id;

      /* START Validate name and dp so message always sends */
      var othersName, othersDp, senderName, senderDp;
      if (!this.otherUserName || this.otherUserName === undefined) {
        othersName = null;
      } else {
        othersName = this.otherUserName;
      }
      if (!this.otherUserDp || this.otherUserDp === undefined) {
        othersDp = null;
      } else {
        othersDp = this.otherUserDp;
      }
      if (!this.user.profile.name || this.user.profile.name === undefined) {
        senderName = null;
      } else {
        senderName = this.user.profile.name;
      }
      if (!this.user.profile.dpUrl || this.user.profile.dpUrl === undefined) {
        senderDp = null;
      } else {
        senderDp = this.user.profile.dpUrl;
      }

      /* END Validate name and dp so message always sends*/

      //Sending message to yourself should only send one message
      this.newMessage.lastMessageText = lastMessageTextOrImg;
      this.newMessage.lastMessageBy = this.user.data.uid;
      this.newMessage.messageId = newId;
      this.newMessage.receiverName = othersName;
      this.newMessage.receiverId = this.otherUserId;
      this.newMessage.receiverDpUrl = othersDp;
      this.newMessage.senderId = this.user.data.uid;
      this.newMessage.senderName = senderName;
      this.newMessage.senderDpUrl = senderDp;
      this.newMessage.received = "false";

      batch.set(
        createMyMessageRef,
        {
          lastMessageText: lastMessageTextOrImg,
          lastMessageBy: this.user.data.uid,
          othersId: this.otherUserId,
          othersName: othersName,
          othersDp: othersDp,
        },
        { merge: true }
      );

      batch.set(myMessageCollectionRef, this.newMessage);
    

      await batch
        .commit()
        .then(async () => {
          await console.log("Success. Chat Batch is on firestore now :)");
        })
        .catch((error) => {
          vm.lastMessageError = true;
          console.log("error committing message batch. Error: " + error);
        });

      this.newMessage = {
        text: "",
        images: [],
        documents: [],
        uid: this.user.data.uid,
        isLargeImage: false,
        datetime: null,
      };
      return "done";
    },

    uploadImage(e, fileType) {
      var vm = this;
      var uuid = require("uuid");
      var id = uuid.v4();
      var metadata, storageRef;

      if (e.target.files[0]) {
        var file = e.target.files[0];
        //console.log(file);

        // Create the file metadata
        if (fileType === "jpeg") {
          storageRef = firebase
            .storage()
            .ref("chat/" + this.user.data.uid + "/" + id);
          metadata = { contentType: "image/jpeg" };
        } else {
          //if document
          storageRef = firebase
            .storage()
            .ref("chat/documents/" + this.user.data.uid + "/" + id);
          metadata = {
            contentType:
              ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf",
            customMetadata: {
              originalName: file.name,
            },
          };
        }

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                //console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                //console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            console.log(error);
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              //...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                if (fileType === "jpeg") {
                  vm.newMessage.images.push(downloadURL);
                } else {
                  //if document
                  vm.newMessage.documents.push({
                    downloadURL: downloadURL,
                    filename: file.name,
                  });
                }
              });
          }
        );
      }
    },

    deleteImage(fileType, img, index, imgArr) {
      var image = firebase.storage().refFromURL(img);
      image
        .delete()
        .then(() => {
          console.log("image deleted");
        })
        .catch((error) => {
          console.log("error deleting image: " + error);
        });
      imgArr.splice(index, 1);
      if (fileType === "image") {
        this.$refs.imageToUpload.value = "";
      } else {
        //if document
        this.$refs.documentToUpload.value = "";
      }
    },
    getProfile(userID) {
      var docRef = db.collection("profile").doc(userID);
      var vm = this;
      docRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            vm.otherUserName = await doc.data().name;
            vm.otherUserDp = await doc.data().dpUrl;
            vm.$set(vm.alert, "type", "success");
          } else {
            vm.$set(vm.alert, "type", "error");
            vm.$set(vm.alert, "msg", "This user does not exist.");
          }
        })
        .catch((error) => {
          vm.$set(vm.alert, "type", "error");
          vm.$set(vm.alert, "msg", "This user does not exist. Error: " + error);
        });
    },

    goBack() {
      window.history.back();
    },
  },
  mounted() {
    this.getProfile(this.otherUserId);
  },
};
</script>

<style lang="scss" scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/5.4.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.file-touchscreen {
 -webkit-touch-callout: initial //this shows the add to photos on iphone
}
.image {
  
}
.large-image {
 height: 400px;
 width: 400px;
 -webkit-touch-callout: initial; //this shows the add to photos on iphone
}
.alert {
  margin: 5px 0px;
  padding: 8px;
  border-radius: 5px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 1px;

  top: 20px;
  text-align: center;
  position: fixed;
  display: none;
  margin-top: 105px;
  z-index: 100;
  width: 350px;
  left: calc(50vw - 175px);
  right: calc(50vw - 175px);
}
.alert:hover {
  cursor: pointer;
}
.alert:before {
  padding-right: 12px;
}
.alert:after {
  /* content: '\f00d'; */
  font-family: "FontAwesome";
  float: left;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffe6e6;
  border: 1px solid darken(#ffbaba, 15%);
  display: block;
}
.alert-error:before {
  content: "\f071";
  font-family: "FontAwesome";
  padding-right: 3px;
}

.alert-success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid darken(#dff2bf, 15%);
  display: block;
}
.alert-success:before {
  content: "\f058";
  font-family: "FontAwesome";
}

main {
  height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

form,
#form {
  height: fit-content;
  position: fixed;
  bottom: 0;
  background-color: rgb(240, 240, 240);
  background-color: white;
  width: 100%;
  display: flex;
  font-size: 1.5rem;
}
// @media (max-width: 420px){
//   form,
//   #form {
//     bottom: 16px;
//   }
// }

form button,
#form button {
  width: 20%;
  background-color: rgb(56, 56, 143);
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

ul,
li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #42b983;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.img-wrap {
  position: relative;
}
.img-wrap span.delete-img {
  position: absolute;
  top: -5px;
  right: 1px;
}
.img-wrap span.delete-img:hover {
  cursor: pointer;
}

div {
  margin-bottom: 0px;
  position: relative;
}

input[type="number"] {
  width: 100px;
}

input + span {
  padding-right: 30px;
}

input:invalid + span:after {
  position: absolute;
  content: "✖";
  padding-left: 5px;
}

input:valid + span:after {
  position: absolute;
  content: "✓";
  padding-left: 5px;
}

/* Set the size of the div element that contains the map */
#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

.small-width {
  width: 70px;
}

#fileinput,
.fileinput {
  // color: #0b93f6;
  color: #42b983c0;
}
.fa-image {
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 300;
}

.fa-file-arrow-up {
  font-size: 27px;
  margin-left: 2px;
  margin-right: 13px;
  margin-top: 14px;
}

div {
  margin-bottom: 10px;
  color: white;
}
/*
img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}
*/

.chat-container {
  // overflow-y: scroll;
  overflow-y: hidden;
  height: 400px;
  max-width: 100vw;
}

// start of ChatComponent style

// @charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500&amp;display=swap");

.fa-chevron-left {
  font-size: 18px;
  font-weight: 1000;
  color: #42b983c0;
  margin-right: 10px;
}

.container {
  margin: 0px;
  padding: 0px;
  padding-top: 33px;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgb(252, 252, 252);
}
.header-title {
  // border: 1px solid black;
  font-family: Google Sans;
  font-size: 21px;
  font-weight: 400;
  // height: fit-content;
  height: 45px;
  margin: 0px;
  padding-top: 15px;
  padding-left: 10px;
  color: rgb(119, 119, 119);
  background-color: white;
  // background: linear-gradient(0.25turn, rgb(253, 253, 253), 75%, #18b36d4f);
  // border-bottom: 1px solid rgb(245, 245, 245);
  box-shadow: 0 4px 5px -5px rgba(51, 51, 51, 0.3);
  z-index: 9;
}

// start of ChatComponent style

// @charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Manrope:300,400,500,600,700&display=swap&subset=latin-ext");

[data-theme="purple"] {
  --theme-color: #9f7aea;
  --button-color: #9f7aea;
  --button-bg-color: rgba(159, 122, 234, 0.12);
}

[data-theme="green"] {
  --theme-color: #38b2ac;
  --button-color: #38b2ac;
  --button-bg-color: rgba(56, 178, 171, 0.15);
}

[data-theme="orange"] {
  --theme-color: #ed8936;
  --button-color: #ed8936;
  --button-bg-color: rgba(237, 137, 54, 0.12);
}

.blue {
  background-color: #0086ff;
}

.purple {
  background-color: #9f7aea;
}

.green {
  background-color: #38b2ac;
}

.orange {
  background-color: #ed8936;
}

* {
  outline: none;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  background-color: #e5ecef;
  font-family: Google Sans;
  color: #273346;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.app {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  // background-color: #fff;
  width: 100vw;
  margin: 0 auto;
  // overflow: hidden;
  position: absolute;
  padding-bottom: 85px;
  bottom: -85px;
  top: 85px;
}

// .header {
//   height: 50px;
//   width: 100%;
//   border: 1px solid #eef2f4;
//   display: flex;
//   align-items: center;
//   padding: 0 20px;
// }

.wrapper {
  width: 100%;
  display: flex;
  // flex-grow: 1;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  height: 100vh;
  padding-bottom: 10px;
  bottom: -10px;
}

.conversation-area,
.detail-area {
  width: 450px;
  flex-shrink: 0;
  height: 100%;
}

.detail-area {
  border-left: 1px solid #eef2f4;
  margin-left: auto;
  padding: 30px 30px 0 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.chat-area {
  // flex-grow: 1;
  background-color: rgba(255, 255, 255, 0);
  bottom: 0px;
  padding-bottom: 100px;
  margin-bottom: -100px;
}

.search-bar {
  // height: 40px;
  z-index: 3;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #eef2f4;
  border: 1px solid #eef2f4;
  position: sticky;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
}
.search-bar input {
  height: 100%;
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  color: #273346;
  padding: 0 54px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23c1c7cd'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 25px 48%;
  font-family: Google Sans;
  font-size: 17px;
}
.search-bar input::placeholder {
  color: #a2a2a2;
}
.active-chatter {
  height: 40px;
  z-index: 3;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #eef2f4;
  border-top: 1px solid #eef2f4;
  position: sticky;
  display: flex;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: #808080;
  font-size: 20px;
  font-family: Google Sans;
  position: absolute;
  top: 0;
}
.active-chatter span {
  margin-top: 10px;
  margin-left: 20px;
}

.logo {
  color: #0086ff;
  width: 38px;
  flex-shrink: 0;
  opacity: 1;
}
.logo svg {
  width: 100%;
}

.chat-bubble-logo {
  color: #0086ff;
  width: 38px;
  flex-shrink: 0;
  opacity: 1;
  margin-left: 10px;
}
.chat-bubble-logo svg {
  width: 100%;
}

.user-settings {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  flex-shrink: 0;
}
.user-settings > * + * {
  margin-left: 14px;
}

.dark-light {
  width: 22px;
  height: 22px;
  color: #9fa7ac;
  flex-shrink: 0;
}
.dark-light svg {
  width: 100%;
  fill: transparent;
  transition: 0.5s;
}

.user-profile {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.settings {
  color: #9fa7ac;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
}

.conversation-area {
  border-right: 1px solid #eef2f4;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
}

.msg-profile {
  min-width: 60px;
  min-height: 50px;
  max-width: 50px;
  max-height: 60px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 15px;
}
.msg-profile.group {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef2f4;
}
.msg-profile.group svg {
  width: 60%;
}

.msg {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  border: 1px solid rgb(241, 241, 241);
  border-top: none;
  font-family: Google Sans;
}
.msg:hover {
  background-color: rgba(238, 242, 244, 0.4);
}
.msg.active {
  background: linear-gradient(
    to right,
    rgba(238, 242, 244, 0.4) 0%,
    rgba(238, 242, 244, 0) 100%
  );
  border-left: 4px solid #0086ff;
}
.msg.online:before {
  content: "";
  position: absolute;
  background-color: #23be7e;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 2px solid #fff;
  left: 50px;
  bottom: 19px;
}

.msg-username {
  margin-bottom: 4px;
  // font-weight: 600;
  font-size: 16px;
}

.msg-detail {
  overflow: hidden;
}

.msg-content {
  // font-weight: 500;
  font-size: 13px;
  display: flex;
}

.msg-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #969eaa;
}

.msg-date {
  font-size: 14px;
  color: #c0c7d2;
  margin-left: 3px;
}
.msg-date:before {
  content: "•";
  margin-right: 2px;
}

.add {
  position: sticky;
  bottom: 25px;
  background-color: #0086ff;
  width: 60px;
  height: 60px;
  border: 0;
  border-radius: 50%;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'%3e%3cpath d='M12 5v14M5 12h14'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 28px;
  box-shadow: 0 0 16px #0086ff;
  margin: auto auto -55px;
  flex-shrink: 0;
  z-index: 1;
  cursor: pointer;
}

.overlay {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 340px;
  flex-shrink: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 65%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 80px;
}

.chat-area {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.chat-area-header {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;

  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 78%,
    rgba(255, 255, 255, 0) 100%
  );
}
.chat-area-profile {
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.chat-area-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 280px;
}

@media (max-width: 780px) {
  .chat-area-title {
    margin-left: 0px;
  }
}

.chat-area-main {
  flex-grow: 1;
  padding-bottom: 20px;
}

.chat-msg-img {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.chat-msg-profile {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: -20px;
  position: relative;
}

.chat-msg-date {
  position: absolute;
  left: calc(100% + 12px);
  bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: #c0c7d2;
  white-space: nowrap;
}

.chat-msg {
  display: flex;
  // padding: 0 20px 45px;
  padding-bottom: 15px;
}
.chat-msg-content {
  margin-left: 12px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
}
.chat-msg-text {
  background-color: #96968c;
  padding: 5px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-radius: 10px 10px 10px 0;
  line-height: 1.5;
  font-size: 14px;
  font-family: Google Sans;
  color: black;
  // font-weight: 500;
}
.chat-msg-text + .chat-msg-text {
  // margin-top: 10px;
}

.document-chat-msg-text{
  width: fit-content;
  text-decoration: underline;
  color: #ffffff;
}

.owner {
  flex-direction: row-reverse;
}
.owner .chat-msg-content {
  margin-left: 0;
  margin-right: 12px;
  align-items: flex-end;
}
.owner .chat-msg-text {
  background-color: #0086ff;
  color: #fff;
  border-radius: 10px 10px 0 10px;
}
.owner .chat-msg-date {
  left: auto;
  right: calc(100% + 12px);
}

.owner .chat-msg-text-error {
  background-color: red;
}

.chat-msg-text img {
  max-width: 300px;
  width: 100%;
}

.chat-area-footer {
  width: 100vw;
  background-color: white;
  // height: 50px;
  height: fit-content;
  height: 1000px;
  z-index: 9;
}
.chat-area-footer-form {
  border-top: 1px solid #dee2e4;
  z-index: 9999999999;
}
.upload-icon-div {
  position: relative;
  // border: 1px solid black;
  min-width: 55px;
}
.upload-icon {
  height: 39px;
  width: 39px;
  cursor: pointer;
  position: absolute;
  margin-left: 5px;
  opacity: 0.75;
}

.chat-area-footer input {
  border: none;
  color: #273346;
  background-color: white;
  padding: 6px;
  border-radius: 4px;
  font-size: 16px;
  // margin: 0 12px;
  border: 1px solid #ddd;
  height: 40px;
  margin-top: 10px;
}
.chat-area-footer input::placeholder {
  color: #a2a2a2;
}
.send-button {
  // float: right;
  max-width: 60px;
  width: 50px;
  line-height: 15px;
  color: #42b983c0;
  background-color: rgb(0, 0, 0, 0);
  font-size: 25px;
  margin: auto;
  padding: 0 12.5px;
}

.detail-area-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detail-area-header .msg-profile {
  margin-right: 0;
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.detail-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.detail-subtitle {
  font-size: 12px;
  font-weight: 600;
  color: #c0c7d2;
}

.detail-button {
  border: 0;
  background-color: #f0f7ff;
  padding: 10px 14px;
  border-radius: 5px;
  color: #0086ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
}
.detail-button svg {
  width: 18px;
  margin-right: 10px;
}
.detail-button:last-child {
  margin-left: 8px;
}

.detail-buttons {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.detail-area input {
  background-color: transparent;
  border: none;
  width: 100%;
  color: #273346;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23c1c7cd'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 100%;
  font-family: Google Sans;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #eef2f4;
  padding: 14px 0;
}
.detail-area input::placeholder {
  color: #919ca2;
}

.detail-changes {
  margin-top: 40px;
}

.detail-change {
  color: #919ca2;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #eef2f4;
  padding: 14px 0;
  display: flex;
}
.detail-change svg {
  width: 16px;
  margin-left: auto;
}

.colors {
  display: flex;
  margin-left: auto;
}

.color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
}
.color.selected {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='%23fff' stroke-width='3' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.color:not(:last-child) {
  margin-right: 4px;
}

.detail-photo-title {
  display: flex;
  align-items: center;
}
.detail-photo-title svg {
  width: 16px;
}

.detail-photos {
  margin-top: 30px;
  text-align: center;
}

.detail-photo-title {
  color: #919ca2;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}
.detail-photo-title svg {
  margin-right: 8px;
}

.detail-photo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  grid-template-rows: repeat(3, 60px);
}
.detail-photo-grid img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  object-position: center;
}

.view-more {
  color: #0086ff;
  font-weight: 600;
  font-size: 15px;
  margin: 25px 0;
}

.follow-me {
  text-decoration: none;
  font-size: 14px;
  width: calc(100% + 60px);
  margin-left: -30px;
  display: flex;
  align-items: center;
  margin-top: auto;
  overflow: hidden;
  color: #9c9cab;
  padding: 0 20px;
  height: 52px;
  flex-shrink: 0;
  position: relative;
  justify-content: center;
}
.follow-me svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.follow-text {
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.follow-me:hover .follow-text {
  transform: translateY(100%);
}

.dark-mode .search-bar input,
.dark-mode .detail-area input {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%236f7073'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
}
.dark-mode .dark-light svg {
  fill: #ffce45;
  stroke: #ffce45;
}
.dark-mode .chat-area-group span {
  color: #d1d1d2;
}

.chat-area-group {
  flex-shrink: 0;
  display: flex;
}
.chat-area-group * {
  border: 2px solid #fff;
}
.chat-area-group * + * {
  margin-left: -5px;
}
.chat-area-group span {
  width: 32px;
  height: 32px;
  background-color: #f0f7ff;
  color: #0086ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 1120px) {
  .detail-area {
    display: none;
  }
}
@media (max-width: 780px) {
  .conversation-area {
    display: none;
  }

  .search-bar {
    margin-left: 0;
    flex-grow: 1;
  }
  .search-bar input {
    padding-right: 10px;
  }
}

@media (min-width: 1120px) {
  .inbox-container {
    height: 100vh !important;
    width: 60vh !important;
    z-index: 2147000000 !important;
  }
}

// end of ChatComponent style


// expanded image style

a.lightbox img {
height: 150px;
width: 150px;
border: 3px solid white;
box-shadow: 0px 0px 8px rgba(0,0,0,.3);
// margin: 94px 20px 20px 20px;
}

/* Styles the lightbox, removes it from sight and adds the fade-in transition */
.lightbox-target {
position: fixed;
top: -80%;
left: max(10%, calc((100% - 530px)/2));
width: 80%;
max-width: 530px;
max-height: 75%;
margin: auto;
outline: 1px solid black;
background: rgba(0,0,0,.7);
opacity: 0;
-webkit-transition: opacity .5s ease-in-out;
-moz-transition: opacity .5s ease-in-out;
-o-transition: opacity .5s ease-in-out;
transition: opacity .5s ease-in-out;
overflow: hidden;
}

/* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

.lightbox-target img {
margin: auto;
position: absolute;
width: 100%;
height: 100%;
max-height: 500px;
max-width: 340px;
top: 0;
left:0;
right:0;
bottom: 0;
max-height: 0%;
max-width: 0%;
border: 3px solid white;
box-shadow: 0px 0px 8px rgba(0,0,0,.3);
box-sizing: border-box;
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out;
}

/* Styles the close link, adds the slide down transition */

a.lightbox-close {
display: block;
width:50px;
height:50px;
box-sizing: border-box;
background: #42b9838f;
color: black;
text-decoration: none;
position: absolute;
top: -80px;
right: 0;
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out;
}
a.lightbox-close:hover {
  background: #42b983;
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:before {
content: "";
display: block;
height: 30px;
width: 1.5px;
background: black;
position: absolute;
left: 26px;
top:10px;
-webkit-transform:rotate(45deg);
-moz-transform:rotate(45deg);
-o-transform:rotate(45deg);
transform:rotate(45deg);
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:after {
content: "";
display: block;
height: 30px;
width: 1.5px;
background: black;
position: absolute;
left: 26px;
top:10px;
-webkit-transform:rotate(-45deg);
-moz-transform:rotate(-45deg);
-o-transform:rotate(-45deg);
transform:rotate(-45deg);
}

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

.lightbox-target:target {
opacity: 1;
top: 0;
bottom: 0;
}

.lightbox-target:target img {
max-height: 100%;
max-width: 100%;
}

.lightbox-target:target a.lightbox-close {
top: 0;
}

.file-upload-button{
  width: 40px;
  margin-right: 10px;
  float: left;
}
.file-upload{
  display: none;
}
.attached-file-icon {
  margin-top: 12px;
  width: 33px;
  height: 33px;
  color: #42b983;
}

.attached-image-icon{
  margin-top: 10px;
  width: 36px;
  height: 36px;
}
</style>