<template>
  <div >
    <div v-if="results.length > 0" >
      <ul class="sui-results-container search-section__search-results">
        <li
          v-for="result in results"
          :key="result.id.raw"
          class="search-section__search-result"
        >
          <SearchResult
            :today="today"
            :result="result"
            :searchInputValue="searchInputValue"
          />
        </li>
      </ul>
    </div>
    <div v-else>
      No results. Please change your search query, filters, address and radius, or city to broaden your search.
    </div>
  </div>
</template>

<script>
import SearchResult from "./SearchResult";

export default {
  components: {
    SearchResult,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
    searchInputValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      today: null,
    };
  },
  methods: {
    setToday() {
      var now = new Date();
      //console.log(today);
      this.today = now.getDay();
      // console.log(this.today);
    },
  },
  created() {
    ////console.log(this.result);
    this.setToday();
  },
};
</script>

<style>
.sui-results-container{
  margin: auto;
  padding: auto;
}
.search-section__search-results{
  margin: auto;
  padding: auto;
  padding-top: 10px;
}
ul {
  list-style-type: none;
}
.search-section__search-results {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 1;
  /* overflow-y: auto;
  height:100%; */
}
/* .set-height{
  height: 56.5vh;
  z-index: 9999999;
} */

.search-section__search-result {
  max-width: 400px;
  margin: auto;
  background: rgba(255, 255, 255, 0);
  z-index: 1;
}
@media (min-width: 850px)  {
  .search-section__search-result {
    max-width: 400px;
  }
}
@media (min-width: 1250px)  {
.search-section__search-result {
  max-width: 47%;
}
}
</style>
